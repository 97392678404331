import { observer } from "mobx-react";
import * as React from "react";
import Sys from "../../core/Sys";
import ComboBoxOption from "../../coreui/ComboBoxOption";
import { ErrorBadge } from "../../coreui/ErrorBadge";
import Icon from "../../coreui/Icon";
import { TableChildProps } from "../../coreui/Table";
import {
  CellFocusUtil,
  FocusCellRendererParams,
} from "../../coreui/table/CellFocusUtil";
import { CellUtil } from "../../coreui/table/CellUtil";
import PaneRow, { RuntimeWidget } from "../../models/PaneRow";
import { AccessLevel } from "../AccessLevel";
import { FunctionName } from "../TableSummary";
import { GridColumnConfigProperties } from "./GridColumn";

interface ConfigProperties extends FocusCellRendererParams {
  dataId: string;
  name: string;
  propagated: TableChildProps;
}

interface State {
  isErrorBadgeOpen?: boolean;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
  selectedDisplayValue: string;
  selectedValue: string | null;
}

export class RelationshipComboBoxColumnDisplay extends React.Component<
  ConfigProperties,
  State
> {
  public static readonly widgetType: string = "RelationshipComboBoxColumn";
  private readonly componentId: string;
  private readonly errorMessageId: string;

  public static getFilterText(
    column: GridColumnConfigProperties,
    propagated: TableChildProps,
    row: PaneRow
  ): string {
    const widget = row.getWidgetT<string | null, RuntimeProperties>(
      column.name
    );

    const selectedOption: ComboBoxOption = {
      display: widget.properties.selectedDisplayValue,
      value: widget.properties.selectedValue,
    };

    const options = propagated.parentTable.getRelationshipComboBoxOptions(
      column.name,
      selectedOption
    );

    const currentOption = options.find((o) => o.value === widget.value);

    return currentOption ? currentOption.display : "";
  }

  public static getSummaryValue(
    runtimeData: RuntimeWidget[],
    configProperties: ConfigProperties,
    functionName: FunctionName
  ): string | null {
    return runtimeData.filter((d) => d.value).length.toString();
  }

  public constructor(props: ConfigProperties) {
    super(props);

    this.state = { isErrorBadgeOpen: false };

    CellFocusUtil.subscribeToCellKeyboardFocusedEvent(
      props,
      this.onCellFocus,
      this.onCellBlur
    );

    this.componentId = `relationship-combobox-column-display${Sys.nextId}`;
    this.errorMessageId = `${this.componentId}-error-message`;

    props.eGridCell.addEventListener("keydown", this.onCellKeyDown);
  }

  private onCellBlur = (): void => {
    this.setState({ isErrorBadgeOpen: false });
  };

  private onCellFocus = (): void => {
    this.setState({ isErrorBadgeOpen: true });
  };

  private onCellKeyDown = (event: KeyboardEvent): void => {
    CellUtil.customizeGridNavigation(event, this.props);

    if (event.key === " " && !this.props.api.getEditingCells().length) {
      this.props.api.startEditingCell({
        colKey: this.props.column!.getColId(),
        rowIndex: this.props.rowIndex,
      });

      event.preventDefault();
      event.stopPropagation();
    }
  };

  private onCloseErrorBadge = (): void => {
    this.setState({ isErrorBadgeOpen: false });
  };

  private onOpenErrorBadge = (): void => {
    this.setState({ isErrorBadgeOpen: true });
  };

  public componentWillUnmount(): void {
    CellFocusUtil.unsubscribeToCellKeyboardFocusedEvent(
      this.props,
      this.onCellFocus,
      this.onCellBlur
    );
    this.props.eGridCell.removeEventListener("keydown", this.onCellKeyDown);
  }

  public render(): React.ReactNode {
    const row = this.props.data!;

    const widget = row.getWidgetT<string | null, RuntimeProperties>(
      this.props.name
    );

    const selectedOption: ComboBoxOption = {
      display: widget.properties.selectedDisplayValue,
      value: widget.properties.selectedValue,
    };

    const table = this.props.propagated.parentTable;
    const options = table.getRelationshipComboBoxOptions(
      this.props.name,
      selectedOption
    );

    let rowErrors: string | undefined = this.props.propagated.parentTable
      .getTable()
      .rowErrorMessages.get(row.rowKey);

    let value = null;

    if (widget.value) {
      const currentOption = options.find((o) => o.value === widget.value);
      value = currentOption ? currentOption.display : null;
    }

    CellUtil.setReadOnlyAttribute(
      this.props.eGridCell,
      widget.properties.accessLevel <= AccessLevel.actionable
    );

    const errors: string[] = row.hasChanges(this.props.name)
      ? []
      : [...widget.properties.businessErrors];

    let result: React.ReactNode = null;

    if (widget.properties.accessLevel >= AccessLevel.enterable) {
      if (errors.length) {
        CellUtil.setAriaAttributes(
          this.props.eGridCell,
          this.errorMessageId,
          true
        );

        rowErrors = `${errors.join(". ")}. ${rowErrors || ""}`;

        result = (
          <ErrorBadge
            errors={errors}
            isShort={row.isNew && table.isDocumentGrid}
            onClose={this.onCloseErrorBadge}
            onOpen={this.onOpenErrorBadge}
            open={this.state.isErrorBadgeOpen}
            suppressEdit={true}
          >
            <div
              aria-describedby={this.errorMessageId}
              aria-errormessage={this.errorMessageId}
              aria-invalid="true"
              role="textbox"
              style={{
                alignItems: "center",
                display: "flex",
                flex: "auto",
                margin: "0px 20px 0px 24px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  flex: "auto",
                  lineHeight: "normal",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {value}
              </div>
              <div className="cx-print">
                <Icon
                  fixedWidth
                  icon="fas fa-caret-down"
                  style={{
                    fontSize: 16,
                    marginLeft: ".4em",
                    marginRight: 1,
                  }}
                />
              </div>
            </div>
            <div id={this.errorMessageId} style={{ display: "none" }}>
              {rowErrors}
            </div>
          </ErrorBadge>
        );
      } else {
        CellUtil.setAriaAttributes(
          this.props.eGridCell,
          this.errorMessageId,
          !!rowErrors
        );

        result = (
          <div
            aria-describedby={rowErrors ? this.errorMessageId : undefined}
            aria-errormessage={rowErrors ? this.errorMessageId : undefined}
            aria-invalid={rowErrors ? true : false}
            role="textbox"
            style={{
              alignItems: "center",
              display: "flex",
              height: "100%",
              margin: "0 21px 0 24px",
            }}
          >
            <div
              style={{
                flex: "auto",
                lineHeight: "normal",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {value}
            </div>
            <div className="cx-print">
              <Icon
                fixedWidth
                icon="fas fa-caret-down"
                style={{
                  fontSize: 16,
                  height: "auto",
                  marginLeft: ".4em",
                }}
              />
            </div>
          </div>
        );
      }
    } else {
      CellUtil.setAriaAttributes(
        this.props.eGridCell,
        this.errorMessageId,
        !!rowErrors
      );

      result = (
        <div
          aria-describedby={rowErrors ? this.errorMessageId : undefined}
          aria-errormessage={rowErrors ? this.errorMessageId : undefined}
          aria-invalid={rowErrors ? true : false}
          role="textbox"
          style={{
            alignItems: "center",
            display: "flex",
            height: "100%",
            margin: "0px 24px",
          }}
        >
          <div
            style={{
              lineHeight: "normal",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "100%",
            }}
          >
            {value}
          </div>
        </div>
      );
    }

    return result;
  }
}

export default observer(RelationshipComboBoxColumnDisplay);

import { InputAdornment } from "@mui/material";
import * as React from "react";
import { SiteSearchInfo } from "../../config";
import Localization from "../../core/Localization";
import Button from "../../coreui/Button";
import ComboBoxOption from "../../coreui/ComboBoxOption";
import Icon from "../../coreui/Icon";
import Select, { SelectRef } from "../../coreui/Select";
import TextField from "../../coreui/TextField";

interface CollapseButtonProps {
  onCollapse: () => void;
}

interface SearchCriteriaProps {
  helperText?: string;
  mandatory?: boolean;
  onCriteriaChange: (value: string) => void;
  onSearch: () => void;
  value: string;
}

interface SearchSelectProps {
  onSelect: (search: SiteSearchInfo | null) => void;
  selectedSearch: SiteSearchInfo | null;
  siteSearches: SiteSearchInfo[];
}

export function CollapseButton(props: CollapseButtonProps): JSX.Element {
  return (
    <Button
      aria-label={Localization.getBuiltInMessage("siteCriteriaCollapseSearch")}
      fab
      onClick={() => props.onCollapse()}
      size="small"
    >
      <Icon icon="fas fa-times" />
    </Button>
  );
}

export function SearchCriteriaField(props: SearchCriteriaProps): JSX.Element {
  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key !== "Enter") {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    props.onSearch();
  };

  const onValueChange = (value: string): void => {
    props.onCriteriaChange(value);
  };

  return (
    <TextField
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" style={{ marginTop: -4 }}>
            <Button
              aria-label={Localization.getBuiltInMessage("search")}
              color="dark"
              icon="fas fa-search"
              onClick={() => props.onSearch()}
              size="small"
            />
          </InputAdornment>
        ),
      }}
      helperText={props.helperText}
      label={Localization.getBuiltInMessage("search")}
      name="SiteCriteria-Search"
      onKeyPress={onKeyPress}
      onValueChange={onValueChange}
      required={props.mandatory}
      role="group"
      sx={!!props.helperText ? { marginBottom: { md: "-24px" } } : undefined}
      value={props.value}
      variant="filled"
    />
  );
}

export function SearchSelect(props: SearchSelectProps): JSX.Element {
  const selectRef = React.useRef<SelectRef>(null);

  React.useEffect(() => {
    setTimeout(() => selectRef.current!.focus());
  }, []);

  const onValueChange = (option: ComboBoxOption) => {
    const selectedSearches: SiteSearchInfo[] = props.siteSearches.filter(
      (s) => s.url === option.value
    );
    if (selectedSearches.length === 0) {
      props.onSelect(null);
    } else {
      props.onSelect(selectedSearches[0]);
    }
  };

  const options: ComboBoxOption[] = props.siteSearches.map((s) => ({
    display: s.description,
    value: s.url,
  }));

  return (
    <Select
      label={Localization.getBuiltInMessage("siteCriteriaSearchFor")}
      onValueChange={onValueChange}
      options={options}
      ref={selectRef}
      value={props.selectedSearch !== null ? props.selectedSearch.url : null}
    />
  );
}

import {
  colors,
  FormControl,
  FormHelperText,
  FormLabel,
  RadioGroup,
  styled,
} from "@mui/material";
import { action, autorun, Lambda, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { DialogChildProps } from "../config";
import { useWidth, WidthProps } from "../core/Responsive";
import Sys from "../core/Sys";
import ComboBoxOption from "../coreui/ComboBoxOption";
import getFieldHelperText from "../coreui/FieldHelperText";
import Radio from "../coreui/Radio";
import { TableVerticalLayoutProps } from "../coreui/Table";
import TextField from "../coreui/TextField";
import PaneRow from "../models/PaneRow";
import RoundTripService from "../services/RoundTripService";
import ErrorsStore from "../stores/ErrorsStore";
import SubPaneControlStore from "../stores/SubPaneControlStore";
import { AccessLevel } from "./AccessLevel";

interface ConfigProperties {
  controlKey: string | null;
  controlledPaneKeysByValue: object | null;
  dataId: string;
  disabledHelpText: string;
  helperText: string;
  isPaneController: boolean;
  label: string;
  name: string;
  propagated?: DialogChildProps & TableVerticalLayoutProps;
  roundTripOnChange: boolean;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
  options: ComboBoxOption[];
  selectedDisplayValue: string;
  selectedValue: string | null;
  showAsMandatory: boolean;
  showDisabledHelp: boolean;
}

const RadioSelectFormControl = styled(FormControl)({
  "&:hover": { backgroundColor: "transparent" },
  backgroundColor: "transparent",
});

export class RadioSelect extends React.Component<
  ConfigProperties & WidthProps
> {
  private readonly componentId: string;
  private componentName: string;
  private config: ConfigProperties;
  private dataId: string = "";
  private dialogRowKey?: string;
  private disposeObserve: Lambda;
  private readonly helperTextId: string;
  private readonly labelId: string;
  private name: string = "";
  private rowKey?: string;

  public constructor(props: ConfigProperties & WidthProps) {
    super(props);

    makeObservable<
      RadioSelect,
      "dataId" | "name" | "onChange" | "syncDerivedWithProps"
    >(this, {
      dataId: observable,
      name: observable,
      onChange: action,
      syncDerivedWithProps: action,
    });

    this.componentId = `radio-select-${Sys.nextId}`;
    this.helperTextId = `${this.componentId}-helper-text`;
    this.labelId = `${this.componentId}-label`;

    this.syncDerivedWithProps();
  }

  private announceErrors(errors: string[]): void {
    if (errors.length > 0) {
      Sys.announce(errors.join("; "));
    }
  }

  private onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value !== "" ? event.target.value : null;

    const row = PaneRow.get(this.dataId, this.rowKey);
    const widget = row!.getWidgetT<string | null, RuntimeProperties>(this.name);

    const oldValue = widget.value;

    ErrorsStore.clearBusinessErrorsForWidget(this.dataId, this.name);
    widget.setValue(value);

    if (!this.config.roundTripOnChange) {
      return;
    }

    RoundTripService.standardRoundTrip(
      "RadioSelect/OnChange",
      { dataId: this.dataId, name: this.name },
      {
        dialogRowKey: this.dialogRowKey,
      }
    ).catch((reason) => {
      if (reason) {
        throw reason;
      } else {
        // If the round trip fails, undo the value change.
        widget.setValue(oldValue);
      }
    });
  };

  private syncDerivedWithProps(): void {
    this.dataId = this.props.dataId;
    this.name = this.props.name;
    this.componentName = `${this.componentId}-${this.props.name}`;
    this.config = { ...this.props };
    this.dialogRowKey =
      this.props.propagated?.parentDialog?.rowKey || undefined;

    this.rowKey = undefined;
    if (this.props.propagated?.parentTable) {
      this.rowKey = this.props.propagated.rowKey;
    }
  }

  protected showSubPane(): void {
    if (!this.config.isPaneController) {
      return;
    }

    const row = PaneRow.get(this.dataId, this.rowKey);
    const widget = row!.getWidgetT<string | null, RuntimeProperties>(this.name);

    const controlledPaneKey =
      this.config.controlledPaneKeysByValue![widget.value || ""];

    const controlKey: string = `${this.config.controlKey}_`;
    if (controlledPaneKey) {
      SubPaneControlStore.showPane(controlKey, controlledPaneKey);
    } else {
      SubPaneControlStore.hidePane(controlKey);
    }
  }

  public componentDidMount(): void {
    this.disposeObserve = autorun(() => {
      this.showSubPane();
    });
  }

  public componentDidUpdate(): void {
    this.syncDerivedWithProps();
  }

  public componentWillUnmount(): void {
    this.disposeObserve();
  }

  public render(): React.ReactNode {
    const row = PaneRow.get(this.dataId, this.rowKey);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<string | null, RuntimeProperties>(this.name);

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    if (widget.properties.accessLevel === AccessLevel.disabled) {
      return (
        <TextField
          disabled={true}
          disabledHelpText={
            widget.properties.showDisabledHelp
              ? this.props.disabledHelpText
              : undefined
          }
          label={this.props.label}
          variant="filled"
        />
      );
    }

    if (widget.properties.accessLevel === AccessLevel.readOnly) {
      const selectedItem = widget.properties.options.find(
        (item) => item.value === widget.value
      );
      return (
        <TextField
          label={this.props.label}
          name={this.componentName}
          readOnly={true}
          value={selectedItem ? selectedItem.display : "-"}
          variant="filled"
        />
      );
    }

    const fieldHelperText = getFieldHelperText({
      getErrors: () => widget.properties.businessErrors,
      helperText: this.props.helperText,
    });

    const options = [...widget.properties.options];
    if (widget.properties.selectedValue) {
      // Add the initially selected value as a historic option if is
      // not among the current candidates
      const optionInList = options.find(
        (o) => o.value === widget.properties.selectedValue
      );

      if (optionInList === undefined) {
        const historicOption: ComboBoxOption = {
          display: widget.properties.selectedDisplayValue,
          historic: true,
          value: widget.properties.selectedValue,
        };

        options.push(historicOption);
      }
    }

    return (
      <RadioSelectFormControl
        error={fieldHelperText.hasErrors}
        fullWidth={true}
        onBlur={(event: React.FocusEvent<HTMLElement>) => {
          if (
            event.relatedTarget instanceof Element &&
            event.currentTarget.contains(event.relatedTarget)
          ) {
            // Focus is moving within the group
            return;
          }

          this.announceErrors(fieldHelperText.errors);
        }}
        required={widget.properties.showAsMandatory}
      >
        <FormLabel id={this.labelId} style={{ color: colors.grey[800] }}>
          {this.props.label}
        </FormLabel>
        <RadioGroup
          aria-describedby={
            fieldHelperText.helperText ? this.helperTextId : undefined
          }
          aria-labelledby={this.labelId}
          aria-required={widget.properties.showAsMandatory}
          id={this.componentId}
          name={this.componentName}
          onChange={this.onChange}
          row={this.props.width !== "xs"}
          value={widget.value || ""}
        >
          {options.map((option, index) => (
            <Radio
              key={option.value ? option.value : ""}
              label={option.display}
              margin={index < options.length - 1}
              roundTripOnChange={this.props.roundTripOnChange}
              value={option.value || ""}
            />
          ))}
        </RadioGroup>
        {fieldHelperText.helperText && (
          <FormHelperText
            component="div"
            id={this.helperTextId}
            style={{ marginTop: 0 }}
          >
            {fieldHelperText.helperText}
          </FormHelperText>
        )}
      </RadioSelectFormControl>
    );
  }
}

// FUTURE
// This wrapper component was created to avoid the scope of converting this
// component to a hooks component during the MUI 5 upgrade. When the legacy
// component is converted to a hooks component, this wrapper can be removed and
// hooks it calls can be called by the converted component directly.
const Observer = observer(RadioSelect);
export default function Wrapped(props: ConfigProperties): JSX.Element {
  const width = useWidth();
  return <Observer {...props} width={width} />;
}

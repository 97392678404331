import { observer } from "mobx-react";
import * as React from "react";
import FormData from "../coreui/FormData";
import { TableVerticalLayoutProps } from "../coreui/Table";
import Thumbnail, { ThumbnailTypes } from "../coreui/Thumbnail";
import PaneRow from "../models/PaneRow";
import DocumentService from "../services/DocumentService";
import { AccessLevel } from "./AccessLevel";

interface ConfigProperties {
  dataId: string;
  imageHeight: number;
  imageWidth: number;
  name: string;
  parentColumnHeader?: string;
  propagated: TableVerticalLayoutProps;
  thumbnailType: ThumbnailTypes;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  alternateText: string;
  documentHandle: string | null;
  downloadToken: string;
  fileName: string;
  hasThumbnailImage: boolean;
  iconName: string;
  pendingDocumentId: number | null;
  pendingThumbnailId: number | null;
}

export class ThumbnailDisplay extends React.Component<ConfigProperties> {
  public render() {
    const row = PaneRow.get(this.props.dataId, this.props.propagated.rowKey)!;
    const widget = row.getWidgetT<null, RuntimeProperties>(this.props.name);

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    if (
      !widget.properties.documentHandle &&
      !widget.properties.pendingDocumentId
    ) {
      return null;
    }

    const imgSrc: string = DocumentService.getThumbnailUrl(
      widget.properties.documentHandle,
      widget.properties.pendingDocumentId,
      widget.properties.pendingThumbnailId,
      this.props.thumbnailType,
      widget.properties.downloadToken
    );

    const downloadUrl: string = DocumentService.getDocumentUrl(
      widget.properties.documentHandle,
      widget.properties.pendingDocumentId,
      widget.properties.fileName,
      widget.properties.downloadToken
    );

    let content: React.ReactNode = (
      <Thumbnail
        alternateText={widget.properties.alternateText}
        downloadUrl={downloadUrl}
        hasImage={widget.properties.hasThumbnailImage}
        iconName={widget.properties.iconName}
        imageHeight={this.props.imageHeight}
        imageWidth={this.props.imageWidth}
        imgSrc={imgSrc}
        thumbnailType={this.props.thumbnailType}
      />
    );

    if (this.props.parentColumnHeader) {
      content = (
        <FormData label={this.props.parentColumnHeader}>{content}</FormData>
      );
    }

    return <div>{content}</div>;
  }
}

export default observer(ThumbnailDisplay);

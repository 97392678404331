import { Layout, PresentationConfig } from "../config";
import AppServer, { State as AppServerState } from "../core/AppServer";
import RequestPromise from "../core/RequestPromise";
import { PaneDataByDataId } from "../stores/PaneDataStore";
import SessionMessagesStore, {
  SessionMessage,
} from "../stores/SessionMessagesStore";
import BaseService from "./BaseService";

export interface PresentationConfigResponse {
  objectDefDescription: string;
  presentationConfig: object;
  scriptsByDataId: object;
}

export interface PageDataResponse {
  appServerState: AppServerState;
  paneDataByDataId: PaneDataByDataId;
}

export interface PresentationDataResponse {
  appServerState: AppServerState;
  layoutId: number | null;
  newSessionMessages: SessionMessage[];
  objectTitle: string | null;
  paneDataByDataId: PaneDataByDataId | null;
  shouldRedirectHome: boolean;
  validationErrors?: string[];
}

export default class PresentationService {
  public static getAuthenticatedPageData(
    appServerState: AppServerState | null
  ): RequestPromise<PageDataResponse> {
    return BaseService.requestObject(
      "Presentation/AuthenticatedPageData",
      null,
      null,
      { appServerState },
      "POST"
    );
  }

  public static getGuestPageData(
    appServerState: AppServerState | null
  ): RequestPromise<PageDataResponse> {
    return BaseService.requestObject(
      "Presentation/GuestPageData",
      null,
      null,
      { appServerState },
      "POST"
    );
  }

  public static getLandingPageData(): RequestPromise<PageDataResponse> {
    return BaseService.requestObject(
      "Presentation/LandingPageData",
      null,
      null,
      null,
      "GET"
    );
  }

  public static getPresentationConfig(
    layoutId: number
  ): RequestPromise<PresentationConfig> {
    const request = BaseService.requestObject<PresentationConfig>(
      `Presentation/PresentationConfig/${layoutId}`,
      null,
      null,
      null,
      "GET"
    );

    return request.then((config: PresentationConfig) => {
      Layout.initialize(config);

      return config;
    });
  }

  public static getPresentationData(
    layoutId: number,
    objectHandle: string,
    parameters: string | null = null
  ): RequestPromise<PresentationDataResponse> {
    const result = BaseService.requestObject<PresentationDataResponse>(
      `Presentation/PresentationData/${layoutId}/${objectHandle}`,
      null,
      null,
      {
        appServerState: AppServer.getState(),
        arguments: parameters,
      },
      "POST"
    );

    result.then((response: PresentationDataResponse) => {
      if (response.newSessionMessages.length) {
        SessionMessagesStore.addMessages(response.newSessionMessages);
      }
    });

    return result;
  }

  public static getPresentationIdForName(
    presentationName: string,
    objectHandle: string
  ): RequestPromise<number> {
    const url =
      "Presentation/GetPresentationIdForName" +
      `/${presentationName}` +
      `/${objectHandle}`;

    return BaseService.requestObject(url, null, null, null, "GET");
  }
}

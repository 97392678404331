import {
  Breakpoint,
  ClickAwayListener,
  Grow,
  MenuList,
  Menu as MuiMenu,
  Paper,
  Popper,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { useWidth } from "../core/Responsive";
import MaskingStore from "../stores/MaskingStore";

interface Props {
  anchorEl?: HTMLElement | null;
  "aria-label"?: string;
  children: React.ReactNode;
  labelId?: string;
  menuId?: string;
  modal?: boolean;
  offset?: number;
  onClose?: () => void;
  variant: "menu" | "selectedMenu";
}

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    flex: 1,
    minHeight: 0,
    outline: "none",
    overflow: "auto",
  },
  paper: {
    display: "flex",
    flex: 1,
    minHeight: 0,
  },
  popper: {
    display: "flex",
    flexDirection: "column",
    // Force marginTop to 0 to avoid inheriting the top margin that Grid.tsx
    // applies to the contents the nested GridItem divs. This happens when
    // disablePortal=true and the menu is inline.
    marginTop: "0 !important",
    zIndex: theme.zIndex.modal,
  },
}));

export default function Menu(props: Props): JSX.Element {
  const classes = useStyles();
  const width = useWidth();

  const didClickAway = React.useRef<boolean>(false);
  const isMasked = React.useRef<boolean>(false);
  const prevAnchorEl = React.useRef<HTMLElement | null>(props.anchorEl || null);
  const prevWidth = React.useRef<Breakpoint>(width);

  const onClickAway = React.useCallback(
    (event: MouseEvent | TouchEvent): void => {
      if (props.anchorEl?.contains(event.target as HTMLElement)) {
        return;
      }

      didClickAway.current = true;
      if (props.onClose) {
        props.onClose();
      }
    },
    [props.anchorEl, props.onClose]
  );

  const onClose = React.useCallback(
    (event: React.KeyboardEvent | React.MouseEvent): void => {
      if (props.onClose) {
        props.onClose();
      }
    },
    [props.onClose]
  );

  // The MUI menu will disable scrolling, but we need to increment / decrement
  // the masking store to indicate when masking is applied, in order to
  // add or remove the body class that overrides overflow-y
  const onEntering = React.useCallback(
    (node: HTMLElement, isAppearing: boolean) => {
      if (props.modal && !isMasked.current) {
        MaskingStore.maskOpened();
        isMasked.current = true;
      }
    },
    [props.modal]
  );

  const onExit = React.useCallback((node: HTMLElement) => {
    if (isMasked.current) {
      MaskingStore.maskClosed();
      isMasked.current = false;
    }
  }, []);

  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent): void => {
      event.stopPropagation();

      switch (event.key) {
        case "Escape":
          if (props.onClose) {
            props.onClose();
          }
          break;

        case "Tab":
          if (props.onClose) {
            props.onClose();
          }

          event.preventDefault();
          break;
        default:
      }
    },
    [props.onClose]
  );

  React.useEffect(() => {
    if (prevAnchorEl.current && !props.anchorEl) {
      if (!didClickAway.current) {
        prevAnchorEl.current.focus();
      }
    }
    prevAnchorEl.current = props.anchorEl || null;
    didClickAway.current = false;
  }, [props.anchorEl]);

  React.useEffect(() => {
    if (prevWidth.current !== width) {
      if (props.onClose) {
        props.onClose();
      }
    }
    prevWidth.current = width;
  }, [width]);

  React.useEffect(() => {
    return () => {
      if (isMasked.current) {
        MaskingStore.maskClosed();
        isMasked.current = false;
      }
    };
  }, []);

  if (props.modal) {
    return (
      <MuiMenu
        anchorEl={props.anchorEl}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        className={classes.list}
        disableAutoFocusItem={props.variant === "menu"}
        MenuListProps={{
          "aria-label": props["aria-label"],
          "aria-labelledby": props.labelId,
          disableListWrap: true,
          id: props.menuId,
          role: props.variant === "selectedMenu" ? "listbox" : "menu",
          variant: props.variant,
        }}
        onClose={onClose}
        open={!!props.anchorEl}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        TransitionProps={{
          onEntering,
          onExit,
        }}
        variant={props.variant}
      >
        {props.children}
      </MuiMenu>
    );
  }

  const rootAncestor: Element | null = props.anchorEl
    ? props.anchorEl.closest("#root")
    : null;

  // The root element is not an ancestor of the anchorEl if this component is
  // rendered in a dialog. In that case, the scroll parent is used to contain
  // the menu.
  const boundariesElement: Element | string = rootAncestor || "scrollParent";

  return (
    <Popper
      anchorEl={props.anchorEl}
      className={classes.popper}
      disablePortal={true}
      modifiers={[
        {
          name: "offset",
          options: { offset: [0, props.offset] },
        },
        {
          name: "preventOverflow",
          options: {
            boundary: boundariesElement,
          },
        },
      ]}
      onKeyDown={onKeyDown}
      open={props.anchorEl !== undefined}
      placement="bottom-start"
      role={undefined}
      transition
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom",
          }}
          timeout={300}
        >
          <Paper className={classes.paper} elevation={8}>
            {props.anchorEl ? (
              // Setting touchEvent to false prevents the menu from closing when
              // using VoiceOver on iOS. This still will click away when using
              // mouse or iphone without VoiceOver turned on.
              <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent={false}
                onClickAway={onClickAway}
              >
                <MenuList
                  aria-labelledby={props.labelId}
                  role={props.variant === "selectedMenu" ? "listbox" : "menu"}
                  id={props.menuId}
                  autoFocusItem={true}
                  className={classes.list}
                  disableListWrap={true}
                  variant={props.variant}
                >
                  {props.children}
                </MenuList>
              </ClickAwayListener>
            ) : null}
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

import {
  DialogContent as MuiDialogContent,
  DialogContentProps as MuiDialogContentProps,
  Theme,
} from "@mui/material";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import * as React from "react";
import multiClassName from "./MultiClassName";

interface Props extends MuiDialogContentProps {}

interface State {
  hasScrollbar?: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.only("xs")]: {
        "&:first-child": {
          paddingTop: theme.dialog.padding.xs,
        },

        paddingLeft: theme.dialog.padding.xs,
        paddingRight: theme.dialog.padding.xs,
        paddingTop: theme.dialog.padding.xs,
      },
      [theme.breakpoints.up("sm")]: {
        "&:first-child": {
          paddingTop: theme.dialog.padding.sm,
        },

        paddingLeft: theme.dialog.padding.sm,
        paddingRight: theme.dialog.padding.sm,
        paddingTop: theme.dialog.padding.sm,
      },
      [theme.breakpoints.up("lg")]: {
        "&:first-child": {
          paddingTop: theme.dialog.padding.lg,
        },

        paddingLeft: theme.dialog.padding.lg,
        paddingRight: theme.dialog.padding.lg,
        paddingTop: theme.dialog.padding.lg,
      },
      paddingBottom: 4, // Space for button shadow
    },
    rootHasScrollbar: {
      [theme.breakpoints.up("sm")]: {
        paddingRight: 16,
      },
    },
  });

export class DialogContent extends React.PureComponent<
  Props & WithStyles<typeof styles>,
  State
> {
  private readonly resizeObserver: ResizeObserver;
  private resizeTimeout: number;
  private readonly rootRef = React.createRef<HTMLDivElement>();

  public constructor(props: Props & WithStyles<typeof styles>) {
    super(props);

    this.state = { hasScrollbar: false };

    this.resizeObserver = new ResizeObserver(this.onContentResize);
  }

  private onContentResize = (entries: ResizeObserverEntry[]): void => {
    window.clearTimeout(this.resizeTimeout);
    this.resizeTimeout = window.setTimeout(() => {
      const div = entries[0].target as HTMLElement;
      this.setState({
        hasScrollbar: div.clientHeight < div.scrollHeight,
      });
    }, 100);
  };

  public componentDidMount(): void {
    this.resizeObserver.observe(this.rootRef.current!);
  }

  public componentWillUnmount(): void {
    this.resizeObserver.unobserve(this.rootRef.current!);
    window.clearTimeout(this.resizeTimeout);
  }

  public render(): React.ReactNode {
    const { classes, ...contentProps } = this.props;

    return (
      <MuiDialogContent
        {...contentProps}
        classes={{
          root: multiClassName(
            classes.root,
            this.state.hasScrollbar ? classes.rootHasScrollbar : ""
          ),
        }}
        ref={this.rootRef}
      />
    );
  }
}

export default withStyles(styles)(DialogContent);

import { styled } from "@mui/material";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../core/Localization";
import Button from "../coreui/Button";
import Menu from "../coreui/Menu";
import MenuItem from "../coreui/MenuItem";
import { TableChildProps } from "../coreui/Table";
import Typography from "../coreui/Typography";
import PaneRow from "../models/PaneRow";
import PaneDataStore from "../stores/PaneDataStore";
import { DashboardChildProps, Search } from "./DashboardGridControl";

interface Props {
  propagated: TableChildProps & DashboardChildProps;
}

interface State {
  buttonElement?: HTMLButtonElement;
  value?: string;
}

const FilterSelect = styled(Button)({
  "&:focus": {
    boxShadow: "none",
  },
  background: "none",
  boxShadow: "none",
  padding: 8,
  textAlign: "left",
  textTransform: "none",
});

export class DashboardCriteria extends React.PureComponent<Props, State> {
  public static values: Map<string, string> = new Map<string, string>();
  private readonly key: string;

  public constructor(props: Props) {
    super(props);

    this.key = this.props.propagated.parentTable.configProps.contentDataId;

    let value: string = props.propagated.parentDashboard.initialSearch;

    if (DashboardCriteria.values.has(this.key)) {
      value = DashboardCriteria.values.get(this.key)!;
    } else {
      DashboardCriteria.values.set(this.key, value);
    }

    this.state = { value };
  }

  private async onChange(value: string): Promise<void> {
    if (value === DashboardCriteria.values.get(this.key)) {
      this.setState({ buttonElement: undefined });

      return;
    }

    this.setState({ buttonElement: undefined });
    this.setState({ value });

    try {
      await this.props.propagated.parentDashboard.search(value);
      DashboardCriteria.values.set(this.key, value);
    } catch {
      this.setState({
        value: DashboardCriteria.values.get(this.key)!,
      });
    }
  }

  private onClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    const target: HTMLButtonElement = event.currentTarget;
    this.setState((prevState) => {
      return {
        buttonElement: prevState.buttonElement ? undefined : target,
      };
    });
  };

  private onCloseMenu = (): void => {
    this.setState({ buttonElement: undefined });
  };

  public render(): React.ReactNode {
    const contentDataId =
      this.props.propagated.parentTable.configProps.contentDataId;
    const describedById = `${contentDataId}-filter-described-by`;
    const gridRows: PaneRow[] = PaneDataStore.getPaneCollection(contentDataId);
    let rowCountMessage: string;
    const searches: Search[] = this.props.propagated.parentDashboard.searches;
    const selected: Search = searches.find((s) => s.name === this.state.value)!;
    const initialSearch: string =
      this.props.propagated.parentDashboard.initialSearch;

    switch (gridRows.length) {
      case 0:
        rowCountMessage = Localization.getBuiltInMessage(
          "DataTable.gridFilterAlertZero"
        );
        break;
      case 1:
        rowCountMessage = Localization.getBuiltInMessage(
          "DataTable.gridFilterAlertSingle"
        );
        break;
      default:
        rowCountMessage = Localization.getBuiltInMessage(
          "DataTable.gridFilterAlertMultiple",
          { count: gridRows.length }
        );
        break;
    }

    return (
      <div>
        <FilterSelect
          aria-describedby={describedById}
          aria-expanded={!!this.state.buttonElement}
          aria-haspopup="menu"
          endIcon="fas fa-caret-down"
          onClick={this.onClick}
          sx={{ display: { sm: "inline-flex", xs: "none" } }}
          tabIndex={-1}
        >
          <div>
            <Typography variant="caption">
              {Localization.getBuiltInMessage("filterBy")}
            </Typography>
            <Typography>{selected.description}</Typography>
          </div>
        </FilterSelect>
        <Button
          aria-describedby={describedById}
          aria-expanded={!!this.state.buttonElement}
          aria-haspopup="menu"
          aria-label={Localization.getBuiltInMessage("filterBy")}
          color={selected.name !== initialSearch ? "dark" : undefined}
          fab
          icon="fas fa-history"
          onClick={this.onClick}
          size="small"
          sx={{ display: { sm: "none" } }}
          tabIndex={-1}
        />
        <div id={describedById} style={{ display: "none" }}>
          {rowCountMessage}
        </div>
        <Menu
          anchorEl={this.state.buttonElement}
          aria-label={Localization.getBuiltInMessage("filterBy")}
          modal={true}
          onClose={this.onCloseMenu}
          variant="selectedMenu"
        >
          {searches.map((l) => (
            <MenuItem
              aria-selected={l.name === this.state.value}
              children={l.description}
              key={l.name}
              onClick={() => this.onChange(l.name)}
              role="option"
              selected={l.name === this.state.value}
              value={l.name}
            />
          ))}
        </Menu>
      </div>
    );
  }
}

export default observer(DashboardCriteria);

import { observer } from "mobx-react";
import * as React from "react";
import { PaneUse, PaneUseConfig } from "../config";
import PaneRow from "../models/PaneRow";
import { AccessLevel } from "./AccessLevel";

interface ConfigProperties {
  dataId: string;
  description: string;
  projectGridPaneUses: PaneUseConfig[];
  name: string;
}

export interface ProjectGridControlChildProps {
  parentProjectGridControl: {
    description: string;
  };
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  projectGridPaneUseKey: string;
}

const ProjectGridControl = observer(
  (props: ConfigProperties): JSX.Element | null => {
    const row = PaneRow.get(props.dataId);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<null, RuntimeProperties>(props.name);

    if (!widget.properties) {
      return null;
    }

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    const paneUseKey = widget.properties.projectGridPaneUseKey;
    const projectGridPaneUse = props.projectGridPaneUses.find(
      (p) => p.paneUseKey === paneUseKey
    );

    if (!projectGridPaneUse) {
      return null;
    }

    const childPropagated: ProjectGridControlChildProps = {
      parentProjectGridControl: { description: props.description },
    };

    return <PaneUse config={projectGridPaneUse} propagated={childPropagated} />;
  }
);

export default ProjectGridControl;

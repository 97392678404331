import { Input } from "@mui/material";
import { ICellEditorParams } from "ag-grid-community";
import { observer } from "mobx-react";
import * as React from "react";
import EditMask, { EditMask as EditMaskBase } from "../../coreui/EditMask";
import { ErrorBadge } from "../../coreui/ErrorBadge";
import { TableChildProps } from "../../coreui/Table";
import { CellUtil } from "../../coreui/table/CellUtil";
import PaneRow from "../../models/PaneRow";
import ErrorsStore from "../../stores/ErrorsStore";
import { GridColumnConfigProperties } from "./GridColumn";

interface ConfigProperties extends ICellEditorParams<PaneRow> {
  dataCase: "lower" | "UPPER" | "Any";
  dataId: string;
  dataSize: number;
  editMask: string;
  maxSizeError: string;
  name: string;
  propagated: TableChildProps;
}

interface State {
  value?: string | null;
}

interface RuntimeProperties {
  businessErrors: string[];
}

export class SLTextEditColumnEdit extends React.Component<
  ConfigProperties,
  State
> {
  private originalValue: string | null;
  protected inputElement: HTMLInputElement;

  public static getErrors(
    props: ConfigProperties,
    row: PaneRow,
    value: string | null,
    editingValueHasChanged: boolean = false
  ): string[] {
    const widget = row.getWidgetT<string | null, RuntimeProperties>(props.name);
    // Ensure errors are always accessed so the component knows to observe them
    const widgetErrors = [...widget.properties.businessErrors];
    const errors = row.hasChanges(props.name) ? [] : widgetErrors;

    if (props.dataSize && value && value.length > props.dataSize) {
      errors.push(props.maxSizeError);
    }

    if (!editingValueHasChanged && props.editMask) {
      errors.push(...EditMaskBase.getErrors(props.editMask, value));
    }

    return errors;
  }

  public constructor(props: ConfigProperties) {
    super(props);

    this.state = { value: props.value };

    this.originalValue = this.state.value!;
  }

  private get column(): GridColumnConfigProperties | undefined {
    const parentTable = this.props.propagated.parentTable;
    const tableColumns = parentTable.columns as GridColumnConfigProperties[];
    return tableColumns.find((c) => c.name === this.props.colDef.colId);
  }

  private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: string = event.target.value;

    switch (this.props.dataCase) {
      case "lower":
        value = value.toLowerCase();
        break;
      case "UPPER":
        value = value.toUpperCase();
        break;
      case "Any":
        break;
      default:
        break;
    }

    ErrorsStore.clearBusinessErrorsForTableCell(
      this.props.dataId,
      this.props.name,
      this.props.data.rowKey
    );
    this.setState({ value });
  };

  private onMaskValueChange = (value: string) => {
    ErrorsStore.clearBusinessErrorsForTableCell(
      this.props.dataId,
      this.props.name,
      this.props.data.rowKey
    );
    this.setState({ value });
  };

  public componentDidMount() {
    CellUtil.disableGridNavigation(this.props.eGridCell, this.inputElement);

    CellUtil.setInitialFocus(this.inputElement);
  }

  public componentWillUnmount() {
    CellUtil.enableGridNavigation(this.props.eGridCell);
  }

  public getValue(): string | null {
    return this.state.value === "" ? null : this.state.value!;
  }

  public render() {
    const row = this.props.data!;
    const value = this.state.value || "";

    const errors: string[] = SLTextEditColumnEdit.getErrors(
      this.props,
      row,
      value,
      this.state.value !== this.originalValue
    );

    let input: React.ReactNode;
    if (this.props.editMask) {
      input = (
        <EditMask
          dataCase={this.props.dataCase}
          InputProps={{
            style: { height: "100%", margin: "0 24px", paddingLeft: 2 },
          }}
          inputRef={(r) => (this.inputElement = r)}
          mask={this.props.editMask}
          name={this.props.name}
          onChange={this.onMaskValueChange}
          required={this.column?.required}
          style={{ height: "calc(100% + 2px)" }}
          value={value}
          variant="standard"
        />
      );
    } else {
      input = (
        <Input
          autoFocus={true}
          error={errors.length > 0}
          fullWidth={true}
          inputProps={{
            style: { margin: "0 24px", paddingLeft: 0, paddingRight: 0 },
          }}
          inputRef={(r) => (this.inputElement = r)}
          onChange={this.onChange}
          required={this.column?.required}
          style={{ height: "calc(100% + 2px)", paddingLeft: 2 }}
          value={value}
        />
      );
    }

    return (
      <ErrorBadge
        errors={errors}
        isShort={row.isNew && this.props.propagated.parentTable.isDocumentGrid}
      >
        {input}
      </ErrorBadge>
    );
  }
}

export default observer(SLTextEditColumnEdit);

import { Paper, SnackbarContent, styled, Theme } from "@mui/material";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import Button from "../coreui/Button";
import Collapse from "../coreui/Collapse";
import Icon from "../coreui/Icon";
import BaseService from "../services/BaseService";
import SessionMessagesStore, {
  SessionMessage,
} from "../stores/SessionMessagesStore";

const styles = (theme: Theme) =>
  createStyles({
    caution: {
      "& > svg": {
        top: "2px !important",
      },
      fontSize: ".5em",
    },
    content: {
      "& > div": {
        [theme.breakpoints.only("xs")]: { width: "100%" },
        [theme.breakpoints.only("sm")]: { width: 568 },
        [theme.breakpoints.only("md")]: { width: 912 },
        [theme.breakpoints.up("lg")]: { width: 1200 },
      },
      [theme.breakpoints.only("xs")]: {
        paddingLeft: 8,
        paddingRight: 8,
      },
      [theme.breakpoints.up("sm")]: {
        maxWidth: "none",
      },
      justifyContent: "center",
    },
    message: {
      alignItems: "center",
      display: "flex",
    },
    root: {
      position: "sticky",
      top: 0,
      zIndex: theme.zIndex.drawer - 1,
    },
    text: {
      flex: "auto",
      margin: "0px .4em",
    },
  });

const IconContainer = styled((props: React.HTMLAttributes<HTMLDivElement>) => (
  <div role="img" {...props} />
))({
  display: "inline-block",
  height: "1em",
  width: "1em",
});

export class SessionMessageDisplay extends React.Component<
  WithStyles<typeof styles>
> {
  private removeMessage(message: SessionMessage): void {
    SessionMessagesStore.removeMessage(message);

    const url: string = `${BaseService.baseUrl}User/SessionMessage/${message.messageId}`;

    fetch(url, { method: "DELETE" });
  }

  public render(): React.ReactNode {
    const content: React.ReactNode[] = [];
    const messages: SessionMessage[] = SessionMessagesStore.messages;

    for (const message of messages) {
      let icon: React.ReactNode = null;
      let messageText: string = message.message;

      switch (message.messageType) {
        case "Caution":
          icon = (
            <IconContainer
              aria-label={Localization.getBuiltInMessage(
                "SessionMessage.caution"
              )}
              className="fa-layers"
            >
              <Icon
                className={this.props.classes.caution}
                icon="fas fa-exclamation"
                sx={{ color: "dark.main" }}
              />
              <Icon color="warning" icon="far fa-triangle" />
            </IconContainer>
          );

          messageText = `${Localization.getBuiltInMessage(
            "SessionMessage.warning"
          )} ${message.message}`;
          break;
        case "Danger":
          icon = (
            <IconContainer
              aria-label={Localization.getBuiltInMessage(
                "SessionMessage.warning"
              )}
            >
              <Icon
                icon="far fa-octagon-exclamation"
                sx={{ color: "danger.main" }}
              />
            </IconContainer>
          );

          messageText = `${Localization.getBuiltInMessage(
            "SessionMessage.warning"
          )} ${message.message}`;
          break;
        case "Info":
          icon = (
            <IconContainer
              aria-label={Localization.getBuiltInMessage(
                "SessionMessage.information"
              )}
            >
              <Icon color="info" icon="far fa-info-circle" />
            </IconContainer>
          );
          break;
        case "Success":
          icon = (
            <IconContainer
              aria-label={Localization.getBuiltInMessage(
                "SessionMessage.success"
              )}
            >
              <Icon color="success" icon="far fa-check-circle" />
            </IconContainer>
          );
          break;
        default:
          throw new Error(`Unknown message type ${message.messageType}`);
      }

      content.push(
        <SnackbarContent
          className={this.props.classes.content}
          elevation={0}
          key={message.messageId}
          message={
            <div className={this.props.classes.message}>
              {icon}
              <div className={this.props.classes.text}>{message.message}</div>
              <Button
                aria-label={Localization.getBuiltInMessage(
                  "SessionMessage.closeButton",
                  { messageText }
                )}
                fab
                icon="fas fa-times"
                onClick={() => this.removeMessage(message)}
                size="small"
              />
            </div>
          }
        />
      );

      setTimeout(() => {
        Sys.announce(messageText);
      }, 2000);
    }

    return (
      <Collapse className={this.props.classes.root} in={messages.length > 0}>
        <Paper elevation={6}>{content}</Paper>
      </Collapse>
    );
  }
}

export default withStyles(styles)(observer(SessionMessageDisplay));

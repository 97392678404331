import { MenuItem, Theme } from "@mui/material";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import * as React from "react";
import WithForwardedRef, {
  WithForwardedRefProps,
} from "../core/WithForwardedRef";
import multiClassName from "./MultiClassName";

interface Props extends WithForwardedRefProps {
  children?: React.ReactNode;
  className?: string;
  disabled: boolean;
  indent?: number;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      "@media(max-width:1280px)": { paddingLeft: 24, paddingRight: 24 },
      "@media(max-width:960px)": { paddingLeft: 16, paddingRight: 16 },
      "@media(min-width:1281px)": { paddingLeft: 40, paddingRight: 40 },
      boxSizing: "border-box",
      paddingBottom: 10,
      paddingTop: 10,
    },
  });

export class MenuItemDisplay extends React.PureComponent<
  Props & WithStyles<typeof styles>
> {
  public render() {
    const {
      children,
      classes,
      className,
      disabled,
      forwardedRef,
      indent,
      ...otherProps
    } = this.props;

    return (
      <MenuItem
        className={multiClassName(className, classes.root)}
        disabled={disabled}
        ref={forwardedRef}
        style={{ marginLeft: indent ?? 0 }}
        {...otherProps}
      >
        {children}
      </MenuItem>
    );
  }
}

export default withStyles(styles)(WithForwardedRef(MenuItemDisplay));

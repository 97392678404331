import { FocusTrap } from "@mui/base";
import { Backdrop, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { autorun, Lambda } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import AppServer from "../core/AppServer";
import Sys from "../core/Sys";
import multiClassName from "../coreui/MultiClassName";
import PaneRow from "../models/PaneRow";
import { AccessLevel } from "../mustangui/AccessLevel";
import { EmbeddedAddOn } from "../mustangui/EmbeddedAddOn";
import EmbeddedAddOnService, {
  OnRoundTripResponse,
} from "../services/EmbeddedAddOnService";
import ErrorsStore from "../stores/ErrorsStore";
import PaneDataStore from "../stores/PaneDataStore";
import RequestsStore from "../stores/RequestsStore";

type JsonData = boolean | number | object | string;

export interface AddOnHostProps {
  configuration: string;
  componentTypeName: string;
  dataId: string;
  expansionSize: "Dialog" | "Full";
  hostId: string;
  name: string;
}

interface FrameProps {
  height: number | null;
  isExpanded: boolean;
}

interface PosseApi {
  _isLoaded: () => boolean;
  _refresh: () => void;
  _roundTripStarting: (roundTrip: Promise<void>) => void;
  _setConfiguration: (name: string) => void;
  _setReadOnly: (readOnly: boolean) => void;
  _showData: () => void;
}

export interface RuntimeProperties {
  accessLevel: AccessLevel;
  addOnUrl: string;
  objectHandle: string;
  processHandle: string;
}

interface State {
  height: number | null;
  isExpanded: boolean;
  isMounted: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  addon: {
    borderWidth: 0,
    flex: "auto",
    height: "100%",
  },
  animate: {
    transition:
      "box-shadow 1500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, height 500ms, left 500ms, top 500ms, transform 500ms, width 500ms !important",
  },
  container: {
    backgroundColor: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    // Force marginTop to 0 to avoid inheriting the top margin that Grid.tsx
    // applies to the contents the nested GridItem divs. This happens when the
    // Grid renders the add-on host directly.
    marginTop: "0 !important",
    overflow: "hidden",
    position: "absolute",
    transition: "height 500ms",
  },
  defaultModalHeight: {
    [theme.breakpoints.up("sm")]: {
      height: "88vh !important",
    },
  },
  dialog: {
    left: "calc(50vw - 300px) !important",
    width: "600px !important",
  },
  full: {
    left: "6vw !important",
    width: "88vw !important",
  },
  modal: {
    [theme.breakpoints.only("xs")]: {
      height: "100vh !important",
      left: "0px !important",
      maxHeight: "none",
      top: "0px !important",
      width: "100% !important",
    },
    [theme.breakpoints.up("sm")]: {
      // eslint-disable-next-line max-len
      boxShadow:
        "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
      top: "50vh !important",
      transform: "translate(0, -50%)",
    },
    maxHeight: "88vh",
    position: "fixed",
    transition: "height 500ms, top 500ms, transform 500ms",
    zIndex: theme.zIndex.modal,
  },
}));

const AddOnHostFrame = observer(
  React.forwardRef(
    (props: AddOnHostProps & FrameProps, ref: React.Ref<HTMLDivElement>) => {
      const classes = useStyles();
      const containerRef = React.useRef<HTMLDivElement>();

      // Flag to ensure that the add-on host is only animated when expanding
      // or collapsing.
      const [isAnimating, setIsAnimating] = React.useState<boolean>(false);

      // Flag to transition the add-on host to a modal expanded flag. This is used
      // instead of props.isExpanded because we must first react to
      // props.isExpanded in order to set the isAnimating flag. If the modal
      // classes are applied with props.isExpanded, they will be applied before
      // the isAnimating flag is set and then no animation will occur when the
      // add-on is collapsed.
      const [isModal, setIsModal] = React.useState<boolean>(false);

      React.useEffect(() => {
        if (props.isExpanded) {
          document.body.classList.remove("disableDialogNoScroll");
        } else {
          document.body.classList.add("disableDialogNoScroll");
        }

        setIsModal(props.isExpanded);
        setIsAnimating(true);
        // Wait for the animation.
        setTimeout(() => setIsAnimating(false), 600);
      }, [props.isExpanded]);

      React.useEffect(() => {
        const rootElement: HTMLElement = document.getElementById("root")!;

        let element: Element = containerRef.current!;
        while (element !== rootElement) {
          const parentElement: Element = element.parentElement!;

          let sibling = parentElement.firstElementChild;
          while (sibling !== null) {
            if (sibling !== element) {
              if (isModal) {
                sibling.setAttribute("aria-hidden", "true");
              } else {
                sibling.removeAttribute("aria-hidden");
              }
            }

            sibling = sibling.nextElementSibling;
          }

          element = parentElement;
        }
      }, [isModal]);

      const sandboxAttributes: string[] = [
        "allow-downloads",
        "allow-forms",
        "allow-modals",
        "allow-popups",
        "allow-popups-to-escape-sandbox",
        "allow-same-origin",
        "allow-scripts",
        "allow-top-navigation-by-user-activation",
      ];

      const row: PaneRow | null = PaneRow.get(props.dataId);

      if (!row) {
        return null;
      }

      const widget = row.getWidgetT<string, RuntimeProperties>(props.name);

      if (widget.properties.accessLevel === AccessLevel.hidden) {
        return null;
      }

      const delimiter: string = widget.properties.addOnUrl.includes("?")
        ? "&"
        : "?";
      const queryArgs: string = Sys.objectToQueryString({
        PosseAddOnHostId: props.hostId,
      });

      return (
        <React.Fragment>
          <div
            className={multiClassName(
              classes.container,
              isAnimating ? classes.animate : undefined,
              isModal ? classes.modal : undefined,
              isModal
                ? props.expansionSize === "Dialog"
                  ? classes.dialog
                  : classes.full
                : undefined,
              isModal && props.height === null
                ? classes.defaultModalHeight
                : undefined
            )}
            style={props.height ? { height: props.height } : undefined}
            ref={(node: HTMLDivElement) => {
              containerRef.current = node;
              if (typeof ref === "function") {
                ref(node);
              } else {
                (ref as React.MutableRefObject<HTMLDivElement>).current = node;
              }
            }}
          >
            <FocusTrap open={props.isExpanded}>
              <iframe
                className={classes.addon}
                sandbox={sandboxAttributes.join(" ")}
                src={`${widget.properties.addOnUrl}${delimiter}${queryArgs}`}
              />
            </FocusTrap>
          </div>
          <Backdrop
            open={props.isExpanded}
            style={{ marginTop: 0 }}
            sx={{ zIndex: (theme) => theme.zIndex.modal - 1 }}
          />
        </React.Fragment>
      );
    }
  )
);

class RoundTrip {
  public promise: Promise<void>;
  public rejectPromise: () => void;
  public resolvePromise: () => void;

  public constructor() {
    this.promise = new Promise<void>((resolve, reject) => {
      this.rejectPromise = (): void => reject();
      this.resolvePromise = (): void => resolve();
    });
  }
}

export class AddOnHost extends React.Component<AddOnHostProps, State> {
  private static instances = new Map<string, AddOnHost>();
  private static roundTrip: RoundTrip | null = null;
  private static roundTripSkipCount: number = 0;

  private api: PosseApi | null = null;
  private callDeferTimeout: number = 30000;
  private dataBufferBeforeRoundTrip: string;
  private deferredCalls = new Map<string, number>();
  private disposeObserve: Lambda;
  private embeddedAddOns = new Set<EmbeddedAddOn>();
  private frame: React.RefObject<HTMLDivElement>;
  private hostRef: React.RefObject<HTMLDivElement>;
  private lastObjectHandle: string | null = null;
  private target: HTMLElement | null = null;
  private targetTimeoutHandle: NodeJS.Timeout | null = null;

  public readonly callDeferPeriod: number = 100;

  public static clearRoundTrip(): void {
    AddOnHost.roundTrip = null;
  }

  public static getAddOn(hostId: string): AddOnHost {
    const addOn: AddOnHost | undefined = AddOnHost.instances.get(hostId);
    if (!addOn) {
      throw Error(`No add-on exists with hostId ${hostId}`);
    }

    return addOn;
  }

  public static get hasAddOns(): boolean {
    return AddOnHost.instances.size > 0;
  }

  public static onGridItemResize(): void {
    for (const [hostId, addOn] of AddOnHost.instances) {
      if (!addOn.isMounted) {
        continue;
      }

      addOn.updatePosition();
    }
  }

  public static rejectRoundTrip(): void {
    if (AddOnHost.roundTripSkipCount > 0) {
      AddOnHost.roundTripSkipCount--;
      return;
    }

    if (AddOnHost.roundTrip === null) {
      throw Error("A round trip must be started before it may be rejected");
    }

    AddOnHost.roundTrip.rejectPromise();
    AddOnHost.roundTrip = null;

    for (const [hostId, addOn] of AddOnHost.instances) {
      if (!addOn.isMounted) {
        continue;
      }

      // FUTURE
      // Due to how business errors are currently handled, the value of the data
      // buffer can no longer be relied on if any errors are encountered in a
      // round trip. To work around this for now, simply set the value of the
      // data buffer to what it was before the round trip started.
      // This work around can be removed when business error handling simply
      // returns the errors and does not touch the pane data.
      const row: PaneRow = PaneRow.get(addOn.props.dataId)!;
      row.setProperty(addOn.props.name, addOn.dataBufferBeforeRoundTrip);
    }
  }

  public static resolveRoundTrip(): void {
    if (AddOnHost.roundTripSkipCount > 0) {
      AddOnHost.roundTripSkipCount--;
      return;
    }

    if (AddOnHost.roundTrip === null) {
      throw Error("A round trip must be started before it may be resolved");
    }

    AddOnHost.roundTrip.resolvePromise();
    AddOnHost.roundTrip = null;

    for (const [hostId, addOn] of AddOnHost.instances) {
      if (!addOn.isMounted) {
        continue;
      }

      addOn.refresh();
    }
  }

  public static roundTripStarting(): void {
    if (AddOnHost.instances.size === 0) {
      AddOnHost.roundTripSkipCount++;
      return;
    }

    if (AddOnHost.roundTrip !== null) {
      // FUTURE
      // There currently exists an edge case where a round trip may be triggered
      // from within a dialog (at which point the dialog round trip will still
      // be in progress. For now, this is handled by just skipping the round
      // trip, since any add ons outside the dialog are only concerned about the
      // data state before the dialog opens and after the dialog closes.
      //
      // Ideally, a widget should know when it is in a dialog and simply not
      // call roundTripStarting(); however, this scope is too much at the end of
      // the 7.4.1 project. Once this is done, roundTripSkipCount can be changed
      // to a boolean flag called roundTripSkipped.
      //
      // Note that there is a case this does not handle, namely that an embedded
      // add on configured on a dialog will not receive a round trip starting
      // event. This is considered to be a corner case since in WebUI it will be
      // rare for an add on to be configured in a dialog. Rarer still for the
      // add on to be configured with other widgets. And even rarer yet for that
      // add on to require notification if any of its peers trigger a
      // round trip.
      AddOnHost.roundTripSkipCount++;
      return;
    }

    AddOnHost.roundTrip = new RoundTrip();

    for (const [hostId, addOn] of AddOnHost.instances) {
      if (!addOn.isMounted) {
        continue;
      }

      addOn.roundTripStarting(AddOnHost.roundTrip!.promise);
    }
  }

  public static tryGetAddOn(hostId: string): AddOnHost | null {
    const addOn: AddOnHost | undefined = AddOnHost.instances.get(hostId);
    if (!addOn) {
      return null;
    }

    return addOn;
  }

  constructor(props: AddOnHostProps) {
    super(props);

    this.state = { height: null, isExpanded: false, isMounted: false };
    this.frame = React.createRef<HTMLDivElement>();
    this.hostRef = React.createRef<HTMLDivElement>();
  }

  private get isMounted(): boolean {
    return this.state.isMounted;
  }

  private clearDefer(callName: string): void {
    if (this.deferredCalls.has(callName)) {
      this.deferredCalls.delete(callName);
    }
  }

  private getApi(): PosseApi | null {
    let result: PosseApi | null = null;

    // eslint-disable-next-line no-underscore-dangle
    if (this.api && this.api._isLoaded()) {
      result = this.api;
    }

    return result;
  }

  private refresh(): void {
    const callName: string = `refresh for ${this.props.name}`;
    const api: PosseApi | null = this.getApi();

    if (api) {
      this.clearDefer(callName);
      // eslint-disable-next-line no-underscore-dangle
      api._refresh();
    } else {
      this.defer(this.refresh, arguments, callName);
    }
  }

  private roundTripStarting(roundTrip: Promise<void>): void {
    const api: PosseApi | null = this.getApi();
    const row: PaneRow = PaneRow.get(this.props.dataId)!;
    const widget = row.getWidgetT<string, RuntimeProperties>(this.props.name);

    // If the addon is not available then there won't be any pending changes.
    if (api && widget.properties.accessLevel >= AccessLevel.enterable) {
      // eslint-disable-next-line no-underscore-dangle
      api._roundTripStarting(roundTrip);
    }

    this.dataBufferBeforeRoundTrip = widget.value;
  }

  private setReadOnly(readOnly: boolean): void {
    const callName: string = `setReadOnly for ${this.props.name}`;
    const api: PosseApi | null = this.getApi();

    if (api) {
      this.clearDefer(callName);
      // eslint-disable-next-line no-underscore-dangle
      api._setReadOnly(readOnly);
    } else {
      this.defer(this.setReadOnly, arguments, callName);
    }
  }

  private showData(): void {
    const callName: string = `showData for ${this.props.name}`;
    const api: PosseApi | null = this.getApi();

    if (api) {
      this.clearDefer(callName);
      // eslint-disable-next-line no-underscore-dangle
      api._showData();
    } else {
      this.defer(this.showData, arguments, callName);
    }
  }

  private synchronizeWidgetState(): void {
    const row = PaneRow.get(this.props.dataId);
    if (!row) {
      return;
    }

    const widget = row.getWidgetT<string, RuntimeProperties>(this.props.name);

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      this.unmount();
    } else {
      // Use a setTimeout to give the embedded add-on a chance to render
      setTimeout(this.updateTarget);
    }

    if (this.api !== null) {
      this.setReadOnly(widget.properties.accessLevel === AccessLevel.readOnly);

      // Call showData the first time an object is rendered.
      if (this.lastObjectHandle !== widget.properties.objectHandle) {
        this.lastObjectHandle = widget.properties.objectHandle;
        AddOnHost.getAddOn(this.props.hostId).showData();
      }
    }
  }

  private unmount(): void {
    this.setState({ isMounted: false });
    this.api = null;
    this.lastObjectHandle = null;
  }

  private updatePosition = (): void => {
    if (!this.frame.current) {
      return;
    }

    if (this.state.isExpanded) {
      return;
    }

    if (!this.target) {
      return;
    }

    if (!this.hostRef.current) {
      return;
    }

    // Element.getBoundingClientRect() returns a position relative to the
    // viewport and therefore the position changes every time the scroll
    // position changes. Since the frame position is calculated as the
    // difference between these two positions, the scroll position cancels out
    // and therefore does not need to be accounted for.
    const targetRect = this.target.getBoundingClientRect();
    const hostRect = this.hostRef.current.getBoundingClientRect();

    // Setting the frame position based on the difference between the host and
    // the target only works because the host uses a non-static position. Thus,
    // the frame is positioned relative to the host.
    this.frame.current.style.height = `${targetRect.height}px`;
    this.frame.current.style.left = `${targetRect.left - hostRect.left}px`;
    this.frame.current.style.top = `${targetRect.top - hostRect.top}px`;
    this.frame.current.style.width = `${targetRect.width}px`;
  };

  private updateTarget = (): void => {
    this.targetTimeoutHandle = null;
    this.target = null;
    for (const embeddedAddOn of this.embeddedAddOns) {
      if (embeddedAddOn.target.current !== null) {
        this.target = embeddedAddOn.target.current;
        break;
      }
    }

    if (this.target === null) {
      this.unmount();
      return;
    }

    this.setState({ isMounted: true }, this.updatePosition);
  };

  public addOnLoaded(api: PosseApi): void {
    this.api = api;
    this.synchronizeWidgetState();
    // eslint-disable-next-line no-underscore-dangle
    this.getApi()!._setConfiguration(this.props.configuration);
  }

  public collapse(): void {
    this.setState({ height: null, isExpanded: false }, () => {
      this.updatePosition(); // Ensure height tracks the embedded add-on
    });

    for (const embeddedAddOn of this.embeddedAddOns) {
      embeddedAddOn.setHeight(null);
    }
  }

  public componentDidMount(): void {
    this.disposeObserve = autorun(() => {
      this.synchronizeWidgetState();
    });

    window.addEventListener("resize", this.updatePosition);
    AddOnHost.instances.set(this.props.hostId, this);
  }

  public componentWillUnmount(): void {
    this.disposeObserve();
    window.removeEventListener("resize", this.updatePosition);
    AddOnHost.instances.delete(this.props.hostId);
  }

  public configurationChanged(configurationName: string): void {
    // Do nothing, method provided for backwards compatibility.
  }

  public defer(method: Function, args: IArguments, callName: string) {
    let ok: boolean = true;

    if (callName) {
      if (this.deferredCalls.has(callName)) {
        if (
          new Date().getTime() - this.deferredCalls.get(callName)! >
          this.callDeferTimeout
        ) {
          this.clearDefer(callName);
          ok = false;
          ErrorsStore.showErrors([
            `Deferred function call ${callName} timed out`,
          ]);
        }
      } else {
        this.deferredCalls.set(callName, new Date().getTime());
      }
    }

    if (ok) {
      Sys.defer(method, this.callDeferPeriod, this, args);
    }
  }

  public embeddedAddOnChanged(): void {
    if (this.targetTimeoutHandle === null) {
      this.targetTimeoutHandle = setTimeout(this.updateTarget);
    }
  }

  public expand(): void {
    this.setState({ height: null, isExpanded: true });
  }

  public getDataBuffer(): JsonData | JsonData[] | null {
    const row = PaneRow.get(this.props.dataId);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<string, RuntimeProperties>(this.props.name);

    return JSON.parse(widget.value) as JsonData | JsonData[] | null;
  }

  public getObjectBuffer(): object[] | null {
    const row = PaneRow.get(this.props.dataId);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<string, RuntimeProperties>(this.props.name);

    return [
      {
        componentTypeName: this.props.componentTypeName,
        objectHandle: widget.properties.objectHandle,
        processHandle: widget.properties.processHandle,
      },
    ];
  }

  public isExpanded(): boolean {
    return this.state.isExpanded;
  }

  public registerEmbeddedAddOn(embeddedAddOn: EmbeddedAddOn): void {
    this.embeddedAddOns.add(embeddedAddOn);
    if (this.targetTimeoutHandle === null) {
      this.targetTimeoutHandle = setTimeout(this.updateTarget);
    }
  }

  public render(): React.ReactNode {
    if (!this.state.isMounted) {
      return null;
    }

    // AddOnHost.updatePosition() must calculate left and top values for the
    // add-on frame that position it over the placeholder rendered by the
    // embedded add-on at the given break-point. The add-on host frame uses
    // absolute positioning and therefore calculates its position using its left
    // and top values relative to the first ancestor that uses non-static
    // positioning. Using a non-static position (i.e. "relative") on the host
    // div ensures the add-on frame's left and top values are always relative to
    // the host div. With this in place, calculating the left and top values for
    // the add-on frame that will position the frame over the placeholder can be
    // accomplished simply by taking the difference in positions between the
    // placeholder and the host div.
    return (
      <div
        id={this.props.hostId}
        ref={this.hostRef}
        style={{ position: "relative" }}
      >
        <AddOnHostFrame
          height={this.state.height}
          isExpanded={this.state.isExpanded}
          ref={this.frame}
          {...this.props}
        />
      </div>
    );
  }

  public roundTrip(): Promise<void> {
    RequestsStore.instance.processingStarted();
    AddOnHost.roundTripStarting();
    ErrorsStore.clearErrors();

    const row: PaneRow = PaneRow.get(this.props.dataId)!;
    EmbeddedAddOnService.onRoundTrip(
      row.rowKey,
      this.props.dataId,
      this.props.name
    )
      .then((response: OnRoundTripResponse) => {
        if (response.validationErrors.length > 0) {
          ErrorsStore.showErrors(response.validationErrors);
          AddOnHost.rejectRoundTrip();
          return;
        }

        if (response.businessErrors.length > 0) {
          ErrorsStore.clearBusinessErrors();
        }

        AppServer.setState(response.appServerState);
        PaneDataStore.loadResponse(response.paneDataByDataId);

        if (ErrorsStore.setBusinessErrors(response.businessErrors, false)) {
          AddOnHost.rejectRoundTrip();
          return;
        }

        AddOnHost.resolveRoundTrip();
      })
      .finally(() => RequestsStore.instance.processingStopped());

    return AddOnHost.roundTrip!.promise;
  }

  public setBusy(busy: boolean): void {
    if (busy) {
      setTimeout(() => {
        RequestsStore.instance.processingStarted();
      });
    } else {
      setTimeout(() => {
        if (RequestsStore.instance.processingInfo.isProcessing) {
          RequestsStore.instance.processingStopped();
        }
      });
    }
  }

  public setDataBuffer(value: JsonData | JsonData[] | null): void {
    const row: PaneRow = PaneRow.get(this.props.dataId)!;
    row.setProperty(this.props.name, JSON.stringify(value));
  }

  public setHeight(height: number): void {
    this.setState({ height });
    if (!this.state.isExpanded) {
      for (const embeddedAddOn of this.embeddedAddOns) {
        embeddedAddOn.setHeight(height);
      }
    }
  }

  public unregisterEmbeddedAddOn(embeddedAddOn: EmbeddedAddOn): void {
    this.embeddedAddOns.delete(embeddedAddOn);
    if (this.targetTimeoutHandle === null) {
      this.targetTimeoutHandle = setTimeout(() => {
        // In the event of a presentation navigation, it's possible that this
        // add-on host is going away in addition to the embedded add-on
        // placeholder. Verify that the current add-on host still exists before
        // attempting to update the target. If this check isn't done,
        // updateTarget() will eventually attempt to setState(), which React
        // will warn about in the console as a possible memory leak.
        if (AddOnHost.instances.get(this.props.hostId)) {
          this.updateTarget();
        }
      });
    }
  }
}

import { observer } from "mobx-react";
import * as React from "react";
import { useWidth } from "../core/Responsive";
import PaneRow from "../models/PaneRow";
import { RuntimeProperties as HeadingRuntimeProperties } from "../mustangui/GroupHeading";
import {
  Layout,
  LayoutChildProps,
  LayoutConfig,
  Props as LayoutProps,
} from "./Layout";

export interface DialogChildProps extends LayoutChildProps {
  parentDialog?: {
    rowKey: string | null;
  };
}

export interface DialogLayoutConfig extends LayoutConfig {
  titleHeadings: HeadingInfo[];
}

interface Props extends LayoutProps {
  config: DialogLayoutConfig;
  labelledById: string;
  propagated?: DialogChildProps;
}

interface HeadingInfo {
  breakPoint: string;
  dataId: string;
  name: string;
}

export const Dialog = observer((props: Props): JSX.Element => {
  const width = useWidth();
  const searchWidth = width === "xl" ? "lg" : width;
  const headingInfo = props.config.titleHeadings.find(
    (h) => h.breakPoint === searchWidth
  );

  let headingText: string | undefined = undefined;
  if (headingInfo) {
    const row = PaneRow.get(headingInfo.dataId)!;
    const widget = row.getWidgetT<null, HeadingRuntimeProperties>(
      headingInfo.name
    )!;
    headingText = widget.properties.headingText;
  }

  return (
    <form>
      <div id={props.labelledById} style={{ display: "none" }}>
        {headingText}
      </div>
      <Layout config={props.config} propagated={props.propagated} />
    </form>
  );
});

import { styled } from "@mui/material";
import * as React from "react";
import { Layout, LayoutConfig } from "../../config";
import KeyboardNavigationGroup from "../../coreui/KeyboardNavigationGroup";
import { Container } from "./Container";

interface FooterRootProps {
  backgroundColor: string;
}

interface Props extends FooterRootProps {
  layout?: LayoutConfig;
}

const Content = styled(Container)(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    flexBasis: "100%",
  },
}));

const FooterRoot = styled("footer")<FooterRootProps>(({ theme, ...props }) => ({
  [theme.breakpoints.up("lg")]: {
    paddingBottom: theme.paper.padding.lg,
    paddingTop: theme.paper.padding.lg,
  },
  [theme.breakpoints.only("md")]: {
    paddingBottom: theme.paper.padding.md,
    paddingTop: theme.paper.padding.md,
  },
  [theme.breakpoints.only("sm")]: {
    paddingBottom: theme.paper.padding.sm,
    paddingTop: theme.paper.padding.sm,
  },
  [theme.breakpoints.only("xs")]: {
    paddingBottom: theme.paper.padding.xs,
    paddingTop: theme.paper.padding.xs,
  },
  "@media print": {
    display: "none",
  },
  alignItems: "center",
  backgroundColor: props.backgroundColor,
  display: "flex",
  overflow: "hidden",
}));

export const Footer = (props: Props): JSX.Element => (
  <FooterRoot backgroundColor={props.backgroundColor}>
    {props.layout ? (
      <Content>
        <KeyboardNavigationGroup childSelector="a">
          <Layout
            config={props.layout}
            preventClear={true}
            propagated={{ isInPageFooter: true }}
          />
        </KeyboardNavigationGroup>
      </Content>
    ) : (
      <div style={{ height: 20 }} />
    )}
  </FooterRoot>
);

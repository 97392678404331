import AppServer, { State as AppServerState } from "../core/AppServer";
import { BusinessError } from "../stores/ErrorsStore";
import LayoutStateStore from "../stores/LayoutStateStore";
import BaseService from "./BaseService";

export interface OnClickResponse {
  appServerState: AppServerState | null;
  businessErrors: BusinessError[];
  url: string | null;
  validationErrors: string[];
}

export default class PresentationButtonService {
  public static onClick(
    rowKey: string,
    dataId: string,
    widgetName: string
  ): Promise<OnClickResponse> {
    return BaseService.requestObject<OnClickResponse>(
      `PresentationButton/OnClick/${rowKey}/${dataId}/${widgetName}`,
      null,
      null,
      {
        appServerState: AppServer.getState(),
        layoutState: LayoutStateStore.getCurrentState(),
      },
      "POST"
    );
  }
}

import Sys from "./Sys";

export class TraceKey {
  private static readonly cookieName: string = "traceKey";

  public static clear(): void {
    Sys.setCookie(TraceKey.cookieName, "");
  }

  public static prompt(): void {
    const traceKey = prompt("Enter the key given to you by the help desk:", "");
    if (traceKey) {
      TraceKey.set(traceKey);
      alert("Trace key has been set; execute the action you wish to trace");
    } else {
      alert("Blank trace keys are not set. Try again");
    }
  }

  public static set(traceKey: string): void {
    Sys.setCookie(TraceKey.cookieName, traceKey);
  }
}

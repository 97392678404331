import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../core/Localization";
import { useWidth, WidthProps } from "../core/Responsive";
import Sys from "../core/Sys";
import FocusTracker from "../coreui/FocusTracker";
import ProcessingMask from "../coreui/ProcessingMask";
import Table, { TableChildProps, TableProps } from "../coreui/Table";
import AsyncData, {
  AsyncData as AsyncDataBase,
  GetDataResponse,
  LoadingState,
} from "../coreui/table/AsyncData";
import PaneRow from "../models/PaneRow";
import RoundTripService from "../services/RoundTripService";
import PaneDataStore from "../stores/PaneDataStore";
import { AccessLevel } from "./AccessLevel";
import GridColumn, { GridColumnConfigProperties } from "./Columns/GridColumn";
import { DashboardCriteria as DashboardCriteriaBase } from "./DashboardCriteria";
import ErrorBoundary from "./ErrorBoundary";

interface ConfigProperties {
  cardDepth: number;
  columns: GridColumnConfigProperties[];
  contentDataId: string;
  data?: object;
  dataId: string;
  description: string;
  footerToolbar?: object;
  headerToolbar?: object;
  name: string;
  searches: { description: string; name: string }[];
  summaryToolbar?: object;
  tableKey: string;
  verticalLayout?: object;
}

export interface DashboardChildProps {
  parentDashboard: {
    initialSearch: string;
    search: (search: string) => Promise<void>;
    searches: Search[];
  };
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  initialSearch: string;
}

export interface Search {
  description: string;
  name: string;
}

interface State {
  isMounted: boolean;
  isSearching: boolean;
}

export class DashboardGridControl extends React.Component<
  ConfigProperties & WidthProps,
  State
> {
  private asyncDataRef: React.RefObject<AsyncData>;
  private readonly contentDataId: string;
  private currentSearchName: string;
  private readonly dataId: string;
  private focusTrackerRef: React.RefObject<FocusTracker>;
  private loadingHasBeenNotified: boolean = false;
  private readonly name: string;
  private populate: ((rows: PaneRow[]) => void) | null = null;
  private propagated: TableChildProps & DashboardChildProps;
  private tableProps: TableProps;

  public constructor(props: ConfigProperties & WidthProps) {
    super(props);

    this.state = { isMounted: false, isSearching: false };
    this.asyncDataRef = React.createRef();
    this.focusTrackerRef = React.createRef();

    this.contentDataId = props.contentDataId;
    this.dataId = props.dataId;
    this.name = props.name;

    const row = PaneRow.get(this.dataId)!;
    const widget = row.getWidgetT<null, RuntimeProperties>(this.name);

    if (DashboardCriteriaBase.values.has(this.props.contentDataId)) {
      this.currentSearchName = DashboardCriteriaBase.values.get(
        this.props.contentDataId
      )!;
    } else {
      this.currentSearchName = widget.properties.initialSearch;
    }

    const tableChildProps = {
      parentTable: {
        cardDepth: props.cardDepth,
        columns: props.columns,
        configProps: {
          contentDataId: props.contentDataId,
          data: props.data,
          dataId: props.dataId,
          name: props.name,
          tableKey: props.tableKey,
        },
        description: props.description,
        hasRelatedEditDialog: false,
        isDocumentGrid: false,
        populateData: () => this.populateData(),
      },
    } as TableChildProps;

    this.propagated = {
      parentDashboard: {
        initialSearch: widget.properties.initialSearch,
        search: this.search,
        searches: props.searches,
      },
      ...tableChildProps,
    };

    this.tableProps = {
      "aria-label": this.props.description,
      cardDepth: props.cardDepth,
      cellEdit: false,
      columns: [],
      contentDataId: props.contentDataId,
      dataId: props.dataId,
      footerToolbarChild: props.footerToolbar,
      getAccessibleDescription: this.getAccessibleDescription,
      headerToolbarChild: props.headerToolbar,
      ignoreBusinessErrors: true,
      isColumnFlex: (colId: string) =>
        GridColumn.isColumnFlex(props.columns, colId),
      isColumnVisible: (colId: string, breakpoint: string) =>
        GridColumn.isColumnVisible(props.columns, colId, breakpoint),
      minRowHeight: GridColumn.getColumnsMinRowHeight(props.columns),
      name: props.name,
      propagated: this.propagated,
      resetPageOnPopulate: true,
      setPopulate: (populate) => (this.populate = populate),
      showNoData: true,
      summaryToolbarChild: props.summaryToolbar,
    };
  }

  private announceLoadingComplete = (): void => {
    setTimeout(() => {
      Sys.announce(
        Localization.getBuiltInMessage("DataTable.loadComplete", {
          gridDescription: this.props.description,
        }),
        true
      );
    }, 1000);
  };

  private announceLoadingStarted = (): void => {
    Sys.announce(
      Localization.getBuiltInMessage("DataTable.loadStarted", {
        gridDescription: this.props.description,
      }),
      true
    );
  };

  private getAccessibleDescription(rowCount: number): string {
    if (rowCount === 0) {
      return Localization.getBuiltInMessage("DataTable.tableRowCountZero");
    }

    if (rowCount === 1) {
      return Localization.getBuiltInMessage("DataTable.tableRowCountSingle");
    }

    return Localization.getBuiltInMessage("DataTable.tableRowCountMultiple", {
      count: rowCount,
    });
  }

  private getData = (searchName?: string) => {
    const row = PaneRow.get(this.dataId)!;
    return RoundTripService.partialDataRetrevial<GetDataResponse>(
      `DashboardGridControl/GetRowsData/${row.rowKey}/${this.dataId}/${this.name}`,
      undefined,
      { SearchName: searchName ? searchName : this.currentSearchName }
    );
  };

  private onFocusChanged = (isFocused: boolean): void => {
    if (!this.asyncDataRef) {
      return;
    }

    if (isFocused) {
      const loadingState: LoadingState =
        this.asyncDataRef.current!.getLoadingState();
      if (loadingState.isLoadingData || loadingState.isPopulatingData) {
        this.announceLoadingStarted();
      }
    } else {
      this.loadingHasBeenNotified = false;
    }
  };

  private onIsLoadingChanged = (
    isLoadingData: boolean,
    isPopulatingData: boolean
  ): void => {
    if (!this.focusTrackerRef) {
      return;
    }

    if (!this.focusTrackerRef.current?.isFocused()) {
      return;
    }

    if (isLoadingData || isPopulatingData) {
      if (!this.loadingHasBeenNotified) {
        this.announceLoadingStarted();
        this.loadingHasBeenNotified = true;
      }
    } else {
      this.announceLoadingComplete();
      this.loadingHasBeenNotified = false;
    }
  };

  private populateData = () => {
    const rows: PaneRow[] = PaneDataStore.getPaneCollection(this.contentDataId);

    if (this.populate !== null) {
      this.populate(rows);
    }
  };

  private search = async (search: string) => {
    this.setState({ isSearching: true });

    try {
      await AsyncDataBase.processResponse(this.getData(search));
      const rows: PaneRow[] = PaneDataStore.getPaneCollection(
        this.props.contentDataId
      );

      this.currentSearchName = search;

      setTimeout(() => {
        Sys.announce(
          Localization.getBuiltInMessage(
            rows.length === 0
              ? "DataTable.tableRowCountZero"
              : rows.length === 1
              ? "DataTable.tableRowCountSingle"
              : "DataTable.tableRowCountMultiple",
            { count: rows.length }
          )
        );
      }, 2000);
    } finally {
      this.setState({ isSearching: false });
    }
  };

  public componentDidMount(): void {
    for (const column of this.props.columns) {
      this.tableProps.columns.push(
        GridColumn.getColumnDef(column, this.props.columns, this.propagated)
      );
    }

    this.setState({ isMounted: true });
  }

  public componentDidUpdate(prevProps: ConfigProperties & WidthProps): void {
    if (prevProps.width !== this.props.width) {
      setTimeout(() => this.populateData());
    }
  }

  public componentWillUnmount(): void {
    this.setState({ isMounted: false });
  }

  public render(): React.ReactNode {
    const row = PaneRow.get(this.props.dataId);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<null, RuntimeProperties>(this.props.name);

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    return (
      <FocusTracker
        onFocusChanged={this.onFocusChanged}
        ref={this.focusTrackerRef}
      >
        <ErrorBoundary title={this.props.name}>
          <div style={{ position: "relative" }}>
            <AsyncData
              contentDataId={this.props.contentDataId}
              dataId={this.props.dataId}
              getData={this.getData}
              onIsLoadingChanged={this.onIsLoadingChanged}
              populateData={this.populateData}
              ref={this.asyncDataRef}
            />
            <ProcessingMask isOpen={this.state.isSearching} />
            {
              // FUTURE
              // An isMounted flag is used to ensure the Table constructor only
              // fires when there are actually columns for the table. The root
              // cause is that the Table component is doing rendering logic in
              // the constructor, which is counter to the architecture of React.
              // At "some point", likely when moving to the MUI Grid component,
              // this should be reworked.
              this.state.isMounted && (
                <Table
                  {...this.tableProps}
                  fullWidthChild={this.props.verticalLayout}
                  tableKey={this.props.tableKey}
                />
              )
            }
          </div>
        </ErrorBoundary>
      </FocusTracker>
    );
  }
}

// FUTURE
// This wrapper component was created to avoid the scope of converting this
// component to a hooks component during the MUI 5 upgrade. When the legacy
// component is converted to a hooks component, this wrapper can be removed and
// hooks it calls can be called by the converted component directly.
const Observer = observer(DashboardGridControl);
export default function Wrapped(props: ConfigProperties): JSX.Element {
  const width = useWidth();
  return <Observer {...props} width={width} />;
}

import { Badge, styled, Tab, TabProps, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import Localization from "../core/Localization";
import { useWidth } from "../core/Responsive";
import Button from "./Button";
import Icon from "./Icon";

interface Props extends TabProps {
  businessErrorsCount: number;
  complete: boolean;
  last: boolean;
  selected: boolean;
  step: number;
  stepCount: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  badge: {
    [theme.breakpoints.up("sm")]: {
      left: "50%",
    },
    backgroundColor: theme.palette.error.main,
    left: 20,
    top: 10,
    width: 20,
    zIndex: 2000, // Ensure the badge is above the fab.
  },
  badgeRoot: {
    width: "100%",
  },
  disabled: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    opacity: "1 !important" as any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pointerEvents: "auto !important" as any,
  },
  label: {
    // Required because Chrome can't do math.
    [theme.breakpoints.up("lg")]: { maxWidth: 134, minWidth: 133.5 },
    [theme.breakpoints.only("md")]: { maxWidth: 136, minWidth: 136 },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 8,
      paddingTop: 24,
      textAlign: "center",
    },
    [theme.breakpoints.only("sm")]: { maxWidth: 126, minWidth: 126 },
    [theme.breakpoints.only("xs")]: { maxWidth: "100%", minWidth: 300 },
    color: theme.palette.text.primary,
    overflow: "hidden",
    paddingLeft: 32,
    paddingRight: 8,
    textAlign: "left",
    textOverflow: "ellipsis",
  },
  labelDisabled: {
    color: theme.palette.grey[300],
  },
  root: {
    "&:focus-visible": { fontWeight: "bold" },
    alignItems: "flex-start",
    flex: "auto",
    height: "100%",
    maxWidth: "100%",
    overflow: "visible",
  },
  selected: {
    backgroundColor: "transparent !important",
  },
  step: {
    [theme.breakpoints.up("sm")]: {
      flexDirection: "column",
      paddingTop: 8,
    },
    alignItems: "center",
    display: "flex",
    flex: "auto",
    width: "100%",
  },
  wrapped: {
    [theme.breakpoints.up("lg")]: { maxWidth: 150, minWidth: 150 },
    [theme.breakpoints.only("md")]: { maxWidth: 152, minWidth: 152 },
    [theme.breakpoints.only("sm")]: { maxWidth: 142, minWidth: 142 },
    [theme.breakpoints.only("xs")]: { maxWidth: "100%", minWidth: 300 },
    alignItems: "flex-start",
    flex: "auto",
    height: "100%",
    maxWidth: "100%",
    overflow: "visible",
  },
}));

const ErrorBadgeIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.common.white,
  width: 8,
}));

interface StepBarProps {
  complete: boolean;
  first: boolean;
  last: boolean;
  selected: boolean;
}

const StepBar = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "complete" &&
    prop !== "first" &&
    prop !== "last" &&
    prop !== "selected",
})<StepBarProps>(
  ({ theme, first, last }) => ({
    [theme.breakpoints.up("sm")]: {
      height: 8,
      marginBottom: 26,
      marginLeft: 0,
      marginTop: -34,
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      height: first || last ? 46 : 56,
      marginBottom: last ? 10 : undefined,
      marginLeft: -24,
      marginTop: first ? 10 : undefined,
      width: 8,
    },
    backgroundColor: theme.palette.grey[300],
    minWidth: 8,
  }),
  ({ theme, complete }) =>
    complete && {
      backgroundColor: theme.palette.success.main,
    },
  ({ theme, selected }) =>
    selected && {
      backgroundColor: theme.palette.grey[800],
    }
);

interface StepButtonProps {
  complete: boolean;
  selected: boolean;
}

const StepButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "complete" && prop !== "selected",
})<StepButtonProps>(
  ({ theme }) => ({
    [theme.breakpoints.up("sm")]: {
      fontSize: 24,
      height: 60,
      width: 60,
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: 16,
    },
    minWidth: 40,
  }),
  ({ theme, complete }) => complete && { color: theme.palette.common.white },
  ({ theme, complete, selected }) =>
    complete &&
    selected && {
      borderColor: theme.palette.success.main,
      borderStyle: "solid",
      borderWidth: 4,
    }
);

export default function Step(props: Props): JSX.Element {
  const {
    businessErrorsCount,
    complete,
    last,
    onChange,
    onClick,
    selected,
    step,
    stepCount,
    ...tabProps
  } = props;

  const classes = useStyles();
  const width = useWidth();

  const onTabClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (!props.disabled && onClick) {
        onClick(event);
        event.stopPropagation();
      }
    },
    [props.disabled, onClick]
  );

  let labelClasses: string = classes.label;
  if (tabProps.disabled) {
    labelClasses += ` ${classes.labelDisabled}`;
  }

  const accessibleDescription: string = Localization.getBuiltInMessage(
    complete ? "Wizard.stepComplete" : "Wizard.stepIncomplete",
    {
      count: stepCount,
      step,
    }
  );

  tabProps.label = (
    <div className={classes.step}>
      <StepButton
        aria-hidden={true}
        color={selected ? "dark" : complete ? "success" : "default"}
        complete={complete}
        component="span"
        disabled={tabProps.disabled}
        disableRipple={true}
        icon={(complete && !selected) || last ? "fas fa-check" : undefined}
        fab
        role={undefined}
        selected={selected}
        tabIndex={-1}
      >
        {(complete && !selected) || last ? null : step}
      </StepButton>
      <StepBar
        complete={complete}
        first={step === 1}
        last={last}
        selected={selected}
      />
      <span className="screenReaderOnly">{accessibleDescription}</span>
      <span className={labelClasses}>{tabProps.label}</span>
    </div>
  );

  if (businessErrorsCount > 0) {
    let accessibleErrorMessage: string;

    if (businessErrorsCount > 1) {
      accessibleErrorMessage = Localization.getBuiltInMessage(
        "Wizard.erroredFieldsCountMultiple",
        {
          widgetErrorCount: businessErrorsCount,
        }
      );
    } else {
      accessibleErrorMessage = Localization.getBuiltInMessage(
        "Wizard.erroredFieldsCountSingle"
      );
    }

    tabProps.label = (
      <Badge
        badgeContent={<ErrorBadgeIcon icon="fas fa-exclamation" />}
        classes={{ badge: classes.badge, root: classes.badgeRoot }}
      >
        {tabProps.label}
        <span className="screenReaderOnly">{accessibleErrorMessage}</span>
      </Badge>
    );
  }

  return (
    <Tab
      classes={{
        disabled: classes.disabled,
        root:
          ((width === "xl" || width === "lg") && step > 8) ||
          (width === "md" && step > 6) ||
          (width === "sm" && step > 4)
            ? classes.wrapped
            : classes.root,
        selected: classes.selected,
      }}
      onClick={onTabClick}
      {...tabProps}
      // VERSION_WARNING MUI 5.14.9
      // The `selected` prop is not part of the Tab api. Normally it is set by
      // the Tabs component, which is not used in this case and therefore
      // `selected` must be propagated manually.
      // See https://mui.com/material-ui/api/tab/#Tab-css-selected
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...({ selected } as any)}
    />
  );
}

import { Input, InputAdornment } from "@mui/material";
import { ICellEditorParams } from "ag-grid-community";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../../core/Localization";
import Sys from "../../core/Sys";
import Button from "../../coreui/Button";
import { ErrorBadge } from "../../coreui/ErrorBadge";
import { TableChildProps } from "../../coreui/Table";
import { CellUtil } from "../../coreui/table/CellUtil";
import PaneRow from "../../models/PaneRow";
import ErrorsStore from "../../stores/ErrorsStore";
import { NumericEdit } from "../NumericEdit";
import { GridColumnConfigProperties } from "./GridColumn";

interface ConfigProperties extends ICellEditorParams<PaneRow, string | null> {
  dataId: string;
  increment: number | null;
  justification: "Left" | "Right";
  maximum: number | null;
  maximumError: string | null;
  minimum: number | null;
  minimumError: string | null;
  name: string;
  propagated: TableChildProps;
  scale: number | null;
  scaleError: string | null;
}

interface State {
  value?: string | null;
}

interface RuntimeProperties {
  businessErrors: string[];
}

export class NumericEditColumnEdit extends React.Component<
  ConfigProperties,
  State
> {
  private updateValue: boolean;
  protected inputElement: HTMLInputElement;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static getCurrentValueParsed(value: any) {
    let parsed: number | null = null;

    if (value) {
      switch (typeof value) {
        case "string":
          const cleaned = value.replace(
            Sys.settings.decimalSeparator,
            NumericEdit.systemDecimalCharacter
          );

          const regex = new RegExp(`\\${Sys.settings.thousandsSeparator}`, "g");
          parsed = parseFloat(cleaned.replace(regex, ""));
          if (isNaN(parsed)) {
            parsed = null;
          }
          break;
        case "number":
          parsed = value as number;
          break;
        default:
          throw new Error(
            "Unexpected numeric edit value type " + `${typeof value}`
          );
      }
    }

    return parsed;
  }

  public static getErrors(
    props: ConfigProperties,
    row: PaneRow,
    value: string | null
  ): string[] {
    const widget = row.getWidgetT<string | null, RuntimeProperties>(props.name);
    // Ensure errors are always accessed so the component knows to observe them
    const widgetErrors = [...widget.properties.businessErrors];
    const errors = row.hasChanges(props.name) ? [] : [...widgetErrors];

    const parsedValue = NumericEditColumnEdit.getCurrentValueParsed(value);

    if (parsedValue !== null) {
      if (props.minimum !== null && parsedValue < props.minimum) {
        errors.push(props.minimumError!);
      }

      if (props.maximum !== null && parsedValue > props.maximum) {
        errors.push(props.maximumError!);
      }

      if (props.increment !== null) {
        const scale = props.scale !== null ? Math.pow(10, props.scale) : 1;
        if (
          (Math.round(parsedValue * scale) % (props.increment * scale)) /
            scale !==
          0
        ) {
          errors.push(props.scaleError!);
        }
      }
    }

    return errors;
  }

  public constructor(props: ConfigProperties) {
    super(props);

    this.state = { value: props.value };
  }

  private get column(): GridColumnConfigProperties | undefined {
    const parentTable = this.props.propagated.parentTable;
    const tableColumns = parentTable.columns as GridColumnConfigProperties[];
    return tableColumns.find((c) => c.name === this.props.colDef.colId);
  }

  private formatValue(value: number | null, userFormatted: boolean) {
    return NumericEdit.formatNumericValue(
      value,
      userFormatted,
      this.props.scale
    );
  }

  private handleIncreaseOrDecreaseValue(increase: boolean) {
    if (this.updateValue) {
      this.increaseOrDecreaseValue(increase);

      setTimeout(() => {
        this.handleIncreaseOrDecreaseValue(increase);
      }, 150);
    }
  }

  private increaseOrDecreaseValue(increase: boolean) {
    if (this.props.increment === null) {
      return;
    }

    let currentValue = NumericEditColumnEdit.getCurrentValueParsed(
      this.state.value || ""
    );

    if (
      this.props.minimum !== null &&
      (currentValue === null || currentValue < this.props.minimum)
    ) {
      currentValue = this.props.minimum;
    } else if (
      this.props.maximum !== null &&
      (currentValue === null || currentValue > this.props.maximum)
    ) {
      currentValue = this.props.maximum;
    } else {
      currentValue = currentValue || 0;
      currentValue = currentValue + this.props.increment * (increase ? 1 : -1);
    }

    if (
      (increase ||
        this.props.minimum === null ||
        currentValue >= this.props.minimum) &&
      (!increase ||
        this.props.maximum === null ||
        currentValue <= this.props.maximum)
    ) {
      this.setFormattedValue(currentValue);
    }

    if (
      (this.props.minimum !== null &&
        currentValue !== undefined &&
        currentValue < this.props.minimum + this.props.increment!) ||
      (this.props.maximum !== null &&
        currentValue !== undefined &&
        currentValue > this.props.maximum - this.props.increment!)
    ) {
      this.updateValue = false;

      if (this.inputElement) {
        this.inputElement.focus();
      } else {
        this.props.api!.startEditingCell({
          colKey: this.props.column.getColId(),
          rowIndex: this.props.rowIndex,
        });
      }
    }
  }

  private onKeyUp = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!event.key || !this.props.increment) {
      return;
    }

    if (event.key === "ArrowUp") {
      this.increaseOrDecreaseValue(false);
    }

    if (event.key === "ArrowDown") {
      this.increaseOrDecreaseValue(true);
    }
  };

  private onMouseUp = () => {
    this.updateValue = false;
    window.removeEventListener("mouseup", this.onMouseUp);
  };

  private setFormattedValue(value: number | null) {
    ErrorsStore.clearBusinessErrorsForTableCell(
      this.props.dataId,
      this.props.name,
      this.props.data.rowKey
    );
    this.setState({ value: this.formatValue(value, false) });
  }

  private startIncreaseOrDecreaseValue(increase: boolean) {
    this.updateValue = true;

    window.addEventListener("mouseup", this.onMouseUp);

    setTimeout(() => {
      this.handleIncreaseOrDecreaseValue(increase);
    }, 250);
  }

  private validateKeyPress(
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    if (!event.key) {
      return;
    }

    const validCharacters = [
      Sys.settings.thousandsSeparator,
      Sys.settings.decimalSeparator,
      "-",
    ];
    for (let i = 0; i <= 9; i++) {
      validCharacters.push(i.toString());
    }

    const isControlChar = event.key.length > 1;
    if (
      !event.ctrlKey &&
      !isControlChar &&
      validCharacters.indexOf(event.key) < 0
    ) {
      event.preventDefault();
    }
  }

  public componentDidMount() {
    CellUtil.disableGridNavigation(this.props.eGridCell, this.inputElement);

    CellUtil.setInitialFocus(this.inputElement);
  }

  public componentWillUnmount() {
    CellUtil.enableGridNavigation(this.props.eGridCell);
  }

  public getValue(): string | null {
    this.updateValue = false;

    return this.formatValue(
      NumericEditColumnEdit.getCurrentValueParsed(this.state.value),
      false
    );
  }

  public render() {
    const value = this.state.value || "";

    const errors: string[] = NumericEditColumnEdit.getErrors(
      this.props,
      this.props.data,
      value
    );

    const parsedValue = NumericEditColumnEdit.getCurrentValueParsed(value);
    const incrementButtons =
      this.props.increment !== null ? (
        <InputAdornment
          position="end"
          style={{ marginRight: 24, marginTop: -4 }}
        >
          <React.Fragment>
            <Button
              aria-label={Localization.getBuiltInMessage("decrement")}
              disabled={
                parsedValue !== null &&
                this.props.minimum !== null &&
                parsedValue - this.props.increment < this.props.minimum
              }
              icon="fas fa-minus"
              size="small"
              onClick={() => this.increaseOrDecreaseValue(false)}
              onMouseDown={() => this.startIncreaseOrDecreaseValue(false)}
            />
            <Button
              aria-label={Localization.getBuiltInMessage("increment")}
              disabled={
                parsedValue !== null &&
                this.props.maximum !== null &&
                parsedValue + this.props.increment > this.props.maximum
              }
              icon="fas fa-plus"
              size="small"
              style={{ marginLeft: 8 }}
              onClick={() => this.increaseOrDecreaseValue(true)}
              onMouseDown={() => this.startIncreaseOrDecreaseValue(true)}
            />
          </React.Fragment>
        </InputAdornment>
      ) : undefined;

    return (
      <ErrorBadge
        errors={errors}
        isShort={
          this.props.data!.isNew &&
          this.props.propagated.parentTable.isDocumentGrid
        }
      >
        <Input
          autoFocus={true}
          fullWidth={true}
          endAdornment={incrementButtons}
          error={errors.length > 0}
          inputProps={{
            max: this.props.maximum,
            min: this.props.minimum,
            style: { marginLeft: "24px", paddingLeft: 0, paddingRight: 0 },
          }}
          inputRef={(element) => {
            this.inputElement = element;
          }}
          onChange={(e) => {
            ErrorsStore.clearBusinessErrorsForTableCell(
              this.props.dataId,
              this.props.name,
              this.props.data.rowKey
            );
            this.setState({ value: e.target.value });
          }}
          onKeyDown={(e) => this.validateKeyPress(e)}
          // ArrowUp and ArrowDown are eaten by the onKeyDown event so use
          // onKeyUp for increment/decrement.
          onKeyUp={(e) => this.onKeyUp(e)}
          required={this.column?.required}
          style={{ height: "calc(100% + 2px)", paddingLeft: 2 }}
          value={value}
        />
      </ErrorBadge>
    );
  }
}

export default observer(NumericEditColumnEdit);

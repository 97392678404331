import {
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
  Theme,
} from "@mui/material";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import * as React from "react";
import Sys from "../core/Sys";
import WithForwardedRef, {
  WithForwardedRefProps,
} from "../core/WithForwardedRef";
import Typography from "../coreui/Typography";
import Icon from "./Icon";
import multiClassName from "./MultiClassName";

export interface MenuItemProps extends MuiMenuItemProps, WithForwardedRefProps {
  button?: true;
  href?: string;
  iconName?: string;
  indent?: number;
  target?: string;
}

const styles = (theme: Theme) =>
  createStyles({
    disabled: {
      "& > p": { color: theme.palette.grey[300] },
      "& > span": { color: theme.palette.grey[300] },
      "&:hover": { backgroundColor: "transparent" },
      cursor: "default",
    },
    link: {
      textDecoration: "none",
    },
    root: {
      color: theme.palette.grey[800],
    },
    typography: {
      color: theme.palette.grey[800],
    },
  });

export class MenuItem extends React.PureComponent<
  MenuItemProps & WithStyles<typeof styles>
> {
  private anchorRef = React.createRef<HTMLAnchorElement>();
  private readonly componentId: string;
  private readonly labelId: string;

  public constructor(props: MenuItemProps & WithStyles<typeof styles>) {
    super(props);

    this.componentId = `menu-item-${Sys.nextId}`;
    this.labelId = `${this.componentId}-label`;
  }

  private onClick = (event: React.MouseEvent<HTMLLIElement>) => {
    if (this.props.onClick) {
      this.props.onClick(event);
    }
  };

  private onKeyUp = (event: React.KeyboardEvent<HTMLLIElement>) => {
    // Suppress the Space key for menus
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  public render(): React.ReactNode {
    const {
      children,
      classes,
      className,
      component,
      href,
      iconName,
      forwardedRef,
      onClick,
      role,
      target,
      ...itemProps
    } = this.props;

    let content: React.ReactNode = children;
    let labelId: string | undefined = undefined;
    if (typeof content === "string") {
      labelId = this.labelId;
      content = (
        <Typography
          aria-hidden={true}
          className={classes.typography}
          ellipsis
          id={this.labelId}
        >
          {content}
        </Typography>
      );
    }

    if (iconName) {
      content = (
        <React.Fragment>
          <Icon icon={iconName} style={{ marginRight: ".4em" }} />
          {content}
        </React.Fragment>
      );
    }

    return (
      <MuiMenuItem
        {...itemProps}
        aria-labelledby={labelId}
        className={multiClassName(
          className,
          classes.root,
          itemProps.disabled ? classes.disabled : "",
          href ? classes.link : ""
        )}
        component={component || (href !== undefined ? "a" : "li")}
        href={href}
        onClick={this.onClick}
        onKeyUp={this.onKeyUp}
        ref={forwardedRef}
        rel={href !== undefined && target === "_blank" ? "noopener" : undefined}
        role={role || "menuitem"}
        style={{ marginLeft: itemProps.indent }}
        target={href !== undefined ? target : undefined}
      >
        {content}
      </MuiMenuItem>
    );
  }
}

export default withStyles(styles)(WithForwardedRef(MenuItem));

import { styled } from "@mui/material";
import { observer } from "mobx-react";
import * as React from "react";
import { useWidth } from "../core/Responsive";
import KeyboardNavigationGroup from "../coreui/KeyboardNavigationGroup";
import Step from "../coreui/Step";
import ErrorsStore from "../stores/ErrorsStore";
import { AccessLevel } from "./AccessLevel";
import { WizardControl as WizardControlBase } from "./WizardControl";

const StepsContainer = styled(KeyboardNavigationGroup)({
  display: "flex",
  flexWrap: "wrap",
});

export const WizardStepsDisplay = observer((): JSX.Element | null => {
  const width = useWidth();
  const widgetProperties = WizardControlBase.getWidgetProperties();
  const steps = WizardControlBase.getSteps();

  if (!steps) {
    return null;
  }

  if (widgetProperties.accessLevel === AccessLevel.hidden) {
    return null;
  }

  return (
    <StepsContainer
      childSelector={'[role="tab"]'}
      role="tablist"
      upDown={width === "xs"}
    >
      {steps.map((step, index) => {
        const stepWidgetProps = widgetProperties.steps.find(
          (s) => s.paneUseKey === step.paneUseKey
        )!;

        if (!stepWidgetProps) {
          return null;
        }

        if (stepWidgetProps.accessLevel === AccessLevel.hidden) {
          return null;
        }

        return (
          <Step
            complete={stepWidgetProps.stepComplete}
            disabled={stepWidgetProps.accessLevel === AccessLevel.disabled}
            businessErrorsCount={ErrorsStore.getBusinessErrorsCountForPane(
              step.paneUseKey
            )}
            key={step.paneUseKey}
            label={step.label}
            last={index + 1 === widgetProperties.steps.length}
            onClick={() => {
              WizardControlBase.gotoStep(step.paneUseKey);
            }}
            selected={step.paneUseKey === widgetProperties.selectedPaneUseKey}
            step={WizardControlBase.getStepNumber(index)}
            stepCount={WizardControlBase.getStepCount()}
            tabIndex={-1}
            value={step.paneUseKey}
          />
        );
      })}
    </StepsContainer>
  );
});

import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { Dialog, DialogChildProps, DialogLayoutConfig } from "../config";
import AppServer from "../core/AppServer";
import Localization from "../core/Localization";
import RequestPromise from "../core/RequestPromise";
import { useWidth, WidthProps } from "../core/Responsive";
import Sys from "../core/Sys";
import ComboBoxOption from "../coreui/ComboBoxOption";
import { BreakPointColumn } from "../coreui/Dialog";
import FocusTracker from "../coreui/FocusTracker";
import Table, { TableChildProps, TableProps } from "../coreui/Table";
import AsyncData, {
  GetDataResponse,
  LoadingState,
} from "../coreui/table/AsyncData";
import DocumentDropArea from "../coreui/table/DocumentDropArea";
import PaneRow from "../models/PaneRow";
import DialogService from "../services/DialogService";
import RoundTripService from "../services/RoundTripService";
import SimpleGridControlService, {
  OnDialogCloseResponse,
} from "../services/SimpleGridControlService";
import DocumentStore, {
  CompleteDocumentUploadResponse,
  CreateDocumentRowsResponse,
} from "../stores/DocumentStore";
import ErrorsStore from "../stores/ErrorsStore";
import PaneDataStore from "../stores/PaneDataStore";
import { AccessLevel } from "./AccessLevel";
import GridColumn, { GridColumnConfigProperties } from "./Columns/GridColumn";
import TableEditDialog from "./Columns/TableEditDialog";
import { EmbeddedAddOn as EmbeddedAddOnBase } from "./EmbeddedAddOn";
import ErrorBoundary from "./ErrorBoundary";
import { GridRelatedEditButton } from "./GridRelatedEditButton";
import Panel from "./Panel";

interface ConfigProperties {
  cardDepth: number;
  columns: GridColumnConfigProperties[];
  contentDataId: string;
  data?: object;
  dataId: string;
  description: string;
  dialogLayoutId?: number;
  footerToolbar?: object;
  headerToolbar?: object;
  isDocumentGrid: boolean;
  maxFileBytes: number | null;
  maxFileBytesError: string;
  name: string;
  rowSelection: boolean;
  selectionToolbar?: object;
  summaryToolbar?: object;
  tableKey: string;
  validExtensions: string[];
  validExtensionsError: string;
  verticalLayout?: object;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  relationshipComboBoxOptions: { name: string; options: ComboBoxOption[] }[];
}

interface State {
  editDialogInfo?: { isFirstOpenOfNewRow: boolean; rowKey: string };
  isMounted: boolean;
}

export class SimpleGridControl extends React.Component<
  ConfigProperties & WidthProps,
  State
> {
  private asyncDataRef: React.RefObject<AsyncData>;
  private readonly contentDataId: string;
  private readonly dataId: string;
  private dialogLabelledById: string;
  private dialogLayoutConfig: DialogLayoutConfig | null = null;
  private dialogLayoutId?: number;
  private dialogPropagatedProps: DialogChildProps | undefined;
  private documentStore: DocumentStore;
  private focusTrackerRef: React.RefObject<FocusTracker>;
  private loadingHasBeenNotified: boolean = false;
  private readonly name: string;
  private populate: ((rows: PaneRow[]) => void) | null = null;
  private propagated: TableChildProps;
  private restoreFocusElement: HTMLElement | null = null;
  private restoreLostFocus: (() => void) | null;
  private scrollToRow: ((rowKey: string) => void) | null;
  private tableProps: TableProps;

  public constructor(props: ConfigProperties & WidthProps) {
    super(props);

    makeObservable<
      SimpleGridControl,
      "dialogLayoutConfig" | "setDialogLayoutConfig"
    >(this, {
      dialogLayoutConfig: observable,
      setDialogLayoutConfig: action,
    });

    this.state = { isMounted: false };
    this.asyncDataRef = React.createRef();
    this.focusTrackerRef = React.createRef();

    this.contentDataId = props.contentDataId;
    this.dataId = props.dataId;
    this.dialogLayoutId = props.dialogLayoutId;
    this.name = props.name;

    this.dialogLabelledById = `dialog-${Sys.nextId}-labelled-by`;

    this.propagated = {
      parentTable: {
        cardDepth: props.cardDepth,
        columns: props.columns,
        configProps: {
          contentDataId: props.contentDataId,
          data: props.data,
          dataId: props.dataId,
          name: props.name,
        },
        description: props.description,
        getRelationshipComboBoxOptions: this.getRelationshipComboBoxOptions,
        hasRelatedEditDialog: props.dialogLayoutId ? true : false,
        isDocumentGrid: props.isDocumentGrid,
        openRowEditDialog: (r, i, e) => this.openRowEditDialog(r, i, e),
        populateData: () => this.populateData(),
        uploadFiles: this.onUploadFiles,
        validExtensions: props.validExtensions,
      },
    } as TableChildProps;

    this.tableProps = {
      "aria-label": props.description,
      cardDepth: props.cardDepth,
      cellEdit: true,
      columns: [],
      contentDataId: props.contentDataId,
      dataId: props.dataId,
      footerToolbarChild: props.footerToolbar,
      getAccessibleDescription: this.getAccessibleDescription,
      headerToolbarChild: props.headerToolbar,
      isColumnFlex: (colId: string) =>
        GridColumn.isColumnFlex(props.columns, colId),
      isColumnVisible: (colId: string, breakpoint: string) =>
        GridColumn.isColumnVisible(props.columns, colId, breakpoint),
      isDocumentGrid: props.isDocumentGrid!,
      minRowHeight: GridColumn.getColumnsMinRowHeight(props.columns),
      name: props.name,
      propagated: this.propagated,
      rowSelection: props.rowSelection ? "multiple" : undefined,
      selectToolbarChild: props.selectionToolbar,
      setPopulate: (populate) => (this.populate = populate),
      setRestoreLostFocus: (restoreFocus) =>
        (this.restoreLostFocus = restoreFocus),
      setScrollToRow: (scrollToRow) => (this.scrollToRow = scrollToRow),
      summaryToolbarChild: props.summaryToolbar,
    };

    this.documentStore = new DocumentStore(
      props.contentDataId,
      this.completeUpload,
      this.createRows,
      this.populateData
    );
  }

  private announceLoadingComplete = (): void => {
    setTimeout(() => {
      Sys.announce(
        Localization.getBuiltInMessage("DataTable.loadComplete", {
          gridDescription: this.props.description,
        }),
        true
      );
    }, 1000);
  };

  private announceLoadingStarted = (): void => {
    Sys.announce(
      Localization.getBuiltInMessage("DataTable.loadStarted", {
        gridDescription: this.props.description,
      }),
      true
    );
  };

  private completeUpload = (
    uploadedFiles: object[]
  ): RequestPromise<CompleteDocumentUploadResponse> => {
    const row: PaneRow = PaneRow.get(this.props.dataId)!;

    return SimpleGridControlService.completeDocumentUpload(
      row.rowKey,
      this.props.dataId,
      this.props.name,
      uploadedFiles
    );
  };

  private getAccessibleDescription(rowCount: number): string {
    if (rowCount === 0) {
      return Localization.getBuiltInMessage("DataTable.tableRowCountZero");
    }

    if (rowCount === 1) {
      return Localization.getBuiltInMessage("DataTable.tableRowCountSingle");
    }

    return Localization.getBuiltInMessage("DataTable.tableRowCountMultiple", {
      count: rowCount,
    });
  }

  private createRows = (
    fileInfo: object[]
  ): RequestPromise<CreateDocumentRowsResponse> => {
    const row = PaneRow.get(this.dataId)!;

    return SimpleGridControlService.createDocumentRows(
      row.rowKey,
      this.dataId,
      this.name,
      fileInfo
    );
  };

  private getData = () => {
    const row = PaneRow.get(this.dataId)!;

    return RoundTripService.partialDataRetrevial<GetDataResponse>(
      `SimpleGridControl/GetRowsData/${row.rowKey}/${this.dataId}/${this.name}`
    );
  };

  private getRelationshipComboBoxOptions = (
    widgetName: string,
    selectedOption: ComboBoxOption
  ): ComboBoxOption[] => {
    const row = PaneRow.get(this.dataId);
    if (!row) {
      return [];
    }

    const widget = row.getWidgetT<null, RuntimeProperties>(this.name);

    const options = [
      ...widget.properties.relationshipComboBoxOptions.find(
        (o) => o.name === widgetName
      )!.options,
    ];

    if (selectedOption.value) {
      const optionInList = options.find(
        (o) => o.value === selectedOption.value
      );

      if (optionInList === undefined) {
        const historicOption = { ...selectedOption };
        historicOption.historic = true;
        options.push(historicOption);
      }
    }

    return options;
  };

  private onDialogAccept = async (parentRowKey: string): Promise<void> => {
    ErrorsStore.clearErrors();

    const row: PaneRow = PaneRow.get(this.dataId)!;
    const response: OnDialogCloseResponse =
      await SimpleGridControlService.onDialogClose(
        row.rowKey,
        this.dataId,
        this.name,
        parentRowKey
      );

    if (response.validationErrors.length > 0) {
      ErrorsStore.showErrors(response.validationErrors);
      return Promise.reject();
    }

    AppServer.setState(response.appServerState);

    if (response.businessErrors.length > 0) {
      ErrorsStore.clearErrors();
    } else {
      ErrorsStore.clearBusinessErrorsForTableRow(
        this.contentDataId,
        parentRowKey
      );
    }

    PaneDataStore.loadResponse(response.paneDataByDataId);

    if (ErrorsStore.setBusinessErrors(response.businessErrors, false)) {
      return Promise.reject();
    }

    AppServer.clearStateRecoveryPoint();
  };

  private onDialogClose = (accepted: boolean) => {
    if (accepted && this.state.editDialogInfo!.isFirstOpenOfNewRow) {
      this.scrollToRow!(this.state.editDialogInfo!.rowKey);
    }

    this.dialogPropagatedProps = undefined;

    this.setState({ editDialogInfo: undefined });

    if (accepted) {
      EmbeddedAddOnBase.resolveRoundTrip();
    } else {
      EmbeddedAddOnBase.rejectRoundTrip();
    }
  };

  private onDialogExited = (): void => {
    if (this.restoreFocusElement !== null) {
      this.restoreFocusElement.focus();
      this.restoreFocusElement = null;
    } else {
      this.restoreLostFocus!();
    }
  };

  private onDialogOpen = async (
    parentRowKey: string
  ): Promise<BreakPointColumn[]> => {
    ErrorsStore.clearErrors();
    if (!this.state.editDialogInfo!.isFirstOpenOfNewRow) {
      AppServer.createStateRecoveryPoint();
      EmbeddedAddOnBase.roundTripStarting();
    }

    const row = PaneRow.get(this.dataId)!;
    const dialogOpenRequest = SimpleGridControlService.onDialogOpen(
      row.rowKey,
      this.dataId,
      this.name,
      parentRowKey
    );

    const configRequest = DialogService.getConfig(this.dialogLayoutId!);

    const [dialogOpenResponse, configResponse] = await Promise.all([
      dialogOpenRequest,
      configRequest,
    ]);

    if (dialogOpenResponse.validationErrors.length > 0) {
      ErrorsStore.showErrors(dialogOpenResponse.validationErrors);
      AppServer.clearStateRecoveryPoint();

      return Promise.reject();
    }

    if (dialogOpenResponse.businessErrors.length > 0) {
      ErrorsStore.clearErrors();
      ErrorsStore.setBusinessErrors(dialogOpenResponse.businessErrors, false);
      ErrorsStore.pushErrorsToWidgets();

      return Promise.reject();
    }

    AppServer.setState(dialogOpenResponse.appServerState);
    PaneDataStore.loadResponse(dialogOpenResponse.paneDataByDataId);

    this.dialogPropagatedProps = {
      parentDialog: {
        rowKey: parentRowKey,
      },
    };
    this.setDialogLayoutConfig(configResponse.dialogLayout);

    return configResponse.breakPoints;
  };

  private onFocusChanged = (isFocused: boolean): void => {
    if (!this.asyncDataRef) {
      return;
    }

    if (isFocused) {
      const loadingState: LoadingState =
        this.asyncDataRef.current!.getLoadingState();
      if (loadingState.isLoadingData || loadingState.isPopulatingData) {
        this.announceLoadingStarted();
      }
    } else {
      this.loadingHasBeenNotified = false;
    }
  };

  private onIsLoadingChanged = (
    isLoadingData: boolean,
    isPopulatingData: boolean
  ): void => {
    if (!this.focusTrackerRef) {
      return;
    }

    if (!this.focusTrackerRef.current?.isFocused()) {
      return;
    }

    if (isLoadingData || isPopulatingData) {
      if (!this.loadingHasBeenNotified) {
        this.announceLoadingStarted();
        this.loadingHasBeenNotified = true;
      }
    } else {
      this.announceLoadingComplete();
      this.loadingHasBeenNotified = false;
    }
  };

  private onUploadFiles = async (files: FileList): Promise<void> => {
    const validFiles: File[] = [];

    for (let i = 0; i < files.length; i++) {
      const file: File = files[i];
      const fullFileName: string = file.name;
      const index: number = fullFileName.lastIndexOf(".");
      const extension: string =
        index > 0 ? fullFileName.substring(index + 1) : "";

      const errors: string[] = [];

      if (
        this.props.validExtensions.length > 0 &&
        this.props.validExtensions.indexOf(extension.toLowerCase()) < 0
      ) {
        errors.push(
          this.props.validExtensionsError.replace("{extension}", extension)
        );
      }

      if (
        this.props.maxFileBytes !== null &&
        file.size > this.props.maxFileBytes
      ) {
        errors.push(this.props.maxFileBytesError);
      }

      if (errors.length === 0) {
        validFiles.push(file);
      } else {
        ErrorsStore.showErrors(
          errors.map((error) => `${file.name} - ${error}`)
        );
      }
    }

    if (validFiles.length > 0) {
      await this.documentStore.uploadFiles(
        this.props.dataId,
        this.props.name,
        validFiles
      );
    }
  };

  private openRowEditDialog(
    rowKey: string,
    isFirstOpenOfNewRow: boolean,
    restoreFocusElement: HTMLElement | null
  ): void {
    this.setState({ editDialogInfo: { isFirstOpenOfNewRow, rowKey } });
    this.restoreFocusElement = restoreFocusElement;
  }

  private populateData = (): void => {
    const rows: PaneRow[] = PaneDataStore.getPaneCollection(this.contentDataId);

    if (this.populate !== null) {
      this.populate(rows);
    }
  };

  private setDialogLayoutConfig(config: DialogLayoutConfig): void {
    this.dialogLayoutConfig = config;
  }

  public componentDidMount(): void {
    for (const column of this.props.columns) {
      this.tableProps.columns.push(
        GridColumn.getColumnDef(column, this.props.columns, this.propagated)
      );
    }

    if (this.props.dialogLayoutId) {
      this.tableProps.columns.push(
        GridRelatedEditButton.createColDef(this.propagated)
      );
    }

    this.setState({ isMounted: true });
  }

  public componentDidUpdate(prevProps: ConfigProperties & WidthProps): void {
    if (prevProps.width !== this.props.width) {
      setTimeout(() => this.populateData());
    }
  }

  public componentWillUnmount(): void {
    this.setState({ isMounted: false });
  }

  public render(): React.ReactNode {
    const row = PaneRow.get(this.dataId);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<null, RuntimeProperties>(this.name);

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    const isEnterableDocumentGrid =
      this.props.isDocumentGrid &&
      widget.properties.accessLevel >= AccessLevel.enterable;

    this.tableProps.hideEmptyDocument = isEnterableDocumentGrid;

    return (
      <FocusTracker
        onFocusChanged={this.onFocusChanged}
        ref={this.focusTrackerRef}
      >
        <ErrorBoundary title={this.props.name}>
          <div style={{ position: "relative" }}>
            <AsyncData
              contentDataId={this.props.contentDataId}
              dataId={this.props.dataId}
              getData={this.getData}
              onIsLoadingChanged={this.onIsLoadingChanged}
              populateData={this.populateData}
              ref={this.asyncDataRef}
            />
            {
              // FUTURE
              // An isMounted flag is used to ensure the Table constructor only
              // fires when there are actually columns for the table. The root
              // cause is that the Table component is doing rendering logic in
              // the constructor, which is counter to the architecture of React.
              // At "some point", likely when moving to the MUI Grid component,
              // this should be reworked.
              this.state.isMounted && (
                <Table
                  aria-label={this.props.description}
                  {...this.tableProps}
                  dropAreaChild={
                    isEnterableDocumentGrid ? (
                      <DocumentDropArea
                        dataId={this.props.contentDataId}
                        files={this.documentStore.documents}
                        renderProgressDialog={
                          this.props.verticalLayout ? true : false
                        }
                        uploadFiles={this.onUploadFiles}
                      />
                    ) : null
                  }
                  fullWidthChild={this.props.verticalLayout}
                  tableKey={this.props.tableKey}
                  uploadFiles={this.documentStore.documents}
                />
              )
            }
            <TableEditDialog
              contentDataId={this.props.contentDataId}
              dataId={this.props.dataId}
              isFirstOpenOfNewRow={
                this.state.editDialogInfo
                  ? this.state.editDialogInfo.isFirstOpenOfNewRow
                  : false
              }
              labelledById={this.dialogLabelledById}
              name={this.props.name}
              onAccept={this.onDialogAccept}
              onClose={this.onDialogClose}
              onDeleteRow={() => this.populateData()}
              onExited={this.onDialogExited}
              onOpen={this.onDialogOpen}
              parentRowKey={
                this.state.editDialogInfo
                  ? this.state.editDialogInfo.rowKey
                  : undefined
              }
            >
              {this.dialogLayoutConfig !== null ? (
                <Panel presentationId={this.dialogLayoutConfig.layoutId}>
                  <Dialog
                    config={this.dialogLayoutConfig}
                    labelledById={this.dialogLabelledById}
                    propagated={this.dialogPropagatedProps}
                  />
                </Panel>
              ) : null}
            </TableEditDialog>
          </div>
        </ErrorBoundary>
      </FocusTracker>
    );
  }
}

// FUTURE
// This wrapper component was created to avoid the scope of converting this
// component to a hooks component during the MUI 5 upgrade. When the legacy
// component is converted to a hooks component, this wrapper can be removed and
// hooks it calls can be called by the converted component directly.
const Observer = observer(SimpleGridControl);
export default function Wrapped(props: ConfigProperties): JSX.Element {
  const width = useWidth();
  return <Observer {...props} width={width} />;
}

import AppServer, { State as AppServerState } from "../core/AppServer";
import RequestPromise from "../core/RequestPromise";
import {
  CompleteDocumentUploadResponse,
  CreateDocumentRowsResponse,
} from "../stores/DocumentStore";
import { BusinessError } from "../stores/ErrorsStore";
import LayoutStateStore from "../stores/LayoutStateStore";
import PaneDataStore, { PaneDataByDataId } from "../stores/PaneDataStore";
import BaseService from "./BaseService";

export interface OnDialogCloseResponse {
  appServerState: AppServerState;
  businessErrors: BusinessError[];
  paneDataByDataId: PaneDataByDataId;
  validationErrors: string[];
}

export interface OnDialogOpenResponse {
  appServerState: AppServerState;
  businessErrors: BusinessError[];
  paneDataByDataId: PaneDataByDataId;
  validationErrors: string[];
}

export default class SimpleGridControlService {
  public static completeDocumentUpload(
    rowKey: string,
    dataId: string,
    widgetName: string,
    uploadedFiles: object[]
  ): RequestPromise<CompleteDocumentUploadResponse> {
    return BaseService.requestObject<CompleteDocumentUploadResponse>(
      `SimpleGridControl/CompleteDocumentUpload/${rowKey}/${dataId}/${widgetName}`,
      null,
      null,
      {
        appServerState: AppServer.getState(),
        dataChanges: PaneDataStore.getChanges(),
        uploadedFiles,
      },
      "POST"
    );
  }

  public static createDocumentRows(
    rowKey: string,
    dataId: string,
    widgetName: string,
    fileInfo: object[]
  ): RequestPromise<CreateDocumentRowsResponse> {
    return BaseService.requestObject<CreateDocumentRowsResponse>(
      `SimpleGridControl/CreateDocumentRows/${rowKey}/${dataId}/${widgetName}`,
      null,
      null,
      {
        appServerState: AppServer.getState(),
        dataChanges: PaneDataStore.getChanges(),
        fileInfo,
      },
      "POST"
    );
  }

  public static async onDialogClose(
    rowKey: string,
    dataId: string,
    widgetName: string,
    dialogRowKey: string
  ): Promise<OnDialogCloseResponse> {
    return BaseService.requestObject<OnDialogCloseResponse>(
      `SimpleGridControl/OnDialogClose/${rowKey}/${dataId}/${widgetName}`,
      null,
      null,
      {
        appServerState: AppServer.getState(),
        dataChanges: PaneDataStore.getChanges(),
        dialogRowKey,
        layoutState: LayoutStateStore.getCurrentState(),
      },
      "POST"
    );
  }

  public static async onDialogOpen(
    rowKey: string,
    dataId: string,
    widgetName: string,
    dialogRowKey: string
  ): Promise<OnDialogOpenResponse> {
    return BaseService.requestObject<OnDialogOpenResponse>(
      `SimpleGridControl/OnDialogOpen/${rowKey}/${dataId}/${widgetName}`,
      null,
      null,
      {
        appServerState: AppServer.getState(),
        dataChanges: PaneDataStore.getChanges(),
        dialogRowKey,
      },
      "POST"
    );
  }
}

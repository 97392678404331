import { action, IObservableArray, observable } from "mobx";

export interface SessionMessage {
  message: string;
  messageId: number;
  messageType: "Caution" | "Danger" | "Info" | "Success";
}

export default class SessionMessagesStore {
  private static sessionMessages: IObservableArray<SessionMessage> = observable(
    []
  );

  public static addMessages = action((messages: SessionMessage[]): void => {
    SessionMessagesStore.sessionMessages.push(...messages);
  });

  public static removeMessage = action((message: SessionMessage): void => {
    SessionMessagesStore.sessionMessages.remove(message);
  });

  public static setMessages = action((messages: SessionMessage[]): void => {
    SessionMessagesStore.sessionMessages.replace(messages);
  });

  public static get messages(): SessionMessage[] {
    return SessionMessagesStore.sessionMessages;
  }
}

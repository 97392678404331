// VERSION_WARNING Material-UI 4.9.14
// This component implements the internal structure of the MUI TextField /
// Select components.  We are customizing this to be able to implement the
// ARIA 1.2 read-only combo box pattern, vs. the ARIA 1.1 popup button with
// menulist pattern that MUI supports.  This allows us to support features like
// aria-required, aria-invalid, separate label and value, the combobox role
// (vs. the button role), and the ability to use Popper for non-modal selects.
//
// In the future when MUI supports the ARIA 1.2 pattern, WebUI can migrate to
// using the built-in Select component vs. this customized version.
//
// See original source at:
// - https://github.com/mui-org/material-ui/blob/v4.9.14/packages/material-ui/src/Select/Select.js
// - https://github.com/mui-org/material-ui/blob/v4.9.14/packages/material-ui/src/TextField/TextField.js
import {
  FilledInput,
  FormControl,
  FormHelperText,
  InputLabel,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import Sys from "../core/Sys";
import ComboBoxOption from "./ComboBoxOption";
import getFieldHelperText from "./FieldHelperText";
import Icon from "./Icon";
import multiClassName from "./MultiClassName";
import SelectInput from "./SelectInput";
import TextField from "./TextField";

interface Props {
  "aria-label"?: string;
  disableUnderline?: boolean;
  error?: boolean;
  getErrors?: (value: string) => string[];
  helperText?: React.ReactNode;
  iconClassName?: string;
  inputBaseStyle?: React.CSSProperties;
  label?: string;
  onClose?: () => void;
  onFocus?: () => void;
  onValueChange?: (value: ComboBoxOption) => void;
  openOnMount?: boolean;
  options: ComboBoxOption[];
  readOnly?: boolean;
  selectDisplayStyle?: React.CSSProperties;
  showAsMandatory?: boolean;
  value?: string | null;
}

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    cursor: "pointer",
    fontSize: 16,
    height: "auto",
    pointerEvents: "none",
    position: "absolute",
    right: 12,
    top: "50%",
    transform: "translateY(-50%)",
  },
  inputLabelRoot: {
    width: "calc(100% - 33px)",
  },
  inputLabelShrink: {
    width: "calc((100% - 33px) * 1.333)",
  },
  label: {
    marginBottom: 8,
  },
}));

export interface SelectRef {
  focus: () => void;
}

const Select = React.forwardRef(
  (props: Props, ref: React.ForwardedRef<SelectRef>): JSX.Element => {
    const componentId = React.useRef<string>(`select-${Sys.nextId}`);
    const helperTextId = `${componentId.current}-helper-text`;
    const labelId = `${componentId.current}-label`;

    const selectInputRef = React.useRef<HTMLElement>(null);
    const selectRef = React.useRef<HTMLInputElement>(null);

    const classes = useStyles();

    React.useImperativeHandle(ref, () => ({
      focus(): void {
        // Use a setTimeout to ensure the SelectInput component has enough time
        // to fully wire up its inputRef.
        // This is relevant if focus() is called as soon as the Select component
        // is mounted; in this case the SelectInput component still needs to run
        // its own effects on mount, which includes wiring up the inputRef. The
        // setTimeout() ensures the call to focus() the inputRef waits until the
        // next event cycle because by then the initial setup in SelectInput
        // will be done.
        setTimeout(() => {
          if (selectInputRef.current === null) {
            // This should never happen, but if it does it is not worth having
            // the application crash over it.
            console.warn(
              `No input element existed when attempting to focus select ${componentId.current}`
            );
          } else {
            selectInputRef.current.focus();
          }
        });
      },
    }));

    const inputLabelClasses = {
      root: classes.inputLabelRoot,
      shrink: classes.inputLabelShrink,
    };

    const selectedOption: ComboBoxOption = props.options.find(
      (o) => o.value === props.value
    )!;

    if (props.readOnly) {
      return (
        <TextField
          label={props.label}
          readOnly={true}
          value={selectedOption ? selectedOption.display : "-"}
          variant="filled"
        />
      );
    }

    const fieldHelperText = getFieldHelperText({
      getErrors: props.getErrors,
      helperText: props.helperText,
      value: props.value ? props.value : undefined,
    });

    return (
      <FormControl
        error={fieldHelperText.hasErrors || props.error}
        fullWidth={true}
        required={props.showAsMandatory}
        variant="filled"
      >
        {props.label && (
          <InputLabel
            aria-hidden={true}
            classes={inputLabelClasses}
            htmlFor={componentId.current}
            id={labelId}
          >
            {props.label}
          </InputLabel>
        )}
        <FilledInput
          disableUnderline={props.disableUnderline}
          fullWidth={true}
          inputComponent={SelectInput}
          inputProps={{
            "aria-label": props["aria-label"],
            errors: fieldHelperText.errors,
            IconComponent: () => (
              <Icon
                className={multiClassName(classes.icon, props.iconClassName)}
                icon="fas fa-caret-down"
              />
            ),
            labelId: props.label ? labelId : undefined,
            onClose: props.onClose,
            onFocus: props.onFocus,
            onValueChange: props.onValueChange,
            openOnMount: props.openOnMount,
            options: props.options,
            required: props.showAsMandatory,
            SelectDisplayProps: {
              "aria-describedby": fieldHelperText.helperText
                ? helperTextId
                : undefined,
              id: componentId.current,
              style: props.selectDisplayStyle,
            },
          }}
          inputRef={selectInputRef}
          ref={selectRef}
          style={props.inputBaseStyle}
          value={selectedOption?.value ?? ""}
        />
        {fieldHelperText.helperText && (
          <FormHelperText
            aria-hidden={true}
            component="div"
            id={helperTextId}
            {...fieldHelperText.formHelperTextProps}
          >
            {fieldHelperText.helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);

export default Select;

export interface LayoutState {
  selectedTabs: { [tabControlKey: string]: string | null };
  selectedWizardStep: string | null;
}

export default class LayoutStateStore {
  private static currentState: LayoutState = {
    selectedTabs: {},
    selectedWizardStep: null,
  };

  public static clear() {
    LayoutStateStore.currentState.selectedTabs = {};
    LayoutStateStore.currentState.selectedWizardStep = null;
  }

  public static getCurrentState(): LayoutState {
    return LayoutStateStore.currentState;
  }

  public static setSelectedStep(selectedStepPaneUseKey: string | null) {
    LayoutStateStore.currentState.selectedWizardStep = selectedStepPaneUseKey;
  }

  public static setSelectedTab(
    dataId: string,
    controlName: string,
    selectedTabPaneUseKey: string | null
  ) {
    LayoutStateStore.currentState.selectedTabs[
      `${dataId}_${controlName}`
    ] = selectedTabPaneUseKey;
  }
}

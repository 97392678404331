import { Breakpoint, Theme } from "@mui/material";
import {
  createStyles,
  CSSProperties,
  WithStyles,
  withStyles,
  WithTheme,
  withTheme,
} from "@mui/styles";
import { observer } from "mobx-react";
import * as React from "react";
import Presentation from "../coreui/Presentation";
import PaneRow, { WidgetValue } from "../models/PaneRow";
import { getPanelMeasurements, PanelMeasurements } from "../theme";
import { AccessLevel } from "./AccessLevel";

export interface ConfigProperties {
  childWidget: object & { type: string };
  childWidgetName: string;
  isLayoutOnlyWidget: boolean;
  isVisible: { [key in Breakpoint]: boolean };
}

interface Props extends ConfigProperties {
  dataId: string;
  propagated: object;
}

interface RuntimeProperties {
  accessLevel?: AccessLevel;
}

const styles = (theme: Theme) => {
  const visibilityStyles = {};
  for (const breakPoint of theme.visibilityBreakPoints) {
    visibilityStyles[`root-visible-${breakPoint}`] = {
      [theme.breakpoints.only(breakPoint)]: {
        display: "block",
      },
    };
  }

  const itemSpaceStyle = {};
  for (const breakPoint of theme.spacingBreakPoints) {
    const itemSpacing = theme.freeflow.item.spacing[breakPoint];

    itemSpaceStyle[theme.breakpoints.up(breakPoint)] = {
      paddingBottom: itemSpacing.vertical * 0.5,
      paddingLeft: itemSpacing.horizontal * 0.5,
      paddingRight: itemSpacing.horizontal * 0.5,
      paddingTop: itemSpacing.vertical * 0.5,
    };
  }

  const measurementsByBreakpoint: {
    [breakPoint in Breakpoint]: PanelMeasurements;
  } = {
    lg: getPanelMeasurements(theme, "lg"),
    md: getPanelMeasurements(theme, "md"),
    sm: getPanelMeasurements(theme, "sm"),
    xl: { columnWidth: 0, gutterWidth: 0 },
    xs: { columnWidth: 0, gutterWidth: 0 },
  };

  const result = {
    fieldWidget: {
      [theme.breakpoints.up("lg")]: {
        width:
          measurementsByBreakpoint.lg.columnWidth * 3 +
          measurementsByBreakpoint.lg.gutterWidth * 2,
      },
      [theme.breakpoints.only("md")]: {
        width:
          measurementsByBreakpoint.md.columnWidth * 3 +
          measurementsByBreakpoint.md.gutterWidth * 2,
      },
      [theme.breakpoints.only("sm")]: {
        width:
          measurementsByBreakpoint.sm.columnWidth * 4 +
          measurementsByBreakpoint.sm.gutterWidth * 3,
      },
      [theme.breakpoints.only("xs")]: {
        width: "100%",
      },
    },
    otherWidget: {
      width: "auto",
    },
    root: {
      "& .helper-text": {
        [theme.breakpoints.up("lg")]: {
          maxWidth:
            measurementsByBreakpoint.lg.columnWidth * 3 +
            measurementsByBreakpoint.lg.gutterWidth * 2,
        },
        [theme.breakpoints.only("md")]: {
          maxWidth:
            measurementsByBreakpoint.md.columnWidth * 3 +
            measurementsByBreakpoint.md.gutterWidth * 2,
        },
        [theme.breakpoints.only("sm")]: {
          maxWidth:
            measurementsByBreakpoint.sm.columnWidth * 4 +
            measurementsByBreakpoint.sm.gutterWidth * 3,
        },
        [theme.breakpoints.only("xs")]: {
          maxWidth: "100%",
        },
      },
      display: "none",
      minWidth: 0,
      ...itemSpaceStyle,
    } as CSSProperties,
    ...visibilityStyles,
  };

  return createStyles(result);
};

export class FreeflowContainerItem extends React.Component<
  Props & WithStyles<typeof styles> & WithTheme
> {
  private static fieldWidgetTypes: string[] = [
    "DateEdit",
    "DocumentEdit",
    "DomainComboBox",
    "NumericEdit",
    "RelationshipComboBox",
    "SLTextEdit",
  ];

  public static isVisible(
    dataId: string,
    item: ConfigProperties,
    breakPoint: Breakpoint
  ): boolean {
    if (!item.isVisible[breakPoint]) {
      return false;
    }

    if (item.isLayoutOnlyWidget) {
      return true;
    }

    const row = PaneRow.get(dataId)!;
    const widget = row.getWidgetT<WidgetValue, RuntimeProperties>(
      item.childWidgetName
    );

    if (widget.properties.accessLevel === undefined) {
      // Widgets that have no access level property are assumed to be visible.
      return true;
    }

    return widget.properties.accessLevel >= AccessLevel.disabled;
  }

  private get isChildFieldWidget(): boolean {
    if (this.props.isLayoutOnlyWidget) {
      return false;
    }

    const row = PaneRow.get(this.props.dataId);
    if (!row) {
      return false;
    }

    const widget = row.getWidgetT<WidgetValue, RuntimeProperties>(
      this.props.childWidgetName
    );

    if (
      widget.properties.accessLevel !== AccessLevel.enterable &&
      widget.properties.accessLevel !== AccessLevel.disabled
    ) {
      return false;
    }

    return FreeflowContainerItem.fieldWidgetTypes.includes(
      this.props.childWidget.type
    );
  }

  public render(): React.ReactNode {
    const classes: string[] = [this.props.classes.root];
    const theme = this.props.theme as Theme;

    for (const breakPoint of theme.visibilityBreakPoints) {
      if (
        FreeflowContainerItem.isVisible(
          this.props.dataId,
          this.props,
          breakPoint
        )
      ) {
        const className = `root-visible-${breakPoint}`;
        classes.push(this.props.classes[className]);
      }
    }

    if (this.isChildFieldWidget) {
      classes.push(this.props.classes.fieldWidget);
    } else {
      classes.push(this.props.classes.otherWidget);
    }

    return (
      <div className={classes.join(" ")}>
        {Presentation.create(this.props.childWidget, this.props.propagated)}
      </div>
    );
  }
}

export default withStyles(styles)(withTheme(observer(FreeflowContainerItem)));

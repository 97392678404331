import { colors } from "@mui/material";
import {
  action,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
} from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../../core/Localization";
import Sys from "../../core/Sys";
import { FileInfo, ProgressStatus } from "../../stores/DocumentStore";
import PaneDataStore from "../../stores/PaneDataStore";
import RequestsStore from "../../stores/RequestsStore";
import Icon from "../Icon";
import Typography from "../Typography";
import UploadDialog from "./UploadDialog";

interface Props {
  dataId: string;
  files: FileInfo[];
  renderProgressDialog: boolean;
  uploadFiles: (files: FileList) => Promise<void>;
}

interface State {
  dialogPercent?: number;
  isDialogOpen?: boolean;
  isDragging?: boolean;
}

export class DocumentDropArea extends React.Component<Props, State> {
  private dataId: string = "";
  private dragEnterStack: number = 0;
  private files: FileInfo[] = [];
  private filesDisposer: IReactionDisposer;
  private isFinalizing = false;
  private lastFilesNumber = 0;
  // Dictionary of upload progress, keyed by upload file.
  private progress: Map<object, number> = new Map<object, number>();
  private progressTotal: number = 0;
  private renderProgressDialog: boolean = false;

  public constructor(props: Props) {
    super(props);

    makeObservable<
      DocumentDropArea,
      "dataId" | "files" | "renderProgressDialog" | "syncDerivedWithProps"
    >(this, {
      dataId: observable,
      files: observable,
      renderProgressDialog: observable,
      syncDerivedWithProps: action,
    });

    this.state = {
      dialogPercent: 0,
      isDialogOpen: false,
      isDragging: false,
    };

    this.syncDerivedWithProps();
  }

  private onDragEnterIntoDocument = () => {
    this.dragEnterStack++;
    this.setState({ isDragging: true });
  };

  private onDragLeaveOutOfDocument = () => {
    this.dragEnterStack--;

    if (this.dragEnterStack < 1) {
      this.setState({ isDragging: false });
    }
  };

  private onDragOverIntoWidget = (event: React.DragEvent<HTMLDivElement>) => {
    event.dataTransfer.dropEffect = "copy";
    event.preventDefault();
    event.stopPropagation();

    return false;
  };

  private onDropIntoDocument = (event: DragEvent) => {
    // Delay so the widget drop event fires (delay the re-render)
    setTimeout(() => {
      this.dragEnterStack = 0;
      this.setState({ isDragging: false });
    });

    event.preventDefault();

    return false;
  };

  private onDropIntoWidget = (event: React.DragEvent<HTMLDivElement>) => {
    this.props.uploadFiles(event.dataTransfer.files);
  };

  private onFilesChange = (files: FileInfo[]) => {
    if (files.length > 0 && this.lastFilesNumber === 0) {
      this.progress.clear();
      this.progressTotal = 0;
      if (this.renderProgressDialog) {
        this.setState({ isDialogOpen: true });
      }
    } else if (files.length === 0 && this.lastFilesNumber > 0) {
      if (this.renderProgressDialog) {
        this.setState({ isDialogOpen: false });
      } else {
        RequestsStore.instance.processingStopped();
      }

      this.isFinalizing = false;
    }

    for (const fileInfo of files) {
      this.uploadProgress(fileInfo.file, fileInfo.status, fileInfo.event);
    }

    this.lastFilesNumber = files.length;
  };

  private syncDerivedWithProps(): void {
    this.dataId = this.props.dataId;
    this.files = this.props.files;
    this.renderProgressDialog = this.props.renderProgressDialog;
  }

  private updatePercent(percent: number) {
    if (percent >= this.state.dialogPercent!) {
      this.setState({ dialogPercent: percent });

      // When complete wait 1 second and then close the dialog.
      if (percent === 100) {
        setTimeout(() => this.setState({ isDialogOpen: false }), 1000);
      }
    }
  }

  private uploadProgress(
    file: File,
    status: ProgressStatus,
    event?: ProgressEvent
  ) {
    switch (status) {
      case "Finalized":
        break;

      case "Ongoing":
        if (!this.renderProgressDialog || !event) {
          return;
        }

        let percent: number = 0;

        // Loaded may report more bytes than the file size.
        if (event.loaded < file.size) {
          this.progress.set(file, event.loaded);
        }

        this.progress.forEach((loaded) => {
          percent += loaded;
        });

        if (event.loaded / event.total >= 1) {
          percent = 98;
        } else {
          // Only show 90% complete until the upload has finished
          percent = Math.round((percent / this.progressTotal / 1.11) * 100);
        }

        this.updatePercent(percent);
        break;

      case "Started":
        this.progress.set(file, 0);
        this.progressTotal += file.size;
        this.setState({ dialogPercent: 0 });
        break;

      case "Uploaded":
        if (!this.isFinalizing) {
          if (this.renderProgressDialog) {
            this.updatePercent(100);
          } else {
            RequestsStore.instance.processingStarted(
              Localization.getBuiltInMessage("uploadDone")
            );
          }

          this.isFinalizing = true;
        }
        break;

      default:
    }
  }

  public componentDidMount(): void {
    document.body.addEventListener("dragenter", this.onDragEnterIntoDocument);
    document.body.addEventListener("dragleave", this.onDragLeaveOutOfDocument);
    document.body.addEventListener("drop", this.onDropIntoDocument);

    this.filesDisposer = reaction(
      () => this.files.map((f) => ({ ...f })),
      this.onFilesChange
    );
  }

  public componentDidUpdate(): void {
    this.syncDerivedWithProps();
  }

  public componentWillUnmount(): void {
    document.body.removeEventListener(
      "dragenter",
      this.onDragEnterIntoDocument
    );
    document.body.removeEventListener(
      "dragleave",
      this.onDragLeaveOutOfDocument
    );
    document.body.removeEventListener("drop", this.onDropIntoDocument);

    if (this.filesDisposer) {
      this.filesDisposer();
    }
  }

  public render(): React.ReactNode {
    const isEmpty =
      PaneDataStore.getPaneCollection(this.props.dataId).length === 0;

    return (
      <React.Fragment>
        {this.state.isDragging ? (
          <div
            className="cx-drop-mask"
            onDragOver={this.onDragOverIntoWidget}
            onDrop={this.onDropIntoWidget}
          >
            <Typography
              className="cx-drop-mask-message"
              component="div"
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                margin: "24px 0px",
              }}
              variant="h3"
            >
              <Typography style={{ marginBottom: 24 }} variant="h1">
                <Icon icon="fas fa-cloud-upload" />
              </Typography>
              {Localization.getBuiltInMessage("drop")}
            </Typography>
          </div>
        ) : null}
        <div
          style={{
            borderBottom: isEmpty ? `1px solid ${colors.grey[300]}` : "0px",
            borderTop: `1px solid ${colors.grey[300]}`,
            display: Sys.isMobile ? "none" : "flex",
          }}
        >
          <div className="cx-drop-banner" style={{ height: isEmpty ? 96 : 48 }}>
            <Typography
              style={{
                display: "inline-block",
                fontSize: 20,
                height: "1em",
                width: "1em",
              }}
            >
              <Icon icon="fas fa-cloud-upload" />
            </Typography>
            <Typography
              style={{ fontSize: 12, marginLeft: ".4em" }}
              variant="body2"
            >
              {Localization.getBuiltInMessage("drag")}
            </Typography>
            <Typography style={{ fontSize: 12 }}>
              &nbsp;{Localization.getBuiltInMessage("uploadFiles")}
            </Typography>
          </div>
        </div>
        <UploadDialog
          onClose={() => this.setState({ isDialogOpen: false })}
          open={this.state.isDialogOpen!}
          percent={this.state.dialogPercent!}
        />
      </React.Fragment>
    );
  }
}

export default observer(DocumentDropArea);

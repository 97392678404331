import { styled } from "@mui/material";
import * as React from "react";
import Icon from "../../coreui/Icon";
import { TableChildProps } from "../../coreui/Table";
import { FocusCellRendererParams } from "../../coreui/table/CellFocusUtil";
import { CellUtil } from "../../coreui/table/CellUtil";
import PaneRow, { RuntimeWidget } from "../../models/PaneRow";
import { AccessLevel } from "../AccessLevel";
import { FunctionName } from "../TableSummary";
import { GridColumnConfigProperties } from "./GridColumn";

interface ConfigProperties extends FocusCellRendererParams {
  dataId: string;
  name: string;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  alternateText: string;
  iconName: string;
}

const Container = styled("div")({
  display: "grid",
  height: "100%",
  placeItems: "center",
});

export class IconDisplayColumn extends React.PureComponent<ConfigProperties> {
  public static readonly widgetType: string = "IconDisplayColumn";

  public static getFilterText(
    column: GridColumnConfigProperties,
    propagated: TableChildProps,
    row: PaneRow
  ): string {
    return "";
  }

  public static getSummaryValue(
    runtimeData: RuntimeWidget[],
    configProperties: ConfigProperties,
    functionName: FunctionName
  ): string | null {
    let result = 0;
    for (const data of runtimeData) {
      const runtimeProperties = data.properties as RuntimeProperties;

      if (runtimeProperties.accessLevel >= AccessLevel.readOnly) {
        result += 1;
      }
    }

    return result.toString();
  }

  public constructor(props: ConfigProperties) {
    super(props);

    props.eGridCell.addEventListener("keydown", this.onCellKeyDown);
  }

  private onCellKeyDown = (event: KeyboardEvent): void => {
    CellUtil.customizeGridNavigation(event, this.props);
  };

  public componentWillUnmount(): void {
    this.props.eGridCell.removeEventListener("keydown", this.onCellKeyDown);
  }

  public render(): React.ReactNode {
    const row = this.props.data!;
    const widget = row.getWidgetT<null, RuntimeProperties>(this.props.name);

    CellUtil.setReadOnlyAttribute(this.props.eGridCell, true);

    if (widget.properties.accessLevel <= AccessLevel.hidden) {
      return null;
    }

    return (
      <Container>
        <Icon
          aria-label={widget.properties.alternateText}
          icon={widget.properties.iconName}
          style={{
            fontSize: 16,
            height: "auto",
          }}
        />
      </Container>
    );
  }
}

export default IconDisplayColumn;

import Localization from "../core/Localization";
import Logging from "../core/Logging";
import RequestPromise from "../core/RequestPromise";
import Routing from "../core/Routing";
import Sys from "../core/Sys";
import ErrorsStore from "../stores/ErrorsStore";

export interface Response {
  responseText: string;
}

interface ExceptionInfo {
  errorDetails?: string;
  errorMessage?: string;
  errorTemplateId?: string;
  exceptionType?: string;
}

export default class BaseService {
  private static readonly errorMessageByStatus: { [status: number]: string } = {
    408: "Request Timeout",
    413: "Upload Limit Exceeded",
  };
  private static readonly requestId: string = "dataId";
  private static renderSignInAttempts: number = 0;
  public static readonly baseUrl: string = "dynamic/";

  // Dictionary of requests, keyed by id.
  public static requests: Map<string, XMLHttpRequest> = new Map<
    string,
    XMLHttpRequest
  >();
  public static requestTimeoutMilliseconds: number = 30000;

  private static abortRequest(args: object) {
    BaseService.requests.forEach((request) => {
      if (request["config"]["args"]) {
        if (BaseService.hasAll(request["config"]["args"], args)) {
          request.abort();
        }
      }
    });
  }

  private static getRequestException(
    request: XMLHttpRequest
  ): ExceptionInfo | null {
    if (!request.responseText) {
      return null;
    }

    try {
      return JSON.parse(request.responseText);
    } catch {
      return null;
    }
  }

  private static goToErrorPage(statusCode: number, message?: string): void {
    Routing.goToErrorPage(message || "", statusCode);
  }

  private static handleRequestError(request: XMLHttpRequest): void {
    BaseService.logRequestException(request);
    if (request.status > 0) {
      BaseService.goToErrorPage(request.status);
    } else {
      // A request.status = 0 is a result of an HttpRequest error
      // If in the future we need to make this message available in multiple
      // languages we need to chose an unique error code and a BuiltIn Message
      BaseService.goToErrorPage(500, "Network connection error.");
    }
  }

  private static handleRequestTimeout(request: XMLHttpRequest): void {
    BaseService.logRequestException(request);

    const message: string = BaseService.errorMessageByStatus[408];
    BaseService.goToErrorPage(408, message);
  }

  // Returns true if all properties in object2 match properties in object1.
  private static hasAll(object1: object | null, object2: object | null) {
    let result = false;

    if (object1 && object2) {
      for (const property of Object.keys(object2)) {
        if (object2[property]) {
          if (object1[property] === object2[property]) {
            result = true;
          } else {
            result = false;
            break;
          }
        } else {
          result = false;
          break;
        }
      }
    }

    return result;
  }

  private static logRequestException(request: XMLHttpRequest): void {
    const exception: ExceptionInfo | null =
      BaseService.getRequestException(request);

    const message: string = exception?.errorMessage
      ? exception.errorMessage
      : "";

    if (request["config"]) {
      const url: string = request["config"]["url"];
      const args: string = JSON.stringify(request["config"]["args"]);

      Logging.log(
        `${request.status} ${url} ${args}`,
        `Request Exception ${message}`
      );
    } else {
      Logging.log(`Request Exception ${message}`);
    }

    if (exception?.errorDetails) {
      Logging.log(exception.errorDetails, "Request Exception Details");
    } else if (request.responseText) {
      Logging.log(request.responseText, "Request Response");
    }
  }

  public static getRequestExceptionMessage(request: XMLHttpRequest): string {
    if (!request) {
      return "An unexpected error occurred";
    }

    if (request["config"]?.["timeout"]) {
      return BaseService.errorMessageByStatus[408];
    }

    const exception: ExceptionInfo | null =
      BaseService.getRequestException(request);
    if (exception?.errorMessage) {
      return exception.errorMessage;
    }

    if (request.status in BaseService.errorMessageByStatus) {
      return BaseService.errorMessageByStatus[request.status];
    }

    if (request.statusText) {
      return request.statusText;
    }

    return "An unexpected error occurred";
  }

  // FUTURE
  // Replace with string templates: `${replaceThis}`
  public static getUrl(url: string, args?: object | null): string {
    let result: string = url;

    if (args) {
      for (const arg of Object.keys(args)) {
        if (result!.indexOf(`{${arg}}`) > -1) {
          result = result!.replace(`{${arg}}`, `${args[arg]}`);
          delete args[arg];
        }
      }
    }

    return result;
  }

  public static handleRequestException(request: XMLHttpRequest): void {
    const exception: ExceptionInfo | null =
      BaseService.getRequestException(request);

    BaseService.logRequestException(request);

    if (exception) {
      switch (exception["exceptionType"]) {
        case "AuthenticationRequiredException":
          ErrorsStore.showErrors([
            Localization.getBuiltInMessage("authenticationRequired"),
          ]);
          Routing.renderSignIn();
          return;

        case "InvalidUserOrPasswordException":
          ErrorsStore.showErrors([
            Localization.getBuiltInMessage("credentialsInvalid"),
          ]);
          return;

        case "SessionExpiredException":
          // Prevent infinite loops to sign-in if something
          // unexpected happens to sessions
          BaseService.renderSignInAttempts++;
          if (BaseService.renderSignInAttempts <= 3) {
            Sys.deleteCookie(Sys.guestSessionTokenCookie);
            Sys.deleteCookie(Sys.sessionTokenCookie);
            ErrorsStore.showErrors([
              Localization.getBuiltInMessage("sessionExpired"),
            ]);
            Routing.renderSignIn();

            return;
          }

          Logging.log(
            "Expired Session Sign-In rendering stopped " +
              " after 3 attempts - Try clearing cookies"
          );
          break;

        default:
      }
    }

    BaseService.renderSignInAttempts = 0;

    const message: string = BaseService.getRequestExceptionMessage(request);
    const statusCode: number = request["config"]?.["timeout"]
      ? 408
      : request.status;

    BaseService.goToErrorPage(statusCode, message);
  }

  public static request(
    url: string,
    args?: object,
    method: string = "POST",
    redirect: boolean = true,
    request: XMLHttpRequest = new XMLHttpRequest()
  ): RequestPromise<Response> {
    return new RequestPromise<Response>(
      (resolve, reject) => {
        request.timeout = BaseService.requestTimeoutMilliseconds;

        request.onabort = () => {
          BaseService.requests.delete(request["config"]["id"]);
          request["config"]["duration"] =
            new Date().getTime() - request["config"]["started"];
          request["config"]["aborted"] = true;

          reject(request);
        };

        request.onerror = () => {
          BaseService.requests.delete(request["config"]["id"]);
          request["config"]["duration"] =
            new Date().getTime() - request["config"]["started"];
          request["config"]["error"] = true;

          if (redirect) {
            BaseService.handleRequestError(request);
          }
          reject(request);
        };

        request.ontimeout = () => {
          BaseService.requests.delete(request["config"]["id"]);
          request["config"]["duration"] =
            new Date().getTime() - request["config"]["started"];
          request["config"]["timeout"] = true;

          if (redirect) {
            BaseService.handleRequestTimeout(request);
          }
          reject(request);
        };

        request.onreadystatechange = (event: Event) => {
          if (request.readyState === XMLHttpRequest.DONE) {
            BaseService.requests.delete(request["config"]["id"]);
            request["config"]["duration"] =
              new Date().getTime() - request["config"]["started"];
            request["config"]["responseText"] = request.responseText;

            if (request.status === 200) {
              resolve(request);
            } else if (request.status > 0) {
              if (redirect) {
                BaseService.handleRequestException(request);
              } else {
                BaseService.logRequestException(request);
              }
              reject(request);
            }
          }
        };

        request["config"] = Object.create(null);
        request["config"]["started"] = new Date().getTime();
        request["config"]["url"] = url;

        if (args) {
          request["config"]["args"] = args;

          if (BaseService.requestId in args) {
            request["config"]["id"] = args[BaseService.requestId];
          } else {
            request["config"]["id"] = Sys.nextId;
          }
        } else {
          request["config"]["id"] = Sys.nextId;
        }

        // If a pending request exists with the same id, abort it.
        if (
          BaseService.requests.has(request["config"]["id"]) &&
          request["config"]["args"]
        ) {
          BaseService.abortRequest(request["config"]["args"]);
        }

        BaseService.requests.set(request["config"]["id"], request);

        if (url && url.toLowerCase().startsWith("http")) {
          request.open(method, url, true);
        } else {
          request.open(method, `${BaseService.baseUrl}${url}`, true);
        }

        if (args && (method === "POST" || method === "PUT")) {
          if ("jsonData" in args) {
            request.setRequestHeader("Content-type", "application/json");
            request.send(args["jsonData"] as string);
          } else if ("formData" in args) {
            // Content type must be set by send to get the boundary
            // value.
            request.send(args["formData"] as FormData);
          } else {
            request.setRequestHeader(
              "Content-type",
              "application/x-www-form-urlencoded"
            );
            request.send(Sys.objectToQueryString(args));
          }
        } else {
          request.send();
        }
      },
      () => {
        request.abort();
      }
    );
  }

  public static requestObject<T>(
    url: string,
    urlParams?: object | null,
    formData?: object | null,
    jsonData?: object | null,
    method: string = "POST",
    redirect: boolean = true
  ): RequestPromise<T> {
    let request: RequestPromise<Response>;

    const args = { ...formData };

    if (jsonData) {
      args["jsonData"] = JSON.stringify(jsonData);
    }

    return new RequestPromise<T>(
      (resolve, reject) => {
        request = BaseService.request(
          BaseService.getUrl(url, urlParams),
          args,
          method,
          redirect
        );
        request
          .then((response) => {
            resolve(JSON.parse(response.responseText));
          })
          .catch((response) => reject(response));
      },
      () => {
        request.abort();
      }
    );
  }
}

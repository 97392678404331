import { Breakpoint, ButtonBase } from "@mui/material";
import * as React from "react";
import { ButtonColor, ButtonSize } from "../config";
import { useWidth } from "../core/Responsive";
import Sys from "../core/Sys";
import Button from "../coreui/Button";
import ButtonLink from "../coreui/ButtonLink";
import DisabledHelpBadge from "../coreui/DisabledHelpBadge";
import Icon from "../coreui/Icon";

interface Props {
  alternateText?: string;
  "aria-controls"?: React.AriaAttributes["aria-controls"];
  "aria-expanded"?: React.AriaAttributes["aria-expanded"];
  "aria-haspopup"?: React.AriaAttributes["aria-haspopup"];
  buttonColor: ButtonColor;
  children?: null;
  disabled?: boolean;
  disabledHelpText: string;
  disabledHelpVisible?: boolean;
  endIcon?: string;
  href?: string;
  iconName?: string;
  id?: string;
  isIconOnly: boolean;
  label: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  renderAsLink?: boolean;
  size?: "large" | "medium" | "small" | ButtonSize;
  tabIndex?: number;
  target?: string;
}

export default function ApiButton(props: Props): JSX.Element {
  const width = useWidth();

  const defaultComponentId = React.useRef<string>(
    `action-button-${Sys.nextId}`
  );
  const [isDisabledHelpOpen, setIsDisabledHelpOpen] =
    React.useState<boolean>(false);
  const [isFocusRippleVisible, setIsFocusRippleVisible] =
    React.useState<boolean>(false);

  const componentId = props.id || defaultComponentId.current;
  const describedById = `${componentId}-described-by`;

  const isDisabledHelpVisible: boolean = !!(
    props.disabled && props.disabledHelpVisible
  );

  let content: JSX.Element;

  let ariaLabel = undefined;
  let label = undefined;
  if (props.isIconOnly) {
    ariaLabel = props.label;
  } else {
    label = props.label;
  }

  if (props.renderAsLink) {
    content = (
      <React.Fragment>
        <ButtonLink
          aria-controls={props["aria-controls"]}
          aria-describedby={describedById}
          aria-expanded={props["aria-expanded"]}
          aria-haspopup={props["aria-haspopup"]}
          aria-label={ariaLabel}
          endIcon={props.endIcon}
          disabled={props.disabled}
          href={props.href}
          icon={props.iconName}
          id={componentId}
          linkText={label!}
          onClick={props.onClick}
          tabIndex={props.tabIndex}
          target="_self"
        />
        <div id={describedById} style={{ display: "none" }}>
          {isDisabledHelpVisible ? props.disabledHelpText : props.alternateText}
        </div>
      </React.Fragment>
    );
  } else {
    let size: "large" | "medium" | "small" | undefined = undefined;
    if (!props.size || typeof props.size === "string") {
      size = props.size;
    } else if (width in props.size) {
      size = props.size[width];
    }

    content = (
      <React.Fragment>
        <Button
          aria-controls={props["aria-controls"]}
          aria-describedby={describedById}
          aria-expanded={props["aria-expanded"]}
          aria-haspopup={props["aria-haspopup"]}
          aria-label={ariaLabel}
          color={props.buttonColor}
          component="button"
          disabled={props.disabled}
          endIcon={props.endIcon}
          href={props.href}
          icon={props.iconName}
          id={componentId}
          label={label}
          fullWidth={true}
          onClick={props.onClick}
          size={size}
          tabIndex={props.tabIndex}
          target={props.target}
        />
        <div id={describedById} style={{ display: "none" }}>
          {isDisabledHelpVisible ? props.disabledHelpText : props.alternateText}
        </div>
      </React.Fragment>
    );
  }

  // "Fake" the button using the Disabled Help Badge so that the user still
  // "navigates" to the disabled button, but actually interacts with the
  // disabled help badge. This supports:
  //  1. The user can navigate to the "button" using the keyboard so it can be
  //     read out by screen readers.
  //  2. The user can click on the "button" and the disabled help badge will be
  //     displayed.
  //  3. From the user's perspective, they are still interacting with the
  //     button.
  if (isDisabledHelpVisible) {
    return (
      <DisabledHelpBadge
        helpText={props.disabledHelpText}
        isFocusRippleVisible={isFocusRippleVisible}
        isHelpOpen={isDisabledHelpOpen}
        onHelpOpenChange={setIsDisabledHelpOpen}
      >
        <ButtonBase
          aria-activedescendant={componentId}
          component="div"
          onBlur={() => setIsFocusRippleVisible(false)}
          onClick={() => setIsDisabledHelpOpen(!isDisabledHelpOpen)}
          onFocusVisible={() => setIsFocusRippleVisible(true)}
          role={undefined} // Override default "button" role
          style={{ width: "100%" }}
          tabIndex={0}
        >
          {content}
        </ButtonBase>
      </DisabledHelpBadge>
    );
  }

  return content;
}

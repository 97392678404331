import { Chip, styled } from "@mui/material";
import * as React from "react";
import Localization from "../core/Localization";

interface Props {
  inVerticalLayout?: true;
}

interface InnerProps {
  className?: string;
}

export const GridNewRowChip = styled(
  (props: InnerProps): JSX.Element => (
    <Chip
      className={props.className}
      label={Localization.getBuiltInMessage("newRow")}
      size="small"
    />
  )
)<Props>(({ theme, inVerticalLayout }) => ({
  backgroundColor: theme.palette.common.white,
  borderColor: theme.palette.success.main,
  borderStyle: "solid",
  borderWidth: 2,
  color: theme.palette.text.primary,
  fontSize: theme.typography.fontSize,
  height: 24,
  marginTop: inVerticalLayout ? undefined : -12,
  zIndex: 1,
}));

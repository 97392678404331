import AppServer, { State as AppServerState } from "../core/AppServer";
import PaneDataStore, { PaneDataByDataId } from "../stores/PaneDataStore";
import BaseService from "./BaseService";

export interface OnDialogOpenResponse {
  appServerState: AppServerState;
  dialogLayoutId: number;
  paneDataByDataId: PaneDataByDataId;
  validationErrors: string[];
}

export default class ComplexGridControlService {
  public static async onDialogOpen(
    rowKey: string,
    dataId: string,
    widgetName: string,
    dialogRowKey: string
  ): Promise<OnDialogOpenResponse> {
    return BaseService.requestObject<OnDialogOpenResponse>(
      `ComplexGridControl/OnDialogOpen/${rowKey}/${dataId}/${widgetName}`,
      null,
      null,
      {
        appServerState: AppServer.getState(),
        dataChanges: PaneDataStore.getChanges(),
        dialogRowKey,
      },
      "POST"
    );
  }
}

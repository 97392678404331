import { FormHelperTextProps, styled, Typography } from "@mui/material";
import * as React from "react";
import { ErrorList } from "./ErrorList";

interface Props {
  getErrors?: (value?: string) => string[];
  helperText: React.ReactNode;
  value?: string;
}

interface Result {
  formHelperTextProps: Partial<FormHelperTextProps>;
  errors: string[];
  hasErrors: boolean;
  helperText: React.ReactNode;
}

const ErrorContainer = styled(ErrorList)({
  overflow: "visible",
  whiteSpace: "normal",
});

const HelperText = styled(Typography)({
  overflow: "hidden",
  textOverflow: "ellipsis",
});

export default function getFieldHelperText(props: Props): Result {
  const result: Result = {
    errors: [],
    formHelperTextProps: {
      // Required so the DOM is still valid when error
      // messages are displayed in the helper text.
      component: "div",
      style: {
        marginLeft: 16,
        marginRight: 0,
      },
    },
    hasErrors: false,
    helperText: props.helperText,
  };

  if (!props.getErrors) {
    return result;
  }

  const errors: string[] = props.getErrors(props.value);
  if (errors.length === 0) {
    return result;
  }

  result.hasErrors = true;
  result.errors = errors;

  result.helperText = props.helperText ? (
    <div>
      <HelperText variant="caption">{props.helperText}</HelperText>
      <ErrorContainer errors={errors} />
    </div>
  ) : (
    <ErrorContainer errors={errors} />
  );

  return result;
}

import { ICellRendererParams } from "ag-grid-community";
import * as React from "react";
import { TableChildProps } from "../../coreui/Table";
import Typography from "../../coreui/Typography";
import PaneRow, {
  RuntimeProperties,
  RuntimeWidget,
} from "../../models/PaneRow";
import { NumericEdit } from "../NumericEdit";
import { FunctionName } from "../TableSummary";
import {
  GridColumnConfigProperties,
  RenderInlineProperties,
} from "./GridColumn";
import TextColumn from "./TextColumn";

interface ConfigProperties
  extends WidgetProperties,
    ICellRendererParams<PaneRow> {
  propagated: TableChildProps;
}

export interface WidgetProperties {
  dataId: string;
  justification: "Left" | "Right";
  name: string;
  scale: number | null;
}

export class NumericEditColumn extends React.PureComponent<ConfigProperties> {
  public static readonly widgetType: string = "NumericEditColumn";

  public static getFilterText(
    column: GridColumnConfigProperties,
    propagated: TableChildProps,
    row: PaneRow
  ): string {
    const widget = row.getWidgetT<string | null, RuntimeProperties>(
      column.name
    );

    if (!widget.value) {
      return "";
    }

    const configProps = column.widgetProps as WidgetProperties;

    const parsed = parseFloat(widget.value);
    const scale = configProps.scale || null;
    const filter = NumericEdit.formatNumericValue(parsed, true, scale);

    return filter !== null ? filter : "";
  }

  public static getSummaryValue(
    runtimeData: RuntimeWidget[],
    configProperties: ConfigProperties,
    functionName: FunctionName
  ): string | null {
    let result = 0;
    let count = 0;
    let isFirst = true;
    for (const data of runtimeData) {
      if (data.value === null) {
        continue;
      }
      if (typeof data.value !== "string") {
        throw new Error(`Unexpected data type ${typeof data.value}`);
      }

      const value: number = parseFloat(data.value);

      switch (functionName) {
        case "Average":
          result += value;
          count += 1;
          break;
        case "Count":
          count += 1;
          break;
        case "Maximum":
          result = isFirst || value > result ? value : result;
          isFirst = false;
          break;
        case "Minimum":
          result = isFirst || value < result ? value : result;
          isFirst = false;
          break;
        case "Sum":
          result += value;
          break;
        default:
          throw new Error(`Unknown summary function ${functionName}`);
      }
    }

    if (functionName === "Count") {
      return count.toString();
    }

    if (functionName === "Average" && count > 0) {
      result = result / count;
    }

    return NumericEdit.formatNumericValue(result, true, configProperties.scale);
  }

  public static renderInline(
    props: RenderInlineProperties
  ): JSX.Element | null {
    const widget = props.row.getWidgetT<string | null, RuntimeProperties>(
      props.column.name
    );
    const configProps = props.column.widgetProps as WidgetProperties;

    if (!widget.value) {
      return null;
    }

    const displayValue: string = NumericEdit.formatNumericValue(
      parseFloat(widget.value),
      true,
      configProps.scale
    )!;

    return (
      <Typography className={props.className} variant="body1">
        {displayValue}
      </Typography>
    );
  }

  public constructor(props: ConfigProperties) {
    super(props);

    props.eGridCell.addEventListener("keydown", this.onCellKeyDown);
  }

  private onCellKeyDown = (event: KeyboardEvent): void => {
    if (event.key === " " && !this.props.api.getEditingCells().length) {
      this.props.api.startEditingCell({
        colKey: this.props.column!.getColId(),
        rowIndex: this.props.rowIndex,
      });

      event.preventDefault();
      event.stopPropagation();
    }

    if (event.key === "Tab" && !!this.props.api.getEditingCells().length) {
      this.props.api.stopEditing();

      if (event.shiftKey) {
        this.props.api.tabToPreviousCell();
      } else {
        this.props.api.tabToNextCell();
      }

      event.preventDefault();
      event.stopPropagation();
    }
  };

  public render() {
    const { scale, value, ...otherProps } = this.props;

    const formattedValue: string | null = NumericEdit.formatNumericValue(
      value ? parseFloat(value) : null,
      true,
      scale
    );

    return <TextColumn showEllipsis value={formattedValue} {...otherProps} />;
  }
}

export default NumericEditColumn;

import { Checkbox, styled } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import * as React from "react";
import Localization from "../../core/Localization";
import Sys from "../../core/Sys";
import Icon from "../Icon";
import { TableChildProps } from "../Table";
import { CellUtil } from "./CellUtil";

interface Props extends ICellRendererParams {
  propagated: TableChildProps;
}

interface State {
  isChecked?: boolean;
}

const Container = styled("div")({
  display: "grid",
  height: "100%",
  placeItems: "center",
});

class SelectionRenderer extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      isChecked: props.propagated.parentTable.selection.isRowSelected(
        props.node
      ),
    };

    CellUtil.runOnAction(props.eGridCell, () => {
      this.setState(
        (prevState) => {
          return { isChecked: !prevState.isChecked };
        },
        () => {
          this.props.propagated.parentTable.selection.setRowsSelected(
            [this.props.node],
            this.state.isChecked!
          );

          Sys.announce(
            this.state.isChecked
              ? Localization.getBuiltInMessage("CheckBox.announceChecked")
              : Localization.getBuiltInMessage("CheckBox.announceUnchecked")
          );
        }
      );
    });

    CellUtil.setReadOnlyAttribute(props.eGridCell, false);
    props.eGridCell.addEventListener("keydown", this.onCellKeyDown);
  }

  private onCellKeyDown = (event: KeyboardEvent): void => {
    CellUtil.customizeGridNavigation(event, this.props);
  };

  private onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    this.props.propagated.parentTable.selection.setRowsSelected(
      [this.props.node],
      checked
    );
    this.setState({ isChecked: checked });
  };

  public componentWillUnmount(): void {
    this.props.eGridCell.removeEventListener("keydown", this.onCellKeyDown);
  }

  public refresh(): boolean {
    this.forceUpdate();

    return true;
  }

  public render(): React.ReactNode {
    let controlLabel = Localization.getBuiltInMessage(
      "DataTable.selectRowLabel"
    );

    if (this.props.data.isNew) {
      const newRowLabel = Localization.getBuiltInMessage(
        "DataTable.newRowAccessibleLabel"
      );
      controlLabel += `. ${newRowLabel}`;
    }

    const checked = this.props.propagated.parentTable.selection.isRowSelected(
      this.props.node
    );

    return (
      <Container aria-label={controlLabel}>
        <Checkbox
          aria-label={
            checked
              ? Localization.getBuiltInMessage("CheckBox.announceChecked")
              : Localization.getBuiltInMessage("CheckBox.announceUnchecked")
          }
          checked={checked}
          checkedIcon={<Icon icon="fas fa-check-square" />}
          color="default"
          icon={<Icon icon="far fa-square" />}
          onChange={this.onChange}
          tabIndex={-1}
        />
      </Container>
    );
  }
}

export default SelectionRenderer;

import * as React from "react";
import Icon from "../../coreui/Icon";
import { TableChildProps } from "../../coreui/Table";
import { FocusCellRendererParams } from "../../coreui/table/CellFocusUtil";
import { CellUtil } from "../../coreui/table/CellUtil";
import PaneRow, { RuntimeWidget } from "../../models/PaneRow";
import { AccessLevel } from "../AccessLevel";
import { FunctionName } from "../TableSummary";
import { GridColumnConfigProperties } from "./GridColumn";

interface ConfigProperties extends FocusCellRendererParams<null> {
  dataId: string;
  name: string;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  iconName: string;
  label: string;
}

export class ComponentTypeDisplayColumn extends React.PureComponent<ConfigProperties> {
  public static readonly widgetType: string = "ComponentTypeDisplayColumn";

  public static getFilterText(
    column: GridColumnConfigProperties,
    propagated: TableChildProps,
    row: PaneRow
  ): string {
    const widget = row.getWidgetT<null, RuntimeProperties>(column.name);
    return widget.properties.label;
  }

  public static getSummaryValue(
    runtimeData: RuntimeWidget[],
    configProperties: ConfigProperties,
    functionName: FunctionName
  ): string | null {
    let result = 0;
    for (const data of runtimeData) {
      const runtimeProperties = data.properties as RuntimeProperties;

      if (runtimeProperties.accessLevel >= AccessLevel.readOnly) {
        result += 1;
      }
    }

    return result.toString();
  }

  public constructor(props: ConfigProperties) {
    super(props);

    props.eGridCell.addEventListener("keydown", this.onCellKeyDown);
  }

  private onCellKeyDown = (event: KeyboardEvent): void => {
    CellUtil.customizeGridNavigation(event, this.props);
  };

  public componentWillUnmount(): void {
    this.props.eGridCell.removeEventListener("keydown", this.onCellKeyDown);
  }

  public render(): React.ReactNode {
    const row = this.props.data!;
    const widget = row.getWidgetT<null, RuntimeProperties>(this.props.name);

    CellUtil.setReadOnlyAttribute(this.props.eGridCell, true);

    if (widget.properties.accessLevel <= AccessLevel.hidden) {
      return null;
    }

    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          margin: "0px 24px",
        }}
      >
        <Icon
          icon={widget.properties.iconName}
          style={{
            fontSize: 16,
            height: "auto",
            marginLeft: 1,
            marginRight: ".4em",
          }}
        />
        <div
          style={{
            lineHeight: "normal",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%",
          }}
        >
          {widget.properties.label}
        </div>
      </div>
    );
  }
}

export default ComponentTypeDisplayColumn;

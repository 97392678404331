import { Breakpoint, Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import * as React from "react";
import { useWidth } from "../core/Responsive";
import Presentation from "../coreui/Presentation";
import PaneRow from "../models/PaneRow";
import { AccessLevel } from "./AccessLevel";
import MustangGrid from "./Grid";

interface ConfigProperties {
  contentsGridWidth: { [key in Breakpoint]: number };
  items: object[];
  media: MediaWidgetProps;
  mediaGrouping: MediaGrouping;
  orientation: Orientation;
  propagated: object;
}

interface Orientation {
  lg: "Left" | "Top" | null;
  md: "Left" | "Top" | null;
  sm: "Left" | "Top" | null;
  xs: "Left" | "Top" | null;
}

export interface MediaChild {
  parentMediaOrientation?: "Left" | "Top" | null;
}

interface MediaGrouping {
  lg: "Normal" | "Tight" | null;
  md: "Normal" | "Tight" | null;
  sm: "Normal" | "Tight" | null;
  xs: "Normal" | "Tight" | null;
}

interface MediaWidgetProps {
  props: RuntimeProperties;
  type: string;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  dataId: string;
  name: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    minWidth: "100px",
  },
  lgleft: {
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      flexWrap: "nowrap",
    },
  },
  lgleftcontent: {
    [theme.breakpoints.up("lg")]: {
      alignSelf: "center",
      marginLeft: 40,
    },
  },
  lgtop: {
    [theme.breakpoints.up("lg")]: {
      alignItems: "center",
      flexDirection: "column",
    },
  },
  lgtopcontent: {
    [theme.breakpoints.up("lg")]: {
      "& > div": {
        justifyItems: "center",
      },
      alignSelf: "stretch",
      marginTop: 24,
    },
  },
  mdleft: {
    [theme.breakpoints.only("md")]: {
      flexDirection: "row",
      flexWrap: "nowrap",
    },
  },
  mdleftcontent: {
    [theme.breakpoints.only("md")]: {
      alignSelf: "center",
      marginLeft: 24,
    },
  },
  mdtop: {
    [theme.breakpoints.only("md")]: {
      alignItems: "center",
      flexDirection: "column",
    },
  },
  mdtopcontent: {
    [theme.breakpoints.only("md")]: {
      "& > div": {
        justifyItems: "center",
      },
      alignSelf: "stretch",
      marginTop: 24,
    },
  },
  root: {
    maxWidth: "100%",
  },
  smleft: {
    [theme.breakpoints.only("sm")]: {
      flexDirection: "row",
      flexWrap: "nowrap",
    },
  },
  smleftcontent: {
    [theme.breakpoints.only("sm")]: {
      alignSelf: "center",
      marginLeft: 24,
    },
  },
  smtop: {
    [theme.breakpoints.only("sm")]: {
      alignItems: "center",
      flexDirection: "column",
    },
  },
  smtopcontent: {
    [theme.breakpoints.only("sm")]: {
      "& > div": {
        justifyItems: "center",
      },
      alignSelf: "stretch",
      marginTop: 24,
    },
  },
  xsleft: {
    [theme.breakpoints.only("xs")]: {
      flexDirection: "row",
      flexWrap: "nowrap",
    },
  },
  xsleftcontent: {
    [theme.breakpoints.only("xs")]: {
      alignSelf: "center",
      marginLeft: 16,
    },
  },
  xstop: {
    [theme.breakpoints.only("xs")]: {
      alignItems: "center",
      flexDirection: "column",
    },
  },
  xstopcontent: {
    [theme.breakpoints.only("xs")]: {
      "& > div": {
        justifyItems: "center",
      },
      alignSelf: "stretch",
      marginTop: 16,
    },
  },
}));

export const Media = observer((props: ConfigProperties): JSX.Element | null => {
  const classes = useStyles();
  const width = useWidth();

  const mediaWidgetProperties = props.media;
  const row = PaneRow.get(mediaWidgetProperties.props.dataId);
  if (!row) {
    return null;
  }

  const mediaWidget = row.getWidgetT<null, RuntimeProperties>(
    mediaWidgetProperties.props.name
  );
  if (mediaWidget.properties.accessLevel <= AccessLevel.disabled) {
    return null;
  }

  const rootClasses: string[] = [classes.root];
  const contentClasses: string[] = [classes.content];

  const breakPoints: Breakpoint[] = ["xs", "sm", "md", "lg"];
  for (const breakPoint of breakPoints) {
    const orientation = props.orientation[breakPoint];
    if (orientation) {
      const className = `${breakPoint}${orientation.toLowerCase()}`;

      rootClasses.push(classes[className]);
      contentClasses.push(classes[`${className}content`]);
    }
  }

  const gridGrouping =
    props.mediaGrouping[width] === "Normal"
      ? "Closely Related"
      : props.mediaGrouping[width];

  const propagated: MediaChild = {
    ...props.propagated,
    parentMediaOrientation: props.orientation[width],
  };

  return (
    <Grid container className={rootClasses.join(" ")}>
      <Grid item>{Presentation.create(props.media, propagated)}</Grid>
      {props.items.length > 0 ? (
        <Grid
          className={contentClasses.join(" ")}
          item
          style={{ flex: "1 1 0%" }}
        >
          <MustangGrid
            grouping={gridGrouping}
            lg={props.contentsGridWidth["lg"]}
            md={props.contentsGridWidth["md"]}
            sm={props.contentsGridWidth["sm"]}
            xs={props.contentsGridWidth["xs"]}
          >
            {props.items.map((item, index) => (
              <React.Fragment key={index}>
                {Presentation.create(item, propagated)}
              </React.Fragment>
            ))}
          </MustangGrid>
        </Grid>
      ) : null}
    </Grid>
  );
});

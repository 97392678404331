import { ClickAwayListener, darken, styled, Theme } from "@mui/material";
import * as React from "react";
import Button from "./Button";
import { ErrorList } from "./ErrorList";
import { ErrorTooltip } from "./ErrorTooltip";
import { TableChildProps } from "./Table";

interface Props {
  errors: string[];
  propagated: TableChildProps;
  rowKey: string;
}

interface State {
  open: boolean;
}

const BadgeButton = styled(Button)({
  marginBottom: 8,
  marginRight: 8,
  pointerEvents: "auto",
});

export class RowErrorBadge extends React.PureComponent<Props, State> {
  private buttonRef = React.createRef<HTMLButtonElement>();

  public constructor(props: Props) {
    super(props);

    this.state = { open: false };

    props.propagated.parentTable
      .getTable()
      .rowErrorBadges.set(props.rowKey, this);
  }

  public close = () => {
    this.setState({ open: false });
  };

  public open = () => {
    this.setState({ open: true });
  };

  public render() {
    if (!this.props.errors) {
      return null;
    }

    return (
      <ClickAwayListener
        mouseEvent="onMouseDown"
        onClickAway={() => this.setState({ open: false })}
      >
        <ErrorTooltip
          // DisableHoverListener
          enterTouchDelay={0}
          open={this.state.open}
          title={<ErrorList errors={this.props.errors} />}
        >
          <BadgeButton
            aria-hidden={true}
            color="danger"
            customSize="tiny"
            icon="fas fa-exclamation"
            onClick={() => {
              // Fix for Safari not opening tooltip
              this.buttonRef.current!.focus();
              this.setState({ open: true });
            }}
            ref={this.buttonRef}
            tabIndex={-1}
          />
        </ErrorTooltip>
      </ClickAwayListener>
    );
  }
}

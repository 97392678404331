import AppServer, { State as AppServerState } from "../core/AppServer";
import { BusinessError } from "../stores/ErrorsStore";
import LayoutStateStore from "../stores/LayoutStateStore";
import PaneDataStore, { PaneDataByDataId } from "../stores/PaneDataStore";
import BaseService from "./BaseService";

export interface OnRoundTripResponse {
  appServerState: AppServerState;
  businessErrors: BusinessError[];
  paneDataByDataId: PaneDataByDataId;
  validationErrors: string[];
}

export default class EmbeddedAddOnService {
  public static async onRoundTrip(
    rowKey: string,
    dataId: string,
    widgetName: string
  ): Promise<OnRoundTripResponse> {
    return BaseService.requestObject<OnRoundTripResponse>(
      `EmbeddedAddOn/OnRoundTrip/${rowKey}/${dataId}/${widgetName}`,
      null,
      null,
      {
        appServerState: AppServer.getState(),
        dataChanges: PaneDataStore.getChanges(),
        layoutState: LayoutStateStore.getCurrentState(),
      },
      "POST"
    );
  }
}

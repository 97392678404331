import { Theme } from "@mui/material";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import { observer } from "mobx-react";
import * as React from "react";
import { TableChildProps } from "../coreui/Table";
import PaneRow from "../models/PaneRow";
import { AccessLevel } from "./AccessLevel";

interface ChildWidgetProperties {
  accessLevel?: AccessLevel;
}

interface Props {
  childLayoutWidgetNames: string[];
  children: React.ReactNode;
  childWidgetNames: string[];
  dataId: string;
  justify: "start" | "end";
  propagated: TableChildProps;
  toolbarType: "header" | "footer" | "selection" | "summary";
  verticalLayout?: boolean;
}

const styles = (theme: Theme) => {
  const sectionSpaceStyle = {};
  for (const breakPoint of theme.spacingBreakPoints) {
    const groupSpacing = theme.freeflow.group.spacing[breakPoint];
    const sectionSpacing = theme.freeflow.section.spacing[breakPoint];

    sectionSpaceStyle[theme.breakpoints.up(breakPoint)] = {
      marginLeft: -groupSpacing.horizontal * 0.5,
      marginRight: -groupSpacing.horizontal * 0.5,

      paddingLeft: sectionSpacing.horizontal * 0.5,
      paddingRight: sectionSpacing.horizontal * 0.5,
    };
  }

  return createStyles({
    container: {
      alignItems: "center",
      display: "flex",
      flex: 1,
      flexWrap: "wrap",
      ...sectionSpaceStyle,
    },
  });
};

export class ToolbarContainerSection extends React.Component<
  Props & WithStyles<typeof styles>
> {
  private shouldRender(): boolean {
    if (this.props.childLayoutWidgetNames.length > 0) {
      return true;
    }

    const row = PaneRow.get(this.props.dataId)!;
    for (const childWidgetName of this.props.childWidgetNames) {
      const childWidget = row.getWidgetT<null, ChildWidgetProperties>(
        childWidgetName
      );

      if (childWidget.properties.accessLevel === undefined) {
        return true;
      }

      if (childWidget.properties.accessLevel >= AccessLevel.disabled) {
        return true;
      }
    }

    return false;
  }

  public render(): React.ReactNode {
    if (!this.shouldRender()) {
      return null;
    }

    let justifyContent = {
      end: "flex-end",
      start: "flex-start",
    }[this.props.justify];

    // When table is in vertical layout the footer toolbar container will
    // render centered justified since there is only one full width section
    // instead of a start and end section.
    if (
      this.props.propagated.parentTable?.isVerticalLayout &&
      this.props.toolbarType === "footer"
    ) {
      justifyContent = "center";
    }

    return (
      <div
        className={this.props.classes.container}
        style={{
          justifyContent,
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export default withStyles(styles)(observer(ToolbarContainerSection));

import {
  AccordionSummary,
  Badge,
  Accordion as MuiAccordion,
  Theme,
} from "@mui/material";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import * as React from "react";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import DisabledHelpBadge from "./DisabledHelpBadge";
import Icon from "./Icon";
import Typography from "./Typography";

interface Props {
  badge?: "contents" | "errors";
  businessErrorsCount: number;
  children: React.ReactNode;
  disabled?: boolean;
  disabledHelpText: string | null;
  expanded: boolean;
  label?: React.ReactNode;
  labelId?: string;
  onChange: (event: React.ChangeEvent<{}>, value: string) => void;
  showDisabledHelp?: boolean;
  value: string;
}

const styles = (theme: Theme) =>
  createStyles({
    badge: {
      display: "inline",
      right: -12,
      top: 4,
    },
    badgeDisabled: {
      right: -12,
      top: 4,
    },
    errorIcon: {
      color: theme.palette.error.main,
      fontSize: "16px",
    },
    expandIcon: {
      display: "flex",
      flex: "auto",
      justifyContent: "flex-end",
    },
    label: {
      display: "inline-block",
      fontSize: 14,
      letterSpacing: 1,
      textTransform: "uppercase",
    },
    labelDisabled: {
      color: theme.palette.grey[300],
    },
    link: {
      justifyContent: "flex-start",
      marginTop: 1,
      minHeight: 0,
      minWidth: 0,
      padding: 0,
    },
    linkLabel: {
      overflow: "visible",
    },
    summary: {
      [theme.breakpoints.up("xs")]: {
        paddingLeft: 16,
        paddingRight: 12,
      },
      [theme.breakpoints.up("md")]: {
        paddingLeft: 24,
        paddingRight: 20,
      },
      [theme.breakpoints.up("lg")]: {
        paddingLeft: 40,
        paddingRight: 36,
      },
    },
    summaryDisabled: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      opacity: "1 !important" as any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      pointerEvents: "auto !important" as any,
    },
    summaryFocusVisible: {
      backgroundColor: `${theme.palette.grey[100]} !important`,
    },
  });

interface State {
  isDisabledHelpOpen: boolean;
  isFocusRippleVisible: boolean;
}

export class ExpansionPanel extends React.PureComponent<
  Props & WithStyles<typeof styles>,
  State
> {
  private readonly componentId: string;
  private readonly describedById: string;
  private isDisabledHelpVisible: boolean;

  public constructor(props: Props & WithStyles<typeof styles>) {
    super(props);

    this.componentId = `expansion-panel-${Sys.nextId}`;
    this.describedById = `${this.componentId}-described-by`;
    this.isDisabledHelpVisible = false;

    this.state = { isDisabledHelpOpen: false, isFocusRippleVisible: false };
  }

  private onBlur = (event: React.FocusEvent<HTMLDivElement>): void => {
    if (this.props.disabled) {
      this.setState({ isDisabledHelpOpen: false });
    }
    this.setState({ isFocusRippleVisible: false });
  };

  private onChange = (event: React.ChangeEvent<{}>) => {
    if (!this.props.disabled) {
      this.props.onChange(event, this.props.value);
    }
  };

  private onClick = (event: React.MouseEvent<HTMLElement>): void => {
    if (this.props.disabled) {
      this.setState({
        isDisabledHelpOpen: !this.state.isDisabledHelpOpen,
      });
    }
  };

  private onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (this.props.disabled) {
      if (event.key === "Escape") {
        this.setState({ isDisabledHelpOpen: false });
        event.preventDefault();
        event.stopPropagation();
      }
    }
  };

  public render() {
    let labelClasses: string = this.props.classes.label;
    if (this.props.disabled) {
      labelClasses += ` ${this.props.classes.labelDisabled}`;
    }

    // Only specify the describedById if its element is actually rendered.
    let describedById: string | undefined = undefined;
    let label: React.ReactNode = null;

    if (this.props.disabled) {
      if (this.props.showDisabledHelp) {
        // DisabledHelpText is mandatory if a disabled condition is configured
        this.isDisabledHelpVisible = true;
        describedById = this.describedById;
        label = (
          <DisabledHelpBadge
            classes={{
              badge: this.props.classes.badgeDisabled,
              root: labelClasses,
            }}
            helpText={this.props.disabledHelpText!}
            isFocusRippleVisible={this.state.isFocusRippleVisible}
            isHelpOpen={this.state.isDisabledHelpOpen}
            onHelpOpenChange={(isHelpOpen: boolean): void =>
              this.setState({ isDisabledHelpOpen: isHelpOpen })
            }
          >
            {this.props.label}
            <span id={this.describedById} style={{ display: "none" }}>
              {this.props.disabledHelpText}
            </span>
          </DisabledHelpBadge>
        );
      }
    } else if (this.props.badge === "contents") {
      describedById = this.describedById;
      label = (
        <Badge
          badgeContent={
            <Icon
              color="info"
              fixedWidth
              icon="far fa-exclamation-circle"
              style={{
                fontSize: 16,
              }}
            />
          }
          classes={{
            badge: this.props.classes.badge,
            root: labelClasses,
          }}
          overlap="rectangular"
        >
          {this.props.label}
          <span id={this.describedById} style={{ display: "none" }}>
            {Localization.getBuiltInMessage("Tab.hasContents")}
          </span>
        </Badge>
      );
    } else if (this.props.badge === "errors") {
      describedById = this.describedById;
      label = (
        <Badge
          badgeContent={
            <Icon
              className={this.props.classes.errorIcon}
              fixedWidth
              icon="far fa-octagon-exclamation"
            />
          }
          classes={{
            badge: this.props.classes.badge,
            root: labelClasses,
          }}
          overlap="rectangular"
        >
          {this.props.label}
          <span id={this.describedById} style={{ display: "none" }}>
            {this.props.businessErrorsCount > 1
              ? Localization.getBuiltInMessage(
                  "Tab.erroredFieldsCountMultiple",
                  {
                    widgetErrorCount: this.props.businessErrorsCount,
                  }
                )
              : Localization.getBuiltInMessage("Tab.erroredFieldsCountSingle")}
          </span>
        </Badge>
      );
    } else {
      label = <div className={labelClasses}>{this.props.label}</div>;
    }

    return (
      <MuiAccordion
        elevation={0}
        expanded={this.props.expanded}
        onChange={this.onChange}
      >
        <AccordionSummary
          aria-describedby={describedById}
          aria-disabled={this.props.disabled}
          classes={{
            disabled: this.props.classes.summaryDisabled,
            focusVisible: this.props.classes.summaryFocusVisible,
            root: this.props.classes.summary,
          }}
          disableRipple={false}
          focusRipple={!this.isDisabledHelpVisible}
          onBlur={this.onBlur}
          onClick={this.onClick}
          onFocusVisible={() =>
            this.setState({
              isFocusRippleVisible: true,
            })
          }
          onKeyDown={this.onKeyDown}
        >
          <Typography component="span" id={this.props.labelId}>
            {label}
          </Typography>
          <Typography className={this.props.classes.expandIcon}>
            <Icon
              icon={this.props.expanded ? "fas fa-minus" : "fas fa-plus"}
              fixedWidth
              style={{
                height: 24,
                marginLeft: this.props.badge || this.props.disabled ? 32 : 8,
                width: 24,
              }}
            />
          </Typography>
        </AccordionSummary>
        {this.props.expanded ? this.props.children : null}
      </MuiAccordion>
    );
  }
}

export default withStyles(styles)(ExpansionPanel);

// VERSION_WARNING Material-UI 4.9.14
// This component is based on the internal SelectInput component used by MUI's
// Select component.  See comments in Select.tsx for further background.
//
// See original source at https://github.com/mui-org/material-ui/blob/v4.9.14/packages/material-ui/src/Select/SelectInput.js
// This code attempts to follow the existing source where possible with some
// removal of unnecesary features (multi-select, controlled open), built-in
// MenuItem generation (to simplify) and other changes where necessary to
// support ARIA 1.2.

import { InputBaseComponentProps, Theme } from "@mui/material";
import { useControlled, useForkRef } from "@mui/material/utils";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import ComboBoxOption from "./ComboBoxOption";
import Menu from "./Menu";
import MenuItem from "./MenuItem";
import multiClassName from "./MultiClassName";

// Styles are taken from the MUI NativeSelect component and are necessary to
// style the select
const useStyles = makeStyles((theme: Theme) => ({
  /* Pseudo-class applied to the select component `disabled` class. */
  disabled: {},
  /* Styles applied to the select component if `variant="filled"`. */
  filled: {
    "&&": {
      paddingRight: 32,
    },
  },
  /* Styles applied to the select component `root` class. */
  root: {},
  /* Styles applied to the select component `select` class. */
  select: {
    "&$disabled": {
      cursor: "default",
    },
    "&&": {
      paddingRight: 24,
    },
    // Remove IE 11 arrow
    "&::-ms-expand": {
      display: "none",
    },
    "&:focus": {
      // Show that it's not an text input
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      borderRadius: 0, // Reset Chrome style
    },
    "&:not([multiple]) option, &:not([multiple]) optgroup": {
      backgroundColor: theme.palette.background.paper,
    },
    "&[multiple]": {
      height: "auto",
    },
    "-moz-appearance": "none", // Reset
    "-webkit-appearance": "none", // Reset
    borderRadius: 0, // Reset
    cursor: "pointer",
    // When interacting quickly, the text can end up selected.
    // Native select can't be selected either.
    minWidth: 16, // So it doesn't collapse.
    userSelect: "none",
  },
  /* Styles applied to the select component `selectMenu` class. */
  selectMenu: {
    height: "auto", // Resets for multpile select with chips
    minHeight: "1.1876em", // Required for select\text-field height consistency
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

interface Props extends InputBaseComponentProps {
  defaultValue?: string;
  errors: string[];
  IconComponent: React.ElementType;
  inputRef: React.Ref<InputRef>;
  labelId?: string;
  name: string;
  openOnMount: boolean;
  onValueChange: (value: ComboBoxOption) => void;
  options: ComboBoxOption[];
  required?: boolean;
  SelectDisplayProps?: React.HTMLAttributes<HTMLDivElement>;
  value?: string;
}

interface InputRef {
  focus: () => void;
  node: HTMLInputElement | null;
  value: string;
}

const SelectInput = React.forwardRef<InputRef, Props>((props, ref) => {
  const {
    "aria-label": ariaLabel,
    autoFocus,
    className,
    defaultValue,
    disabled,
    errors,
    IconComponent,
    inputRef: inputRefProp,
    labelId,
    name,
    onBlur,
    onClose,
    onChange,
    onFocus,
    onValueChange,
    openOnMount,
    options,
    readOnly,
    required,
    SelectDisplayProps,
    tabIndex: tabIndexProp,
    // Catching `type` from Input which makes no sense for SelectInput
    type,
    value: valueProp,
    ...other
  } = props;

  const [value, setValue] = useControlled({
    controlled: valueProp,
    default: defaultValue,
    name: "Select",
  });

  const classes = useStyles();
  const inputRef = React.useRef(null);
  const [displayNode, setDisplayNode] = React.useState<HTMLDivElement | null>(
    null
  );
  const [openState, setOpenState] = React.useState(false);
  const handleRef = useForkRef(ref, inputRefProp);

  React.useImperativeHandle(
    handleRef,
    () => ({
      focus: () => {
        displayNode!.focus();
      },
      node: inputRef.current,
      value,
    }),
    [displayNode, value]
  );

  React.useEffect(() => {
    if (displayNode) {
      if (!labelId) {
        return;
      }
      const label = displayNode.ownerDocument!.getElementById(labelId);
      if (label) {
        const handler = () => {
          if (getSelection()!.isCollapsed) {
            displayNode.focus();
          }
        };
        label.addEventListener("click", handler);
        return () => {
          label.removeEventListener("click", handler);
        };
      }
    }

    return undefined;
  }, [labelId, displayNode]);

  React.useEffect(() => {
    if (!openOnMount) {
      return;
    }

    setTimeout(() => {
      if (displayNode) {
        displayNode.focus();
        setOpenState(true);
      }
    }, 100);
  }, [displayNode]);

  const update = (
    openValue: boolean,
    event?:
      | React.MouseEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (!openValue && onClose) {
      onClose(event);
    }
    setOpenState(openValue);
  };

  const onMouseDownDisplayNode = (event: React.MouseEvent<HTMLDivElement>) => {
    // Ignore everything but left-click
    if (event.button !== 0) {
      return;
    }
    // Hijack the default focus behavior.
    event.preventDefault();
    displayNode!.focus();

    // Toggle openState. Because the drop down is non-model, clicking the input
    // element again should collapse the drop down
    update(!openState, event);
  };

  const onKeyDownDisplayNode = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const validKeys = [
      " ",
      "ArrowUp",
      "ArrowDown",
      // The native select doesn't respond to enter on MacOS, but it's recommended by
      // https://www.w3.org/TR/wai-aria-practices/examples/listbox/listbox-collapsible.html
      "Enter",
    ];

    if (validKeys.indexOf(event.key) !== -1) {
      event.preventDefault();
      update(true, event);
    }
  };

  const onCloseMenu = (): void => {
    update(false);
    if (displayNode) {
      displayNode.focus();
    }
  };

  const onClickMenuItem = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    optionValue: ComboBoxOption
  ): void => {
    if (onValueChange) {
      onValueChange(optionValue);
    }
    update(false);
    if (displayNode) {
      displayNode.focus();
    }
  };

  const open = displayNode !== null && openState;

  const onBlurDisplayNode = (event: React.FocusEvent<HTMLInputElement>) => {
    // If open event.stopImmediatePropagation
    if (onBlur) {
      if (!open) {
        event.persist();
        // Preact support, target is read only property on a native event.
        Object.defineProperty(event, "target", {
          value: { name, value },
          writable: true,
        });
      }
      onBlur(event);
    }
  };

  let display;
  const componentId =
    SelectDisplayProps!.id || (name ? `select-${name}` : undefined);
  const menuId = `${componentId}-list`;
  let selectedId: string | undefined = undefined;

  const comboBoxOptions: React.ReactNode[] = options.map((o, i) => {
    const selected = o.value === value || (!o.value && !value);
    const optionId = `${menuId}-${i}`;
    if (selected) {
      selectedId = optionId;
      // Only display if this is a non-null value
      if (value) {
        display = o.display;
      }
    }

    return (
      <MenuItem
        aria-selected={selected}
        disabled={o.historic}
        key={o.value ? o.value : ""}
        id={optionId}
        onClick={(event) => onClickMenuItem(event, o)}
        onKeyUp={(event) => {
          if (event.key === " ") {
            // Otherwise MenuItem dispatches a click event which is not the
            // behaviour of the native <option>.  This causes the select to
            // close immediately.
            event.preventDefault();
          }
        }}
        role="option"
        selected={selected}
        value={o.value ? o.value : ""}
      >
        {o.display}
      </MenuItem>
    );
  });

  let tabIndex;
  if (typeof tabIndexProp !== "undefined") {
    tabIndex = tabIndexProp;
  } else {
    tabIndex = disabled ? undefined : 0;
  }

  return (
    <React.Fragment>
      <div
        className={multiClassName(
          classes.root,
          classes.select,
          classes.selectMenu,
          classes.filled,
          disabled ? classes.disabled : undefined,
          className
        )}
        ref={setDisplayNode}
        tabIndex={tabIndex}
        role="combobox"
        aria-activedescendant={selectedId}
        aria-controls={menuId}
        aria-disabled={disabled ? "true" : undefined}
        // Must explicitly set aria-expanded to false for some screen readers
        aria-expanded={open ? "true" : "false"}
        aria-haspopup="listbox"
        aria-invalid={errors && errors.length > 0 ? "true" : undefined}
        aria-label={ariaLabel}
        aria-labelledby={labelId}
        aria-required={required}
        onKeyDown={onKeyDownDisplayNode}
        onMouseDown={onMouseDownDisplayNode}
        onBlur={onBlurDisplayNode}
        onFocus={(event) =>
          onFocus && onFocus(event as React.FocusEvent<HTMLInputElement>)
        }
        {...SelectDisplayProps}
        // The id is required for proper a11y
        id={componentId}
      >
        {/* So the vertical align positioning algorithm kicks in. */}
        {!display ? (
          // eslint-disable-next-line react/no-danger
          <span dangerouslySetInnerHTML={{ __html: "&#8203;" }} />
        ) : (
          display
        )}
      </div>
      <input
        value={value}
        name={name}
        ref={inputRef}
        type="hidden"
        autoFocus={autoFocus}
        {...other}
      />
      <IconComponent />
      <Menu
        anchorEl={open && displayNode ? displayNode : undefined}
        aria-label={ariaLabel}
        labelId={labelId}
        menuId={menuId}
        modal={true}
        onClose={onCloseMenu}
        variant="selectedMenu"
      >
        {comboBoxOptions}
      </Menu>
    </React.Fragment>
  );
});

SelectInput.displayName = "SelectInput";

export default SelectInput;

import { styled } from "@mui/material";
import * as React from "react";
import { ErrorMessage } from "../coreui/ErrorMessage";
import Typography from "../coreui/Typography";

interface Props {
  children?: React.ReactNode;
  title?: string;
}

interface State {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
}

const Root = styled("div")(({ theme }) => ({
  borderColor: theme.palette.error.main,
  borderStyle: "solid",
  borderWidth: 1,
  display: "grid",
  padding: 8,
  rowGap: 8,
}));

export class ErrorBoundary extends React.PureComponent<Props, State> {
  private childrenWithError: React.ReactNode;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  public constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public componentDidCatch(error: any, errorInfo: any) {
    this.childrenWithError = this.props.children;
  }

  public componentDidUpdate() {
    if (this.props.children !== this.childrenWithError) {
      this.childrenWithError = null;
      this.setState({ error: null });
    }
  }

  public render() {
    if (!this.state.error) {
      return this.props.children;
    }

    let message: string = "An unexpected error occurred";
    let title: React.ReactNode = null;

    if (process.env.NODE_ENV !== "production") {
      if (this.props.title) {
        title = <Typography>{this.props.title}</Typography>;
      }

      message =
        this.state.error instanceof Error
          ? this.state.error.message
          : this.state.error.toString();
    }

    return (
      <Root>
        {title}
        <Typography color="error" component="div">
          <ErrorMessage message={message} />
        </Typography>
      </Root>
    );
  }
}

export default ErrorBoundary;

import { Typography } from "@mui/material";
import { observer } from "mobx-react";
import * as React from "react";
import { useWidth } from "../core/Responsive";
import Icon from "../coreui/Icon";
import PaneRow from "../models/PaneRow";
import { AccessLevel } from "./AccessLevel";

interface IconHeight {
  lg: number | undefined;
  md: number | undefined;
  sm: number | undefined;
  xs: number | undefined;
}

interface ConfigProperties {
  dataId: string;
  iconHeight: IconHeight;
  name: string;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  alternateText: string;
  iconName: string;
}

export const IconDisplay = observer(
  (props: ConfigProperties): JSX.Element | null => {
    const width = useWidth();

    const row = PaneRow.get(props.dataId);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<null, RuntimeProperties>(props.name);

    if (widget.properties.accessLevel <= AccessLevel.hidden) {
      return null;
    }

    let height: number | undefined = undefined;
    if (width in props.iconHeight) {
      height = props.iconHeight[width];
    }

    return (
      <div style={{ overflow: "hidden" }}>
        <Typography
          style={{
            fontSize: height,
            height,
            width: height,
          }}
        >
          <Icon
            aria-label={widget.properties.alternateText || undefined}
            icon={widget.properties.iconName}
          />
        </Typography>
      </div>
    );
  }
);

import * as React from "react";

type ActionTypes =
  | "first"
  | "last"
  | "next"
  | "previous"
  | "set active index"
  | "set count";

interface Action {
  activeIndex?: number | null;
  count?: number;
  type: ActionTypes;
}

interface State {
  activeIndex: number | null;
  count: number;
}

function reducer(state: State, action: Action): State {
  const firstIndex: number = 0;
  const lastIndex: number = state.count - 1;

  switch (action.type) {
    case "first":
      return { ...state, activeIndex: firstIndex };

    case "last":
      return { ...state, activeIndex: lastIndex };

    case "next":
      if (state.activeIndex === null) {
        return state;
      }

      const nextIndex = Math.min(state.activeIndex + 1, lastIndex);
      return { ...state, activeIndex: nextIndex };

    case "previous":
      if (state.activeIndex === null) {
        return state;
      }

      const previousIndex = Math.max(state.activeIndex - 1, 0);
      return { ...state, activeIndex: previousIndex };

    case "set active index":
      if (action.activeIndex === undefined) {
        throw Error(
          `activeIndex must be provided when action is "${action.type}"`
        );
      }

      return { ...state, activeIndex: action.activeIndex };

    case "set count":
      if (action.count === undefined) {
        throw Error(
          `newCount must be provided when action is "${action.type}"`
        );
      }

      const activeIndex =
        state.activeIndex !== null && state.activeIndex >= action.count
          ? action.count - 1
          : state.activeIndex;

      return { ...state, activeIndex, count: action.count };

    default:
      throw Error(`Unknown action type "${action.type}" provided.`);
  }
}

export function useNavigation(count: number): [State, React.Dispatch<Action>] {
  return React.useReducer(reducer, { activeIndex: null, count });
}

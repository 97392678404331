import Logging from "../core/Logging";
import CustomIconService from "../services/CustomIconService";

interface CustomIcon {
  contents: string;
  name: string;
}

export default class CustomIconStore {
  private static iconsByName: { [name: string]: CustomIcon };

  public static async iconForName(name: string): Promise<CustomIcon | null> {
    const normalizedName = name.toLowerCase();

    if (this.iconsByName && normalizedName in this.iconsByName) {
      return this.iconsByName[normalizedName];
    }

    const iconContent = await CustomIconService.getIcon(name);
    if (iconContent !== null) {
      const icon = {
        contents: iconContent,
        name: normalizedName,
      };

      this.iconsByName[icon.name] = icon;

      return icon;
    }

    Logging.warn(`Custom icon not found: ${name}`);

    return null;
  }

  public static load(contentsByName: { [name: string]: string }) {
    this.iconsByName = {};
    for (const name in contentsByName) {
      if (!contentsByName.hasOwnProperty(name)) {
        continue;
      }

      const icon = {
        contents: contentsByName[name],
        name: name.toLowerCase(),
      };

      this.iconsByName[icon.name] = icon;
    }
  }
}

import * as React from "react";
import * as ReactDOM from "react-dom";
import AppServer from "../core/AppServer";
import RequestsStore from "../stores/RequestsStore";
import {
  ErrorCodeConfig,
  ErrorPageTemplate,
  ErrorPageTemplateConfig,
} from "../templates";

export interface ErrorPageConfig {
  backgroundImage: string;
  errorCodes: { [status: number]: ErrorCodeConfig };
  message: string;
  solutionsTitle: string;
  solutions: string[];
  template: ErrorPageTemplateConfig;
}

export class ErrorPage {
  private static config: ErrorPageConfig | null = null;

  public static render(message: string | null, status: number) {
    AppServer.clearState();
    RequestsStore.instance.clearAllProcessing();

    if (ErrorPage.config !== null) {
      const config: ErrorCodeConfig = ErrorPage.config.errorCodes[status] || {
        backgroundImage: undefined,
        message: ErrorPage.config.message,
        solutions: ErrorPage.config.solutions,
      };

      if (!config.backgroundImage) {
        config.backgroundImage = ErrorPage.config.backgroundImage;
      }

      ReactDOM.render(
        <ErrorPageTemplate
          backgroundColor={ErrorPage.config.template.backgroundColor}
          config={config}
          footer={ErrorPage.config.template.footer}
          foreground={ErrorPage.config.template.foreground}
          header={ErrorPage.config.template.header}
          message={status >= 500 ? message : null}
          solutionsTitle={ErrorPage.config.solutionsTitle}
          status={status}
        />,
        document.getElementById("root")
      );
    } else {
      ReactDOM.render(
        <ErrorPageTemplate
          config={{ message: message || "", solutions: [] }}
          status={status}
        />,
        document.getElementById("root")
      );
    }
  }

  public static setConfig(config: ErrorPageConfig) {
    if (ErrorPage.config !== null) {
      throw new Error("Error page config is already set");
    }

    ErrorPage.config = config;
  }
}

import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../core/Localization";
import { TableChildProps } from "../coreui/Table";
import Typography from "../coreui/Typography";

interface Props {
  dataId: string;
  name: string;
  propagated: TableChildProps;
}

interface State {
  selectedRowCount: number;
}

export class GridSelectedCount extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = { selectedRowCount: 0 };
  }

  private updateSelectedRowCount = () => {
    const parentTable = this.props.propagated.parentTable;
    this.setState({
      selectedRowCount: parentTable.selection.getSelectedRows().length,
    });
  };

  public componentDidMount() {
    this.props.propagated.parentTable
      .getApi()
      .addEventListener("selectionChanged", this.updateSelectedRowCount);

    this.updateSelectedRowCount();
  }

  public componentWillUnmount() {
    const tableApi = this.props.propagated.parentTable.getApi();
    if (tableApi) {
      tableApi.removeEventListener(
        "selectionChanged",
        this.updateSelectedRowCount
      );
    }
  }

  public render() {
    return (
      <Typography variant="body2">
        {Localization.getBuiltInMessage("numberSelected", {
          numSelected: this.state.selectedRowCount.toString(),
        })}
      </Typography>
    );
  }
}

export default observer(GridSelectedCount);

import { styled, Tooltip, TooltipProps } from "@mui/material";
import * as React from "react";

export const ErrorTooltip = styled(
  React.forwardRef(
    ({ className, ...props }: TooltipProps, ref): JSX.Element => (
      // Poppers make styling tooltips more complicated, since the style needs
      // to be applied to the popper that is created rather than the root
      // component itself.
      <Tooltip {...props} classes={{ popper: className }} ref={ref} />
    )
  )
)(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    backgroundColor: theme.palette.grey["100"],
    color: theme.palette.danger.main,
  },
  "& .MuiTooltip-tooltipPlacementBottom": {
    "@media(min-width:601px)": {
      marginTop: 16,
    },
  },
}));

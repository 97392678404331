import {
  InputAdornment,
  TextField as MuiTextField,
  Theme,
} from "@mui/material";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import Button from "../coreui/Button";
import getFieldHelperText from "../coreui/FieldHelperText";
import { TableVerticalLayoutProps } from "../coreui/Table";
import TextField from "../coreui/TextField";
import PaneRow from "../models/PaneRow";
import ErrorsStore from "../stores/ErrorsStore";
import { AccessLevel } from "./AccessLevel";

interface ConfigProperties {
  dataId: string;
  disabledHelpText: string;
  helperText: string;
  increment: number | null;
  justification: "Left" | "Right";
  label: string;
  maximum: number | null;
  maximumError: string | null;
  minimum: number | null;
  minimumError: string | null;
  name: string;
  propagated?: TableVerticalLayoutProps;
  scale: number | null;
  scaleError: string | null;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
  showAsMandatory: boolean;
  showDisabledHelp: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    inputLabelRoot: {
      width: "calc(100% - 33px)",
    },
    inputLabelShrink: {
      width: "calc((100% - 33px) * 1.333)",
    },
    labelRootWithIncrementors: {
      width: "calc(100% - 102px)",
    },
    labelShrinkWithIncrementors: {
      width: "calc((100% - 102px) * 1.333)",
    },
  });

export class NumericEdit extends React.Component<
  ConfigProperties & WithStyles<typeof styles>
> {
  public static readonly systemDecimalCharacter = ".";

  private readonly componentId: string;
  private dataId: string = "";
  private isFocused: boolean = false;
  private isFocusing: boolean = false;
  private name: string = "";
  private rowKey?: string;
  private updateValueInterval: number;

  private static addThousandsSeparators(value: string) {
    const parts = value.split(Sys.settings.decimalSeparator);
    let part1 = parts[0];
    const part2 =
      parts.length > 1 ? Sys.settings.decimalSeparator + parts[1] : "";
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(part1)) {
      part1 = part1.replace(rgx, `$1${Sys.settings.thousandsSeparator}$2`);
    }

    return part1 + part2;
  }

  private static round(value: number, decimals: number): string {
    // Use exponential notation to avoid rounding issues
    // https://stackoverflow.com/a/32178833
    const rounded = Math.round(Number(`${value}e${decimals}`));

    return Number(`${rounded}e-${decimals}`).toFixed(decimals);
  }

  public static formatNumericValue(
    value: number | null,
    userFormatted: boolean,
    scale: number | null
  ) {
    if (value === null) {
      return null;
    }

    let formattedValue =
      scale !== null ? NumericEdit.round(value, scale) : value.toString();

    if (userFormatted) {
      formattedValue = formattedValue.replace(
        NumericEdit.systemDecimalCharacter,
        Sys.settings.decimalSeparator
      );
      formattedValue = NumericEdit.addThousandsSeparators(formattedValue);
    }

    return formattedValue;
  }

  public constructor(props: ConfigProperties & WithStyles<typeof styles>) {
    super(props);

    this.componentId = `numeric-edit-${Sys.nextId}`;

    makeObservable<
      NumericEdit,
      | "dataId"
      | "isFocused"
      | "name"
      | "setFormattedValue"
      | "setIsFocused"
      | "setValue"
      | "syncDerivedWithProps"
    >(this, {
      dataId: observable,
      isFocused: observable,
      name: observable,
      setFormattedValue: action,
      setIsFocused: action,
      setValue: action,
      syncDerivedWithProps: action,
    });

    this.syncDerivedWithProps();
  }

  private announceErrors(errors: string[]): void {
    if (errors.length > 0) {
      Sys.announce(errors.join("; "));
    }
  }

  private formatValue(value: number | null, userFormatted: boolean) {
    const scale: number = this.props.scale === null ? 0 : this.props.scale;
    return NumericEdit.formatNumericValue(value, userFormatted, scale);
  }

  private getCurrentValueForEdit(): string | null {
    const row = PaneRow.get(this.dataId, this.rowKey)!;
    const widget = row.getWidgetT<string | null, RuntimeProperties>(this.name);

    if (widget.value === null) {
      return null;
    }

    return widget.value.replace(
      NumericEdit.systemDecimalCharacter,
      Sys.settings.decimalSeparator
    );
  }

  private getCurrentValueParsed(): number | null {
    const row = PaneRow.get(this.dataId, this.rowKey)!;
    const widget = row.getWidgetT<string | number | null, RuntimeProperties>(
      this.name
    );

    if (!widget.value) {
      return null;
    }

    switch (typeof widget.value) {
      case "string":
        const value = widget.value.replace(
          Sys.settings.decimalSeparator,
          NumericEdit.systemDecimalCharacter
        );

        const regex = new RegExp(`\\${Sys.settings.thousandsSeparator}`, "g");
        const parsed = parseFloat(value.replace(regex, ""));
        return isNaN(parsed) ? null : parsed;

      case "number":
        return widget.value as number;

      default:
        throw new Error(
          "Unexpected numeric edit value type " + `${typeof widget.value}`
        );
    }
  }

  private getErrors(): string[] {
    const row = PaneRow.get(this.dataId, this.rowKey);
    if (!row) {
      return [];
    }

    const widget = row.getWidgetT<string | null, RuntimeProperties>(this.name);
    const currentValue = this.getCurrentValueParsed();

    const result: string[] = [...widget.properties.businessErrors];

    let index = result.indexOf(this.props.minimumError!);
    if (
      this.props.minimum !== null &&
      currentValue !== null &&
      currentValue < this.props.minimum
    ) {
      if (index < 0) {
        result.push(this.props.minimumError!);
      }
    } else if (index >= 0) {
      result.splice(index, 1);
    }

    index = result.indexOf(this.props.maximumError!);
    if (
      this.props.maximum !== null &&
      currentValue !== null &&
      currentValue > this.props.maximum
    ) {
      if (index < 0) {
        result.push(this.props.maximumError!);
      }
    } else if (index >= 0) {
      result.splice(index, 1);
    }

    index = result.indexOf(this.props.scaleError!);
    if (this.props.increment !== null) {
      const scale =
        this.props.scale !== null ? Math.pow(10, this.props.scale) : 1;

      if (
        currentValue !== null &&
        (Math.round(currentValue * scale) % (this.props.increment * scale)) /
          scale !==
          0
      ) {
        if (index < 0) {
          result.push(this.props.scaleError!);
        }
      } else if (index >= 0) {
        result.splice(index, 1);
      }
    }

    return result;
  }

  private increaseOrDecreaseValue(increase: boolean) {
    if (this.props.increment === null) {
      return;
    }

    let currentValue = this.getCurrentValueParsed();

    if (
      this.props.minimum !== null &&
      (currentValue === null || currentValue < this.props.minimum)
    ) {
      currentValue = this.props.minimum;
    } else if (
      this.props.maximum !== null &&
      (currentValue === null || currentValue > this.props.maximum)
    ) {
      currentValue = this.props.maximum;
    } else {
      currentValue = currentValue === null ? 0 : currentValue;
      currentValue = currentValue + this.props.increment * (increase ? 1 : -1);
    }

    if (
      (increase ||
        this.props.minimum === null ||
        currentValue >= this.props.minimum) &&
      (!increase ||
        this.props.maximum === null ||
        currentValue <= this.props.maximum)
    ) {
      this.setFormattedValue(currentValue);
    }
  }

  private onKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!event.key || !this.props.increment) {
      return;
    }

    if (event.key === "ArrowUp") {
      this.increaseOrDecreaseValue(true);
    }

    if (event.key === "ArrowDown") {
      this.increaseOrDecreaseValue(false);
    }
  };

  private setFormattedValue(value: number | null) {
    ErrorsStore.clearBusinessErrorsForWidget(this.dataId, this.name);

    const row = PaneRow.get(this.dataId, this.rowKey)!;
    const widget = row.getWidgetT<string | null, RuntimeProperties>(this.name);
    widget.setValue(this.formatValue(value, false));
  }

  private setIsFocused(isFocused: boolean): void {
    this.isFocused = isFocused;
  }

  private setValue(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    ErrorsStore.clearBusinessErrorsForWidget(this.dataId, this.name);

    const row = PaneRow.get(this.dataId, this.rowKey)!;
    const widget = row.getWidgetT<string | null, RuntimeProperties>(this.name);
    widget.setValue(event.target.value === "" ? null : event.target.value);
  }

  private startIncreaseOrDecreaseValue(increase: boolean) {
    document.addEventListener("mouseup", this.stopIncreaseOrDecreaseValue);

    this.updateValueInterval = window.setInterval(
      () => this.increaseOrDecreaseValue(increase),
      250
    );
  }

  private stopIncreaseOrDecreaseValue = () => {
    document.removeEventListener("mouseup", this.stopIncreaseOrDecreaseValue);
    clearInterval(this.updateValueInterval);
  };

  private syncDerivedWithProps(): void {
    this.dataId = this.props.dataId;
    this.name = this.props.name;
    this.rowKey = this.props.propagated?.rowKey;
  }

  private validateKeyPress(event: React.KeyboardEvent<HTMLDivElement>) {
    if (!event.key || (event.target as HTMLElement).tagName !== "INPUT") {
      return;
    }

    const validCharacters = [
      Sys.settings.thousandsSeparator,
      Sys.settings.decimalSeparator,
      "-",
    ];
    for (let i = 0; i <= 9; i++) {
      validCharacters.push(i.toString());
    }

    const isControlChar = event.key.length > 1;
    if (
      !event.ctrlKey &&
      !isControlChar &&
      validCharacters.indexOf(event.key) < 0
    ) {
      event.preventDefault();
    }
  }

  public componentDidUpdate(): void {
    this.syncDerivedWithProps();
  }

  public render(): React.ReactNode {
    const row = PaneRow.get(this.dataId, this.rowKey);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<string | number | null, RuntimeProperties>(
      this.name
    );

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    if (widget.properties.accessLevel === AccessLevel.disabled) {
      return (
        <TextField
          disabled={true}
          disabledHelpText={
            widget.properties.showDisabledHelp
              ? this.props.disabledHelpText
              : undefined
          }
          label={this.props.label}
          variant="filled"
        />
      );
    }

    if (widget.properties.accessLevel === AccessLevel.readOnly) {
      const value = this.formatValue(this.getCurrentValueParsed(), true);

      return (
        <TextField
          label={this.props.label}
          name={this.props.name}
          readOnly={true}
          value={value ? value : "-"}
          variant="filled"
        />
      );
    }

    const parsedValue = this.getCurrentValueParsed();

    const incrementButtons =
      this.props.increment !== null ? (
        <InputAdornment position="end" style={{ marginTop: -4 }}>
          <React.Fragment>
            <Button
              aria-label={Localization.getBuiltInMessage("decrement")}
              disabled={
                parsedValue !== null &&
                this.props.minimum !== null &&
                parsedValue - this.props.increment < this.props.minimum
              }
              icon="fas fa-minus"
              onClick={() => {
                this.increaseOrDecreaseValue(false);
                Sys.debounceMethod(
                  () => {
                    Sys.announce(this.getCurrentValueForEdit() || "");
                  },
                  "NumericEditUpdated",
                  300
                );
              }}
              onMouseDown={() => this.startIncreaseOrDecreaseValue(false)}
              size="small"
              tabIndex={-1}
            />
            <Button
              aria-label={Localization.getBuiltInMessage("increment")}
              disabled={
                parsedValue !== null &&
                this.props.maximum !== null &&
                parsedValue + this.props.increment > this.props.maximum
              }
              icon="fas fa-plus"
              onClick={() => {
                this.increaseOrDecreaseValue(true);
                Sys.debounceMethod(
                  () => {
                    Sys.announce(this.getCurrentValueForEdit() || "");
                  },
                  "NumericEditUpdated",
                  300
                );
              }}
              onMouseDown={() => this.startIncreaseOrDecreaseValue(true)}
              size="small"
              style={{ marginLeft: 8 }}
              tabIndex={-1}
            />
          </React.Fragment>
        </InputAdornment>
      ) : undefined;

    const fieldHelperText = getFieldHelperText({
      getErrors: () => this.getErrors(),
      helperText: this.props.helperText,
    });

    let formatted: string | null;
    if (this.isFocused) {
      formatted = this.getCurrentValueForEdit();
    } else {
      formatted = this.formatValue(parsedValue, true);
    }

    const alignment = this.props.justification === "Right" ? "right" : "left";

    return (
      <MuiTextField
        error={fieldHelperText.hasErrors}
        FormHelperTextProps={{
          "aria-hidden": true,
          ...fieldHelperText.formHelperTextProps,
        }}
        fullWidth={true}
        helperText={fieldHelperText.helperText}
        id={this.componentId}
        InputLabelProps={{
          classes: {
            root: this.props.increment
              ? this.props.classes.labelRootWithIncrementors
              : this.props.classes.inputLabelRoot,

            shrink: this.props.increment
              ? this.props.classes.labelShrinkWithIncrementors
              : this.props.classes.inputLabelShrink,
          },
        }}
        inputProps={{
          max: this.props.maximum,
          min: this.props.minimum,
          style: {
            textAlign: alignment,
          },
        }}
        InputProps={{
          endAdornment: incrementButtons,
        }}
        label={this.props.label}
        name={this.props.name}
        onBlur={() => {
          if (!this.isFocusing) {
            this.setIsFocused(false);
            this.setFormattedValue(this.getCurrentValueParsed());
          }
          this.announceErrors(fieldHelperText.errors);
        }}
        onChange={(e) => this.setValue(e)}
        onKeyDown={(e) => this.validateKeyPress(e)}
        onKeyUp={(e) => this.onKeyUp(e)}
        onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
          if (this.isFocusing) {
            return;
          }

          const target = e.target;
          this.setIsFocused(true);
          this.isFocusing = true;

          // Firefox loses focus when re-rendering element, so re-focus it
          setTimeout(() => {
            if (document.activeElement !== target) {
              target.focus();
              target.select();
            }
            this.isFocusing = false;
          }, 100);
        }}
        required={widget.properties.showAsMandatory}
        // Number type doesn't support international number characters (space
        // and comma), tel type iphone keypad doesn't support the decimal
        // character
        type="text"
        value={formatted === null ? "" : formatted}
        variant="filled"
      />
    );
  }
}

export default withStyles(styles)(observer(NumericEdit));

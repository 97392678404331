import { observer } from "mobx-react";
import * as React from "react";
import { useWidth } from "../core/Responsive";
import Presentation from "../coreui/Presentation";

interface ConfigProperties {
  horizontalLayout: object;
  propagated: object;
  verticalLayout: object;
}

export const ResponsiveGrid = observer(
  (props: ConfigProperties): JSX.Element | null => {
    const width = useWidth();
    // The key attribute tells React to recreate the component from
    // scratch when the break point changes. Without this child
    // components are reused and constructors are not called

    if (props.verticalLayout && width === "xs") {
      return (
        <React.Fragment key="vertical">
          {Presentation.create(props.verticalLayout, props.propagated)}
        </React.Fragment>
      );
    }
    if (props.horizontalLayout && width !== "xs") {
      return (
        <React.Fragment key="horizontal">
          {Presentation.create(props.horizontalLayout, props.propagated)}
        </React.Fragment>
      );
    }

    // If a grid is moved between break points so the presentation server
    // can't combine them, multiple responsive grids will be rendered
    // causing them to trigger this code path at some break points
    return null;
  }
);

import {
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
  styled,
} from "@mui/material";
import * as React from "react";

interface Props extends MuiTypographyProps {
  ellipsis?: boolean;
}

const EllipsisTypography = styled(MuiTypography)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  width: "100%",
});

export default function Typography(props: Props) {
  const { ellipsis, ...other } = props;

  return ellipsis ? (
    <EllipsisTypography {...other} />
  ) : (
    <MuiTypography {...other} />
  );
}

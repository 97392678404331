import { keyframes, styled } from "@mui/material";
import * as React from "react";

interface Props {
  visible: boolean;
}

const rippleEffect = keyframes({
  from: {
    transform: "scale(1)",
  },
  to: {
    transform: "scale(0.92)",
  },
});

const rippleEnterEffect = keyframes({
  from: {
    opacity: "0.1",
    transform: "scale(0)",
  },
  to: {
    opacity: "0.3",
    transform: "scale(1)",
  },
});

const Ripple = styled("span")(({ theme }) => ({
  animation: `${rippleEnterEffect} 200ms, ${rippleEffect} 1250ms alternate`,
  animationDelay: "0ms, 200ms",
  animationIterationCount: "1, infinite",
  animationTimingFunction: "ease-in-out",
  backgroundColor: theme.palette.grey[800],
  borderRadius: "50%",
  bottom: 0,
  left: 0,
  opacity: 0.3,
  overflow: "visible",
  pointerEvents: "none",
  position: "absolute",
  right: 0,
  top: 0,
}));

export default function FocusRipple(props: Props): JSX.Element | null {
  return props.visible ? <Ripple /> : null;
}

import { Layout, LayoutConfig } from "../config";
import AppServer, { State as AppServerState } from "../core/AppServer";
import { BreakPointColumn } from "../coreui/Dialog";
import { BusinessError } from "../stores/ErrorsStore";
import LayoutStateStore from "../stores/LayoutStateStore";
import PaneDataStore, { PaneDataByDataId } from "../stores/PaneDataStore";
import BaseService from "./BaseService";

export interface GetSelectDialogConfigResponse {
  breakPoints: BreakPointColumn[];
  dialogLayout: LayoutConfig;
}

export interface OnDialogCloseResponse {
  appServerState: AppServerState;
  businessErrors: BusinessError[];
  paneDataByDataId: PaneDataByDataId;
  validationErrors: string[];
}

export interface OnDialogOpenResponse {
  appServerState: AppServerState;
  criteriaBusinessErrors: BusinessError[];
  dataChangesBusinessErrors: BusinessError[];
  paneDataByDataId: PaneDataByDataId;
  validationErrors: string[];
}

export interface OnSearchResponse {
  appServerState: AppServerState;
  businessErrors: BusinessError[];
  paneDataByDataId: PaneDataByDataId;
}

export default class SelectControlService {
  public static getSelectDialogConfig(
    layoutId: number
  ): Promise<GetSelectDialogConfigResponse> {
    return BaseService.requestObject(
      `SelectControl/SelectDialogConfig/${layoutId}`,
      null,
      null,
      null,
      "GET"
    ).then((config: GetSelectDialogConfigResponse) => {
      Layout.initialize(config.dialogLayout);

      return config;
    });
  }

  public static onDialogClose(
    rowKey: string,
    dataId: string,
    widgetName: string,
    addedObjectHandles: string[],
    removedObjectHandles: string[]
  ): Promise<OnDialogCloseResponse> {
    return BaseService.requestObject<OnDialogCloseResponse>(
      `SelectControl/OnDialogClose/${rowKey}/${dataId}/${widgetName}`,
      null,
      null,
      {
        addedObjectHandles,
        appServerState: AppServer.getState(),
        layoutState: LayoutStateStore.getCurrentState(),
        removedObjectHandles,
      },
      "POST"
    );
  }

  public static onDialogOpen(
    rowKey: string,
    dataId: string,
    widgetName: string,
    criteriaWidgetName: string | null
  ): Promise<OnDialogOpenResponse> {
    return BaseService.requestObject<OnDialogOpenResponse>(
      `SelectControl/OnDialogOpen/${rowKey}/${dataId}/${widgetName}`,
      null,
      null,
      {
        appServerState: AppServer.getState(),
        criteriaWidgetName,
        dataChanges: PaneDataStore.getChanges(),
        layoutState: LayoutStateStore.getCurrentState(),
      },
      "POST"
    );
  }

  public static onSearch(
    rowKey: string,
    dataId: string,
    widgetName: string,
    criteriaWidgetName: string | null
  ): Promise<OnSearchResponse> {
    return BaseService.requestObject<OnSearchResponse>(
      `SelectControl/OnSearch/${rowKey}/${dataId}/${widgetName}`,
      null,
      null,
      {
        appServerState: AppServer.getState(),
        criteriaWidgetName,
        dataChanges: PaneDataStore.getChanges(),
        layoutState: LayoutStateStore.getCurrentState(),
      },
      "POST"
    );
  }
}

import { Autocomplete } from "@mui/material";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import Icon from "../coreui/Icon";
import TextField from "../coreui/TextField";
import Typography from "../coreui/Typography";
import PaneRow from "../models/PaneRow";
import AddressSearchService from "../services/AddressSearchService";
import { AccessLevel } from "./AccessLevel";

interface ConfigurationProperties {
  dataId: string;
  geocodeApiUrlFindAddressCandidates: string;
  geocodeApiUrlSuggest: string;
  helperText: string;
  label: string;
  name: string;
  noResultsMessage: string;
  propagated?: object;
}

interface Option {
  isCollection?: boolean;
  magicKey: string;
  text: string;
}

interface State {
  didInputChange: boolean;
  options: Option[];
  value: string;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  category: string;
  countryCode: string;
  location: number[];
  searchExtent: number[];
  showAsMandatory: boolean;
}

export class AddressSearch extends React.PureComponent<
  ConfigurationProperties,
  State
> {
  private accessToken: string | null = null;

  public constructor(props: ConfigurationProperties) {
    super(props);

    this.state = { didInputChange: false, options: [], value: "" };
  }

  private async getCandidate(option: Option): Promise<void> {
    const row: PaneRow = PaneRow.get(this.props.dataId)!;
    const widget = row.getWidgetT<null, RuntimeProperties>(this.props.name);

    const esriResponse = await AddressSearchService.getCandidates(
      this.props.geocodeApiUrlFindAddressCandidates,
      widget.properties.category,
      widget.properties.location,
      option.magicKey,
      widget.properties.searchExtent,
      option.text,
      widget.properties.countryCode,
      this.accessToken
    );

    if (esriResponse.candidates) {
      await AddressSearchService.onSelect(
        row.rowKey,
        this.props.dataId,
        this.props.name,
        esriResponse.candidates[0]
      );
      Sys.announce(
        Localization.getBuiltInMessage("AddressSearch.succeeded", {
          label: this.props.label,
        }),
        true
      );
    } else if (esriResponse.error && esriResponse.error.code === 498) {
      await this.getToken();
      return this.getCandidate(option);
    }
  }

  private async getSuggestions(value: string): Promise<void> {
    const row: PaneRow = PaneRow.get(this.props.dataId)!;
    const widget = row.getWidgetT<null, RuntimeProperties>(this.props.name);

    this.setState({ value });

    try {
      const esriResponse = await AddressSearchService.getSuggestions(
        this.props.geocodeApiUrlSuggest,
        widget.properties.category,
        widget.properties.countryCode,
        widget.properties.location,
        widget.properties.searchExtent,
        value,
        this.accessToken
      );
      if (esriResponse.suggestions) {
        this.setState({
          options: esriResponse.suggestions
            .filter((s) => !s.isCollection)
            .map((s) => ({
              magicKey: s.magicKey,
              text: s.text,
            })),
        });
      } else {
        this.setState({ options: [] });
        if (esriResponse.error && esriResponse.error.code === 498) {
          await this.getToken();
          return this.getSuggestions(value);
        }
      }
    } catch {
      this.setState({ options: [] });
    }
  }

  private async getToken(): Promise<void> {
    const row = PaneRow.get(this.props.dataId)!;

    this.accessToken = await AddressSearchService.getToken(
      row.rowKey,
      this.props.dataId,
      this.props.name
    );
  }

  private onInputChange = (
    event: React.SyntheticEvent,
    value: string,
    reason: string
  ) => {
    this.setState({ didInputChange: true });

    if (reason === "input") {
      this.getSuggestions(value);
    } else {
      // The input is being set based on a selected value or because focus is
      // leaving the field. In either case, the input must be cleared because if
      // a value is selected the round trip script will set the data value and
      // if a value is not selected but focus is leaving the field, the criteria
      // entered in the field should not stick around as it is not "data" that
      // is saved with the rest of the form.
      this.setState({ options: [], value: "" });
    }
  };

  private onValueChange = (
    event: React.SyntheticEvent,
    option: Option | null
  ) => {
    this.setState({ didInputChange: false });

    if (option) {
      this.getCandidate(option);
    }
  };

  public render() {
    const row: PaneRow = PaneRow.get(this.props.dataId)!;
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<null, RuntimeProperties>(this.props.name);

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    if (!this.accessToken) {
      this.getToken();
    }

    return (
      <Autocomplete
        autoHighlight
        autoSelect={!!this.state.value && this.state.didInputChange}
        filterOptions={(options: Option[]) => options}
        forcePopupIcon={false}
        getOptionLabel={(option: Option) => option.text}
        handleHomeEndKeys={false}
        inputValue={this.state.value}
        isOptionEqualToValue={(option: Option, value: Option) =>
          option.text === value.text
        }
        noOptionsText={this.props.noResultsMessage}
        onChange={this.onValueChange}
        onInputChange={this.onInputChange}
        options={this.state.options}
        renderInput={(params) => (
          <TextField
            {...params}
            helperText={this.props.helperText}
            icon="fas fa-search"
            inputProps={{
              ...params.inputProps,
              autoComplete: "off",
              style: { paddingLeft: 4, paddingTop: 24 },
              type: "search",
            }}
            InputProps={{
              ...params.InputProps,
              style: { paddingLeft: 12, paddingTop: 0 },
            }}
            label={this.props.label}
            name={this.props.name}
            required={widget.properties.showAsMandatory}
            variant="filled"
          />
        )}
        renderOption={(props, option: Option) => {
          return (
            <li
              {...props}
              style={{
                alignItems: "center",
                display: "flex",
                minHeight: 40,
                width: "100%",
              }}
            >
              <Icon
                icon="fas fa-map-marker-alt"
                style={{ marginRight: ".4em" }}
              />
              <Typography ellipsis>{option.text}</Typography>
            </li>
          );
        }}
      />
    );
  }
}

export default observer(AddressSearch);

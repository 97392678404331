import { styled } from "@mui/material";
import * as React from "react";
import { ErrorMessage } from "./ErrorMessage";

interface Props {
  errors: string[];
}

const Container = styled("div")({
  display: "grid",
  rowGap: "4px",
});

export const ErrorList = (props: Props): JSX.Element => {
  return (
    <Container>
      {props.errors.map((error) => (
        <ErrorMessage key={error} message={error} />
      ))}
    </Container>
  );
};

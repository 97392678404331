import { InputBaseComponentProps, InputProps, TextField } from "@mui/material";
// @ts-ignore
import imask from "imask";
import { observer } from "mobx-react";
import * as React from "react";
// @ts-ignore
import { IMaskInput } from "react-imask";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import getFieldHelperText from "../coreui/FieldHelperText";

interface MaskedPattern {
  isComplete: boolean;
  mask: string;
  unmaskedValue: string;
  value: string;
}

interface Props {
  dataCase: "lower" | "UPPER" | "Any";
  getErrors?: (value: string) => string[];
  helperText?: string;
  icon?: string;
  iconColor?: string;
  InputProps?: Partial<InputProps>;
  inputRef?: (r: HTMLInputElement) => void;
  label?: string;
  mask: string;
  name?: string;
  onChange: (value: string) => void;
  required?: boolean;
  style?: React.CSSProperties;
  value: string;
  variant?: "filled" | "standard";
}

interface State {
  lazy?: boolean;
  localErrors?: string[];
}

export class EditMask extends React.Component<Props, State> {
  private static readonly patternDefinitions = {
    "#": /\d/,
    "@": /[A-Za-z]/,
    a: /[A-Za-z0-9]/,
    A: /[A-Za-z0-9]/,
    x: /\S/,
    X: /\S/,
  };
  private readonly componentId: string;
  private customInputRef: { maskValue: string } | null = null;
  private isFocused = false;
  private patternMask: MaskedPattern | null = null;
  private readonly placeholderChar = "_";

  private createCustomInput = React.forwardRef(
    (
      props: InputBaseComponentProps,
      inputRef: React.ForwardedRef<HTMLInputElement>
    ): JSX.Element => {
      const { onChange, ...other } = props;

      return (
        <IMaskInput
          {...other}
          definitions={EditMask.patternDefinitions}
          inputRef={inputRef}
          lazy={this.state.lazy}
          mask={this.props.mask}
          // The onChange event doesn't fire when lazy == true and the
          // onAccept event fires when the value is programatically
          // changed, so we use onInput
          onInput={onChange}
          placeholderChar={this.placeholderChar}
          prepare={(v: string) => {
            switch (this.props.dataCase) {
              case "lower":
                return v.toLowerCase();
              case "UPPER":
                return v.toUpperCase();
              default:
                return v;
            }
          }}
          ref={(ref: { maskValue: string }) => {
            this.customInputRef = ref;
          }}
          unmask={true}
        />
      );
    }
  );

  private static createMaskedPattern(
    mask: string,
    placeholderChar: string
  ): MaskedPattern {
    return new imask.MaskedPattern({
      definitions: EditMask.patternDefinitions,
      lazy: false,
      mask,
      placeholderChar,
    });
  }

  public static formatValue(mask: string, value: string): string {
    const patternMask = EditMask.createMaskedPattern(mask, " ");

    patternMask.unmaskedValue = value;

    return patternMask.value;
  }

  public static getErrors(
    mask: string,
    value: string | null,
    patternMask: MaskedPattern | null = null
  ): string[] {
    if (!value) {
      return [];
    }

    const result: string[] = [];

    let maskedPattern = patternMask;
    if (!maskedPattern) {
      maskedPattern = EditMask.createMaskedPattern(mask, " ");
    }
    maskedPattern.unmaskedValue = value;

    if (!maskedPattern.isComplete) {
      result.push(Localization.getBuiltInMessage("incompleteEditMask"));
    }

    return result;
  }

  public constructor(props: Props) {
    super(props);

    this.state = { lazy: true, localErrors: [] };
    this.componentId = `edit-mask-${Sys.nextId}`;
  }

  private announceErrors(errors: string[]): void {
    if (errors.length > 0) {
      Sys.announce(errors.join("; "));
    }
  }

  private getErrors = () => {
    if (!this.props.getErrors) {
      return [];
    }

    let errors: string[] = [...this.props.getErrors(this.props.value)];
    errors = errors.concat(this.state.localErrors!);

    return errors;
  };

  private onChange = () => {
    this.props.onChange(this.customInputRef!.maskValue);
  };

  private onFocus = () => {
    this.isFocused = true;
    this.setLazy();

    // Fix cursor positon for voiceover.
    setTimeout(() => {
      const input = document.getElementById(
        this.componentId
      ) as HTMLInputElement;

      if (input && this.patternMask && input === document.activeElement) {
        this.patternMask.unmaskedValue = this.props.value;

        const index = this.patternMask.value.indexOf(this.placeholderChar);

        if (!this.patternMask.isComplete) {
          input.setSelectionRange(index ? index : 0, index ? index : 0);
        }
      }
    }, 1000);
  };

  private setLazy() {
    this.setState({ lazy: this.isFocused ? false : !this.props.value });
  }

  private setLocalErrors() {
    const localErrors: string[] = EditMask.getErrors(
      this.props.mask,
      this.props.value,
      this.patternMask
    );

    this.setState({ localErrors });
  }

  public componentDidMount() {
    this.patternMask = EditMask.createMaskedPattern(
      this.props.mask,
      this.placeholderChar
    );

    this.setLazy();
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.value !== prevProps.value) {
      this.setLazy();
    }
  }

  public render() {
    const fieldHelperText = getFieldHelperText({
      getErrors: this.getErrors,
      helperText: this.props.helperText,
    });

    const onBlur = () => {
      this.isFocused = false;
      this.setLocalErrors();
      this.announceErrors(fieldHelperText.errors);
      this.setLazy();
    };

    return (
      <TextField
        error={fieldHelperText.hasErrors}
        FormHelperTextProps={{
          "aria-hidden": true,
          ...fieldHelperText.formHelperTextProps,
        }}
        fullWidth={true}
        helperText={fieldHelperText.helperText}
        id={this.componentId}
        InputProps={{
          inputComponent: this.createCustomInput,
          ...this.props.InputProps,
        }}
        inputRef={this.props.inputRef}
        label={this.props.label}
        name={this.props.name}
        onBlur={onBlur}
        onChange={this.onChange}
        onFocus={this.onFocus}
        required={this.props.required}
        style={this.props.style}
        value={this.props.value}
        variant={this.props.variant}
      />
    );
  }
}

export default observer(EditMask);

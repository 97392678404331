import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../core/Localization";
import { useWidth, WidthProps } from "../core/Responsive";
import Button from "../coreui/Button";
import Drawer from "../coreui/Drawer";
import Menu from "../coreui/Menu";
import { TableChildProps } from "../coreui/Table";
import PaneRow from "../models/PaneRow";
import { AccessLevel } from "./AccessLevel";
import { LanguageSelect } from "./LanguageSelect";
import { MenuChild } from "./MenuButton";
import MenuItem from "./MenuItem";
import ToolbarContainerItem from "./ToolbarContainerItem";
import ToolbarContainerOverflowItem, {
  ConfigProperties as ItemProps,
} from "./ToolbarContainerOverflowItem";

interface ConfigProperties {
  childItems: { props: ItemProps }[];
  dataId: string;
  hideIcons: boolean;
  isPageHeader: boolean;
  propagated: TableChildProps;
  toolbarType: "header" | "footer" | "selection" | "summary";
}

interface State {
  overflowButtonRef?: HTMLElement;
}

export interface ToolbarOverflowChild extends MenuChild {
  hideIcons: boolean;
  isDrawerMenu?: boolean;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
}

export class ToolbarContainerOverflowSection extends React.Component<
  ConfigProperties & WidthProps,
  State
> {
  public constructor(props: ConfigProperties & WidthProps) {
    super(props);

    this.state = {};
  }

  private onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const target: HTMLButtonElement = event.currentTarget;
    this.setState((prevState) => {
      return {
        overflowButtonRef: prevState.overflowButtonRef ? undefined : target,
      };
    });
  };

  private onCloseMenu = (): void => {
    this.setState({ overflowButtonRef: undefined });
  };

  private onItemClicked = (): void => {
    this.setState({ overflowButtonRef: undefined });
  };

  private shouldRender(): boolean {
    const row = PaneRow.get(this.props.dataId)!;

    for (const child of this.props.childItems) {
      if (!child.props[this.props.width]) {
        continue;
      }

      if (child.props.isLayoutOnly) {
        if (child.props.child.type === "LanguageSelect") {
          if (LanguageSelect.shouldRender()) {
            return true;
          }

          continue;
        }

        return true;
      }

      const childWidgetName = child.props.child.props.name;
      const childWidget = row.getWidgetT<null, RuntimeProperties>(
        childWidgetName
      );

      if (!childWidget.properties.accessLevel === undefined) {
        return true;
      }

      if (childWidget.properties.accessLevel >= AccessLevel.disabled) {
        return true;
      }
    }

    return false;
  }

  public render(): React.ReactNode {
    if (!this.shouldRender()) {
      return null;
    }

    const propagated: ToolbarOverflowChild = {
      ...this.props.propagated,
      hideIcons: this.props.hideIcons,
      isDrawerMenu: this.props.isPageHeader,
      onItemClicked: this.onItemClicked,
    };

    return (
      <React.Fragment>
        <ToolbarContainerItem
          childLayoutWidgetName=""
          childWidgetName=""
          dataId=""
          lg={true}
          md={true}
          propagated={this.props.propagated}
          sm={true}
          toolbarType={this.props.toolbarType}
          xl={true}
          xs={true}
        >
          <Button
            aria-expanded={!!this.state.overflowButtonRef}
            aria-haspopup={true}
            aria-label={Localization.getBuiltInMessage("menu")}
            icon={this.props.isPageHeader ? "fas fa-bars" : "fas fa-ellipsis-v"}
            onClick={this.onClick}
            size={this.props.isPageHeader ? "large" : "small"}
            tabIndex={-1}
          />
        </ToolbarContainerItem>
        {this.props.isPageHeader ? (
          <Drawer
            anchorEl={this.state.overflowButtonRef}
            onClose={this.onCloseMenu}
          >
            {this.props.childItems.map((child, index) => {
              const isActionable = MenuItem.isActionable(
                child.props.child.props
              );
              // Both disabled and hidden items must be set as disabled for the
              // the MenuList to ignore for focus handling
              child.props.disabled =
                !isActionable || !child.props[this.props.width];

              // Pass in width instead of using withWidth in the item to avoid
              // ref errors.
              return (
                <ToolbarContainerOverflowItem
                  key={index}
                  {...child.props}
                  propagated={propagated}
                  width={this.props.width}
                />
              );
            })}
          </Drawer>
        ) : (
          <Menu
            anchorEl={this.state.overflowButtonRef}
            onClose={this.onCloseMenu}
            variant="menu"
          >
            {this.props.childItems.map((child, index) => {
              const isActionable = MenuItem.isActionable(
                child.props.child.props
              );
              // Both disabled and hidden items must be set as disabled for the
              // the MenuList to ignore for focus handling
              child.props.disabled =
                !isActionable || !child.props[this.props.width];

              // Pass in width instead of using withWidth in the item to avoid
              // ref errors.
              return (
                <ToolbarContainerOverflowItem
                  key={index}
                  {...child.props}
                  propagated={propagated}
                  width={this.props.width}
                />
              );
            })}
          </Menu>
        )}
      </React.Fragment>
    );
  }
}

// FUTURE
// This wrapper component was created to avoid the scope of converting this
// component to a hooks component during the MUI 5 upgrade. When the legacy
// component is converted to a hooks component, this wrapper can be removed and
// hooks it calls can be called by the converted component directly.
const Observer = observer(ToolbarContainerOverflowSection);
export default function Wrapped(props: ConfigProperties): JSX.Element {
  const width = useWidth();
  return <Observer {...props} width={width} />;
}

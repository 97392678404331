import { observer, Observer } from "mobx-react";
import * as React from "react";
import Localization from "../../core/Localization";
import RequestPromise from "../../core/RequestPromise";
import Sys from "../../core/Sys";
import MenuItem from "../../coreui/MenuItem";
import PaneRow from "../../models/PaneRow";
import ActionButtonService, {
  OnRoundTripResponse,
  RoundTripProps,
} from "../../services/ActionButtonService";
import { AccessLevel } from "../AccessLevel";
import { ActionButtonProps, ActionButtonRuntimeProps } from "../ActionButton";
import ApiButton from "../ApiButton";
import { CaptchaControl as CaptchaControlBase } from "../CaptchaControl";
import { MenuItemProps } from "../MenuItem";
import ConfirmationDialog from "./ConfirmationDialog";

interface Props extends ActionButtonProps {}

interface State {
  isConfirmDialogOpen?: boolean;
}

export class DeletePresentationButton extends React.Component<Props, State> {
  private onClickPromise: RequestPromise<void>;

  private static getLabel(runtimeProperties: ActionButtonRuntimeProps): string {
    const label = !!runtimeProperties.label
      ? runtimeProperties.label
      : Localization.getBuiltInMessage("Button.deleteLabel");

    return label;
  }

  private static onClick(props: RoundTripProps): RequestPromise<void> {
    const row = PaneRow.get(props.dataId)!;
    const widget = row.getWidgetT<null, ActionButtonRuntimeProps>(props.name);

    const onClickPromise = ActionButtonService.onRoundTrip(row, props.name)
      .then((response: OnRoundTripResponse) => {
        if (
          response.businessErrors.length === 0 &&
          response.validationErrors.length === 0
        ) {
          Sys.announce(
            Localization.getBuiltInMessage("Button.succeeded", {
              label: DeletePresentationButton.getLabel(widget.properties),
            })
          );
        }

        if (widget.properties.validateCaptcha) {
          CaptchaControlBase.reset();
        }
      })
      .catch((reason) => {
        if (reason) {
          throw reason;
        }
      });

    return onClickPromise;
  }

  public static renderMenuItem(props: MenuItemProps): JSX.Element {
    const { config, runtime, ...otherProps } = props;
    const configProps = config as unknown as Props;
    const runtimeProps = runtime as ActionButtonRuntimeProps;

    const [isConfirmDialogOpen, setIsConfirmDialogOpen] =
      React.useState<boolean>(false);

    const onClick = () => {
      if (props.runtime.accessLevel >= AccessLevel.actionable) {
        if (runtimeProps.confirmMessage) {
          setIsConfirmDialogOpen(true);
        } else {
          DeletePresentationButton.onClick(configProps);
          configProps.propagated.onItemClicked!();
        }
      }
    };

    const onAcceptConfirm = () => {
      setIsConfirmDialogOpen(false);
      DeletePresentationButton.onClick(configProps);
      configProps.propagated.onItemClicked!();
    };

    const onCancelConfirm = () => {
      setIsConfirmDialogOpen(false);
    };

    return (
      <Observer>
        {() => (
          <React.Fragment>
            {runtimeProps.confirmMessage ? (
              <ConfirmationDialog
                cancelButtonText={runtimeProps.cancelButtonText!}
                continueButtonColor={configProps.buttonColor}
                continueButtonIcon={configProps.iconName}
                continueButtonText={runtimeProps.continueButtonText!}
                isOpen={isConfirmDialogOpen}
                message={runtimeProps.confirmMessage}
                onCancel={onCancelConfirm}
                onContinue={onAcceptConfirm}
                title={runtimeProps.confirmTitle!}
              />
            ) : null}
            <MenuItem
              disabled={
                runtimeProps.accessLevel === AccessLevel.disabled ||
                (props.runtime.validateCaptcha! &&
                  !CaptchaControlBase.isValid())
              }
              iconName={configProps.iconName}
              indent={
                props.config.propagated ? props.config.propagated.indent : 0
              }
              onClick={onClick}
              {...otherProps}
            >
              {runtimeProps.label}
            </MenuItem>
          </React.Fragment>
        )}
      </Observer>
    );
  }

  public constructor(props: Props) {
    super(props);

    this.state = { isConfirmDialogOpen: false };
  }

  private onAcceptConfirm = () => {
    this.setState({ isConfirmDialogOpen: false });
    this.onClickPromise = DeletePresentationButton.onClick(this.props);
  };

  private onCancelConfirm = () => {
    this.setState({ isConfirmDialogOpen: false });
  };

  private onClick = () => {
    const row = PaneRow.get(this.props.dataId)!;
    const widget = row.getWidgetT<null, ActionButtonRuntimeProps>(
      this.props.name
    );

    if (widget.properties.confirmMessage) {
      this.setState({ isConfirmDialogOpen: true });
    } else {
      this.onClickPromise = DeletePresentationButton.onClick(this.props);
    }
  };

  public componentWillUnmount() {
    if (this.onClickPromise) {
      this.onClickPromise.abort();
    }
  }

  public render() {
    const row = PaneRow.get(this.props.dataId);

    if (!row) {
      return;
    }

    const widget = row.getWidgetT<null, ActionButtonRuntimeProps>(
      this.props.name
    );

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    return (
      <React.Fragment>
        {widget.properties.confirmMessage ? (
          <ConfirmationDialog
            cancelButtonText={widget.properties.cancelButtonText!}
            continueButtonColor={this.props.buttonColor}
            continueButtonIcon={this.props.iconName}
            continueButtonText={widget.properties.continueButtonText!}
            isOpen={this.state.isConfirmDialogOpen!}
            message={widget.properties.confirmMessage}
            onCancel={this.onCancelConfirm}
            onContinue={this.onAcceptConfirm}
            title={widget.properties.confirmTitle!}
          />
        ) : null}
        <ApiButton
          alternateText={widget.properties.alternateText}
          buttonColor={this.props.buttonColor}
          disabled={
            widget.properties.accessLevel === AccessLevel.disabled ||
            (widget.properties.validateCaptcha && !CaptchaControlBase.isValid())
          }
          disabledHelpText={this.props.disabledHelpText}
          disabledHelpVisible={widget.properties.showDisabledHelp}
          iconName={this.props.iconName}
          isIconOnly={!widget.properties.label}
          label={DeletePresentationButton.getLabel(widget.properties)}
          onClick={this.onClick}
          size={this.props.size}
        />
      </React.Fragment>
    );
  }
}

export default observer(DeletePresentationButton);

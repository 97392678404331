import { Breakpoint } from "@mui/material";
import { action } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { DialogChildProps } from "../config";
import { useWidth } from "../core/Responsive";
import { CheckBoxGroup as CoreUiCheckBoxGroup } from "../coreui/CheckBoxGroup";
import { CheckBoxGroupOption } from "../coreui/CheckBoxGroupOption";
import PaneRow from "../models/PaneRow";
import RoundTripService from "../services/RoundTripService";
import ErrorsStore from "../stores/ErrorsStore";
import { AccessLevel } from "./AccessLevel";

interface ConfigProperties {
  dataId: string;
  disabledHelpText: string;
  helperText: string;
  label: string;
  name: string;
  orientationByBreakPoint: { [key in Breakpoint]: "horizontal" | "vertical" };
  propagated: DialogChildProps;
  roundTripOnChange: boolean;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
  options: CheckBoxGroupOption[];
  showAsMandatory: boolean;
  showDisabledHelp: boolean;
}

export const CheckBoxGroup = observer(
  (props: ConfigProperties): JSX.Element | null => {
    const width = useWidth();

    const onOptionChange = React.useCallback(
      action(
        `CheckBoxGroup ${props.name}.onOptionChange`,
        (option: CheckBoxGroupOption, checked: boolean): void => {
          const row = PaneRow.get(props.dataId)!;
          const widget = row.getWidgetT<string[], RuntimeProperties>(
            props.name
          );

          const oldValues = widget.value;
          const values = [...oldValues];

          if (checked) {
            values.push(option.value);
          } else {
            const index: number = values.indexOf(option.value);
            values.splice(index, 1);
          }

          ErrorsStore.clearBusinessErrorsForWidget(props.dataId, props.name);
          widget.setValue(values);

          if (!props.roundTripOnChange) {
            return;
          }

          RoundTripService.standardRoundTrip(
            "CheckBoxGroup/OnChange",
            { dataId: props.dataId, name: props.name },
            {
              dialogRowKey: props.propagated?.parentDialog?.rowKey || undefined,
            }
          ).catch((reason) => {
            if (reason) {
              throw reason;
            } else {
              // If the round trip fails, undo the value change.
              widget.setValue(oldValues);
            }
          });
        }
      ),
      [props.dataId, props.name, props.propagated, props.roundTripOnChange]
    );

    {
      const row = PaneRow.get(props.dataId);
      if (!row) {
        return null;
      }

      const widget = row.getWidgetT<string[], RuntimeProperties>(props.name);

      if (widget.properties.accessLevel === AccessLevel.hidden) {
        return null;
      }

      return (
        <CoreUiCheckBoxGroup
          disabled={widget.properties.accessLevel === AccessLevel.disabled}
          disabledHelpText={
            widget.properties.showDisabledHelp
              ? props.disabledHelpText
              : undefined
          }
          getErrors={() => widget.properties.businessErrors}
          helperText={props.helperText}
          label={props.label}
          onOptionChange={onOptionChange}
          options={widget.properties.options}
          orientation={props.orientationByBreakPoint[width]}
          readOnly={widget.properties.accessLevel === AccessLevel.readOnly}
          required={widget.properties.showAsMandatory}
          selectedValues={widget.value}
        />
      );
    }
  }
);

import { action, observable } from "mobx";
import * as React from "react";
import Sys from "../core/Sys";

interface Props {}

interface CaptchaResponse {
  token: string;
}

export class CaptchaControl extends React.PureComponent<Props> {
  private static set = action((token: string): void => {
    CaptchaControl.response.token = token;
  });

  public static response: CaptchaResponse = observable({ token: "" });
  private isGuest: boolean = false;

  public static isValid(): boolean {
    return CaptchaControl.response.token !== "";
  }

  public static reset(): void {
    CaptchaControl.set("");
    window["grecaptcha"].reset();
  }

  public constructor(props: Props) {
    super(props);

    this.isGuest = Sys.getCookie(Sys.guestSessionTokenCookie) !== null;

    if (this.isGuest) {
      const languageCode: string = Sys.settings.currentLanguageCode;
      const reCaptcha: HTMLScriptElement = document.createElement("script");

      reCaptcha.setAttribute("type", "text/javascript");
      reCaptcha.setAttribute(
        "src",
        `${Sys.settings.reCaptchaBaseUrl}/api.js?hl=${languageCode}`
      );

      document.head.appendChild(reCaptcha);
      window["mustangReCaptchaCallBack"] = (token: string) => {
        CaptchaControl.set(token);
      };
      window["mustangReCaptchaExpiredCallBack"] = (token: string) => {
        CaptchaControl.set("");
      };
    }
  }

  public componentWillUnmount() {
    const scripts = document.head.querySelectorAll('script[src*="recaptcha"]');
    scripts.forEach((script) => script.remove());

    const iframes = document.querySelectorAll('iframe[src*="recaptcha"]');
    iframes.forEach((iframe) => iframe.remove());
  }

  public render() {
    const siteKey: string = Sys.settings.reCaptchaSiteKey;
    let result: React.ReactNode = null;

    if (this.isGuest) {
      result = (
        <div
          className="g-recaptcha"
          data-callback="mustangReCaptchaCallBack"
          data-expired-callback="mustangReCaptchaExpiredCallBack"
          data-sitekey={siteKey}
        />
      );
    }

    return result;
  }
}

export default CaptchaControl;

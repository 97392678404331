import { Theme, useMediaQuery } from "@mui/material";
import { observer } from "mobx-react";
import * as React from "react";
import MenuItem from "../../coreui/MenuItem";
import PaneRow from "../../models/PaneRow";
import { AccessLevel } from "../AccessLevel";
import { ActionButtonProps, ActionButtonRuntimeProps } from "../ActionButton";
import ApiButton from "../ApiButton";
import { MenuItemProps } from "../MenuItem";
import { WizardControl as WizardControlBase } from "../WizardControl";

interface Props extends ActionButtonProps {}

export const WizardPreviousButtonMenuItem = (
  props: MenuItemProps
): JSX.Element => {
  const { config, runtime, ...otherProps } = props;
  const configProps = config as unknown as Props;
  const runtimeProps = runtime as ActionButtonRuntimeProps;

  const onClick = () => {
    if (props.runtime.accessLevel >= AccessLevel.actionable) {
      WizardControlBase.gotoPreviousStep();
      configProps.propagated.onItemClicked!();
    }
  };

  return (
    <MenuItem
      disabled={props.runtime.accessLevel === AccessLevel.disabled}
      iconName={configProps.iconName}
      indent={props.config.propagated ? props.config.propagated.indent : 0}
      onClick={onClick}
      {...otherProps}
    >
      {runtimeProps.label}
    </MenuItem>
  );
};

export const WizardPreviousButton = observer(
  (props: Props): JSX.Element | null => {
    const isIconOnly = useMediaQuery((theme: Theme) =>
      theme.breakpoints.only("xs")
    );

    const row = PaneRow.get(props.dataId);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<null, ActionButtonRuntimeProps>(props.name);

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    const wizardProperties = WizardControlBase.getWidgetProperties();
    const selectedStep: number = wizardProperties.steps.findIndex(
      (s) => s.paneUseKey === wizardProperties.selectedPaneUseKey
    )!;
    const step: number = WizardControlBase.getStepNumber(selectedStep);

    return (
      <ApiButton
        alternateText={widget.properties.alternateText}
        buttonColor={props.buttonColor}
        disabled={
          widget.properties.accessLevel === AccessLevel.disabled || step === 1
        }
        disabledHelpText={props.disabledHelpText}
        disabledHelpVisible={widget.properties.showDisabledHelp}
        iconName="fas fa-arrow-left"
        isIconOnly={isIconOnly}
        label={widget.properties.label}
        onClick={() => WizardControlBase.gotoPreviousStep()}
        size={props.size}
      />
    );
  }
);

import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import EditMask, { EditMask as EditMaskBase } from "../coreui/EditMask";
import PasswordField from "../coreui/PasswordField";
import { TableVerticalLayoutProps } from "../coreui/Table";
import TextField from "../coreui/TextField";
import PaneRow from "../models/PaneRow";
import ErrorsStore from "../stores/ErrorsStore";
import { AccessLevel } from "./AccessLevel";

interface ConfigProperties {
  dataCase: "lower" | "UPPER" | "Any";
  dataId: string;
  dataSize: number;
  disabledHelpText: string;
  editMask?: string;
  helperText: string;
  isPassword: boolean;
  label: string;
  maxSizeError: string;
  name: string;
  propagated?: TableVerticalLayoutProps;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
  showAsMandatory: boolean;
  showDisabledHelp: boolean;
}

export class SLTextEdit extends React.Component<ConfigProperties> {
  private config: ConfigProperties;
  private dataId: string = "";
  private name: string = "";
  private rowKey?: string;

  public constructor(props: ConfigProperties) {
    super(props);

    makeObservable<
      SLTextEdit,
      "dataId" | "name" | "onValueChange" | "syncDerivedWithProps"
    >(this, {
      dataId: observable,
      name: observable,
      onValueChange: action,
      syncDerivedWithProps: action,
    });

    this.syncDerivedWithProps();
  }

  private getErrors = (value: string): string[] => {
    const row = PaneRow.get(this.dataId, this.rowKey);
    if (!row) {
      return [];
    }

    const widget = row.getWidgetT<string, RuntimeProperties>(this.name);

    const errors: string[] = [...widget.properties.businessErrors];
    if (this.config.dataSize && value && value.length > this.config.dataSize) {
      errors.push(this.config.maxSizeError);
    }

    return errors;
  };

  private onValueChange = (value: string): void => {
    let transformedValue: string | null = null;

    if (value !== "") {
      switch (this.config.dataCase) {
        case "lower":
          transformedValue = value.toLowerCase();
          break;
        case "UPPER":
          transformedValue = value.toUpperCase();
          break;
        case "Any":
          transformedValue = value;
          break;
        default:
          throw new Error(`Unknown case ${this.config.dataCase}`);
      }
    }

    ErrorsStore.clearBusinessErrorsForWidget(this.dataId, this.name);

    const row = PaneRow.get(this.dataId, this.rowKey)!;
    const widget = row.getWidgetT<string | null, RuntimeProperties>(this.name);
    widget.setValue(transformedValue);
  };

  private syncDerivedWithProps(): void {
    this.dataId = this.props.dataId;
    this.name = this.props.name;
    this.config = { ...this.props };
    this.rowKey = this.props.propagated?.rowKey;
  }

  public componentDidUpdate(): void {
    this.syncDerivedWithProps();
  }

  public render(): React.ReactNode {
    const row = PaneRow.get(this.dataId, this.rowKey);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<string | null, RuntimeProperties>(this.name);

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    if (widget.properties.accessLevel === AccessLevel.disabled) {
      return (
        <TextField
          disabled={true}
          disabledHelpText={
            widget.properties.showDisabledHelp
              ? this.props.disabledHelpText
              : undefined
          }
          label={this.props.label}
          name={this.props.name}
          variant="filled"
        />
      );
    }

    if (widget.properties.accessLevel === AccessLevel.readOnly) {
      const value =
        this.props.editMask && widget.value
          ? EditMaskBase.formatValue(this.props.editMask, widget.value)
          : widget.value;

      return (
        <TextField
          label={this.props.label}
          name={this.props.name}
          readOnly
          value={value ? value : "-"}
          variant="filled"
        />
      );
    }

    if (this.props.editMask) {
      return (
        <EditMask
          dataCase={this.props.dataCase}
          getErrors={this.getErrors}
          helperText={this.props.helperText}
          label={this.props.label}
          mask={this.props.editMask}
          name={this.props.name}
          onChange={this.onValueChange}
          required={widget.properties.showAsMandatory}
          value={widget.value || ""}
        />
      );
    }

    if (this.props.isPassword) {
      return (
        <PasswordField
          autoComplete="new-password"
          getErrors={this.getErrors}
          helperText={this.props.helperText}
          label={this.props.label}
          name={this.props.name}
          onChange={this.onValueChange}
          required={widget.properties.showAsMandatory}
        />
      );
    }

    return (
      <TextField
        getErrors={this.getErrors}
        helperText={this.props.helperText}
        label={this.props.label}
        name={this.props.name}
        onValueChange={this.onValueChange}
        required={widget.properties.showAsMandatory}
        value={widget.value || ""}
        variant="filled"
      />
    );
  }
}

export default observer(SLTextEdit);

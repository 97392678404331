import { Input, InputAdornment } from "@mui/material";
import { ICellEditorParams } from "ag-grid-community";
import { observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import Localization from "../../core/Localization";
import Button from "../../coreui/Button";
import CalendarDialog from "../../coreui/CalendarDialog";
import { ErrorBadge } from "../../coreui/ErrorBadge";
import { TableChildProps } from "../../coreui/Table";
import { CellUtil } from "../../coreui/table/CellUtil";
import PaneRow from "../../models/PaneRow";
import ErrorsStore from "../../stores/ErrorsStore";
import { DateEdit } from "../DateEdit";
import { GridColumnConfigProperties } from "./GridColumn";

interface ConfigProperties extends ICellEditorParams<PaneRow, string | null> {
  dataId: string;
  dateFormatError: string;
  name: string;
  propagated: TableChildProps;
}

interface State {
  isCalendarOpen: boolean;
  value?: string | null;
}

interface RuntimeProperties {
  businessErrors: string[];
}

export class DateEditColumnEdit extends React.Component<
  ConfigProperties,
  State
> {
  public static readonly localeName = "current";
  public static readonly widgetType: string = "DateEditColumn";
  private inputElement: HTMLInputElement;
  private lastValidDate: Date | null = null;

  public static getCurrentValueParsed(
    userFormatted: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  ): Date | null {
    let parsed: Date | null = null;

    if (value) {
      const format = DateEdit.getDateFormat(userFormatted);
      const date = moment(
        value,
        format,
        userFormatted ? DateEditColumnEdit.localeName : "en",
        true
      );

      if (date.isValid()) {
        parsed = date.toDate();
      } else {
        if (userFormatted) {
          parsed = Localization.parseDate(value);
          if (!parsed) {
            parsed = value;
          }
        } else {
          parsed = value;
        }
      }
    }

    return parsed;
  }

  public static getErrors(
    props: ConfigProperties,
    row: PaneRow,
    value: string | null
  ): string[] {
    const widget = row.getWidgetT<string | null, RuntimeProperties>(props.name);
    // Ensure errors are always accessed so the component knows to observe them
    const widgetErrors = [...widget.properties.businessErrors];
    const errors = row.hasChanges(props.name) ? [] : widgetErrors;

    const parsedDate = DateEditColumnEdit.getCurrentValueParsed(true, value);
    if (typeof parsedDate === "string") {
      errors.push(props.dateFormatError);
    }

    return errors;
  }

  public constructor(props: ConfigProperties) {
    super(props);

    const currentDate = DateEditColumnEdit.getCurrentValueParsed(
      true,
      props.value
    );
    const formattedValue = DateEdit.formatValue(currentDate, true);

    this.state = {
      isCalendarOpen: false,
      value: formattedValue,
    };

    if (typeof currentDate !== "string") {
      this.lastValidDate = currentDate;
    }

    props.eGridCell.addEventListener("keydown", this.onCellKeyDown);
  }

  private get column(): GridColumnConfigProperties | undefined {
    const parentTable = this.props.propagated.parentTable;
    const tableColumns = parentTable.columns as GridColumnConfigProperties[];
    return tableColumns.find((c) => c.name === this.props.colDef.colId);
  }

  private onCalendarClose = (): void => {
    this.props.propagated.parentTable.setStopEditingWhenGridLosesFocus(true);
    this.setState({ isCalendarOpen: false });
  };

  private onCalendarDateSelected = (date: Date): void => {
    this.setValue(DateEdit.formatValue(date, true));
  };

  private onCalendarRestoreFocus = (): void => {
    if (this.inputElement) {
      this.inputElement.focus();
    }
  };

  private onCellKeyDown = (event: KeyboardEvent): void => {
    // If the button has focus do not stop editing.
    if (
      event.key === "Enter" &&
      document.activeElement &&
      document.activeElement.tagName === "BUTTON"
    ) {
      event.preventDefault();
    }
  };

  private openCalendar = () => {
    this.props.propagated.parentTable.setStopEditingWhenGridLosesFocus(false);
    this.setState({ isCalendarOpen: true });
  };

  private setValue(value: string | null) {
    ErrorsStore.clearBusinessErrorsForTableCell(
      this.props.dataId,
      this.props.name,
      this.props.data.rowKey
    );
    this.setState({ value });
  }

  public componentDidMount() {
    CellUtil.disableGridNavigation(this.props.eGridCell, this.inputElement);

    CellUtil.setInitialFocus(this.inputElement);
  }

  public componentWillUnmount() {
    CellUtil.enableGridNavigation(this.props.eGridCell);
    this.props.eGridCell.removeEventListener("keydown", this.onCellKeyDown);
  }

  public getValue(): string | null {
    return DateEdit.formatValue(
      DateEditColumnEdit.getCurrentValueParsed(true, this.state.value),
      false
    );
  }

  public render() {
    const row = this.props.data!;

    const value = this.state.value || "";
    let currentDate: string | Date | null =
      DateEditColumnEdit.getCurrentValueParsed(true, value);
    if (typeof currentDate === "string") {
      currentDate = this.lastValidDate ? this.lastValidDate : null;
    }

    const errors = DateEditColumnEdit.getErrors(
      this.props,
      this.props.data!,
      value
    );

    return (
      <ErrorBadge
        errors={errors}
        isShort={row.isNew && this.props.propagated.parentTable.isDocumentGrid}
      >
        <Input
          autoFocus={true}
          fullWidth={true}
          inputProps={{
            style: { marginLeft: "24px", paddingLeft: 0, paddingRight: 0 },
          }}
          inputRef={(element) => {
            this.inputElement = element;
          }}
          onChange={(e) => this.setValue(e.target.value)}
          style={{ height: "calc(100% + 2px)", paddingLeft: 2 }}
          endAdornment={
            <InputAdornment
              position="end"
              style={{ marginRight: 24, marginTop: -4 }}
            >
              <Button
                aria-label={Localization.getBuiltInMessage(
                  "DateEdit.selectDateButtonLabel",
                  {
                    datePickerLabel: this.props.colDef.headerName,
                  }
                )}
                icon="fas fa-calendar-alt"
                size="small"
                onClick={this.openCalendar}
                onKeyDown={(event: React.KeyboardEvent<HTMLButtonElement>) => {
                  if (
                    event.key === "Tab" &&
                    !event.shiftKey &&
                    this.props.api
                  ) {
                    this.props.api.stopEditing();
                    this.props.api.tabToNextCell();
                    event.preventDefault();
                    event.stopPropagation();
                  } else if (event.key === "Enter") {
                    event.preventDefault();
                    event.stopPropagation();
                    this.openCalendar();
                  }
                }}
              />
            </InputAdornment>
          }
          error={errors.length > 0}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Tab" && event.shiftKey && this.props.api) {
              this.props.api.stopEditing();
              this.props.api.tabToPreviousCell();

              event.preventDefault();
              event.stopPropagation();
            }
          }}
          required={this.column?.required}
          value={value}
        />
        <CalendarDialog
          onClose={this.onCalendarClose}
          onDateSelected={this.onCalendarDateSelected}
          onRestoreFocus={this.onCalendarRestoreFocus}
          open={this.state.isCalendarOpen}
          title={Localization.getBuiltInMessage(
            "DateEdit.selectDateDialogLabel",
            {
              datePickerLabel: this.props.colDef.headerName,
            }
          )}
          value={currentDate}
        />
      </ErrorBadge>
    );
  }
}

export default observer(DateEditColumnEdit);

import { Breakpoint, colors, styled } from "@mui/material";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../core/Localization";
import { useWidth } from "../core/Responsive";
import Icon from "../coreui/Icon";
import ImageWithLink from "../coreui/ImageWithLink";
import Thumbnail from "../coreui/Thumbnail";
import PaneRow from "../models/PaneRow";
import { AccessLevel } from "./AccessLevel";

interface ConfigProperties {
  dataId: string;
  imageHeight: { [key in Breakpoint]: number | undefined };
  imageRender: "Fit to Size" | "Mask to Size";
  imageType:
    | "Download Link Only"
    | "Full Size"
    | "Large Thumbnail"
    | "Medium Thumbnail";
  imageWidth: { [key in Breakpoint]: number | undefined };
  name: string;
  suppressDownloadLink: boolean;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  alternateText: string;
  documentHandle: string | null;
  downloadToken: string;
  fileName: string;
  iconName: string;
  pendingDocumentId: number | null;
  pendingThumbnailId: number | null;
}

const DataImageContainer = styled("div")(({ theme }) => ({
  overflow: "hidden",
  [theme.breakpoints.only("xs")]: {
    maxWidth: "100%",
  },
}));

const NoImageIcon = styled(Icon)(({ theme }) => ({
  "& > svg": {
    height: "100% !important",
    width: "100% !important",
  },
  color: theme.palette.grey[500],
  height: "75% !important",
  left: "50%",
  maxHeight: "96px",
  maxWidth: "96px",
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "75% !important",
}));

export const DataImageDisplay = observer(
  (props: ConfigProperties): JSX.Element | null => {
    function getUrl(
      documentHandle: string | null,
      pendingDocumentId: number | null,
      pendingThumbnailId: number | null,
      thumbnailType: string,
      documentName: string,
      downloadToken: string
    ): string {
      // DocumentName is only passed to make the url more human readable
      return (
        "dynamic/DataImageDisplay/GetImage" +
        `/${documentHandle}` +
        `/${pendingDocumentId}/${pendingThumbnailId}` +
        `/${thumbnailType}` +
        `/${documentName}` +
        `?downloadToken=${downloadToken}`
      );
    }

    const width = useWidth();

    const row = PaneRow.get(props.dataId);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<null, RuntimeProperties>(props.name);
    if (widget.properties.accessLevel <= AccessLevel.hidden) {
      return null;
    }

    const imageHeight = props.imageHeight[width];
    const imageWidth = props.imageWidth[width];
    if (!imageHeight || !imageWidth) {
      return null;
    }

    if (
      !widget.properties.documentHandle &&
      !widget.properties.pendingDocumentId
    ) {
      if (props.imageType === "Download Link Only") {
        return null;
      }

      // If there *could be* an image (rel with cardinality
      // of 0) but there isn't an image, display the 'no image'
      // default, as per the ux standards
      return (
        <div
          style={{
            background: colors.grey[200],
            height: imageHeight,
            position: "relative",
            width: imageWidth,
          }}
        >
          <NoImageIcon
            aria-label={Localization.getBuiltInMessage("noImage")}
            fixedWidth
            icon="far fa-image"
          />
        </div>
      );
    }

    const imgSrc: string = getUrl(
      widget.properties.documentHandle,
      widget.properties.pendingDocumentId,
      widget.properties.pendingThumbnailId,
      props.imageType,
      widget.properties.fileName,
      widget.properties.downloadToken
    );

    const downloadUrl: string = getUrl(
      widget.properties.documentHandle,
      widget.properties.pendingDocumentId,
      widget.properties.pendingThumbnailId,
      "Full Size",
      widget.properties.fileName,
      widget.properties.downloadToken
    );

    if (props.imageType === "Full Size") {
      const fit = props.imageRender === "Mask to Size" ? "cover" : "contain";

      if (!props.suppressDownloadLink) {
        return (
          <ImageWithLink
            alternateText={widget.properties.alternateText}
            fit={fit}
            height={imageHeight}
            href={downloadUrl}
            imgSrc={imgSrc}
            width={imageWidth}
          />
        );
      }

      return (
        <div style={{ overflow: "hidden" }}>
          <DataImageContainer
            style={{
              height: imageHeight,
            }}
          >
            <img
              aria-label={widget.properties.alternateText}
              src={imgSrc}
              style={{
                height: "100%",
                maxWidth: "100%",
                objectFit: fit,
                width: imageWidth,
              }}
            />
          </DataImageContainer>
        </div>
      );
    }

    return (
      <Thumbnail
        alternateText={widget.properties.alternateText}
        downloadUrl={downloadUrl}
        hasImage={true}
        iconName={widget.properties.iconName}
        imageHeight={imageHeight}
        imageWidth={imageWidth}
        imgSrc={imgSrc}
        thumbnailType={props.imageType}
      />
    );
  }
);

import { colors, createTheme, darken, lighten, Theme } from "@mui/material";
import { ForegroundColorType } from "./ForegroundColorType";
import { Palette } from "./Palette";

export class MustangTheme {
  public static create(
    baseForegroundColor: ForegroundColorType = ForegroundColorType.Grey,
    isHeaderOrFooter: boolean = false
  ): Theme {
    const theme = createTheme({
      baseForegroundColor,
      breakpoints: {
        values: {
          lg: 1281,
          md: 961,
          sm: 601,
          xl: 1920,
          xs: 0,
        },
      },
      components: {
        MuiAccordion: {
          styleOverrides: {
            root: {
              "&:first-child": {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              },
              backgroundColor: colors.grey[100],
            },
          },
        },
        MuiAccordionSummary: {
          styleOverrides: {
            content: {
              "& > :last-child": { paddingRight: 0 },
              "&.Mui-expanded": { margin: "12px 0px" },
            },
            root: {
              "&.Mui-expanded": { minHeight: 48 },
              "&:hover": {
                backgroundColor: darken(colors.grey[100], 0.1),
              },
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            contained: {
              "&.Mui-disabled": { backgroundColor: colors.grey[100] },
              boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.5)",
            },
            root: {
              borderRadius: 0,
              fontSize: 14,
              fontWeight: 900,
              letterSpacing: 1,
              maxWidth: "100%",
              minHeight: 40,
              overflow: "hidden",
              paddingBottom: 10,
              paddingLeft: 40,
              paddingRight: 40,
              paddingTop: 10,
              whiteSpace: "nowrap",
            },
            sizeLarge: {
              borderRadius: 0,
              fontSize: 18,
              fontWeight: 900,
              letterSpacing: 1,
              minHeight: 60,
              paddingBottom: 17,
              paddingLeft: 60,
              paddingRight: 60,
              paddingTop: 17,
            },
            sizeSmall: {
              borderRadius: 0,
              fontSize: 9,
              fontWeight: 900,
              letterSpacing: 1,
              minHeight: 24,
              paddingBottom: 3,
              paddingLeft: 24,
              paddingRight: 24,
              paddingTop: 3,
            },
            text: {
              paddingLeft: 40,
              paddingRight: 40,
            },
          },
        },
        MuiCheckbox: {
          styleOverrides: {
            root: {
              fontSize: 24,
              marginLeft: -1,
              padding: 8,
            },
          },
        },
        MuiCollapse: {
          styleOverrides: {
            entered: {
              overflow: "visible",
            },
          },
        },
        MuiDialog: {
          styleOverrides: {
            paperScrollPaper: {
              maxHeight: "88vh",
            },
          },
        },
        MuiDialogActions: {
          styleOverrides: {
            root: {
              padding: 0,
            },
          },
        },
        MuiDrawer: {
          styleOverrides: {
            paper: {
              "@media(min-width:601px)": {
                minWidth: 320,
              },
            },
          },
        },
        MuiFab: {
          styleOverrides: {
            root: {
              boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.5)",
              fontSize: 24,
              height: 40,
              width: 40,
            },
            sizeMedium: {
              height: 40,
              width: 40,
            },
            sizeSmall: {
              fontSize: 14,
              height: 24,
              minHeight: 24,
              minWidth: 24,
              width: 24,
            },
          },
        },
        MuiFilledInput: {
          styleOverrides: {
            adornedEnd: {
              paddingRight: 16,
            },
            adornedStart: {
              paddingLeft: 16,
            },
            input: {
              paddingBottom: 8,
              paddingLeft: 16,
              paddingRight: 16,
              paddingTop: 24,
            },
            inputMultiline: {
              height: "100%",
              paddingBottom: 0,
              paddingLeft: 0,
              paddingRight: 16,
              paddingTop: 0,
            },
            multiline: {
              paddingBottom: 0,
              paddingLeft: 16,
              paddingRight: 0,
              paddingTop: 24,
            },
            root: {
              "&.Mui-disabled": {
                backgroundColor: colors.grey[100],
                color: colors.grey[300],
              },
              "&.Mui-focused": {
                backgroundColor: colors.grey[200],
              },
              "&:hover": {
                backgroundColor: darken(colors.grey[200], 0.1),
              },
              backgroundColor: colors.grey[200],
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              boxSizing: "border-box",
              color: colors.grey[800],
              fontSize: 16,
              height: 52,
              lineHeight: "20px",
            },
            underline: {
              "&.Mui-disabled:before": {
                borderBottomColor: colors.grey[300],
                borderBottomStyle: "solid",
                borderBottomWidth: 2,
              },
              "&:after": {
                borderBottomColor: colors.grey[700],
                borderBottomStyle: "solid",
                borderBottomWidth: 2,
              },
              "&:before": {
                borderBottomColor: colors.grey[300],
                borderBottomStyle: "solid",
                borderBottomWidth: 2,
              },
              "&:hover:not(.Mui-disabled, .Mui-focused, .Mui-error):before": {
                borderBottomColor: colors.grey[300],
                borderBottomStyle: "solid",
                borderBottomWidth: 2,
              },
            },
          },
        },
        MuiFormControlLabel: {
          styleOverrides: {
            label: {
              fontSize: 14,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
            root: {
              marginLeft: -8,
              marginRight: 8,
              maxWidth: "100%",
            },
          },
        },
        MuiFormHelperText: {
          defaultProps: {
            className: "helper-text",
          },
          styleOverrides: {
            root: {
              color:
                baseForegroundColor === ForegroundColorType.White
                  ? colors.common.white
                  : colors.grey[700],
              marginLeft: 0,
              marginRight: 0,
              marginTop: 8,
            },
          },
        },
        MuiFormLabel: {
          styleOverrides: {
            asterisk: {
              color: colors.red[900],
            },
            root: {
              "&.Mui-focused": { color: colors.grey[700] },
              color: colors.grey[700],
              fontSize: 16,
              lineHeight: "20px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
          },
        },
        MuiIcon: {
          styleOverrides: {
            root: {
              fontSize: "inherit",
            },
          },
        },
        MuiInput: {
          styleOverrides: {
            root: {
              fontSize: 16,
              lineHeight: "20px",
            },
            underline: {
              "&.Mui-disabled:before": {
                borderBottomColor: colors.grey[300],
                borderBottomStyle: "solid",
                borderBottomWidth: 2,
              },
              "&:after": {
                borderBottomColor: colors.grey[700],
                borderBottomStyle: "solid",
                borderBottomWidth: 2,
              },
              "&:before": {
                borderBottomColor: colors.grey[300],
                borderBottomStyle: "solid",
                borderBottomWidth: 2,
              },
              "&:hover:not(.Mui-disabled, .Mui-focused, .Mui-error):before": {
                borderBottomColor: colors.grey[300],
                borderBottomStyle: "solid",
                borderBottomWidth: 2,
              },
              left: -1,
              right: 1,
            },
          },
        },
        MuiInputAdornment: {
          styleOverrides: {
            root: {
              "& button": {
                padding: 0,
              },

              height: 16,
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              boxSizing: "border-box",
              cursor: "default",
            },
          },
        },
        MuiInputLabel: {
          styleOverrides: {
            filled: {
              "&.MuiInputLabel-shrink": {
                transform: "translate(16px, 8px) scale(0.75)",
              },
              transform: "translate(16px, 16px) scale(1)",
            },
            root: {
              pointerEvents: "none",
            },
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            /* eslint-disable sort-keys */
            gutters: {
              "@media(min-width:1281px)": { paddingLeft: 40, paddingRight: 40 },
              "@media(max-width:1280px)": { paddingLeft: 24, paddingRight: 24 },
              "@media(max-width:960px)": { paddingLeft: 16, paddingRight: 16 },
              paddingBottom: 10,
              paddingTop: 10,
            },
            /* eslint-enable sort-keys */
            root: {
              "&.Mui-selected": {
                "&.Mui-focusVisible": {
                  backgroundColor: darken(colors.grey[800], 0.1),
                },
                "& > p, & > span": {
                  color: colors.common.white,
                },
                "&:hover": {
                  backgroundColor: darken(colors.grey[800], 0.1),
                },
                backgroundColor: colors.grey[800],
              },
              "&:hover": {
                backgroundColor: darken(colors.common.white, 0.1),
              },
              "@media (min-width: 601px)": {
                minHeight: 40,
              },
              minHeight: 40,
              minWidth: 192,
            },
          },
        },
        MuiPaper: {
          defaultProps: {
            square: true,
          },
        },
        MuiRadio: {
          styleOverrides: {
            root: {
              fontSize: 24,
              padding: 8,
            },
          },
        },
        MuiSelect: {
          styleOverrides: {
            icon: {
              color: "inherit",
              paddingRight: 16,
            },
            select: {
              "&:focus": { backgroundColor: "transparent" },
            },
          },
        },
        MuiSnackbar: {
          styleOverrides: {
            root: {
              justifyContent: "initial",
              zIndex: 1500,
            },
          },
        },
        MuiSnackbarContent: {
          styleOverrides: {
            action: {
              alignSelf: "flex-start",
              marginRight: 0,
              marginTop: 6,
            },
            root: {
              "@media (min-width: 601px)": {
                maxWidth: 520,
                minWidth: 240,
              },
              backgroundColor: colors.grey[100],
              borderRadius: 0,
              color: colors.grey[800],
              maxHeight: "95vh",
              overflow: "hidden",
              paddingLeft: 24,
              paddingRight: 24,
            },
          },
        },
        MuiTab: {
          styleOverrides: {
            root: {
              "&.Mui-selected": {
                backgroundColor: colors.grey[100],
                fontWeight: 900,
              },
              "@media(min-width:601px)": {
                minWidth: "initial",
              },
              "@media(min-width:961px)": {
                fontSize: 14,
                minWidth: "initial",
              },
              color: colors.grey[800],
              fontSize: 14,
              fontWeight: 400,
              letterSpacing: "1px",
              lineHeight: 1.75,
              minHeight: 56,
              padding: 0,
              position: "relative",
              zIndex: 1,
            },
            textColorInherit: {
              opacity: 1,
            },
          },
        },
        MuiTabs: {
          defaultProps: {
            indicatorColor: "secondary",
            textColor: "inherit",
          },
          styleOverrides: {
            indicator: {
              backgroundColor: colors.grey[800],
              top: 0,
              zIndex: 1,
            },
            root: {
              height: 56,
            },
          },
        },
        MuiTextField: {
          defaultProps: {
            variant: "filled",
          },
        },
        MuiTooltip: {
          styleOverrides: {
            popper: {
              opacity: 1,
            },
            tooltip: {
              backgroundColor: colors.grey[800],
              borderRadius: 0,
              color: colors.common.white,
              fontSize: 12,
              fontWeight: 400,
              lineHeight: "16px",
              maxWidth: 320,
              overflow: "hidden",
            },
            tooltipPlacementBottom: {
              "@media(min-width:601px)": {
                marginBottom: 0,
                marginLeft: 0,
                marginRight: 0,
                marginTop: 8,
              },
            },
            tooltipPlacementTop: {
              "@media(min-width:601px)": {
                marginBottom: 8,
                marginLeft: 0,
                marginRight: 0,
                marginTop: 0,
              },
            },
          },
        },
        MuiTouchRipple: {
          styleOverrides: {
            rippleVisible: {
              animation: "cx-ripple-enter 550ms ease-in-out",
              opacity: 0.4,
            },
          },
        },
        MuiTypography: {
          defaultProps: {
            color: "text.primary",
            variantMapping: {
              button: "p",
              caption: "p",
            },
          },
        },
        MuiUseMediaQuery: {
          defaultProps: {
            // Supporting server-side rendering has the drawback of the media
            // query causing components to render twice. In addition to a
            // performance penalty, this is problematic for components that store
            // off props in the constructor, as many of the grid components do.
            // They will end up with incorrect values if any of those values
            // depend on the results of a Media Query, such as the current width
            // of the screen. For example, the Grid Pager component will start
            // with the incorrect initial page size if SSR is supported.
            noSsr: true,
          },
        },
      },
      container: {
        maxWidth: { lg: 1200, md: 912, sm: 568, xl: 1200, xs: null },
        verticalSpacing: { lg: 80, md: 60, sm: 40, xl: 80, xs: 40 },
      },
      dialog: {
        maxColumns: { lg: 6, md: 8, sm: 8 },
        maxWidths: { lg: 660, md: 648, sm: 600 },
        padding: { lg: 40, sm: 24, xs: 16 },
      },
      freeflow: {
        group: {
          spacing: {
            lg: { horizontal: 40, vertical: 40 },
            md: { horizontal: 24, vertical: 24 },
            sm: { horizontal: 24, vertical: 24 },
            xs: { horizontal: 24, vertical: 24 },
          },
        },
        item: {
          spacing: {
            lg: { horizontal: 24, vertical: 24 },
            md: { horizontal: 24, vertical: 24 },
            sm: { horizontal: 24, vertical: 24 },
            xs: { horizontal: 24, vertical: 24 },
          },
        },
        section: {
          spacing: {
            lg: { horizontal: 40, vertical: 0 },
            md: { horizontal: 24, vertical: 0 },
            sm: { horizontal: 24, vertical: 0 },
            xs: { horizontal: 24, vertical: 0 },
          },
        },
      },
      horizontalSpacing: {
        closelyRelated: { lg: 24, md: 24, sm: 16, xs: 16 },
        related: { lg: 40, md: 24, sm: 16, xl: 40, xs: 16 },
        widget: {
          closelyRelated: 8,
          related: 16,
        },
      },
      isHeaderOrFooter,
      panel: {
        maxColumns: { lg: 12, md: 12, sm: 8, xl: 12 },
        maxWidths: { lg: 1200, md: 912, sm: 568, xl: 1200 },
      },
      paper: {
        color: colors.grey[100],
        padding: { lg: 40, md: 24, sm: 24, xl: 40, xs: 16 },
      },
      spacingBreakPoints: ["xs", "sm", "md", "lg"],
      typography: {
        fontSize: 16,
        /* eslint-disable sort-keys */
        h1: {
          "@media(min-width:1281px)": { fontSize: 56, lineHeight: "72px" },
          "@media(max-width:1280px)": { fontSize: 45, lineHeight: "60px" },
          "@media(max-width:600px)": { fontSize: 34, lineHeight: "44px" },
          fontWeight: 700,
        },
        h2: {
          "@media(min-width:1281px)": { fontSize: 45, lineHeight: "60px" },
          "@media(max-width:1280px)": { fontSize: 34, lineHeight: "44px" },
          "@media(max-width:600px)": { fontSize: 24, lineHeight: "32px" },
          fontWeight: 700,
        },
        h3: {
          "@media(min-width:1281px)": { fontSize: 34, lineHeight: "44px" },
          "@media(max-width:1280px)": { fontSize: 24, lineHeight: "32px" },
          "@media(max-width:600px)": { fontSize: 21, lineHeight: "28px" },
          fontWeight: 700,
        },
        h4: {
          "@media(min-width:1281px)": { fontSize: 24, lineHeight: "32px" },
          "@media(max-width:1280px)": { fontSize: 21, lineHeight: "28px" },
          "@media(max-width:600px)": { fontSize: 16, lineHeight: "20px" },
          fontWeight: 700,
        },
        h5: {
          "@media(min-width:1281px)": { fontSize: 21, lineHeight: "28px" },
          "@media(max-width:1280px)": { fontSize: 16, lineHeight: "20px" },
          fontWeight: 700,
        },
        h6: {
          fontSize: 16,
          fontWeight: 700,
          lineHeight: "20px",
        },
        caption: {
          fontSize: 12,
          fontWeight: 400,
          lineHeight: "16px",
        },
        body1: {
          fontSize: 16,
          fontWeight: 400,
          lineHeight: "20px",
        },
        body2: {
          fontSize: 16,
          fontWeight: 700,
          lineHeight: "20px",
        },
        button: {
          color: colors.grey[800],
          fontSize: 16,
          fontWeight: 700,
        },
        /* eslint-enable sort-keys */
      },
      verticalSpacing: {
        closelyRelated: { lg: 24, md: 24, sm: 24, xs: 24 },
        related: { lg: 40, md: 24, sm: 24, xs: 24 },
        tight: { lg: 8, md: 8, sm: 8, xs: 8 },
        unrelated: { lg: 60, md: 40, sm: 40, xs: 40 },
      },
      visibilityBreakPoints: ["xs", "sm", "md", "lg", "xl"],
      widthBreakPoints: ["sm", "md", "lg"],
    });

    return Palette.augmentTheme(theme);
  }
}

import { observer, Observer } from "mobx-react";
import * as React from "react";
import Localization from "../../core/Localization";
import RequestPromise from "../../core/RequestPromise";
import MenuItem from "../../coreui/MenuItem";
import PaneRow from "../../models/PaneRow";
import ActionButtonService, {
  OnRoundTripResponse,
} from "../../services/ActionButtonService";
import ErrorsStore from "../../stores/ErrorsStore";
import RequestsStore from "../../stores/RequestsStore";
import { AccessLevel } from "../AccessLevel";
import { ActionButtonProps, ActionButtonRuntimeProps } from "../ActionButton";
import ApiButton from "../ApiButton";
import { CaptchaControl as CaptchaControlBase } from "../CaptchaControl";
import { MenuItemProps } from "../MenuItem";

interface Props extends ActionButtonProps {}

export class SavePresentationButton extends React.Component<Props> {
  private onClickPromise: RequestPromise<void>;

  private static onClick(props: {
    dataId: string;
    iconName?: string;
    name: string;
  }): RequestPromise<void> {
    const row: PaneRow = PaneRow.get(props.dataId)!;

    return ActionButtonService.onRoundTrip(row, props.name)
      .then((response: OnRoundTripResponse) => {
        if (response.saved && !response.url) {
          ErrorsStore.clearErrors();
          RequestsStore.instance.setSaved(props.iconName);
        }

        const responseRow = PaneRow.get(props.dataId)!;
        const widget = responseRow.getWidgetT<null, ActionButtonRuntimeProps>(
          props.name
        );
        if (widget.properties.validateCaptcha) {
          CaptchaControlBase.reset();
        }
      })
      .catch((reason) => {
        if (reason) {
          throw reason;
        }
      });
  }

  public static renderMenuItem(props: MenuItemProps): JSX.Element {
    const { config, runtime, ...otherProps } = props;
    const configProps = config as unknown as Props;
    const runtimeProps = runtime as ActionButtonRuntimeProps;

    const onClick = () => {
      if (props.runtime.accessLevel >= AccessLevel.actionable) {
        SavePresentationButton.onClick(configProps);
        configProps.propagated.onItemClicked!();
      }
    };

    return (
      <Observer>
        {() => (
          <MenuItem
            disabled={
              props.runtime.accessLevel === AccessLevel.disabled ||
              (props.runtime.validateCaptcha! && !CaptchaControlBase.isValid())
            }
            iconName={configProps.iconName}
            indent={
              props.config.propagated ? props.config.propagated.indent : 0
            }
            onClick={onClick}
            {...otherProps}
          >
            {runtimeProps.label}
          </MenuItem>
        )}
      </Observer>
    );
  }

  private onClick = () => {
    this.onClickPromise = SavePresentationButton.onClick(this.props);
  };

  public componentWillUnmount() {
    if (this.onClickPromise) {
      this.onClickPromise.abort();
    }
  }

  public render() {
    const row = PaneRow.get(this.props.dataId);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<null, ActionButtonRuntimeProps>(
      this.props.name
    );

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    const isIconOnly = !widget.properties.label;
    const label = isIconOnly
      ? Localization.getBuiltInMessage("Button.saveLabel")
      : widget.properties.label;

    return (
      <ApiButton
        alternateText={widget.properties.alternateText}
        buttonColor={this.props.buttonColor}
        disabled={
          widget.properties.accessLevel === AccessLevel.disabled ||
          (widget.properties.validateCaptcha && !CaptchaControlBase.isValid())
        }
        disabledHelpText={this.props.disabledHelpText}
        disabledHelpVisible={widget.properties.showDisabledHelp}
        iconName={this.props.iconName}
        isIconOnly={isIconOnly}
        label={label}
        onClick={this.onClick}
        size={this.props.size}
      />
    );
  }
}

export default observer(SavePresentationButton);

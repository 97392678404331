export default class Logging {
  private static getMembers(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    object: any,
    showFunctions: boolean = false,
    shallow: boolean = false,
    delimiter: string | null = null,
    limit: number | null = null
  ): string {
    let message = "";

    const delim = delimiter || ", ";

    if (
      typeof object === "string" ||
      typeof object === "boolean" ||
      typeof object === "number"
    ) {
      message = object.toString();
    } else {
      for (const member of Object.keys(object)) {
        if (!limit || message.length < limit) {
          try {
            if (typeof object[member] === "string") {
              message += `${member}: '${object[member]}'${delim}`;
            } else if (typeof object[member] === "function") {
              if (showFunctions) {
                message += `${member}: function()${delim}`;
              }
            } else if (typeof object[member] === "object" && !shallow) {
              if (object[member] === null) {
                message += `${member}: null${delim}`;
              } else {
                const members = Logging.getMembers(
                  object[member],
                  showFunctions,
                  shallow,
                  ", ",
                  200
                );
                message += `${member}: {${members}}${delim}`;
              }
            } else {
              message += `${member}: ${object[member]}${delim}`;
            }
          } catch (exception) {
            message += `${member}: [Not Accessible]${delim}`;
          }
        } else {
          message += "...";
          break;
        }
      }

      if (message.substr(message.length - delim.length) === delim) {
        message = message.substr(0, message.length - delim.length);
      }
    }

    return message;
  }

  public static error(error: Error | string) {
    if (window.console) {
      console.error(error);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static log(message: any, group: string | undefined = undefined): void {
    if (message instanceof Error) {
      Logging.error(message);
      if (window.console) {
        console.warn("Logging.log call with Error, call Logging.error instead");
      }

      return;
    }

    let logMessage: string = message;

    if (typeof message === "object") {
      logMessage = Logging.getMembers(message);
    } else {
      for (let index = 1; index < arguments.length; index++) {
        logMessage = message.replace(`{${index - 1}}`, arguments[index]);
      }
    }

    if (logMessage.length > 0) {
      if (window.console) {
        if (group) {
          console.groupCollapsed(group);
        }

        console.log(logMessage);

        if (group) {
          console.groupEnd();
        }
      }
    }
  }

  public static warn(
    message: string,
    group: string | undefined = undefined
  ): void {
    if (window.console) {
      if (group) {
        console.groupCollapsed(group);
      }

      console.warn(message);

      if (group) {
        console.groupEnd();
      }
    }
  }
}

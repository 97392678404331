import { ButtonBase, styled } from "@mui/material";
import * as React from "react";
import FocusManager from "../../core/FocusManager";
import Localization from "../../core/Localization";
import Sys from "../../core/Sys";

export interface Props {
  href?: string;
  imageUrl: string;
  label: string;
  onClick?: () => void;
}

const LogoButton = styled(ButtonBase)<{ href: string }>(({ theme }) => ({
  "&:focus-visible": {
    outline: "2px solid",
    outlineColor: theme.palette.text.primary,
    outlineOffset: "4px",
  },
  [theme.breakpoints.up("xs")]: {
    height: 40,
    marginRight: 24,
  },
  [theme.breakpoints.up("md")]: {
    height: 60,
  },
  [theme.breakpoints.up("lg")]: {
    marginRight: 40,
  },
  alignItems: "center",
  display: "flex",
  maxWidth: "25%",
  outline: "none",
  overflow: "hidden",
}));

const LogoImage = styled("img")({
  height: "100%",
  maxHeight: "100%",
  maxWidth: "100%",
});

export class Logo extends React.PureComponent<Props> {
  private static readonly componentId: string = "header-logo";

  public static grabFocus(): void {
    const headerLogo = document.getElementById(Logo.componentId)!;
    FocusManager.grabFocus(headerLogo);
  }

  public render() {
    let ariaLabel = "";

    let nonProdEnvironmentClause: string = "";
    if (Sys.settings.nonProdEnvironment) {
      nonProdEnvironmentClause = `${Sys.settings.nonProdEnvironment} - `;
    }

    if (this.props.label && this.props.label !== "") {
      ariaLabel = `${nonProdEnvironmentClause}${this.props.label}`;
    } else {
      ariaLabel = `${nonProdEnvironmentClause}${Localization.getBuiltInMessage(
        "home"
      )}`;
    }

    return (
      <LogoButton
        aria-label={ariaLabel}
        href={this.props.href || ""}
        id={Logo.componentId}
        onClick={this.props.onClick}
        TouchRippleProps={{
          style: {
            height: "calc(100% + 30px)",
            margin: "-15px",
            width: "calc(100% + 30px)",
          },
        }}
      >
        <LogoImage aria-hidden={true} src={this.props.imageUrl} />
      </LogoButton>
    );
  }
}

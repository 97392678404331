import { ButtonBase, styled, Theme } from "@mui/material";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import * as React from "react";
import Localization from "../core/Localization";
import Button from "./Button";
import Icon from "./Icon";

interface Props {
  alternateText: string;
  disableFocusRipple?: boolean;
  height?: number;
  href: string;
  icon: string;
  linkRef?: (r: HTMLElement | null) => void;
  tabIndex?: number;
  width?: number;
}

const styles = (theme: Theme) =>
  createStyles({
    ripple: {
      height: "calc(100% + 30px)",
      margin: "-15px",
      width: "calc(100% + 30px)",
    },
    root: {
      background: theme.palette.grey[200],
      display: "flex",
      height: 96,
      outline: "none",
      overflow: "hidden",
      position: "relative",
      width: 96,
    },
  });

const DisplayIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.grey[500],
  height: "75%",
  width: "75%",
}));

const Link = styled(Button)({
  bottom: 4,
  pointerEvents: "none",
  position: "absolute",
  right: 4,
});

export class IconWithLink extends React.PureComponent<
  Props & WithStyles<typeof styles>
> {
  public render() {
    return (
      <ButtonBase
        className={this.props.classes.root}
        focusRipple={!this.props.disableFocusRipple}
        href={this.props.href}
        ref={(r) =>
          this.props.linkRef ? this.props.linkRef(r as HTMLElement) : null
        }
        style={
          this.props.height
            ? { height: this.props.height, width: this.props.width }
            : {}
        }
        tabIndex={this.props.tabIndex}
        target="_blank"
        TouchRippleProps={{ className: this.props.classes.ripple }}
      >
        <DisplayIcon fullHeight icon={this.props.icon} />
        <Link
          aria-label={Localization.getBuiltInMessage(
            "DocumentDownload.downloadButtonAlternateText",
            { alternateText: this.props.alternateText }
          )}
          customSize="tiny"
          icon="fas fa-download"
          tabIndex={-1}
        />
      </ButtonBase>
    );
  }
}

export default withStyles(styles)(IconWithLink);

import {
  Paper as MuiPaper,
  PaperProps as MuiPaperProps,
  Theme,
} from "@mui/material";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import * as React from "react";
import Logging from "../core/Logging";
import WithForwardedRef, {
  WithForwardedRefProps,
} from "../core/WithForwardedRef";

export interface PaperProps extends MuiPaperProps, WithForwardedRefProps {
  blended?: boolean;
  card?: boolean;
  cardDepth?: number;
  margin?: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    blended: {
      backgroundColor: "transparent",
    },
    card: {
      backgroundColor: theme.palette.grey[100],
    },
    cardAlternate: {
      backgroundColor: theme.palette.common.white,
    },
    margin: {
      [theme.breakpoints.only("xs")]: {
        padding: theme.paper.padding.xs,
      },
      [theme.breakpoints.only("sm")]: {
        padding: theme.paper.padding.sm,
      },
      [theme.breakpoints.only("md")]: {
        padding: theme.paper.padding.md,
      },
      [theme.breakpoints.up("lg")]: {
        padding: theme.paper.padding.lg,
      },
    },
  });

export class Paper extends React.PureComponent<
  PaperProps & WithStyles<typeof styles>
> {
  public render(): React.ReactNode {
    const {
      blended,
      card,
      cardDepth,
      children,
      classes,
      forwardedRef,
      margin,
      ...paperProps
    } = this.props;

    paperProps.className =
      `${classes.root} ` +
      `${paperProps.className ? paperProps.className : ""} `;

    if (blended) {
      paperProps.className += `${classes.blended} `;
    }

    if (card) {
      if (cardDepth === undefined) {
        Logging.log(this.props);
        throw new Error(`Card depth is required on ${name}`);
      }

      if (cardDepth % 2 === 0) {
        paperProps.className += `${classes.card} `;
      } else {
        paperProps.className += `${classes.cardAlternate} `;
      }
    }

    if (margin) {
      paperProps.className += `${classes.margin} `;
    } else {
      paperProps.elevation = 0;
    }

    return (
      <MuiPaper {...paperProps} ref={forwardedRef} style={{ flexGrow: 1 }}>
        {children}
      </MuiPaper>
    );
  }
}

export default withStyles(styles)(WithForwardedRef(Paper));

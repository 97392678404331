import AppServer, { State as AppServerState } from "../core/AppServer";
import RequestPromise from "../core/RequestPromise";
import PaneRow from "../models/PaneRow";
import { EmbeddedAddOn as EmbeddedAddOnBase } from "../mustangui/EmbeddedAddOn";
import ErrorsStore, { BusinessError } from "../stores/ErrorsStore";
import LayoutStateStore from "../stores/LayoutStateStore";
import PaneDataStore, { PaneDataByDataId } from "../stores/PaneDataStore";
import RequestsStore from "../stores/RequestsStore";
import BaseService, { Response } from "./BaseService";

export interface RoundTripResponse {
  appServerState: AppServerState;
  businessErrors: BusinessError[];
  layoutId?: string;
  newObjectId?: string;
  paneDataByDataId: PaneDataByDataId;
  url?: string;
  validationErrors: string[];
}

export default class RoundTripService {
  public static partialDataRetrevial<T>(
    url: string,
    urlParams?: object,
    postArgs?: object
  ): RequestPromise<T> {
    const args = {
      jsonData: JSON.stringify({
        appServerState: AppServer.getState(),
        dataChanges: PaneDataStore.getChanges(),
        ...postArgs,
      }),
    };

    let request: RequestPromise<Response>;

    return new RequestPromise<T>(
      (resolve, reject) => {
        request = BaseService.request(
          BaseService.getUrl(url, urlParams),
          args,
          "POST",
          false
        );
        request
          .then((response) => resolve(JSON.parse(response.responseText)))
          .catch((response) => reject(response));
      },
      () => {
        request.abort();
      }
    );
  }

  public static standardRoundTrip(
    url: string,
    props: {
      dataId?: string;
      name?: string;
    },
    jsonData?: object,
    disableMask: boolean = false
  ): Promise<RoundTripResponse> {
    if (!props.dataId || !props.name) {
      throw new Error("Invalid round trip properties");
    }

    if (!disableMask) {
      RequestsStore.instance.processingStarted();
    }

    EmbeddedAddOnBase.roundTripStarting();

    const paneRow = PaneRow.get(props.dataId)!;
    return BaseService.requestObject<RoundTripResponse>(
      `${url}/${paneRow.rowKey}/${props.dataId}/${props.name}`,
      null,
      null,
      {
        appServerState: AppServer.getState(),
        dataChanges: PaneDataStore.getChanges(),
        layoutState: LayoutStateStore.getCurrentState(),
        ...jsonData,
      },
      "POST"
    )
      .then((response: RoundTripResponse) => {
        if (response.validationErrors.length > 0) {
          ErrorsStore.clearErrors();
          ErrorsStore.showErrors(response.validationErrors);
          EmbeddedAddOnBase.rejectRoundTrip();

          return Promise.reject();
        }

        AppServer.setState(response.appServerState);

        if (response.businessErrors.length > 0) {
          ErrorsStore.clearBusinessErrors();
        }

        PaneDataStore.loadResponse(response.paneDataByDataId);
        if (ErrorsStore.setBusinessErrors(response.businessErrors, false)) {
          EmbeddedAddOnBase.rejectRoundTrip();

          return response;
        }

        EmbeddedAddOnBase.resolveRoundTrip();

        return response;
      })
      .finally(() => {
        if (!disableMask) {
          RequestsStore.instance.processingStopped();
        }
      });
  }
}

import { Fade, Theme } from "@mui/material";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import {
  action,
  autorun,
  IReactionDisposer,
  makeObservable,
  observable,
} from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import Collapse from "../coreui/Collapse";
import Paper from "../coreui/Paper";
import Presentation from "../coreui/Presentation";
import PaneRow, {
  RuntimeProperties as BaseRuntimeProperties,
  WidgetValue,
} from "../models/PaneRow";
import SubPaneControlStore from "../stores/SubPaneControlStore";
import { AccessLevel } from "./AccessLevel";
import ErrorBoundary from "./ErrorBoundary";

interface ConfigProperties {
  cardDepth: number;
  containerStyle: "Blended" | "Visual";
  controlKey: string | null;
  controlType: "Custom" | "Expansion" | "List" | "Static";
  dataId: string;
  dateEditWidgetsInfo: WidgetInfo[];
  name: string;
  propagated?: object;
  subPanes: SubPaneProps[];
}

interface WidgetInfo {
  dataId: string;
  name: string;
}

interface SubPaneProps {
  dataId: string;
  paneUse: object;
  paneKey: string;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  visiblePaneName: string | null;
}

interface State {
  currentPaneKey?: string;
  lastPaneKey?: string;
  paneChanged: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      "&:focus": {
        outline: "none",
      },
    },
  });

export class SubPaneControl extends React.Component<
  ConfigProperties & WithStyles<typeof styles>,
  State
> {
  private autorunDisposer: IReactionDisposer | null = null;
  private config: ConfigProperties;
  private controlKey: string | null = null;
  private dataId: string = "";
  private name: string = "";
  private rowKey?: string;

  public constructor(props: ConfigProperties & WithStyles<typeof styles>) {
    super(props);

    makeObservable<
      SubPaneControl,
      "controlKey" | "dataId" | "name" | "syncDerivedWithProps"
    >(this, {
      controlKey: observable,
      dataId: observable,
      name: observable,
      syncDerivedWithProps: action,
    });

    this.state = {
      paneChanged: false,
    };

    this.syncDerivedWithProps();
  }

  private clearDateEditErrors = (): void => {
    for (const info of this.props.dateEditWidgetsInfo) {
      const row = PaneRow.get(info.dataId);
      if (row === null) {
        continue;
      }

      const widget = row.getWidgetT<WidgetValue, BaseRuntimeProperties>(
        info.name
      )!;
      if (widget.properties.businessErrors.length === 0) {
        continue;
      }

      row.revertValue(info.name);
      widget.properties.businessErrors.length = 0;
    }
  };

  private renderFadePane = (subPane: SubPaneProps): React.ReactNode => {
    const currentPaneKey: string | undefined = this.state.currentPaneKey;
    const lastPaneKey: string | undefined = this.state.lastPaneKey;
    const isSubPaneVisible: boolean =
      subPane.paneKey === currentPaneKey ||
      (!currentPaneKey && subPane.paneKey === lastPaneKey);

    return (
      <Fade
        in={isSubPaneVisible}
        key={subPane.paneKey}
        style={{ display: isSubPaneVisible ? undefined : "none" }}
        timeout={
          this.state.lastPaneKey && subPane.paneKey === currentPaneKey ? 500 : 0
        }
      >
        <div>{this.renderPane(subPane.paneKey)}</div>
      </Fade>
    );
  };

  private renderPane(paneKey: string): React.ReactNode {
    const subPane: SubPaneProps | undefined = this.props.subPanes.find(
      (s) => s.paneKey === paneKey
    );

    if (!subPane) {
      throw new Error(`No sub pane found for ${paneKey}`);
    }

    return (
      <ErrorBoundary title={this.props.name}>
        <Paper
          blended={this.props.containerStyle === "Blended"}
          card={this.props.containerStyle === "Visual"}
          cardDepth={this.props.cardDepth}
          className={this.props.classes.paper}
          elevation={0}
          margin={this.props.containerStyle === "Visual"}
          style={{
            display:
              this.props.containerStyle === "Blended" ? "block" : "inherit",
            flexGrow: 1,
          }}
        >
          {Presentation.create(subPane.paneUse, this.props.propagated)}
        </Paper>
      </ErrorBoundary>
    );
  }

  private setCurrentPaneKey = (): void => {
    const row = PaneRow.get(this.dataId);
    if (!row) {
      return;
    }

    const widget = row.getWidgetT<null, RuntimeProperties>(this.name);

    let currentPaneKey: string | undefined = undefined;

    if (this.config.controlType === "Custom") {
      currentPaneKey = widget.properties.visiblePaneName
        ? widget.properties.visiblePaneName
        : undefined;

      this.setState(
        (prevState) => {
          return {
            currentPaneKey,
            lastPaneKey: prevState.currentPaneKey,
            paneChanged: currentPaneKey !== prevState.currentPaneKey,
          };
        },
        () => {
          this.setState({ paneChanged: false });
        }
      );

      return;
    }

    if (this.controlKey) {
      const rowKey: string = this.rowKey || "";

      currentPaneKey = SubPaneControlStore.getCurrentPaneKeyForControlKey(
        `${this.controlKey}_${rowKey}`
      );
    } else {
      throw new Error(
        `No controlling method found for Sub Pane Control ${this.name}`
      );
    }

    this.setState((prevState) => {
      return {
        currentPaneKey,
        lastPaneKey: prevState.currentPaneKey,
      };
    });
  };

  private syncDerivedWithProps(): void {
    this.controlKey = this.props.controlKey;
    this.dataId = this.props.dataId;
    this.name = this.props.name;
    this.config = { ...this.props };
    this.rowKey =
      this.props.propagated && (this.props.propagated["rowKey"] || undefined);
  }

  public componentDidMount(): void {
    this.autorunDisposer = autorun(this.setCurrentPaneKey);
  }

  public componentDidUpdate(
    prevProps: ConfigProperties,
    prevState: State
  ): void {
    this.syncDerivedWithProps();

    if (prevState.currentPaneKey !== this.state.currentPaneKey) {
      this.clearDateEditErrors();
    }
  }

  public componentWillUnmount(): void {
    if (this.autorunDisposer) {
      this.autorunDisposer();
    }
  }

  public render(): React.ReactNode {
    const row = PaneRow.get(this.dataId);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<null, RuntimeProperties>(this.name);

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    switch (this.props.controlType) {
      case "Custom":
        if (this.state.paneChanged) {
          return null;
        }

        return this.state.currentPaneKey
          ? this.renderPane(this.state.currentPaneKey)
          : null;

      case "Expansion":
      case "List":
        return (
          <Collapse in={this.state.currentPaneKey !== undefined}>
            {this.props.subPanes.map(this.renderFadePane)}
          </Collapse>
        );

      case "Static":
        return this.renderPane(this.props.subPanes[0].paneKey);

      default:
        throw new Error(`Unknown control type ${this.props.controlType}`);
    }
  }
}

export default withStyles(styles)(observer(SubPaneControl));

import { Badge, ButtonBase, styled, Theme, Tooltip } from "@mui/material";
import * as React from "react";
import FocusRipple from "./FocusRipple";
import Icon from "./Icon";

const BadgeButton = styled(ButtonBase)(({ theme }) => ({
  backgroundColor: theme.palette.info.main,
  borderRadius: "50%",
  color: theme.palette.common.white,
  fontSize: 9,
  height: 16,
  minWidth: 16,
  width: 16,
}));

// The badge gets rendered with a display of inline-flex, which causes the
// calculations to be off when calculating whether to show the tab scroll
// buttons. This happens sometimes in Chrome, but consistently in Firefox.
// Changing to display: flex fixes the issue.
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    height: 32,
    padding: 8,
  },
  cursor: "pointer",
  width: "100%",
  display: "flex",
}));

interface Props {
  classes?: { badge?: string; root?: string };
  children: React.ReactNode;
  isFocusRippleVisible: boolean;
  isHelpOpen: boolean;
  helpText: string;
  onHelpOpenChange: (isHelpOpen: boolean) => void;
}

export default function DisabledHelpBadge(props: Props): JSX.Element {
  const onBlur = (event: React.FocusEvent<HTMLElement>): void => {
    props.onHelpOpenChange(false);
  };

  const onClick = (event: React.MouseEvent<HTMLElement>): void => {
    props.onHelpOpenChange(!props.isHelpOpen);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
    if (event.key === "Escape") {
      props.onHelpOpenChange(false);
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <StyledBadge
      badgeContent={
        <Tooltip
          disableHoverListener
          enterTouchDelay={0}
          open={props.isHelpOpen}
          title={props.helpText}
        >
          <div>
            <BadgeButton onClick={onClick} tabIndex={-1}>
              <Icon icon="fas fa-question" style={{ height: 9 }} />
            </BadgeButton>
            <FocusRipple visible={props.isFocusRippleVisible} />
          </div>
        </Tooltip>
      }
      classes={{
        badge: props.classes?.badge,
        root: props.classes?.root,
      }}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      overlap="rectangular"
    >
      {props.children}
    </StyledBadge>
  );
}

import { observer } from "mobx-react";
import * as React from "react";
import { SearchChildProps } from "../config";
import ComboBoxOption from "../coreui/ComboBoxOption";
import Select from "../coreui/Select";
import PaneRow from "../models/PaneRow";
import ErrorsStore from "../stores/ErrorsStore";
import { AccessLevel } from "./AccessLevel";

interface ConfigProperties {
  dataId: string;
  helperText: string;
  label: string;
  mandatory: boolean;
  name: string;
  options: ComboBoxOption[];
  propagated: SearchChildProps;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
}

export class DomainComboBoxCriteria extends React.Component<ConfigProperties> {
  private onValueChange = (value: ComboBoxOption) => {
    ErrorsStore.clearBusinessErrorsForWidget(
      this.props.dataId,
      this.props.name
    );

    const row = PaneRow.get(this.props.dataId)!;
    const widget = row.getWidgetT<string | null, RuntimeProperties>(
      this.props.name
    );
    widget.setValue(value.value);
  };

  public render(): React.ReactNode {
    const row = PaneRow.get(this.props.dataId);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<string | null, RuntimeProperties>(
      this.props.name
    );

    if (widget.properties.accessLevel <= AccessLevel.readOnly) {
      return null;
    }

    return (
      <Select
        getErrors={() => widget.properties.businessErrors}
        helperText={this.props.helperText}
        label={this.props.label}
        onValueChange={this.onValueChange}
        options={this.props.options}
        showAsMandatory={this.props.mandatory}
        value={widget.value}
      />
    );
  }
}

export default observer(DomainComboBoxCriteria);

import moment from "moment";
import Sys from "./Sys";

export interface Language {
  code: string;
  description: string;
}

export default class Localization {
  // The date format as it is configured in Stage. This is used to display the
  // expected format to the user.
  private static configuredDateFormat: string;

  // The date format configured in Stage, but translated to follow the
  // specification required by moment, which is the library that we use to
  // handle the actual formatting of dates.
  private static momentDateFormat: string;

  private static monthAbbreviations: string[];
  private static months: string[];
  private static weekdaysShort: string[];

  public static readonly localeName: string = "current";

  /**
   * Returns the date format that is configured on the WebUI system. This is
   * used to display the expected format to the user.
   */
  public static get dateFormat() {
    return Localization.configuredDateFormat;
  }

  /**
   * Formats a date using the date format configured on the WebUI system.
   */
  public static formatDate(date: Date): string {
    return moment(date).format(Localization.momentDateFormat);
  }

  public static formatMonthTitle(date: Date): string {
    return `${Localization.months[date.getMonth()]} ${date.getFullYear()}`;
  }

  public static formatWeekdayShort(weekDay: number, locale: string): string {
    return `${Localization.weekdaysShort[weekDay]}`;
  }

  public static initializeDateFormatter(
    dateFormat: string,
    days: string[],
    dayAbbreviations: string[],
    months: string[],
    monthAbbreviations: string[]
  ): void {
    moment.defineLocale(Localization.localeName, {
      months,
      monthsShort: monthAbbreviations,
      weekdays: days,
      weekdaysMin: dayAbbreviations,
      weekdaysShort: dayAbbreviations,
    });

    Localization.configuredDateFormat = dateFormat;
    Localization.months = months;
    Localization.monthAbbreviations = monthAbbreviations;
    Localization.weekdaysShort = dayAbbreviations;

    // Set case of date formatter to match moment format
    // https://momentjs.com/docs/#/parsing/string-format/
    Localization.momentDateFormat = dateFormat
      .replace(/d/gi, "D")
      .replace(/m/gi, "M")
      .replace(/y/gi, "Y");
  }

  public static initializeLanguage(
    currentLanguageCode: string,
    availableLanguages: Language[],
    // FUTURE
    // Give builtInMessages a more strictly defined type
    builtInMessages: object
  ): void {
    // FUTURE
    // Replace these settings with something handled natively by the
    // Localization module. This will likely require storing the available
    // languages and current language on the Localization module with
    // properties to expose them to the Language Select and Captcha Control
    // widgets. The builtInMessages translations will become completely owned
    // by the localization module once GetTranslation() is moved from Sys
    // to Localization.
    Sys.settings.availableLanguages = availableLanguages;
    Sys.settings.currentLanguageCode = currentLanguageCode;
    Sys.settings.builtInMessages = builtInMessages;
  }

  public static initializeNumberFormatter(
    decimalSeparator: string,
    thousandsSeparator: string
  ): void {
    // FUTURE
    // Replace these settings with something handled natively by the
    // Localization module. This will require moving the "parse number"
    // and "format number" methods out of the Numeric Edit widget and
    // into the localization module in an implementation that parallels
    // the date formatter
    Sys.settings.decimalSeparator = decimalSeparator;
    Sys.settings.thousandsSeparator = thousandsSeparator;
  }

  /**
   * Returns the builtInMessageTranslation for a WebUI system.
   * Returns baseLanguaga for system "English" if no translation is found.
   */
  public static getBuiltInMessage(code: string | null, args?: object): string {
    let result: string = code || "";

    if (code) {
      if (Sys.settings.builtInMessages[code]) {
        result = Sys.settings.builtInMessages[code];
      }
    }

    if (args) {
      const regx = /\{\w+\}/gi;
      const matches = result.match(regx);

      if (matches) {
        matches.forEach((match) => {
          result = result.replace(
            match,
            args[match.substr(1, match.length - 2)]
          );
        });
      }
    }

    return result;
  }

  /**
   * Parses a date using the date format configured on the WebUI system.
   * Returns null if a date could not be parsed from the string.
   */
  public static parseDate(dateString: string): Date | null {
    // Copied from posse\Outrider\Resources\posseglobal.js
    // and updated to use the month names from the current language
    let aDateVal = dateString;
    let aFormat = Localization.momentDateFormat;

    /* eslint-disable */
    let c,
      f,
      d = 0,
      m = 0,
      y = 0,
      i = 0,
      l;
    let p, pts, parts: string[];
    let prevGood = false;
    let newString = "";
    const badChars = "., -/\\";
    l = aDateVal.length;
    let str;
    while (i < l) {
      str = aDateVal.substr(i, 1);
      if (badChars.indexOf(str) > -1) {
        if (prevGood) {
          newString += "/";
          prevGood = false;
        }
      } else {
        prevGood = true;
        newString += str;
      }
      i++;
    }
    while (newString.substr(newString.length - 1) === "/") {
      newString = newString.substr(0, newString.length - 1);
    }
    aDateVal = newString;

    const months = Localization.months.map((month) => month.toUpperCase());
    const monthAbbreviations = Localization.monthAbbreviations.map((a) =>
      a.toUpperCase()
    );

    parts = aDateVal.split("/");
    for (i = 0; i < parts.length; i++) {
      if (m === 0) {
        const search = parts[i].toUpperCase();
        let pos = monthAbbreviations.findIndex((a) => a === search);
        if (pos < 0) {
          pos = months.findIndex((month) => month.startsWith(search));
        }
        if (pos >= 0) {
          m = pos + 1;
        }
      }
    }

    i = 0;
    pts = [];
    for (p = 0; p < parts.length; p++) {
      const part = parseInt(parts[p], 0);
      if (!isNaN(Number(part))) {
        if (part === 0) {
          // User explicitly set a year of "00" with no century.
          y = 2000;
        } else if (part > 31) {
          y = part;
        } else {
          pts[i] = part;
          i++;
        }
      }
    }
    aFormat = aFormat.toUpperCase();

    p = 0;
    f = 0;
    c = "";
    if (pts.length === 1 || (m > 0 && y > 0)) {
      d = pts[p];
    } else {
      while (f < aFormat.length) {
        c = aFormat.charAt(f);
        if (p >= pts.length) {
          break;
        }
        if (c === "Y" && y === 0) {
          y = pts[p];
          p++;
        } else if (c === "M" && m === 0) {
          m = pts[p];
          p++;
        } else if (c === "D" && d === 0) {
          d = pts[p];
          p++;
        }
        f++;
      }
    }

    const today = new Date();
    if (y === 0) {
      y = today.getFullYear();
    } else if (y < 1000) {
      y = 2000 + y * 1;
    } else if (y > 3999) {
      return null;
    }
    if (m === 0) {
      m = today.getMonth() + 1;
    }

    let rDate: Date | null = new Date(y, m - 1, d);
    if (
      rDate.getFullYear() !== y ||
      rDate.getMonth() !== m - 1 ||
      rDate.getDate() !== d
    ) {
      rDate = null;
    }
    /* eslint-enable */

    return rDate;
  }
}

import * as React from "react";
import ActionButton from "../mustangui/ActionButton";
import AddressSearch from "../mustangui/AddressSearch";
import AddressSearchCriteria from "../mustangui/AddressSearchCriteria";
import AdvancedSearch from "../mustangui/AdvancedSearch";
import BooleanCriteria from "../mustangui/BooleanCriteria";
import { BrandingImageDisplay } from "../mustangui/BrandingImageDisplay";
import CaptchaControl from "../mustangui/CaptchaControl";
import Checkbox from "../mustangui/Checkbox";
import { CheckBoxGroup } from "../mustangui/CheckBoxGroup";
import ComplexGridControl from "../mustangui/ComplexGridControl";
import ComplexResultsGridControl from "../mustangui/ComplexResultsGridControl";
import ComponentTypeDisplay from "../mustangui/ComponentTypeDisplay";
import DashboardCriteria from "../mustangui/DashboardCriteria";
import DashboardGridControl from "../mustangui/DashboardGridControl";
import { DataImageDisplay } from "../mustangui/DataImageDisplay";
import DataLink from "../mustangui/DataLink";
import DateEdit from "../mustangui/DateEdit";
import DateRangeCriteria from "../mustangui/DateRangeCriteria";
import { DividerDisplay } from "../mustangui/DividerDisplay";
import DocumentEdit from "../mustangui/DocumentEdit";
import DomainCheckBoxCriteria from "../mustangui/DomainCheckBoxCriteria";
import DomainComboBox from "../mustangui/DomainComboBox";
import DomainComboBoxCriteria from "../mustangui/DomainComboBoxCriteria";
import EmbeddedAddOn from "../mustangui/EmbeddedAddOn";
import Freeflow from "../mustangui/Freeflow";
import Grid from "../mustangui/Grid";
import GridFilter from "../mustangui/GridFilter";
import GridItem from "../mustangui/GridItem";
import GridPager from "../mustangui/GridPager";
import GridPrint from "../mustangui/GridPrint";
import GridRelatedEditButton from "../mustangui/GridRelatedEditButton";
import GridSelectedCount from "../mustangui/GridSelectedCount";
import GroupHeading from "../mustangui/GroupHeading";
import { IconDisplay } from "../mustangui/IconDisplay";
import IconDisplayVerticalLayout from "../mustangui/IconDisplayVerticalLayout";
import LanguageSelect from "../mustangui/LanguageSelect";
import ManualLink from "../mustangui/ManualLink";
import { Media } from "../mustangui/Media";
import MenuButton from "../mustangui/MenuButton";
import MenuItem from "../mustangui/MenuItem";
import MLTextEdit from "../mustangui/MLTextEdit";
import NumericEdit from "../mustangui/NumericEdit";
import PresentationButton from "../mustangui/PresentationButton";
import ProjectGrid from "../mustangui/ProjectGrid";
import ProjectGridContainer from "../mustangui/ProjectGridContainer";
import ProjectGridControl from "../mustangui/ProjectGridControl";
import RadioSelect from "../mustangui/RadioSelect";
import RelationshipComboBox from "../mustangui/RelationshipComboBox";
import { ResponsiveGrid } from "../mustangui/ResponsiveGrid";
import { ResultsCountDisplay } from "../mustangui/ResultsCountDisplay";
import SelectControl from "../mustangui/SelectControl";
import SelectControlSelectedGrid from "../mustangui/SelectControlSelectedGrid";
import SelectDialogResultsGrid from "../mustangui/SelectDialogResultsGrid";
import SimpleGridControl from "../mustangui/SimpleGridControl";
import SimpleResultsGridControl from "../mustangui/SimpleResultsGridControl";
import SiteCriteria from "../mustangui/SiteCriteria";
import { SiteCriteriaLink } from "../mustangui/SiteCriteriaLink";
import SLTextEdit from "../mustangui/SLTextEdit";
import SubPaneControl from "../mustangui/SubPaneControl";
import TabControl from "../mustangui/TabControl";
import TableSummary from "../mustangui/TableSummary";
import TableSummarySection from "../mustangui/TableSummarySection";
import TextCriteria from "../mustangui/TextCriteria";
import { TextDisplay } from "../mustangui/TextDisplay";
import ThumbnailDisplay from "../mustangui/ThumbnailDisplay";
import Toolbar from "../mustangui/Toolbar";
import ToolbarContainerGroup from "../mustangui/ToolbarContainerGroup";
import ToolbarContainerItem from "../mustangui/ToolbarContainerItem";
import ToolbarContainerOverflowSection from "../mustangui/ToolbarContainerOverflowSection";
import ToolbarContainerSection from "../mustangui/ToolbarContainerSection";
import WizardControl from "../mustangui/WizardControl";
import WizardStepNumberDisplay from "../mustangui/WizardStepNumberDisplay";
import { WizardStepsDisplay } from "../mustangui/WizardStepsDisplay";

type CreatedReactElement = React.DetailedReactHTMLElement<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export default class Presentation {
  private static canvas: HTMLCanvasElement | null = null;
  public static currentComponent: React.Component | undefined;
  public static currentPresentationId: number | undefined;
  public static objectDefDescription: string | null = null;
  public static objectTitle: string | null = null;

  public static create(
    config: object | undefined,
    propagatedProps: {} | null = null
  ): CreatedReactElement | null {
    if (!config) {
      return null;
    }

    let props: object | undefined = undefined;
    if ("props" in config) {
      props = { ...(config["props"] as object) };
      if (propagatedProps) {
        props!["propagated"] = propagatedProps;
      }
    } else if (propagatedProps) {
      props = { propagated: propagatedProps };
    }

    let result: CreatedReactElement | null = null;
    if ("children" in config) {
      // A list of child configurations or components.
      if (config["children"] instanceof Array) {
        const children: (CreatedReactElement | string | null)[] = [];

        (config["children"] as object[]).forEach((child) => {
          if (typeof child === "string") {
            children.push(child);
          } else {
            children.push(Presentation.create(child, propagatedProps));
          }
        });

        result = React.createElement(
          Presentation.getComponent(config["type"]),
          props,
          ...children
        );
      }
      // A single child configuration.
      else if (config["children"] instanceof Object) {
        const child = Presentation.create(config["children"], propagatedProps);

        result = React.createElement(
          Presentation.getComponent(config["type"]),
          props,
          child
        );
      }
      // A literal child such as text for a button.
      else {
        result = React.createElement(
          Presentation.getComponent(config["type"]),
          props,
          config["children"]
        );
      }
    }
    // Configuration without children.
    else {
      result = React.createElement(
        Presentation.getComponent(config["type"]),
        props
      );
    }

    return result;
  }

  // Return the react component identified by the specified type.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static getComponent(type: string | React.ReactNode): any {
    if (typeof type !== "string") {
      return type;
    }

    let result: string | React.ReactNode = null;

    switch (type) {
      case "ActionButton":
        result = ActionButton;
        break;
      case "AddressSearch":
        result = AddressSearch;
        break;
      case "AddressSearchCriteria":
        result = AddressSearchCriteria;
        break;
      case "AdvancedSearch":
        result = AdvancedSearch;
        break;
      case "BooleanCriteria":
        result = BooleanCriteria;
        break;
      case "BrandingImageDisplay":
        result = BrandingImageDisplay;
        break;
      case "CaptchaControl":
        result = CaptchaControl;
        break;
      case "CheckBox":
        result = Checkbox;
        break;
      case "CheckBoxGroup":
        result = CheckBoxGroup;
        break;
      case "ComplexGridControl":
        result = ComplexGridControl;
        break;
      case "ComplexResultsGridControl":
        result = ComplexResultsGridControl;
        break;
      case "ComponentTypeDisplay":
        result = ComponentTypeDisplay;
        break;
      case "DashboardCriteria":
        result = DashboardCriteria;
        break;
      case "DashboardGridControl":
        result = DashboardGridControl;
        break;
      case "DataImageDisplay":
        result = DataImageDisplay;
        break;
      case "DataLink":
        result = DataLink;
        break;
      case "DateEdit":
        result = DateEdit;
        break;
      case "DateRangeCriteria":
        result = DateRangeCriteria;
        break;
      case "div":
        result = "div";
        break;
      case "DividerDisplay":
        result = DividerDisplay;
        break;
      case "DocumentEdit":
        result = DocumentEdit;
        break;
      case "DomainCheckBoxCriteria":
        result = DomainCheckBoxCriteria;
        break;
      case "DomainComboBox":
        result = DomainComboBox;
        break;
      case "DomainComboBoxCriteria":
        result = DomainComboBoxCriteria;
        break;
      case "EmbeddedAddOn":
        result = EmbeddedAddOn;
        break;
      case "Freeflow":
        result = Freeflow;
        break;
      case "Grid":
        result = Grid;
        break;
      case "GridFilter":
        result = GridFilter;
        break;
      case "GridItem":
        result = GridItem;
        break;
      case "GridPager":
        result = GridPager;
        break;
      case "GridPrint":
        result = GridPrint;
        break;
      case "GridRelatedEditButton":
        result = GridRelatedEditButton;
        break;
      case "GridSelectedCount":
        result = GridSelectedCount;
        break;
      case "GroupHeading":
        result = GroupHeading;
        break;
      case "IconDisplay":
        result = IconDisplay;
        break;
      case "IconDisplayVerticalLayout":
        result = IconDisplayVerticalLayout;
        break;
      case "LanguageSelect":
        result = LanguageSelect;
        break;
      case "ManualLink":
        result = ManualLink;
        break;
      case "Media":
        result = Media;
        break;
      case "MenuButton":
        result = MenuButton;
        break;
      case "MenuItem":
        result = MenuItem;
        break;
      case "MLTextEdit":
        result = MLTextEdit;
        break;
      case "NumericEdit":
        result = NumericEdit;
        break;
      case "PresentationButton":
        result = PresentationButton;
        break;
      case "ProjectGrid":
        result = ProjectGrid;
        break;
      case "ProjectGridContainer":
        result = ProjectGridContainer;
        break;
      case "ProjectGridControl":
        result = ProjectGridControl;
        break;
      case "RadioSelect":
        result = RadioSelect;
        break;
      case "RelationshipComboBox":
        result = RelationshipComboBox;
        break;
      case "ResponsiveGrid":
        result = ResponsiveGrid;
        break;
      case "ResultsCountDisplay":
        result = ResultsCountDisplay;
        break;
      case "SelectDialogResultsGrid":
        result = SelectDialogResultsGrid;
        break;
      case "SelectControl":
        result = SelectControl;
        break;
      case "SelectControlSelectedGrid":
        result = SelectControlSelectedGrid;
        break;
      case "SimpleGridControl":
        result = SimpleGridControl;
        break;
      case "SimpleResultsGridControl":
        result = SimpleResultsGridControl;
        break;
      case "SiteCriteria":
        result = SiteCriteria;
        break;
      case "SiteCriteriaLink":
        result = SiteCriteriaLink;
        break;
      case "SLTextEdit":
        result = SLTextEdit;
        break;
      case "SubPaneControl":
        result = SubPaneControl;
        break;
      case "TableSummarySection":
        result = TableSummarySection;
        break;
      case "TabControl":
        result = TabControl;
        break;
      case "TableSummary":
        result = TableSummary;
        break;
      case "TextCriteria":
        result = TextCriteria;
        break;
      case "TextDisplay":
        result = TextDisplay;
        break;
      case "ThumbnailDisplay":
        result = ThumbnailDisplay;
        break;
      case "Toolbar":
        result = Toolbar;
        break;
      case "ToolbarContainerGroup":
        result = ToolbarContainerGroup;
        break;
      case "ToolbarContainerItem":
        result = ToolbarContainerItem;
        break;
      case "ToolbarContainerOverflowSection":
        result = ToolbarContainerOverflowSection;
        break;
      case "ToolbarContainerSection":
        result = ToolbarContainerSection;
        break;
      case "WizardControl":
        result = WizardControl;
        break;
      case "WizardStepNumberDisplay":
        result = WizardStepNumberDisplay;
        break;
      case "WizardStepsDisplay":
        result = WizardStepsDisplay;
        break;
      default:
        throw new Error(`Unknown widget type ${type}`);
    }

    return result;
  }

  public static measureText(
    text: string,
    font: string = "14px roboto"
  ): number {
    let result: number = 0;

    if (text) {
      Presentation.canvas =
        Presentation.canvas || document.createElement("canvas");

      const context = Presentation.canvas.getContext("2d");

      if (context) {
        context.font = font;

        const textMetrics = context.measureText(text);

        result = textMetrics.width;
      }
    }

    return result;
  }
}

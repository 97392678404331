import AppServer, { State as AppServerState } from "../core/AppServer";
import { WidgetData } from "../models/PaneRow";
import { EmbeddedAddOn as EmbeddedAddOnBase } from "../mustangui/EmbeddedAddOn";
import ErrorsStore, { BusinessError } from "../stores/ErrorsStore";
import LayoutStateStore from "../stores/LayoutStateStore";
import PaneDataStore, { PaneDataByDataId } from "../stores/PaneDataStore";
import RequestsStore from "../stores/RequestsStore";
import BaseService from "./BaseService";

export interface CompleteDocumentUploadResponse {
  appServerState: AppServerState;
  paneDataByDataId: PaneDataByDataId;
  uploadErrors: string[];
  widgetData: WidgetData | null;
}

interface DeleteDocumentResponse {
  appServerState: AppServerState;
  businessErrors: BusinessError[];
  paneDataByDataId: PaneDataByDataId;
  validationErrors: string[];
  widgetData: WidgetData | null;
}

export default class DocumentEditService {
  public static async completeDocumentUpload(
    rowKey: string,
    dataId: string,
    widgetName: string,
    dialogRowKey: string | null | undefined,
    file: File,
    pendingDocumentId: number,
    pendingThumbnailId: number
  ): Promise<CompleteDocumentUploadResponse> {
    return BaseService.requestObject<CompleteDocumentUploadResponse>(
      `DocumentEdit/CompleteDocumentUpload/${rowKey}/${dataId}/${widgetName}`,
      null,
      null,
      {
        appServerState: AppServer.getState(),
        dataChanges: PaneDataStore.getChanges(),
        dialogRowKey,
        file: { fileName: file.name, size: file.size },
        layoutState: LayoutStateStore.getCurrentState(),
        pendingDocumentId,
        pendingThumbnailId,
      },
      "POST"
    );
  }

  public static async deleteDocument(
    rowKey: string,
    dataId: string,
    widgetName: string,
    dialogRowKey: string | null | undefined
  ): Promise<void> {
    RequestsStore.instance.processingStarted();
    EmbeddedAddOnBase.roundTripStarting();
    ErrorsStore.clearErrors();

    return BaseService.requestObject<DeleteDocumentResponse>(
      `DocumentEdit/DeleteDocument/${rowKey}/${dataId}/${widgetName}`,
      null,
      null,
      {
        appServerState: AppServer.getState(),
        dataChanges: PaneDataStore.getChanges(),
        dialogRowKey,
        layoutState: LayoutStateStore.getCurrentState(),
      },
      "POST"
    )
      .then((response: DeleteDocumentResponse) => {
        if (response.validationErrors.length > 0) {
          ErrorsStore.showErrors(response.validationErrors);
          EmbeddedAddOnBase.rejectRoundTrip();

          return Promise.reject();
        }

        if (response.businessErrors.length > 0) {
          ErrorsStore.clearBusinessErrors();
          ErrorsStore.setBusinessErrors(response.businessErrors, false);
          ErrorsStore.pushErrorsToWidgets();
          EmbeddedAddOnBase.rejectRoundTrip();

          return Promise.reject();
        }

        AppServer.setState(response.appServerState);
        PaneDataStore.loadResponse(response.paneDataByDataId);
        if (response.widgetData) {
          PaneDataStore.loadWidgetData(dataId, response.widgetData);
        }

        EmbeddedAddOnBase.resolveRoundTrip();
        return Promise.resolve();
      })
      .finally(() => {
        RequestsStore.instance.processingStopped();
      });
  }
}

import { FormControl, Theme } from "@mui/material";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import { observer } from "mobx-react";
import * as React from "react";
import Typography from "./Typography";

interface Props {
  children?: React.ReactNode;
  label: string;
}

const styles = (theme: Theme) =>
  createStyles({
    label: {
      color: theme.palette.grey[700],
      marginBottom: 8,
    },
    root: {
      "&:hover": { backgroundColor: "transparent" },
      backgroundColor: "transparent",
    },
  });

export class FormData extends React.Component<
  Props & WithStyles<typeof styles>
> {
  public render() {
    return (
      <FormControl className={this.props.classes.root} fullWidth>
        <Typography
          variant="caption"
          ellipsis
          className={this.props.classes.label}
        >
          {this.props.label}
        </Typography>
        <Typography component="div" style={{ minHeight: 20 }}>
          {this.props.children}
        </Typography>
      </FormControl>
    );
  }
}

export default withStyles(styles)(observer(FormData));

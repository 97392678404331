import { Breakpoint, styled } from "@mui/material";
import { getPanelMeasurements, PanelMeasurements } from "../../theme";

interface Props {
  content?: true;
  paperWidth?: { [key in Breakpoint]: 3 | 4 | 6 | 8 | null };
  paperColor?: "default" | "alt";
}

export const Container = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "content" && prop !== "paperWidth" && prop !== "paperColor",
})<Props>(
  { marginLeft: "auto", marginRight: "auto" },
  ({ theme, content }) =>
    content
      ? theme.breakpoints.keys.reduce((result, breakpoint) => {
          result[theme.breakpoints.only(breakpoint)] = {
            marginBottom: theme.container.verticalSpacing[breakpoint],
            marginTop: theme.container.verticalSpacing[breakpoint],
          };
          return result;
        }, {})
      : null,
  ({ theme, paperWidth, paperColor }) =>
    theme.breakpoints.keys.reduce((result, breakpoint) => {
      const columns = paperWidth ? paperWidth[breakpoint] : null;
      if (columns === null) {
        const width = theme.container.maxWidth[breakpoint];
        if (width !== null) {
          result[theme.breakpoints.only(breakpoint)] = { width };
        } else {
          result[theme.breakpoints.only(breakpoint)] = {
            marginLeft: theme.horizontalSpacing.related[breakpoint],
            marginRight: theme.horizontalSpacing.related[breakpoint],
          };
        }
      } else {
        const measurements: PanelMeasurements = getPanelMeasurements(
          theme,
          breakpoint
        );
        const gutters = columns - 1;
        const gutterWidth = gutters * measurements.gutterWidth;
        const columnWidth = columns * measurements.columnWidth;
        const width: number = columnWidth + gutterWidth;

        result[theme.breakpoints.only(breakpoint)] = {
          backgroundColor:
            paperColor === "alt"
              ? theme.palette.common.white
              : theme.paper.color,
          padding: theme.paper.padding[breakpoint],
          width,
        };
      }
      return result;
    }, {}),
  ({ theme, paperWidth }) =>
    paperWidth && paperWidth.xs !== null
      ? {
          [theme.breakpoints.only("xs")]: {
            marginLeft: theme.horizontalSpacing.related.xs,
            marginRight: theme.horizontalSpacing.related.xs,
          },
        }
      : null
);

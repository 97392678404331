import { IconButton, styled } from "@mui/material";
import * as React from "react";
import Localization from "../core/Localization";
import Icon from "./Icon";
import TextField from "./TextField";

interface Props {
  autoComplete: string;
  autoFocus?: boolean;
  getErrors?: (value: string) => string[];
  helperText?: string;
  label?: string;
  name?: string;
  onChange: (value: string) => void;
  required?: boolean;
}

interface State {
  showValue: boolean;
  value: string;
}

interface ShowPasswordButtonProps {
  className?: string;
  onToggle: () => void;
  showValue: boolean;
}

const ShowPasswordButton = styled(
  (props: ShowPasswordButtonProps): JSX.Element => {
    return (
      <IconButton
        aria-label={Localization.getBuiltInMessage("passwordVis")}
        className={props.className}
        color="inherit"
        onClick={props.onToggle}
      >
        <Icon icon={props.showValue ? "fas fa-eye-slash" : "fas fa-eye"} />
      </IconButton>
    );
  }
)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 12,
  height: 24,
  width: 24,
}));

export class PasswordField extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = { showValue: false, value: "" };
  }

  private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ value: event.target.value });
    this.props.onChange(event.target.value);
  };

  private onToggleShowValue = () => {
    this.setState((prevState: State) => {
      return { showValue: !prevState.showValue };
    });
  };

  public render(): React.ReactNode {
    return (
      <TextField
        autoComplete={this.props.autoComplete}
        endAdornment={
          <ShowPasswordButton
            onToggle={this.onToggleShowValue}
            showValue={this.state.showValue}
          />
        }
        getErrors={this.props.getErrors}
        helperText={this.props.helperText}
        icon="fas fa-lock"
        inputProps={{ spellCheck: false }}
        label={this.props.label}
        name={this.props.name}
        onChange={this.onChange}
        required={this.props.required}
        type={this.state.showValue ? "text" : "password"}
        value={this.state.value}
        variant="filled"
      />
    );
  }
}

export default PasswordField;

import { Breakpoint, Theme } from "@mui/material";

export interface PanelMeasurements {
  columnWidth: number;
  gutterWidth: number;
}

export function getPanelMeasurements(
  theme: Theme,
  breakPoint: Breakpoint
): PanelMeasurements {
  const maxColumns: number = theme.panel.maxColumns[breakPoint];
  const maxWidth: number = theme.panel.maxWidths[breakPoint];
  const gutterWidth: number = theme.horizontalSpacing.related[breakPoint];
  const columnWidth: number =
    (maxWidth - gutterWidth * (maxColumns - 1)) / maxColumns;

  return { columnWidth, gutterWidth };
}

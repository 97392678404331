import { styled } from "@mui/material";
import * as React from "react";
import Icon from "./Icon";

interface Props {
  message: string;
  style?: object;
}

const ErrorIcon = styled(Icon)({
  display: "block",
  float: "left",
  overflow: "visible",
});

const MessageContainer = styled("div")({
  marginLeft: "1.4em",
});

export const ErrorMessage = (props: Props): JSX.Element => {
  return (
    <div style={props.style}>
      <ErrorIcon fixedWidth icon="far fa-octagon-exclamation" />
      <MessageContainer>{props.message}</MessageContainer>
    </div>
  );
};

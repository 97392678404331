import { Badge, BadgeProps, styled } from "@mui/material";
import * as React from "react";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.info.main,
    borderStyle: "solid",
    borderWidth: 1,
    color: theme.palette.info.main,
    fontSize: 11,
    height: 16,
    minWidth: 16,
    padding: 0,
    width: 16,
  },
}));

export function ContentsExistBadge(props: BadgeProps): JSX.Element {
  const { max, overlap, ...otherProps } = props;

  return (
    <StyledBadge
      max={max || 9}
      overlap={overlap || "rectangular"}
      {...otherProps}
    />
  );
}

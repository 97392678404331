import { Theme } from "@mui/material";
import {
  createStyles,
  CSSProperties,
  WithStyles,
  withStyles,
  WithTheme,
  withTheme,
} from "@mui/styles";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../core/Localization";
import Presentation from "../coreui/Presentation";
import { TableChildProps } from "../coreui/Table";

interface Props {
  childLayoutWidgetNames: string[];
  childWidgetNames: string[];
  dataId: string;
  endChild: object;
  lg: boolean;
  md: boolean;
  propagated: TableChildProps;
  sm: boolean;
  startChild: object;
  xl: boolean;
  xs: boolean;
}

interface State {
  isFocused?: boolean;
}

export interface ToolbarChildProps {
  hasParentToolbar: boolean;
}

const styles = (theme: Theme) => {
  const containerSpaceStyle = {};
  for (const breakPoint of theme.spacingBreakPoints) {
    const sectionSpacing = theme.freeflow.section.spacing[breakPoint];

    containerSpaceStyle[theme.breakpoints.up(breakPoint)] = {
      marginBottom: -sectionSpacing.vertical * 0.5,
      marginLeft: -sectionSpacing.horizontal * 0.5,
      marginRight: -sectionSpacing.horizontal * 0.5,
      marginTop: -sectionSpacing.vertical * 0.5,
    };
  }

  const visibilityStyles = {};
  for (const breakPoint of theme.visibilityBreakPoints) {
    visibilityStyles[`toolbar-${breakPoint}`] = {
      [theme.breakpoints.only(breakPoint)]: {
        display: "flex",
      },
    };
  }

  const stylesToCreate = {
    container: {
      ...containerSpaceStyle,
      alignItems: "center",
      display: "flex",
      flex: "1 1 auto",
      minWidth: 0,
    } as CSSProperties,
    summary: {
      "&:focus-visible": {
        outline: "2px solid",
        outlineColor: theme.palette.grey[800],
        outlineOffset: "-2px",
      },
      alignItems: "flex-start",
      borderBottomColor: theme.palette.grey[300],
      borderBottomStyle: "solid",
      borderBottomWidth: 1,
      borderTopStyle: "solid",
      borderTopWidth: 0,
      display: "none",
      flexWrap: "nowrap",
      justifyContent: "space-between",
      "min-height": 38,
      outline: "none",
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 8,
    } as CSSProperties,
    ...visibilityStyles,
  };

  return createStyles(stylesToCreate);
};

export class TableSummarySection extends React.Component<
  Props & WithStyles<typeof styles> & WithTheme,
  State
> {
  public constructor(props: Props & WithStyles<typeof styles> & WithTheme) {
    super(props);

    this.state = { isFocused: false };
  }

  private shouldRender(): boolean {
    return this.props.childWidgetNames.length > 0;
  }

  public render(): React.ReactNode {
    if (!this.shouldRender()) {
      return null;
    }

    const classes: string[] = [this.props.classes.summary];
    const theme = this.props.theme as Theme;

    for (const breakPoint of theme.visibilityBreakPoints) {
      if (this.props[breakPoint]) {
        classes.push(this.props.classes[`toolbar-${breakPoint}`]);
      }
    }

    const propagated: ToolbarChildProps & TableChildProps = {
      ...this.props.propagated,
      hasParentToolbar: true,
    };

    const ariaLabel: string = Localization.getBuiltInMessage(
      "DataTable.summarySectionLabel",
      {
        description: this.props.propagated.parentTable.description,
      }
    );

    return (
      <div
        aria-label={ariaLabel}
        tabIndex={0}
        className={classes.join(" ")}
        role="group"
      >
        {this.props.startChild ? (
          <div className={this.props.classes.container}>
            {Presentation.create(this.props.startChild, propagated)}
          </div>
        ) : null}
        {this.props.endChild ? (
          <div className={this.props.classes.container}>
            {this.props.endChild
              ? Presentation.create(this.props.endChild, propagated)
              : null}
          </div>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(withTheme(observer(TableSummarySection)));

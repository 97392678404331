import { Checkbox, CheckboxProps, Theme } from "@mui/material";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../../core/Localization";
import Sys from "../../core/Sys";
import { ErrorBadge } from "../../coreui/ErrorBadge";
import Icon from "../../coreui/Icon";
import { TableChildProps } from "../../coreui/Table";
import {
  CellFocusUtil,
  FocusCellRendererParams,
} from "../../coreui/table/CellFocusUtil";
import { CellUtil } from "../../coreui/table/CellUtil";
import Typography from "../../coreui/Typography";
import PaneRow, { RuntimeWidget } from "../../models/PaneRow";
import ErrorsStore from "../../stores/ErrorsStore";
import { AccessLevel } from "../AccessLevel";
import { FunctionName } from "../TableSummary";
import {
  GridColumnConfigProperties,
  RenderInlineProperties,
} from "./GridColumn";

interface ConfigProperties extends FocusCellRendererParams<boolean> {
  dataId: string;
  name: string;
  propagated: TableChildProps;
}

interface State {
  isErrorBadgeOpen?: boolean;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      alignItems: "center",
      display: "flex",
      height: "100%",
      justifyContent: "center",
      marginLeft: -2,
      padding: "0 24px",
    },
  });

export class CheckBoxColumn extends React.Component<
  ConfigProperties & WithStyles<typeof styles>,
  State
> {
  public static readonly widgetType: string = "CheckBoxColumn";

  private readonly componentId: string;
  private readonly errorMessageId: string;

  public static getFilterText(
    column: GridColumnConfigProperties,
    propagated: TableChildProps,
    row: PaneRow
  ): string {
    return "";
  }

  public static getSummaryValue(
    runtimeData: RuntimeWidget[],
    configProperties: ConfigProperties,
    functionName: FunctionName
  ): string | null {
    return runtimeData.filter((d) => d.value).length.toString();
  }

  public static renderInline(
    props: RenderInlineProperties
  ): JSX.Element | null {
    const widget = props.row.getWidgetT<boolean, RuntimeProperties>(
      props.column.name
    );

    if (!widget.value) {
      return null;
    }

    return (
      <Typography className={props.className} variant="body1">
        {props.column.header}
      </Typography>
    );
  }

  public constructor(props: ConfigProperties & WithStyles<typeof styles>) {
    super(props);

    this.state = { isErrorBadgeOpen: false };

    CellUtil.runOnAction(props.eGridCell, () => {
      const row = props.data!;
      const widget = row.getWidgetT<boolean, RuntimeProperties>(props.name);

      ErrorsStore.clearBusinessErrorsForTableCell(
        this.props.dataId,
        this.props.name,
        this.props.data!.rowKey
      );

      widget.setValue(!widget.value);

      Sys.announce(
        widget.value
          ? Localization.getBuiltInMessage("CheckBox.announceChecked")
          : Localization.getBuiltInMessage("CheckBox.announceUnchecked")
      );
    });

    CellFocusUtil.subscribeToCellKeyboardFocusedEvent(
      props,
      this.onCellFocus,
      this.onCellBlur
    );

    this.componentId = `checkbox-column-${Sys.nextId}`;
    this.errorMessageId = `${this.componentId}-error-message`;

    props.eGridCell.addEventListener("keydown", this.onCellKeyDown);
  }

  private onCellBlur = (): void => {
    this.setState({ isErrorBadgeOpen: false });
  };

  private onCellFocus = (): void => {
    this.setState({ isErrorBadgeOpen: true });
  };

  private onCellKeyDown = (event: KeyboardEvent): void => {
    CellUtil.customizeGridNavigation(event, this.props);
  };

  private onCloseErrorBadge = (): void => {
    this.setState({ isErrorBadgeOpen: false });
  };

  private onOpenErrorBadge = (): void => {
    this.setState({ isErrorBadgeOpen: true });
  };

  private onValueChange(checked: boolean): void {
    const row = this.props.data!;

    ErrorsStore.clearBusinessErrorsForTableCell(
      this.props.dataId,
      this.props.name,
      row.rowKey
    );

    const widget = row.getWidgetT<boolean, RuntimeProperties>(this.props.name);
    widget.setValue(checked);
  }

  public componentWillUnmount(): void {
    CellFocusUtil.unsubscribeToCellKeyboardFocusedEvent(
      this.props,
      this.onCellFocus,
      this.onCellBlur
    );
    this.props.eGridCell.removeEventListener("keydown", this.onCellKeyDown);
  }

  public refresh(): boolean {
    this.forceUpdate();

    return true;
  }

  public render(): React.ReactNode {
    const row = this.props.data!;
    const widget = row.getWidgetT<boolean, RuntimeProperties>(this.props.name);

    CellUtil.setReadOnlyAttribute(
      this.props.eGridCell,
      widget.properties.accessLevel <= AccessLevel.actionable
    );

    const rowErrors: string | undefined = this.props.propagated.parentTable
      .getTable()
      .rowErrorMessages.get(row.rowKey);

    // Ensure errors are always accessed so the component knows to observe them
    const widgetErrors = [...widget.properties.businessErrors];
    const errors = row.hasChanges(this.props.name) ? [] : widgetErrors;

    CellUtil.setAriaAttributes(
      this.props.eGridCell,
      this.errorMessageId,
      errors.length > 0 || rowErrors !== undefined
    );

    if (widget.properties.accessLevel <= AccessLevel.readOnly) {
      return (
        <div
          aria-describedby={rowErrors ? this.errorMessageId : undefined}
          aria-errormessage={rowErrors ? this.errorMessageId : undefined}
          aria-invalid={rowErrors ? true : false}
          role="checkbox"
          className={this.props.classes.root}
          style={{ fontSize: 24 }}
        >
          <Icon
            icon={this.props.value ? "fas fa-check" : ""}
            style={{ height: 24 }}
          />
          <div className="screenReaderOnly">
            {this.props.value
              ? Localization.getBuiltInMessage("CheckBox.announceChecked")
              : Localization.getBuiltInMessage("CheckBox.announceUnchecked")}
          </div>
          <div id={this.errorMessageId} style={{ display: "none" }}>
            {rowErrors}
          </div>
        </div>
      );
    }

    const checkboxProps: CheckboxProps = {
      "aria-label": widget.value
        ? Localization.getBuiltInMessage("CheckBox.announceChecked")
        : Localization.getBuiltInMessage("CheckBox.announceUnchecked"),
      checked: widget.value,
      checkedIcon: <Icon icon="fas fa-check-square" />,
      color: "default",
      icon: <Icon icon="far fa-square" />,
      onChange: (event, checked) => this.onValueChange(checked),
      tabIndex: -1,
    };

    if (errors.length) {
      return (
        <ErrorBadge
          errors={errors}
          isShort={
            row.isNew && this.props.propagated.parentTable.isDocumentGrid
          }
          onClose={this.onCloseErrorBadge}
          onOpen={this.onOpenErrorBadge}
          open={this.state.isErrorBadgeOpen}
          style={{ justifyContent: "center" }}
          suppressEdit={true}
        >
          <Checkbox
            {...checkboxProps}
            inputProps={{
              "aria-describedby": this.errorMessageId,
              "aria-errormessage": this.errorMessageId,
              "aria-invalid": true,
              role: "checkbox",
            }}
          />
          <div id={this.errorMessageId} style={{ display: "none" }}>
            {`${errors.join(". ")}. ${rowErrors || ""}`}
          </div>
        </ErrorBadge>
      );
    }

    return (
      <div
        aria-describedby={rowErrors ? this.errorMessageId : undefined}
        aria-errormessage={rowErrors ? this.errorMessageId : undefined}
        aria-invalid={rowErrors ? true : false}
        className={this.props.classes.root}
      >
        <Checkbox {...checkboxProps} />
        <div id={this.errorMessageId} style={{ display: "none" }}>
          {rowErrors}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(observer(CheckBoxColumn));

import { colors, styled } from "@mui/material";
import * as React from "react";
import Sys from "../../core/Sys";

interface BannerProps {
  color: string;
}

interface TitleProps {
  color: string;
}

const Banner = styled("div", {
  shouldForwardProp: (props) => props !== "color",
})<BannerProps>(({ color }) => ({
  backgroundColor: color,
  display: "flex",
  overflow: "hidden",
}));

const BannerContent = styled("span")({
  display: "flex",
  maxWidth: "100%",
  position: "relative",
});

const Title = styled("span", {
  shouldForwardProp: (props) => props !== "color",
})<TitleProps>(({ color }) => ({
  boxSizing: "border-box",
  color,
  display: "inline-block",
  fontFamily: "SegoeScript-Bold, Segoe Script",
  fontSize: "19.47px",
  fontWeight: 700,
  height: 40,
  maxWidth: "100%",
  overflow: "hidden",
  paddingLeft: 24,
  paddingRight: 36,
  paddingTop: 6,
  position: "relative",
  textOverflow: "ellipsis",
  textTransform: "uppercase",
  whiteSpace: "nowrap",
}));

const TitleBackground = styled("span")({
  backgroundColor: colors.grey[800],
  height: "100%",
  left: 0,
  position: "absolute",
  right: 15,
  top: 0,
});

const TitleBackgroundDecoration = styled("svg")({
  color: colors.grey[800],
  height: "calc(100% + 1px)",
  position: "absolute",
  right: 0,
  top: 0,
});

export const EnvironmentBanner = (): JSX.Element | null => {
  if (
    !Sys.settings.environmentBannerColor ||
    !Sys.settings.nonProdEnvironment
  ) {
    return null;
  }

  return (
    <Banner color={Sys.settings.environmentBannerColor}>
      <BannerContent>
        <TitleBackground />
        <TitleBackgroundDecoration viewBox="0 0 103.59 40">
          <polygon
            fill="currentColor"
            points="103.59 40 0 40 0 0 91.59 0 103.59 40"
          />
        </TitleBackgroundDecoration>
        <Title color={Sys.settings.environmentBannerColor}>
          {Sys.settings.nonProdEnvironment}
        </Title>
      </BannerContent>
    </Banner>
  );
};

import { observer } from "mobx-react";
import * as React from "react";
import { ButtonColor } from "../config";
import AppServer from "../core/AppServer";
import Localization from "../core/Localization";
import { useWidth, WidthProps } from "../core/Responsive";
import Sys from "../core/Sys";
import MenuItem from "../coreui/MenuItem";
import PaneRow from "../models/PaneRow";
import PresentationButtonService, {
  OnClickResponse,
} from "../services/PresentationButtonService";
import ErrorsStore from "../stores/ErrorsStore";
import { AccessLevel } from "./AccessLevel";
import ApiButton from "./ApiButton";
import { MenuChild } from "./MenuButton";
import { MenuItemProps } from "./MenuItem";
import { ToolbarChildProps } from "./Toolbar";

type NavigationType =
  | "Existing Component"
  | "New Component"
  | "New Process"
  | "New Related Component"
  | "Search Presentation"
  | "Web Account";

interface ConfigProperties {
  buttonColor: ButtonColor;
  dataId: string;
  disabledHelpText: string;
  endPointLabel?: string;
  href: string;
  iconName: string;
  name: string;
  navigationType: NavigationType;
  objectDefDescription?: string;
  onClick: object;
  propagated?: ToolbarChildProps & MenuChild;
  renderAsLink: boolean;
  size: {
    lg: "large" | "medium" | "small";
    md: "large" | "medium" | "small";
    sm: "large" | "medium" | "small";
    xl: "large" | "medium" | "small";
    xs: "large" | "medium" | "small";
  };
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  alternateText: string;
  label: string;
  showDisabledHelp?: boolean;
  targetLayoutId?: number;
  targetObjectId?: number;
}

function generateUrl(
  navigationType: NavigationType,
  runtimeProperties: RuntimeProperties
) {
  if (navigationType === "Search Presentation") {
    return `#/search/${runtimeProperties.targetLayoutId}`;
  }

  const url =
    "#/object" +
    `/${runtimeProperties.targetObjectId}` +
    `/${runtimeProperties.targetLayoutId}`;

  return url;
}

function shouldGenerateUrl(navigationType: NavigationType) {
  if (
    navigationType === "New Related Component" ||
    navigationType === "New Component" ||
    navigationType === "New Process"
  ) {
    return false;
  }
  return true;
}

export class PresentationButton extends React.Component<
  ConfigProperties & WidthProps
> {
  public static renderMenuItem(props: MenuItemProps): JSX.Element {
    const { config, runtime, ...otherProps } = props;
    const configProps = config as unknown as ConfigProperties;
    const runtimeProperties = runtime as RuntimeProperties;

    let url: string | undefined = undefined;
    if (
      shouldGenerateUrl(configProps.navigationType) &&
      props.runtime.accessLevel >= AccessLevel.actionable
    ) {
      url = generateUrl(configProps.navigationType, runtimeProperties);
    }

    const onClick = (): void => {
      if (props.runtime.accessLevel >= AccessLevel.actionable) {
        if (!url) {
          PresentationButton.onClick(configProps.dataId, configProps.name);
        }
        configProps.propagated!.onItemClicked!();
      }
    };

    return (
      <MenuItem
        disabled={props.runtime.accessLevel === AccessLevel.disabled}
        href={url}
        iconName={configProps.iconName}
        indent={props.config.propagated ? props.config.propagated.indent : 0}
        onClick={onClick}
        target="_self"
        {...otherProps}
      >
        {runtimeProperties.label}
      </MenuItem>
    );
  }

  public static onClick(dataId: string, widgetName: string): void {
    Sys.confirmContinue(false)
      .then(() => {
        const row: PaneRow = PaneRow.get(dataId)!;
        PresentationButtonService.onClick(row.rowKey, dataId, widgetName).then(
          (response: OnClickResponse) => {
            if (response.validationErrors.length > 0) {
              ErrorsStore.clearErrors();
              ErrorsStore.showErrors(response.validationErrors);

              return;
            }

            if (response.businessErrors.length > 0) {
              ErrorsStore.clearBusinessErrors();
              ErrorsStore.setBusinessErrors(response.businessErrors, false);
              ErrorsStore.pushErrorsToWidgets();

              return;
            }

            AppServer.setState(response.appServerState!);

            const newObjectUrl: string = response.url!;
            Sys.ignoreChanges = true;

            if (row.isNew) {
              Sys.setHash(newObjectUrl.substr(1));
            } else {
              window.location.assign(newObjectUrl);
            }
          }
        );
      })
      .catch(() => {
        // User cancelled dialog, do nothing
      });
  }

  private onClick = () => {
    PresentationButton.onClick(this.props.dataId, this.props.name);
  };

  public render(): React.ReactNode {
    const row = PaneRow.get(this.props.dataId);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<null, RuntimeProperties>(this.props.name);

    let size: "large" | "medium" | "small" | undefined = undefined;
    if (this.props.width in this.props.size) {
      size = this.props.size[this.props.width];
    }

    let url: string | undefined = undefined;
    if (shouldGenerateUrl(this.props.navigationType)) {
      url = generateUrl(this.props.navigationType, widget.properties);
    }

    let ariaLabel: string | undefined = undefined;
    switch (this.props.navigationType) {
      case "Existing Component":
        ariaLabel = this.props.endPointLabel || this.props.objectDefDescription;
        break;
      case "New Component":
        ariaLabel = Localization.getBuiltInMessage("Button.newComponentLabel", {
          component: this.props.objectDefDescription,
        });
        break;
      case "New Process":
        ariaLabel = Localization.getBuiltInMessage("Button.newComponentLabel", {
          component: this.props.objectDefDescription,
        });
        break;
      case "New Related Component":
        ariaLabel = Localization.getBuiltInMessage("Button.newComponentLabel", {
          component: this.props.endPointLabel,
        });
        break;
      case "Web Account":
        ariaLabel = Localization.getBuiltInMessage(
          "Button.gotoWebAccountLabel"
        );
        break;
      case "Search Presentation":
        ariaLabel = Localization.getBuiltInMessage("Button.gotoSearchLabel");
        break;
      default:
        throw new Error(`Unknown navigation type ${this.props.navigationType}`);
    }

    const isIconOnly: boolean = !widget.properties.label;
    const label: string = isIconOnly ? ariaLabel! : widget.properties.label;

    return (
      <ApiButton
        alternateText={widget.properties.alternateText}
        buttonColor={this.props.buttonColor}
        disabled={widget.properties.accessLevel === AccessLevel.disabled}
        disabledHelpText={this.props.disabledHelpText}
        disabledHelpVisible={widget.properties.showDisabledHelp}
        href={url}
        iconName={this.props.iconName}
        isIconOnly={isIconOnly}
        label={label}
        onClick={url ? undefined : this.onClick}
        renderAsLink={this.props.renderAsLink}
        size={size}
        tabIndex={
          this.props.propagated && this.props.propagated.hasParentToolbar
            ? -1
            : 0
        }
      />
    );
  }
}

// FUTURE
// This wrapper component was created to avoid the scope of converting this
// component to a hooks component during the MUI 5 upgrade. When the legacy
// component is converted to a hooks component, this wrapper can be removed and
// hooks it calls can be called by the converted component directly.
const Observer = observer(PresentationButton);
export default function Wrapped(props: ConfigProperties): JSX.Element {
  const width = useWidth();
  return <Observer {...props} width={width} />;
}

import { styled } from "@mui/material";
import { GridApi } from "ag-grid-community";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../core/Localization";
import { useWidth, WidthProps } from "../core/Responsive";
import Sys from "../core/Sys";
import Button from "../coreui/Button";
import Icon from "../coreui/Icon";
import Menu from "../coreui/Menu";
import MenuItem from "../coreui/MenuItem";
import { TableChildProps } from "../coreui/Table";
import Typography from "../coreui/Typography";

interface Props {
  dataId: string;
  name: string;
  propagated: TableChildProps;
}

interface State {
  accessibleDescription?: string;
  menuButtonElement?: HTMLButtonElement;
  pageMessage?: string;
}

const GroupContainer = styled("div")(({ theme }) => ({
  "&:focus-visible": {
    outline: "2px solid",
    outlineColor: theme.palette.grey[800],
    outlineOffset: "8px",
  },
  alignItems: "center",
  display: "flex",
}));

const MenuButton = styled(Button)({
  fontSize: 12,
  fontWeight: 400,
  letterSpacing: "normal",
  marginLeft: 8,
  marginRight: 12,
  minWidth: 0,
  paddingLeft: 4,
  paddingRight: 4,
  textTransform: "none",
});

export class GridPager extends React.Component<Props & WidthProps, State> {
  private readonly accessibleDescriptionId: string;
  private readonly allPageSize = 999999;
  private readonly componentId: string;
  private currentPageCount = 0;
  private currentPageNumber = 0;
  private readonly gridApi: GridApi;
  private mounted: boolean = false;
  private nextButtonRef = React.createRef<HTMLButtonElement>();
  private pageSizes: { label: string; onClick: () => void; size: number }[];
  private prevButtonRef = React.createRef<HTMLButtonElement>();
  private readonly rowsPerPageId: string;

  public constructor(props: Props & WidthProps) {
    super(props);

    this.componentId = `grid-pager-${Sys.nextId}`;
    this.accessibleDescriptionId = `${this.componentId}-accessible-description`;
    this.rowsPerPageId = `${this.componentId}-rows-per-page`;

    this.state = {};

    this.gridApi = props.propagated.parentTable.getApi();
    this.gridApi.paginationSetPageSize(
      props.propagated.parentTable.initialPageSize[props.width]
    );
    this.setPageSizes();

    this.gridApi.addEventListener("paginationChanged", this.setCurrentPageInfo);
  }

  private changePage(next: boolean) {
    if (next) {
      this.gridApi.paginationGoToNextPage();
    } else {
      this.gridApi.paginationGoToPreviousPage();
    }

    setTimeout(() => {
      // If the button that was clicked is now disabled, focus the other
      // button. If this isn't done the control loses focus altogether
      const currentPage = this.gridApi.paginationGetCurrentPage();
      if (currentPage <= 0 && this.nextButtonRef.current) {
        this.nextButtonRef.current.focus();
      }
      if (
        currentPage >= this.gridApi.paginationGetTotalPages() - 1 &&
        this.prevButtonRef.current
      ) {
        this.prevButtonRef.current.focus();
      }

      this.props.propagated.parentTable.scrollToTop();

      const count = this.gridApi.paginationGetRowCount() || 0;
      const first = Math.min(count, this.gridApi.getFirstDisplayedRow() + 1);
      const last = this.gridApi.getLastDisplayedRow() + 1;

      if (last < count || first > 1) {
        Sys.debounceMethod(
          () => {
            if (this.mounted) {
              Sys.announce(
                Localization.getBuiltInMessage("DataTable.paginationAlert", {
                  currentPageFirstRow: first,
                  currentPageLastRow: last,
                  totalRows: count,
                })
              );
            }
          },
          "DataTable.paginationAlert",
          1000
        );
      }
    });
  }

  private setCurrentPageInfo = () => {
    const count = this.gridApi.paginationGetRowCount() || 0;
    const first = Math.min(count, this.gridApi.getFirstDisplayedRow() + 1);
    const last = this.gridApi.getLastDisplayedRow() + 1;
    let pageMessage: string;

    this.currentPageNumber = this.gridApi.paginationGetCurrentPage();
    this.currentPageCount = this.gridApi.paginationGetTotalPages();

    if (this.gridApi.paginationGetPageSize() === 1) {
      const builtInMessageTranslationArgs = {
        currentRow: first.toString(),
        totalRows: count.toString(),
      };

      pageMessage = Localization.getBuiltInMessage(
        "rowNumber",
        builtInMessageTranslationArgs
      );
    } else {
      const builtInMessageTranslationArgs = {
        currentPageFirstRow: first.toString(),
        currentPageLastRow: last.toString(),
        totalRows: count.toString(),
      };

      pageMessage = Localization.getBuiltInMessage(
        "visibleRows",
        builtInMessageTranslationArgs
      );
    }

    if (this.mounted) {
      this.setState({
        accessibleDescription: Localization.getBuiltInMessage(
          "DataTable.paginationAlert",
          {
            currentPageFirstRow: first.toString(),
            currentPageLastRow: last.toString(),
            totalRows: count.toString(),
          }
        ),
        pageMessage,
      });
    }
  };

  private onClickMenuButton = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    const target: HTMLButtonElement = event.currentTarget;
    this.setState((prevState) => {
      return {
        menuButtonElement: prevState.menuButtonElement ? undefined : target,
      };
    });
  };

  private onCloseMenu = (): void => {
    this.setState({ menuButtonElement: undefined });
  };

  private setPageSizes = () => {
    this.pageSizes = [];
    let sizes: number[];

    if (this.props.propagated.parentTable.isVerticalLayout) {
      sizes = [1, 5, 10, 20, this.allPageSize];
    } else {
      sizes = [5, 10, 20, this.allPageSize];
    }

    sizes.forEach((size: number) => {
      this.pageSizes.push({
        label: size === this.allPageSize ? "All" : size.toString(),
        onClick: () => {
          const focusedCell = this.gridApi.getFocusedCell();

          this.gridApi.paginationSetPageSize(size);
          this.gridApi.paginationGoToFirstPage();

          if (focusedCell && focusedCell.rowIndex > size - 1) {
            this.gridApi.clearFocusedCell();
          }

          this.setState({ menuButtonElement: undefined });
        },
        size,
      });
    });
  };

  public componentDidMount(): void {
    this.mounted = true;
    this.setCurrentPageInfo();
  }

  public componentWillUnmount(): void {
    this.mounted = false;
    this.gridApi.removeEventListener(
      "paginationChanged",
      this.setCurrentPageInfo
    );
  }

  public render(): React.ReactNode {
    const pageSize = this.gridApi.paginationGetPageSize();
    const isFullWidth = this.props.propagated.parentTable.isVerticalLayout;

    const leftButton = (
      <Button
        aria-label={Localization.getBuiltInMessage("previous")}
        disabled={this.currentPageNumber === 0}
        icon="fas fa-chevron-left"
        onClick={() => this.changePage(false)}
        ref={this.prevButtonRef}
        size="small"
        style={{
          marginLeft: isFullWidth ? 0 : 24,
          marginRight: isFullWidth ? 24 : 8,
        }}
        tabIndex={-1}
      />
    );

    // Describedby moved from outer div to button so both nvda and
    // voiceover will announce it.
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
        }}
      >
        {isFullWidth ? leftButton : null}
        <GroupContainer
          aria-label={Localization.getBuiltInMessage(
            "DataTable.pagerDescription",
            { description: this.props.propagated.parentTable.description }
          )}
          role="group"
          style={{
            flex: isFullWidth ? "auto" : undefined,
            justifyContent: isFullWidth ? "center" : undefined,
          }}
        >
          <Typography
            aria-hidden={true}
            id={this.rowsPerPageId}
            variant="caption"
          >
            {Localization.getBuiltInMessage("rows")}
          </Typography>
          <MenuButton
            aria-describedby={this.accessibleDescriptionId}
            aria-expanded={!!this.state.menuButtonElement}
            aria-haspopup="listbox"
            aria-labelledby={`${this.rowsPerPageId} ${this.componentId}`}
            id={this.componentId}
            onClick={this.onClickMenuButton}
            size="small"
            tabIndex={-1}
            style={{ minWidth: 50 }}
            variant="text"
          >
            {pageSize === this.allPageSize ? "All" : pageSize}
            <Icon
              icon="fas fa-caret-down"
              style={{ fontSize: 16, marginLeft: ".4em" }}
            />
          </MenuButton>
          <Menu
            anchorEl={this.state.menuButtonElement}
            labelId={this.rowsPerPageId}
            modal={true}
            onClose={this.onCloseMenu}
            variant="selectedMenu"
          >
            {this.pageSizes.map((p) => (
              <MenuItem
                aria-selected={p.size === pageSize}
                key={p.label}
                onClick={p.onClick}
                role="option"
                selected={p.size === pageSize}
              >
                {p.label}
              </MenuItem>
            ))}
          </Menu>
          <Typography
            aria-hidden={true}
            style={{ lineHeight: "26px", whiteSpace: "nowrap" }}
            variant="caption"
          >
            {this.state.pageMessage}
          </Typography>
        </GroupContainer>
        {!isFullWidth ? leftButton : null}
        <Button
          aria-label={Localization.getBuiltInMessage("next")}
          disabled={this.currentPageNumber >= this.currentPageCount - 1}
          icon="fas fa-chevron-right"
          onClick={() => this.changePage(true)}
          ref={this.nextButtonRef}
          size="small"
          style={{ marginLeft: isFullWidth ? 24 : 0 }}
          tabIndex={-1}
        />
        <div id={this.accessibleDescriptionId} style={{ display: "none" }}>
          {this.state.accessibleDescription}
        </div>
      </div>
    );
  }
}

// FUTURE
// This wrapper component was created to avoid the scope of converting this
// component to a hooks component during the MUI 5 upgrade. When the legacy
// component is converted to a hooks component, this wrapper can be removed and
// hooks it calls can be called by the converted component directly.
const Observer = observer(GridPager);
export default function Wrapped(props: Props): JSX.Element {
  const width = useWidth();
  return <Observer {...props} width={width} />;
}

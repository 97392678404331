import { colors, styled } from "@mui/material";
import * as React from "react";
import Localization from "../../core/Localization";
import Dialog from "../Dialog";
import DialogContent from "../DialogContent";
import Typography from "../Typography";

interface Props {
  onClose: () => void;
  open: boolean;
  percent: number;
}

const ProgressBar = styled("div")({
  backgroundColor: colors.lightGreen[500],
  height: 8,
  transitionProperty: "width",
  transitionTimingFunction: "linear",
});

const ProgressBackground = styled("div")({
  backgroundColor: colors.lightGreen[100],
  height: 8,
  marginTop: 24,
  width: "100%",
});

export default function UploadDialog(props: Props) {
  const duration: string =
    props.percent > 90 && props.percent < 100 ? "5s" : ".1s";

  const onClose = (
    event: object,
    reason: "escapeKeyDown" | "backdropClick"
  ): void => {
    if (reason === "backdropClick") {
      return;
    }
    props.onClose();
  };

  return (
    <Dialog
      disableEscapeKeyDown={true}
      fullScreen={false}
      fullWidth
      onClose={onClose}
      open={props.open}
    >
      <DialogContent style={{ paddingBottom: 16 }}>
        <Typography variant="h3">
          {Localization.getBuiltInMessage("uploading")}
        </Typography>
        <ProgressBackground>
          <ProgressBar
            style={{
              transitionDuration: duration,
              width: `${props.percent}%`,
            }}
          />
        </ProgressBackground>
      </DialogContent>
    </Dialog>
  );
}

import { observer } from "mobx-react";
import * as React from "react";
import FormData from "../coreui/FormData";
import Icon from "../coreui/Icon";
import { TableVerticalLayoutProps } from "../coreui/Table";
import PaneRow from "../models/PaneRow";
import { AccessLevel } from "./AccessLevel";

interface ConfigProperties {
  dataId: string;
  header: string;
  name: string;
  propagated: TableVerticalLayoutProps;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  iconName: string;
  label: string;
}

export class ComponentTypeDisplay extends React.Component<ConfigProperties> {
  public render() {
    const row = PaneRow.get(this.props.dataId, this.props.propagated.rowKey)!;
    const widget = row.getWidgetT<null, RuntimeProperties>(this.props.name);

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    return (
      <FormData label={this.props.header}>
        <div style={{ alignItems: "center", display: "flex" }}>
          <Icon
            fixedWidth
            icon={widget.properties.iconName}
            style={{
              fontSize: 16,
              height: "auto",
              marginRight: ".4em",
            }}
          />
          <div style={{ lineHeight: "normal" }}>{widget.properties.label}</div>
        </div>
      </FormData>
    );
  }
}

export default observer(ComponentTypeDisplay);

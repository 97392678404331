import { Breakpoint, StyledEngineProvider } from "@mui/material";
import { createGenerateClassName, StylesProvider } from "@mui/styles";
import * as React from "react";
import { LayoutConfig } from "../config";
import SessionMessageDisplay from "../mustangui/SessionMessageDisplay";
import { ForegroundColorType, ThemeProvider } from "../theme";
import {
  BackgroundImage,
  ConfirmContinueDialog,
  Container,
  EnvironmentBanner,
  Footer,
  Header,
  MessagesSnackbar,
  PageProcessing,
} from "./components";

interface FooterConfig {
  backgroundColor: string;
  foreground: ForegroundColorType;
  layout: LayoutConfig;
}

interface HeaderConfig {
  backgroundColor: string;
  bottomBorderColor: string;
  foreground: ForegroundColorType;
  logoUrl: string;
  logoLabel: string;
  layout: LayoutConfig;
}

export interface BasePageTemplateConfig {
  backgroundImageUrl: string | null;
  footer: FooterConfig;
  header: HeaderConfig;
}

interface Props {
  backgroundImageUrl: string | null;
  footer: FooterConfig;
  header: HeaderConfig;
  paperWidth?: { [key in Breakpoint]: 3 | 4 | 6 | 8 | null };
}

const generateHeaderClassName = createGenerateClassName({
  seed: "header",
});

const generateFooterClassName = createGenerateClassName({
  seed: "footer",
});

export function BasePageTemplate(
  props: React.PropsWithChildren<Props>
): JSX.Element {
  // FUTURE
  // The StyledEngineProvider is used to ensure the Emotion styles are injected
  // before JSS. This can be removed when everything is moved over to Emotion.
  // i.e., once the project no longer references @mui/styles.
  return (
    <StyledEngineProvider injectFirst>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <EnvironmentBanner />
        <StylesProvider generateClassName={generateHeaderClassName}>
          <ThemeProvider foreground={props.header.foreground} header>
            <Header
              backgroundColor={props.header.backgroundColor}
              bottomBorderColor={props.header.bottomBorderColor}
              layout={props.header.layout}
              logoProps={{
                href: "#",
                imageUrl: props.header.logoUrl,
                label: props.header.logoLabel,
              }}
            />
          </ThemeProvider>
        </StylesProvider>
        <ThemeProvider>
          <PageProcessing />
          <ConfirmContinueDialog />
          <SessionMessageDisplay />
          <main
            style={{
              flexGrow: 1,
              position: "relative",
            }}
          >
            {props.backgroundImageUrl && (
              <BackgroundImage
                mask
                url={props.backgroundImageUrl}
                visible={{
                  lg: props.paperWidth?.lg != null,
                  md: props.paperWidth?.md != null,
                  sm: props.paperWidth?.sm != null,
                  xl: props.paperWidth?.xl != null,
                  xs: props.paperWidth?.xs != null,
                }}
              />
            )}
            <Container
              content
              paperColor={props.backgroundImageUrl ? "alt" : "default"}
              paperWidth={props.paperWidth}
            >
              {props.children}
            </Container>
          </main>
          <MessagesSnackbar />
        </ThemeProvider>
        <StylesProvider generateClassName={generateFooterClassName}>
          <ThemeProvider footer foreground={props.footer.foreground}>
            <Footer
              backgroundColor={props.footer.backgroundColor}
              layout={props.footer.layout}
            />
          </ThemeProvider>
        </StylesProvider>
      </div>
    </StyledEngineProvider>
  );
}

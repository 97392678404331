import { Breakpoint, styled, useTheme } from "@mui/material";
import * as React from "react";
import Localization from "../../core/Localization";

interface Props {
  mask?: true;
  url: string;
  visible?: boolean | { [key in Breakpoint]: boolean };
}

interface RootProps {
  visible: { [key in Breakpoint]: boolean };
}

const Image = styled("img")({
  height: "100%",
  objectFit: "cover",
  objectPosition: "0 86%",
  position: "absolute",
  width: "100%",
});

const Mask = styled("div")({
  background:
    "linear-gradient(to right, rgba(33,33,33,0.9) 0%, " +
    "rgba(33,33,33,0.5) 50%, rgba(33,33,33,0.9) 100%)",
  height: "100%",
  position: "absolute",
  width: "100%",
});

const Root = styled("div")<RootProps>(
  () => ({
    bottom: 0,
    display: "none",
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: -1,
  }),
  ({ theme, visible }) =>
    theme.breakpoints.keys.reduce((result, breakPoint) => {
      if (visible[breakPoint]) {
        result[theme.breakpoints.only(breakPoint)] = {
          display: "block",
        };
      }

      return result;
    }, {})
);

export const BackgroundImage = (props: Props) => {
  const theme = useTheme();
  const visible = props.visible !== undefined ? props.visible : true;

  let rootVisible: { [key in Breakpoint]: boolean };
  if (typeof visible === "boolean") {
    rootVisible = theme.breakpoints.keys.reduce((result, breakPoint) => {
      result[breakPoint] = visible;
      return result;
    }, {} as { [key in Breakpoint]: boolean });
  } else {
    rootVisible = visible;
  }

  return (
    <Root visible={rootVisible}>
      <Image
        alt={Localization.getBuiltInMessage("background")}
        aria-hidden={true}
        src={props.url}
      />
      {props.mask ? <Mask /> : null}
    </Root>
  );
};

import {
  Tabs as MuiTabs,
  TabsProps as MuiTabsProps,
  styled,
} from "@mui/material";
import * as React from "react";
import { TabScrollButton } from "./TabScrollButton";

interface Props extends MuiTabsProps {}

export const Tabs = styled((props: Props) => {
  return (
    <MuiTabs
      ScrollButtonComponent={TabScrollButton}
      scrollButtons="auto"
      variant="scrollable"
      {...props}
    />
  );
})({
  "& .MuiTabs-flexContainer": {
    outline: "none",
  },
});

export default class AriaHiddenManager {
  /**
   * Keep aria-hidden set to false until the returned callback is triggered.
   * At that time, set it back to the last value the framework intended it to
   * be.
   */
  public static supressAriaHidden(elementToObserve: HTMLElement): () => void {
    let lastAriaState: string | null | undefined = null;

    const observer = new MutationObserver(() => {
      lastAriaState = elementToObserve.getAttribute("aria-hidden");

      if (lastAriaState === "true") {
        // Disconnect the observer while aria-hidden is reverted to false
        // to prevent the observer from triggering.
        observer.disconnect();
        elementToObserve.setAttribute("aria-hidden", "false");
        observer.observe(elementToObserve, {
          attributes: true,
        });
      }
    });

    observer.observe(elementToObserve, {
      attributes: true,
    });

    return (): void => {
      observer.disconnect();
      if (lastAriaState === "true") {
        elementToObserve.setAttribute("aria-hidden", "true");
      }
    };
  }
}

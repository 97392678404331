import { Theme } from "@mui/material";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import { ICellRendererParams } from "ag-grid-community";
import * as React from "react";
import ButtonLink from "../../coreui/ButtonLink";
import { TableChildProps } from "../../coreui/Table";
import { CellUtil } from "../../coreui/table/CellUtil";
import Typography from "../../coreui/Typography";
import PaneRow, { RuntimeWidget } from "../../models/PaneRow";
import { AccessLevel } from "../AccessLevel";
import { FunctionName } from "../TableSummary";
import {
  GridColumnConfigProperties,
  RenderInlineProperties,
} from "./GridColumn";

interface ConfigProperties
  extends WidgetProperties,
    ICellRendererParams<PaneRow> {}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  anchorText: string;
  objectId: number;
  presentationId: number;
}

interface WidgetProperties {
  dataId: string;
  iconName: string;
  name: string;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      alignItems: "center",
      display: "flex",
      height: "100%",
      margin: "0 24px",
    },
    text: {
      fontSize: 14,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "100%",
    },
  });

export class DataLinkColumn extends React.PureComponent<
  ConfigProperties & WithStyles<typeof styles>
> {
  public static readonly widgetType: string = "DataLinkColumn";

  private buttonRef = React.createRef<HTMLButtonElement>();

  private static buildUrl(runtimeProperties: RuntimeProperties): string {
    return (
      `#/object/${runtimeProperties.objectId}` +
      `/${runtimeProperties.presentationId}`
    );
  }

  public static getFilterText(
    column: GridColumnConfigProperties,
    propagated: TableChildProps,
    row: PaneRow
  ): string {
    const widget = row.getWidgetT<null, RuntimeProperties>(column.name);
    return widget.properties.anchorText;
  }

  public static getSummaryValue(
    runtimeData: RuntimeWidget[],
    configProperties: ConfigProperties,
    functionName: FunctionName
  ): string | null {
    let result = 0;
    for (const data of runtimeData) {
      const runtimeProperties = data.properties as RuntimeProperties;

      if (
        runtimeProperties.accessLevel >= AccessLevel.readOnly &&
        runtimeProperties.anchorText
      ) {
        result += 1;
      }
    }

    return result.toString();
  }

  public static renderInline(
    props: RenderInlineProperties
  ): JSX.Element | null {
    const widget = props.row.getWidgetT<null, RuntimeProperties>(
      props.column.name
    );
    const configProps = props.column.widgetProps as WidgetProperties;

    if (widget.properties.accessLevel === AccessLevel.actionable) {
      return (
        <ButtonLink
          className={props.className}
          href={DataLinkColumn.buildUrl(widget.properties)}
          icon={configProps.iconName}
          linkText={widget.properties.anchorText}
          onClick={(e) => e.stopPropagation()}
          target="_self"
        />
      );
    }

    if (widget.properties.accessLevel === AccessLevel.readOnly) {
      return (
        <Typography className={props.className} variant="body1">
          {widget.properties.anchorText}
        </Typography>
      );
    }

    return null;
  }

  public constructor(props: ConfigProperties & WithStyles<typeof styles>) {
    super(props);

    CellUtil.setReadOnlyAttribute(props.eGridCell, true);
    props.eGridCell.addEventListener("keydown", this.onCellKeyDown);
    props.eGridCell.addEventListener("focus", this.onCellFocus);
  }

  private onCellFocus = (): void => {
    if (this.buttonRef.current) {
      this.buttonRef.current.focus();
    }
  };

  private onCellKeyDown = (event: KeyboardEvent): void => {
    CellUtil.customizeGridNavigation(event, this.props);
  };

  public componentWillUnmount(): void {
    this.props.eGridCell.removeEventListener("focus", this.onCellFocus);
    this.props.eGridCell.removeEventListener("keydown", this.onCellKeyDown);
  }

  public render(): React.ReactNode {
    const row = this.props.data!;
    const widget = row.getWidgetT<null, RuntimeProperties>(this.props.name);

    let result: React.ReactNode = null;

    switch (widget.properties.accessLevel) {
      case AccessLevel.actionable:
        result = (
          <ButtonLink
            focusRipple={false}
            href={DataLinkColumn.buildUrl(widget.properties)}
            icon={this.props.iconName}
            linkText={widget.properties.anchorText}
            ref={this.buttonRef}
            style={{ fontSize: "14px" }}
            tabIndex={-1}
            target="_self"
          />
        );
        break;
      case AccessLevel.readOnly:
        result = (
          <div className={this.props.classes.text}>
            {widget.properties.anchorText}
          </div>
        );
        break;
      default:
        break;
    }

    if (result) {
      result = <div className={this.props.classes.root}>{result}</div>;
    }

    return result;
  }
}

export default withStyles(styles)(DataLinkColumn);

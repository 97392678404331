import { IHeaderParams } from "ag-grid-community";
import * as React from "react";
import Localization from "../../core/Localization";
import Sys from "../../core/Sys";
import { FocusCellRendererParams } from "../../coreui/table/CellFocusUtil";
import { CellUtil } from "../../coreui/table/CellUtil";
import PaneRow from "../../models/PaneRow";
import ProjectCurrentJobIndicator from "../ProjectCurrentJobIndicator";

export class ProjectCurrentJobColumn extends React.PureComponent<
  FocusCellRendererParams
> {
  public render(): React.ReactNode {
    CellUtil.setReadOnlyAttribute(this.props.eGridCell, true);

    const row = this.props.data as PaneRow;
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          margin: "0 24px 0 22px",
          width: "16px",
        }}
      >
        <ProjectCurrentJobIndicator data={row} />
      </div>
    );
  }
}

export class ProjectCurrentJobHeader extends React.Component {
  private readonly componentId: string;
  private readonly describedById: string;

  public constructor(params: IHeaderParams) {
    super(params);

    this.componentId = `current-job-header-${Sys.nextId}`;
    this.describedById = `${this.componentId}-described-by`;

    params.eGridHeader.setAttribute("aria-describedby", this.describedById);
  }

  render(): React.ReactNode {
    // The class of screenReaderOnly is needed here instead of using a style of
    // "display: none" because VoiceOver will not read the column header when
    // the column is focused if the header has a style of "display: none"
    return (
      <div className="screenReaderOnly" id={this.describedById}>
        {Localization.getBuiltInMessage(
          "DataTable.projectGridHierarchyLevelHeader"
        )}
      </div>
    );
  }
}

export default ProjectCurrentJobColumn;

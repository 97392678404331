import { IRowNode } from "ag-grid-community";
import { observer, Observer } from "mobx-react";
import * as React from "react";
import { DialogChildProps } from "../../config";
import Localization from "../../core/Localization";
import RequestPromise from "../../core/RequestPromise";
import Sys from "../../core/Sys";
import MenuItem from "../../coreui/MenuItem";
import { TableChildProps } from "../../coreui/Table";
import PaneRow from "../../models/PaneRow";
import ActionButtonService, {
  OnRoundTripResponse,
} from "../../services/ActionButtonService";
import ErrorsStore from "../../stores/ErrorsStore";
import RequestsStore from "../../stores/RequestsStore";
import { AccessLevel } from "../AccessLevel";
import { ActionButtonProps, ActionButtonRuntimeProps } from "../ActionButton";
import ApiButton from "../ApiButton";
import { CaptchaControl as CaptchaControlBase } from "../CaptchaControl";
import { MenuItemProps } from "../MenuItem";
import ConfirmationDialog from "./ConfirmationDialog";

interface Props extends ActionButtonProps {}

interface State {
  isConfirmDialogOpen?: boolean;
}

export class CustomActionButton extends React.Component<Props, State> {
  private onClickPromise: RequestPromise<void>;

  private static getLabel = (
    runtimeProperties: ActionButtonRuntimeProps
  ): string => {
    const label = !!runtimeProperties.label
      ? runtimeProperties.label
      : Localization.getBuiltInMessage("Button.customActionLabel");

    return label;
  };

  private static onClick(config: {
    dataId: string;
    iconName?: string;
    name: string;
    propagated?: DialogChildProps & TableChildProps;
  }): RequestPromise<void> {
    let selectedRowKeys: string[] | null = null;
    if (config.propagated && config.propagated.parentTable) {
      selectedRowKeys = [];

      if (config.propagated.parentRowKey) {
        selectedRowKeys.push(config.propagated.parentRowKey);
      } else {
        const gridApi = config.propagated.parentTable.getApi();

        const rows = config.propagated.parentTable.selection.getSelectedRows();
        if (rows.length <= 0) {
          gridApi.forEachNode((r) => rows.push(r.data!));
          for (let i = 0; i < gridApi.getPinnedTopRowCount(); i++) {
            const rowNode: IRowNode<PaneRow> = gridApi.getPinnedTopRow(i)!;
            rows.push(rowNode.data!);
          }
        }

        for (const selectedRow of rows) {
          selectedRowKeys!.push(selectedRow.rowKey);
        }
      }
    }

    const row = PaneRow.get(config.dataId)!;
    const widget = row.getWidgetT<null, ActionButtonRuntimeProps>(config.name);
    const labelBeforeReload = CustomActionButton.getLabel(widget.properties);

    return ActionButtonService.onRoundTrip(
      row,
      config.name,
      selectedRowKeys,
      config.propagated?.parentDialog?.rowKey
    )
      .then((response: OnRoundTripResponse) => {
        if (
          response.businessErrors.length === 0 &&
          response.validationErrors.length === 0
        ) {
          Sys.announce(
            Localization.getBuiltInMessage("Button.succeeded", {
              label: labelBeforeReload,
            })
          );
        }

        if (response.saved && !response.url) {
          ErrorsStore.clearErrors();
          RequestsStore.instance.setSaved(config.iconName);
        }

        if (widget.properties.validateCaptcha) {
          CaptchaControlBase.reset();
        }
      })
      .catch((reason) => {
        if (reason) {
          throw reason;
        }
      });
  }

  public static renderMenuItem(props: MenuItemProps): JSX.Element {
    const { config, runtime, ...otherProps } = props;
    const configProps = config as unknown as Props;
    const runtimeProps = runtime as ActionButtonRuntimeProps;

    const [isConfirmDialogOpen, setIsConfirmDialogOpen] =
      React.useState<boolean>(false);

    const onClick = () => {
      if (runtimeProps.accessLevel >= AccessLevel.actionable) {
        if (runtimeProps.confirmMessage) {
          setIsConfirmDialogOpen(true);
        } else {
          CustomActionButton.onClick(configProps);
          configProps.propagated.onItemClicked!();
        }
      }
    };

    const onAcceptConfirm = () => {
      setIsConfirmDialogOpen(false);
      CustomActionButton.onClick(configProps);
      configProps.propagated.onItemClicked!();
    };

    const onCancelConfirm = () => {
      setIsConfirmDialogOpen(false);
    };

    return (
      <Observer>
        {() => (
          <React.Fragment>
            {runtimeProps.confirmMessage ? (
              <ConfirmationDialog
                cancelButtonText={runtimeProps.cancelButtonText!}
                continueButtonAlternateText={
                  runtimeProps.continueButtonText === null
                    ? runtimeProps.alternateText
                    : undefined
                }
                continueButtonColor={configProps.buttonColor}
                continueButtonIcon={configProps.iconName}
                continueButtonIsIconOnly={
                  runtimeProps.continueButtonText === null
                }
                continueButtonText={
                  runtimeProps.continueButtonText !== null
                    ? runtimeProps.continueButtonText
                    : Localization.getBuiltInMessage("Button.customActionLabel")
                }
                isOpen={isConfirmDialogOpen}
                message={runtimeProps.confirmMessage}
                onCancel={onCancelConfirm}
                onContinue={onAcceptConfirm}
                title={runtimeProps.confirmTitle!}
              />
            ) : null}
            <MenuItem
              disabled={
                runtimeProps.accessLevel === AccessLevel.disabled ||
                (props.runtime.validateCaptcha! &&
                  !CaptchaControlBase.isValid())
              }
              iconName={configProps.iconName}
              indent={
                props.config.propagated ? props.config.propagated.indent : 0
              }
              onClick={onClick}
              {...otherProps}
            >
              {runtimeProps.label}
            </MenuItem>
          </React.Fragment>
        )}
      </Observer>
    );
  }

  public constructor(props: Props) {
    super(props);

    this.state = { isConfirmDialogOpen: false };
  }

  private onAcceptConfirm = () => {
    this.setState({ isConfirmDialogOpen: false });
    CustomActionButton.onClick(this.props);
  };

  private onCancelConfirm = () => {
    this.setState({ isConfirmDialogOpen: false });
  };

  private onClick = () => {
    const row = PaneRow.get(this.props.dataId)!;
    const widget = row.getWidgetT<null, ActionButtonRuntimeProps>(
      this.props.name
    );

    if (widget.properties.confirmMessage) {
      this.setState({ isConfirmDialogOpen: true });
    } else {
      this.onClickPromise = CustomActionButton.onClick(this.props);
    }
  };

  public componentWillUnmount() {
    if (this.onClickPromise) {
      this.onClickPromise.abort();
    }
  }

  public render() {
    const row = PaneRow.get(this.props.dataId);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<null, ActionButtonRuntimeProps>(
      this.props.name
    );

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    return (
      <React.Fragment>
        {widget.properties.confirmMessage ? (
          <ConfirmationDialog
            cancelButtonText={widget.properties.cancelButtonText!}
            continueButtonAlternateText={
              widget.properties.continueButtonText === null
                ? widget.properties.alternateText
                : undefined
            }
            continueButtonColor={this.props.buttonColor}
            continueButtonIcon={this.props.iconName}
            continueButtonIsIconOnly={
              widget.properties.continueButtonText === null
            }
            continueButtonText={
              widget.properties.continueButtonText !== null
                ? widget.properties.continueButtonText
                : Localization.getBuiltInMessage("Button.customActionLabel")
            }
            isOpen={this.state.isConfirmDialogOpen!}
            message={widget.properties.confirmMessage}
            onCancel={this.onCancelConfirm}
            onContinue={this.onAcceptConfirm}
            title={widget.properties.confirmTitle!}
          />
        ) : null}
        <ApiButton
          alternateText={widget.properties.alternateText}
          buttonColor={this.props.buttonColor}
          disabled={
            widget.properties.accessLevel === AccessLevel.disabled ||
            (widget.properties.validateCaptcha && !CaptchaControlBase.isValid())
          }
          disabledHelpText={this.props.disabledHelpText}
          disabledHelpVisible={widget.properties.showDisabledHelp}
          iconName={this.props.iconName}
          isIconOnly={!widget.properties.label}
          label={CustomActionButton.getLabel(widget.properties)}
          onClick={this.onClick}
          size={this.props.size}
          tabIndex={
            this.props.propagated && this.props.propagated.parentTable ? -1 : 0
          }
        />
      </React.Fragment>
    );
  }
}

export default observer(CustomActionButton);

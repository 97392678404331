import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import MultilineTextField from "../coreui/MultilineTextField";
import { TableVerticalLayoutProps } from "../coreui/Table";
import PaneRow from "../models/PaneRow";
import ErrorsStore from "../stores/ErrorsStore";
import { AccessLevel } from "./AccessLevel";

interface ConfigProperties {
  dataId: string;
  dataSize: number;
  disabledHelpText: string;
  helperText: string;
  label: string;
  maxSizeError: string;
  name: string;
  propagated?: TableVerticalLayoutProps;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
  showAsMandatory: boolean;
  showDisabledHelp: boolean;
}

export class MLTextEdit extends React.Component<ConfigProperties> {
  private config: ConfigProperties;
  private dataId: string = "";
  private name: string = "";
  private rowKey?: string;

  public constructor(props: ConfigProperties) {
    super(props);

    makeObservable<
      MLTextEdit,
      "dataId" | "name" | "onValueChange" | "syncDerivedWithProps"
    >(this, {
      dataId: observable,
      name: observable,
      onValueChange: action,
      syncDerivedWithProps: action,
    });

    this.syncDerivedWithProps();
  }

  private getErrors = (value: string): string[] => {
    const row = PaneRow.get(this.dataId, this.rowKey);
    if (!row) {
      return [];
    }

    const widget = row.getWidgetT<string, RuntimeProperties>(this.name);

    const errors: string[] = [...widget.properties.businessErrors];
    if (this.config.dataSize && value && value.length > this.config.dataSize) {
      errors.push(this.config.maxSizeError);
    }

    return errors;
  };

  private onValueChange = (value: string) => {
    ErrorsStore.clearBusinessErrorsForWidget(this.dataId, this.name);

    const row = PaneRow.get(this.dataId)!;
    const widget = row.getWidgetT<string | null, RuntimeProperties>(this.name);
    widget.setValue(value === "" ? null : value);
  };

  private syncDerivedWithProps(): void {
    this.dataId = this.props.dataId;
    this.name = this.props.name;
    this.config = { ...this.props };
    this.rowKey = this.props.propagated?.rowKey;
  }

  public componentDidUpdate(): void {
    this.syncDerivedWithProps();
  }

  public render(): React.ReactNode {
    const row = PaneRow.get(this.dataId, this.rowKey);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<string | null, RuntimeProperties>(this.name);

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    return (
      <MultilineTextField
        disabled={widget.properties.accessLevel === AccessLevel.disabled}
        disabledHelpText={
          widget.properties.showDisabledHelp
            ? this.props.disabledHelpText
            : undefined
        }
        getErrors={this.getErrors}
        helperText={this.props.helperText}
        label={this.props.label}
        name={this.props.name}
        onValueChange={this.onValueChange}
        readOnly={widget.properties.accessLevel === AccessLevel.readOnly}
        required={widget.properties.showAsMandatory}
        value={widget.value || ""}
        variant="filled"
      />
    );
  }
}

export default observer(MLTextEdit);

﻿import { observer } from "mobx-react";
import * as React from "react";
import FocusManager from "../core/FocusManager";
import Localization from "../core/Localization";
import { useWidth } from "../core/Responsive";
import Button from "../coreui/Button";
import Dialog from "../coreui/Dialog";
import DialogActions from "../coreui/DialogActions";
import DialogContent from "../coreui/DialogContent";
import Icon from "../coreui/Icon";
import Typography from "../coreui/Typography";
import PaneRow, {
  RuntimeProperties as BaseRuntimeProperties,
  WidgetValue,
} from "../models/PaneRow";
import AddressSearchCriteria from "../mustangui/AddressSearchCriteria";
import { DateRangeCriteria } from "../mustangui/DateRangeCriteria";
import { DomainCheckBoxCriteria } from "../mustangui/DomainCheckBoxCriteria";
import Grid from "../mustangui/Grid";
import GridItem from "../mustangui/GridItem";
import { RuntimeProperties as HeadingRuntimeProperties } from "../mustangui/GroupHeading";
import SearchService, {
  ExecuteSearchResponse,
} from "../services/SearchService";
import ErrorsStore from "../stores/ErrorsStore";
import RequestsStore from "../stores/RequestsStore";
import { Layout, LayoutChildProps, LayoutConfig } from "./Layout";

interface HeadingInfo {
  breakPoint: string;
  dataId: string;
  name: string;
}

export interface SearchPresentationConfig extends LayoutConfig {
  criteriaPaneDataId: string;
  criteriaWidgetNames: string[];
  description: string;
  titleHeadings: HeadingInfo[];
}

export interface SearchChildProps extends LayoutChildProps {
  parentSearch: {
    clear: () => void;
    resultsCountMessage: string | null;
    search: () => void;
    succeeded: boolean;
  };
}

export interface SiteSearchInfo {
  description: string;
  helperText: string;
  isDefault: boolean;
  mandatory: boolean;
  url: string;
}

interface Props {
  autoExecute: boolean;
  config: SearchPresentationConfig;
  propagated?: LayoutChildProps;
  queryStringValues: string | null;
}

function WarningDialog(props: {
  onClose: () => void;
  open: boolean;
  title: string;
  text: string;
}): JSX.Element {
  return (
    <Dialog
      disableEscapeKeyDown={true}
      fullScreen={false}
      open={props.open}
      maxWidth="sm"
    >
      <DialogContent>
        <Grid grouping="Closely Related" xs={1}>
          <GridItem xl={1} lg={1} md={1} sm={1} xs={1}>
            <Typography variant="h3">
              <div
                className="fa-layers"
                style={{
                  marginRight: ".4em",
                  width: "1.2em",
                }}
              >
                <Icon color="warning" fixedWidth icon="far fa-triangle" />
                <Icon
                  fixedWidth
                  icon="fas fa-exclamation"
                  style={{
                    bottom: 0,
                    fontSize: ".6em",
                    left: 0,
                    margin: "0 auto",
                    position: "absolute",
                    right: 0,
                    top: ".4em",
                  }}
                />
              </div>
              {props.title}
            </Typography>
          </GridItem>
          <GridItem xl={1} lg={1} md={1} sm={1} xs={1}>
            <Typography>{props.text}</Typography>
          </GridItem>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()}>
          {Localization.getBuiltInMessage("ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export const SearchPresentation = observer((props: Props): JSX.Element => {
  const [exceededRowLimit, setExceededRowLimit] =
    React.useState<boolean>(false);
  const [resultsCountMessage, setResultsCountMessage] = React.useState<
    string | null
  >(null);
  const [succeeded, setSucceeded] = React.useState<boolean>(false);
  const [timedOut, setTimedOut] = React.useState<boolean>(false);

  const width = useWidth();
  const formRef = React.useRef<HTMLFormElement>(null);

  const focusFirstHeading = (): void => {
    if (formRef.current === null) {
      // This should never happen, but if it does it is not worth having the
      // application crash over it.
      console.warn(
        `No form element existed when attempting to focus the page heading`
      );
    } else {
      FocusManager.grabFocusForChild(
        formRef.current,
        [
          'h1[tabindex="-1"]',
          'h2[tabindex="-1"]',
          'h3[tabindex="-1"]',
          'h4[tabindex="-1"]',
          'h5[tabindex="-1"]',
          'h6[tabindex="-1"]',
        ].join(", ")
      );
    }
  };

  const closeDialog = () => {
    setExceededRowLimit(false);
    setTimedOut(false);
  };

  const clear = (): void => {
    ErrorsStore.clearErrors();
    const row: PaneRow = PaneRow.get(props.config.criteriaPaneDataId)!;

    for (const widgetName of props.config.criteriaWidgetNames) {
      const widget = row.getWidgetT<WidgetValue, BaseRuntimeProperties>(
        widgetName
      )!;

      // FUTURE: It would be better to have the widgetType defined in an
      // enumeration. Once that is done widgets can control their data
      // interface directly.
      switch (widget.widgetTypeId) {
        case AddressSearchCriteria.widgetTypeId:
          AddressSearchCriteria.clear(widgetName, row);
          break;
        case DateRangeCriteria.widgetTypeId:
          DateRangeCriteria.clear(widgetName, row);
          break;
        case DomainCheckBoxCriteria.widgetTypeId:
          DomainCheckBoxCriteria.clear(widgetName, row);
          break;
        default:
          row.setWidgetValue(widgetName, null);
      }
    }
  };

  const search = (): void => {
    ErrorsStore.clearErrors();

    RequestsStore.instance.processingStarted();
    SearchService.executeSearch(props.config.layoutId)
      .then(
        (response: ExecuteSearchResponse) => {
          setExceededRowLimit(response.exceededRowLimit);
          setResultsCountMessage(response.resultsCountMessage);
          setSucceeded(response.succeeded);
          setTimedOut(response.timedOut);
        },
        () => {
          // Do nothing on error.
        }
      )
      .finally(() => {
        RequestsStore.instance.processingStopped();
      });
  };

  React.useEffect((): void => {
    if (props.autoExecute) {
      search();
    }
  }, [props.autoExecute, props.queryStringValues]);

  React.useEffect((): void => {
    // The setTimeout appears to be necessary to ensure the runtime data is
    // loaded and everything can render appropriately. Perhaps the mobx
    // observables need another frame to catch up?
    setTimeout(() => focusFirstHeading());
  }, []);

  const propagated: SearchChildProps = {
    parentSearch: {
      clear,
      resultsCountMessage,
      search,
      succeeded,
    },
  };

  const searchWidth = width === "xl" ? "lg" : width;
  const headingInfo = props.config.titleHeadings.find(
    (h) => h.breakPoint === searchWidth
  );

  let headingText: string | undefined = undefined;
  if (headingInfo) {
    const row = PaneRow.get(headingInfo.dataId)!;
    const widget = row.getWidgetT<WidgetValue, HeadingRuntimeProperties>(
      headingInfo.name
    );
    headingText = widget.properties.headingText;
  }

  return (
    <div>
      <form aria-label={headingText} ref={formRef} role="search">
        <Layout config={props.config} propagated={propagated} />
      </form>
      <WarningDialog
        onClose={closeDialog}
        open={timedOut}
        title={Localization.getBuiltInMessage("searchTimedOutTitle")}
        text={Localization.getBuiltInMessage("searchTimedOutText")}
      />
      <WarningDialog
        onClose={closeDialog}
        open={exceededRowLimit}
        title={Localization.getBuiltInMessage("refineCriteriaTitle")}
        text={Localization.getBuiltInMessage("refineCriteriaText")}
      />
    </div>
  );
});

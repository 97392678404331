import { colors, styled } from "@mui/material";
import * as React from "react";

interface Props {
  inputElement: HTMLInputElement;
  onClose?: () => void;
  style?: React.CSSProperties;
}

interface WrapperProps {
  isFocused: boolean;
}

const Wrapper = styled("div")<WrapperProps>(({ isFocused }) => ({
  "& > div > div:after": {
    transform: isFocused ? "scaleX(1)" : undefined,
  },
  backgroundColor: colors.common.white,
  outline: "none",
  position: "absolute",
  right: 32,
}));

export const inputWrapperClassName = "input-wrapper";

export const ToolbarInputWrapper = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<Props>
>(
  (
    props: React.PropsWithChildren<Props>,
    ref: React.ForwardedRef<HTMLDivElement>
  ): JSX.Element => {
    const rootRef = React.useRef<HTMLDivElement>(null);
    const [isFocused, setIsFocused] = React.useState<boolean>(false);

    React.useImperativeHandle(ref, () => rootRef.current!, []);

    const onBlur = (event: React.FocusEvent<HTMLDivElement>) => {
      if (!event.currentTarget.contains(event.relatedTarget as Node)) {
        setIsFocused(false);
      }
    };

    const onFocus = (event: React.FocusEvent<HTMLDivElement>) => {
      if (!event.currentTarget.contains(event.relatedTarget as Node)) {
        setIsFocused(true);
      }
    };

    const onKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Enter") {
        if (event.target === rootRef.current) {
          props.inputElement.focus();
        } else {
          rootRef.current!.focus();
        }
      }

      if (
        event.key === "Escape" &&
        event.target !== rootRef.current &&
        props.onClose
      ) {
        props.onClose();
      }
    };

    return (
      <Wrapper
        className={inputWrapperClassName}
        isFocused={isFocused}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyUp={onKeyUp}
        ref={rootRef}
        style={props.style} // Required by the Grow component that wraps this component
        tabIndex={-1}
      >
        {props.children}
      </Wrapper>
    );
  }
);

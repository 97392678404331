import { colors, CircularProgress as MuiCircularProgress } from "@mui/material";
import * as React from "react";

interface Props {
  size?: "standard" | "mini";
  style?: React.CSSProperties;
}

export function CircularProgress(props: Props): JSX.Element {
  return (
    <MuiCircularProgress
      size={props.size === "mini" ? 24 : 80}
      style={props.style}
      sx={{ color: colors.lightGreen[500] }}
      thickness={props.size === "mini" ? 3.6 : 2.6}
    />
  );
}

import * as React from "react";
import Presentation from "../coreui/Presentation";
import { ProjectGridControlChildProps } from "./ProjectGridControl";

export interface ConfigProperties {
  childWidget: object & { type: string };
  propagated: ProjectGridControlChildProps;
}

export default function ProjectGridContainerItem(
  props: ConfigProperties
): JSX.Element {
  return (
    <React.Fragment>
      {Presentation.create(props.childWidget, props.propagated)}
    </React.Fragment>
  );
}

import { Breakpoint, Theme } from "@mui/material";
import {
  createStyles,
  CSSProperties,
  WithStyles,
  withStyles,
  WithTheme,
  withTheme,
} from "@mui/styles";
import { observer } from "mobx-react";
import * as React from "react";
import FreeflowContainerItem, {
  FreeflowContainerItem as FreeflowContainerItemBase,
  ConfigProperties as ItemConfig,
} from "./FreeflowContainerItem";

export interface ConfigProperties {
  items: ItemConfig[];
}

interface Props extends ConfigProperties {
  dataId: string;
  propagated: object;
}

const styles = (theme: Theme) => {
  const visibilityStyles = {};
  for (const breakPoint of theme.visibilityBreakPoints) {
    visibilityStyles[`root-visible-${breakPoint}`] = {
      [theme.breakpoints.only(breakPoint)]: {
        display: "flex",
      },
    };
  }

  const groupSpaceStyle = {};
  for (const breakPoint of theme.spacingBreakPoints) {
    const itemSpacing = theme.freeflow.item.spacing[breakPoint];
    const groupSpacing = theme.freeflow.group.spacing[breakPoint];

    groupSpaceStyle[theme.breakpoints.up(breakPoint)] = {
      marginBottom: -itemSpacing.vertical * 0.5,
      marginLeft: -itemSpacing.horizontal * 0.5,
      marginRight: -itemSpacing.horizontal * 0.5,
      marginTop: -itemSpacing.vertical * 0.5,

      paddingBottom: groupSpacing.vertical * 0.5,
      paddingLeft: groupSpacing.horizontal * 0.5,
      paddingRight: groupSpacing.horizontal * 0.5,
      paddingTop: groupSpacing.vertical * 0.5,
    };
  }

  const result = {
    root: {
      alignItems: "center",
      display: "none",
      flexWrap: "wrap",
      justifyContent: "inherit",
      minWidth: 0,
      ...groupSpaceStyle,
    } as CSSProperties,
    ...visibilityStyles,
  };

  return createStyles(result);
};

export class FreeflowContainerGroup extends React.Component<
  Props & WithStyles<typeof styles> & WithTheme
> {
  public static isVisible(
    dataId: string,
    group: ConfigProperties,
    breakPoint: Breakpoint
  ): boolean {
    return group.items.some((i) =>
      FreeflowContainerItemBase.isVisible(dataId, i, breakPoint)
    );
  }

  public render(): React.ReactNode {
    const classes: string[] = [this.props.classes.root];
    const theme = this.props.theme as Theme;

    for (const breakPoint of theme.visibilityBreakPoints) {
      if (
        FreeflowContainerGroup.isVisible(
          this.props.dataId,
          this.props,
          breakPoint
        )
      ) {
        const className = `root-visible-${breakPoint}`;
        classes.push(this.props.classes[className]);
      }
    }

    return (
      <div className={classes.join(" ")}>
        {this.props.items.map((item: ItemConfig, index: number) => (
          <FreeflowContainerItem
            {...item}
            dataId={this.props.dataId}
            key={index}
            propagated={this.props.propagated}
          />
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(withTheme(observer(FreeflowContainerGroup)));

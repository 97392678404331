import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  Paper as MuiPaper,
  Theme,
} from "@mui/material";
import { createStyles, makeStyles, withStyles } from "@mui/styles";
import { observer } from "mobx-react";
import * as React from "react";
import Icon from "../coreui/Icon";
import Presentation from "../coreui/Presentation";
import { TableChildProps } from "../coreui/Table";
import PaneRow from "../models/PaneRow";
import PaneDataStore from "../stores/PaneDataStore";
import GridColumn, { GridColumnConfigProperties } from "./Columns/GridColumn";
import ProjectCurrentJobIndicator from "./ProjectCurrentJobIndicator";
import { ProjectGrid as ProjectGridBase } from "./ProjectGrid";

const Accordion = withStyles((theme: Theme) =>
  createStyles({
    disabled: {},
    expanded: {},
    root: {
      "&$expanded": {
        margin: "auto",
      },
      "&:before": {
        display: "none",
      },
      borderTop: "1px solid",
      borderTopColor: theme.palette.divider,
      width: "100%",
    },
  })
)(MuiAccordion);

const AccordionSummary = withStyles((theme: Theme) =>
  createStyles({
    content: {
      alignItems: "center",
    },
    disabled: {},
    expanded: {},
    root: {
      "&$disabled": {
        opacity: 1,
      },
    },
  })
)(MuiAccordionSummary);

const Paper = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderTop: "1px solid",
      borderTopColor: theme.palette.divider,
      padding: 16,
    },
  })
)(MuiPaper);

export interface ProjectGridVerticalHierarchyChildProps {
  parentProjectGridVerticalHierarchy: {
    onFilterChanged: (externalFilter: (row: PaneRow) => boolean) => void;
  };
}

export interface ConfigProperties {
  cardDepth: number;
  dataId: string;
  headerToolbarChild?: object;
  propagated: TableChildProps;
}

interface HierarchyNode {
  children: HierarchyNode[];
  level: number;
  parent: HierarchyNode | null;
  row: PaneRow;
}

interface NodeProps {
  cardDepth: number;
  columns: GridColumnConfigProperties[];
  node: HierarchyNode;
  rootNodeLevel: number;
}

const useNodeStyles = makeStyles((theme: Theme) => ({
  card: {
    backgroundColor: theme.palette.grey[100],
  },
  cardAlternate: {
    backgroundColor: theme.palette.common.white,
  },
  column: {
    "&:after": {
      content: '","',
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      marginRight: 4,
    },
    "&:last-child": {
      "&:after": {
        display: "none",
      },
    },
  },
}));

export default class ProjectGridVerticalHierarchy {
  private static renderNode = observer(
    (props: NodeProps): JSX.Element | null => {
      const classes = useNodeStyles();
      const [isExpanded, setIsExpanded] = React.useState(true);

      const onChange = (): void => {
        setIsExpanded(!isExpanded);
      };

      if (!props.node.row.isVisible) {
        return null;
      }

      const indentLevel: number = props.node.level - props.rootNodeLevel;

      const contents: React.ReactNode = (
        <div
          style={{
            flexGrow: 1,
            marginLeft: 16 * indentLevel,
          }}
        >
          <ProjectCurrentJobIndicator
            data={props.node.row}
            style={{ marginBottom: 16 }}
          />
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {props.columns
              .filter((c) => c.xs)
              .map((c) => (
                <GridColumn.renderInline
                  className={classes.column}
                  column={c}
                  key={c.name}
                  row={props.node.row}
                />
              ))}
          </div>
        </div>
      );

      const rootClasses: string[] = [];
      if (props.cardDepth % 2 === 0) {
        rootClasses.push(classes.card);
      } else {
        rootClasses.push(classes.cardAlternate);
      }

      const visibleChildren: HierarchyNode[] = props.node.children.filter(
        (n) => n.row.isVisible
      );
      if (visibleChildren.length === 0) {
        return (
          <Paper
            className={rootClasses.join(" ")}
            elevation={0}
            square
            tabIndex={0}
          >
            {contents}
          </Paper>
        );
      }

      return (
        <Accordion
          className={rootClasses.join(" ")}
          elevation={0}
          expanded={isExpanded}
          onChange={onChange}
          square
        >
          <AccordionSummary>
            {contents}
            <Icon
              icon={isExpanded ? "fas fa-minus" : "fas fa-plus"}
              style={{
                height: 16,
                marginLeft: 16,
                marginRight: -1,
              }}
            />
          </AccordionSummary>
          <div>
            {props.node.children.map((n) => (
              <ProjectGridVerticalHierarchy.renderNode
                cardDepth={props.cardDepth}
                columns={props.columns}
                key={n.row.rowKey}
                node={n}
                rootNodeLevel={props.rootNodeLevel}
              />
            ))}
          </div>
        </Accordion>
      );
    }
  );

  public static render = observer(
    (props: ConfigProperties): JSX.Element | null => {
      const rootNode: HierarchyNode | null =
        ProjectGridVerticalHierarchy.getHierarchy(props.dataId);

      if (rootNode === null) {
        return null;
      }

      const onFilterChanged = (externalFilter: (row: PaneRow) => boolean) => {
        const rows: PaneRow[] = ProjectGridBase.getPartialHierarchyRows(
          PaneDataStore.getPaneCollection(props.dataId)
        );

        for (const row of rows) {
          row.isVisible = externalFilter(row);
        }
      };

      const childPropagated = {
        parentProjectGridVerticalHierarchy: {
          onFilterChanged,
        },
        ...props.propagated,
      } as TableChildProps & ProjectGridVerticalHierarchyChildProps;
      childPropagated.parentTable.isVerticalLayout = true;

      const columns = props.propagated.parentTable.columns;

      return (
        <div>
          {Presentation.create(props.headerToolbarChild, childPropagated)}
          <ProjectGridVerticalHierarchy.renderNode
            cardDepth={props.cardDepth}
            columns={columns as GridColumnConfigProperties[]}
            node={rootNode}
            rootNodeLevel={rootNode.level}
          />
        </div>
      );
    }
  );

  private static getHierarchy(dataId: string): HierarchyNode | null {
    const rows: PaneRow[] = ProjectGridBase.getPartialHierarchyRows(
      PaneDataStore.getPaneCollection(dataId)
    );

    let previousNode: HierarchyNode | null = null;
    let rootNode: HierarchyNode | null = null;

    for (const row of rows) {
      const newNode = {
        children: [],
        level: row.hierarchyLevel!,
        parent: null,
        row,
      } as HierarchyNode;

      if (previousNode === null) {
        // The first row will always be the root of the hierarchy and
        // therefore does not have a parent.
        previousNode = newNode;
        rootNode = newNode;
        continue;
      }

      // Traverse the node parentage, starting with the previous node, to
      // find the parent for the new node. Because the hierarchy rows are
      // in order, the parent node will always be either the previous node
      // or one of its ancestors - all that is required is to find the
      // node at the appropriate level.
      let parentNode = previousNode;
      while (parentNode.level >= newNode.level) {
        parentNode = parentNode.parent!;
      }

      newNode.parent = parentNode;
      parentNode.children.push(newNode);
      previousNode = newNode;
    }

    if (previousNode === null) {
      // Only possible if the pane data store is not yet loaded.
      return null;
    }

    return rootNode;
  }
}

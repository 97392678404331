import { Theme } from "@mui/material";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import { observer } from "mobx-react";
import * as React from "react";
import FormData from "../coreui/FormData";
import Icon from "../coreui/Icon";
import { TableVerticalLayoutProps } from "../coreui/Table";
import PaneRow from "../models/PaneRow";
import { AccessLevel } from "./AccessLevel";

interface ConfigProperties {
  dataId: string;
  label?: string;
  name: string;
  propagated: TableVerticalLayoutProps;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  alternateText: string;
  iconName: string;
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      alignItems: "left",
      display: "flex",
      width: "16px",
    },
    icon: {
      fontSize: 16,
      height: "auto",
    },
  });

export class IconDisplayVerticalLayout extends React.Component<
  ConfigProperties & WithStyles<typeof styles>
> {
  public render(): React.ReactNode {
    const row = PaneRow.get(this.props.dataId, this.props.propagated.rowKey)!;
    const widget = row.getWidgetT<null, RuntimeProperties>(this.props.name);

    if (widget.properties.accessLevel <= AccessLevel.hidden) {
      return null;
    }

    const icon = (
      <div className={this.props.classes.container}>
        <Icon
          aria-label={widget.properties.alternateText}
          className={this.props.classes.icon}
          icon={widget.properties.iconName}
        />
      </div>
    );

    if (this.props.label) {
      return <FormData label={this.props.label}>{icon}</FormData>;
    }

    return icon;
  }
}

export default withStyles(styles)(observer(IconDisplayVerticalLayout));

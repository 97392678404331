import { AddOnHost } from "../config";
import Localization from "../core/Localization";
import Logging from "../core/Logging";
import PaneRow from "../models/PaneRow";
import { GridItem } from "../mustangui/GridItem";
import BaseService from "../services/BaseService";
import SystemConfigService from "../services/SystemConfigService";
import ConfirmContinueStore from "../stores/ConfirmContinueStore";
import RequestsStore from "../stores/RequestsStore";
import AppServer from "./AppServer";
import AriaHiddenManager from "./AriaHiddenManager";
import Routing from "./Routing";
import { TraceKey } from "./TraceKey";
import TrackableCollection from "./TrackableCollection";
import TrackableModel from "./TrackableModel";

export interface ISettings {
  availableLanguages: { code: string; description: string }[];
  bodyIntegrationBlock: string | null;
  builtInMessages: object;
  currentLanguageCode: string;
  decimalSeparator: string;
  enableEmailAuthentication: boolean;
  environmentBannerColor: string | null;
  headerIntegrationBlock: string | null;
  favIcon: string;
  nonProdEnvironment: string;
  reCaptchaBaseUrl: string;
  reCaptchaSiteKey: string;
  rootUrl: string;
  signOutUrl: string;
  siteName: string;
  thousandsSeparator: string;
  useConfiguredAuthentication: boolean;
}

export default class Sys {
  private static currentNextId: number = 1;
  // Grab the cookie path from the url, this works since it's a single page app
  private static readonly cookiePath = window.location.pathname || "/";
  protected static methodTimers = new Map<string, number>();
  public static guestSessionTokenCookie: string = "guestSessionToken";
  // Indicates if pending changes should be ignored.
  public static ignoreChanges: boolean = false;
  // Indicates if changes to the hash should be ignored.
  public static ignoreHashChanges: boolean = false;
  public static isChrome: boolean =
    window.navigator.userAgent.includes("Chrome") &&
    !window.navigator.userAgent.includes("Edge");
  public static isEdge: boolean = window.navigator.userAgent.includes("Edge");
  public static isFirefox: boolean =
    window.navigator.userAgent.includes("Firefox");
  public static isMobile: boolean =
    window.navigator.userAgent.includes("Android") ||
    window.navigator.userAgent.includes("BlackBerry") ||
    window.navigator.userAgent.includes("iPad") ||
    window.navigator.userAgent.includes("iPhone") ||
    window.navigator.userAgent.includes("webOS") ||
    window.navigator.userAgent.includes("Windows Phone");
  public static isSafari: boolean =
    window.navigator.userAgent.includes("Safari") &&
    !window.navigator.userAgent.includes("Chrome");
  public static languageCodeCookie: string = "languageCode";
  public static lastHash: object | null = null;
  public static sessionTokenCookie: string = "authenticatedSessionToken";
  // Must provide initial value for ajaxTimeout so settings can be requested.
  // Bracket placement is non standard because of linter bug.
  public static settings: ISettings = {
    availableLanguages: [],
    bodyIntegrationBlock: "",
    builtInMessages: Object.create(null),
    currentLanguageCode: "",
    decimalSeparator: ".",
    enableEmailAuthentication: false,
    environmentBannerColor: null,
    externalAuthenticators: [
      {
        description: "",
        iconName: "",
        providerName: "",
        url: "",
      },
    ],
    favIcon: "",
    headerIntegrationBlock: "",
    nonProdEnvironment: "",
    reCaptchaBaseUrl: "",
    reCaptchaSiteKey: "",
    rootUrl: "",
    signOutUrl: "",
    siteName: "",
    thousandsSeparator: ",",
    useConfiguredAuthentication: false,
  } as ISettings;

  public static get nextId(): number {
    return Sys.currentNextId++;
  }

  public static announce(message: string, assertive: boolean = false) {
    let announcer: HTMLElement | null = null;
    let messageDiv: HTMLElement | null = null;

    if (assertive) {
      announcer = document.getElementById("announcerAssertive");
    } else {
      announcer = document.getElementById("announcer");
    }

    if (announcer) {
      messageDiv = document.createElement("div");
      messageDiv.innerText = message;
      announcer.appendChild(messageDiv);

      // Allow dom to update before cleaning up.
      setTimeout(() => {
        announcer!.removeChild(messageDiv as Node);
      }, 10000);
    }
  }

  public static bodyIntegrationBlock() {
    // Include content from the BodyIntegrationBlock.
    if (this.settings.bodyIntegrationBlock) {
      const range = document.createRange();
      range.selectNode(document.body);
      const fragment = range.createContextualFragment(
        this.settings.bodyIntegrationBlock
      );
      document.body.appendChild(fragment);
    }
  }

  // Prompts the user if outstanding changes exist.
  public static async confirmContinue(
    clearState: boolean = true
  ): Promise<boolean> {
    const promise = new Promise<boolean>((resolve, reject) => {
      if (Sys.ignoreChanges) {
        resolve(true);
        return;
      }

      if (Sys.hasChanges()) {
        ConfirmContinueStore.instance.openDialog(resolve, reject);
      } else {
        resolve(true);
      }
    });

    if (!Sys.ignoreChanges && Sys.hasChanges() && clearState) {
      promise
        .then(() => {
          AppServer.clearState();
        })
        .catch(() => {
          /* Do nothing */
        });
    }

    return promise;
  }

  public static debounceMethod(method: Function, key: string, delay: number) {
    if (Sys.methodTimers.has(key)) {
      window.clearTimeout(Sys.methodTimers.get(key));
      Sys.methodTimers.delete(key);
    }

    Sys.methodTimers.set(
      key,
      window.setTimeout(() => {
        method();
        Sys.methodTimers.delete(key);
      }, delay)
    );
  }

  public static defer(
    method: Function,
    deferPeriod: number,
    scope: object,
    args: IArguments
  ): void {
    if (deferPeriod) {
      setTimeout(() => {
        method.call(scope, ...args);
      }, deferPeriod);
    } else {
      method.call(scope, ...args);
    }
  }

  public static deleteCookie(name: string): void {
    if (name) {
      // eslint-disable-next-line max-len
      document.cookie = `${encodeURIComponent(name)}=; path=${
        Sys.cookiePath
      }; expires==Thu, 01 Jan 1970 00:00:01 GMT`;
    }
  }

  public static getCookie(name: string): string | null {
    let result: string | null = null;
    let cookies: string[];

    if (name && document.cookie) {
      cookies = document.cookie.split(";");

      cookies.forEach((cookie) => {
        const cookieParts: string[] = cookie.split("=");

        if (cookieParts[0].trim() === encodeURIComponent(name)) {
          result = decodeURIComponent(cookieParts[1]);
        }
      });
    }

    return result;
  }

  // Indicates if the application has any pending changes.
  public static hasChanges(): boolean {
    const items = Array.from(TrackableModel.models.values());

    if (items.length === 0) {
      return false;
    }

    if (AppServer.hasChanges()) {
      return true;
    }

    return items.some((item) => !item.ignoreChanges && item.hasChanges());
  }

  public static headerIntegrationBlock() {
    // Include content from the HeaderIntegrationBlock.
    if (this.settings.headerIntegrationBlock) {
      const range = document.createRange();
      range.selectNode(document.head);
      const fragment = range.createContextualFragment(
        this.settings.headerIntegrationBlock
      );
      document.head.appendChild(fragment);
    }
  }

  public static async initialize() {
    if (!Sys.settings.useConfiguredAuthentication) {
      if (await Routing.isNonConfiguredAuthenticationSessionExpiredRoute()) {
        return;
      }
    }

    window["mustang"] = {
      AddOnHost, // Used by PosseAPI_WebUI.js
      BaseService,
      Logging,
      PaneRow,
      Sys,
      TrackableCollection,
      TrackableModel,
    };

    window["PosseClearTraceKey"] = () => TraceKey.clear();
    window["PosseSetTraceKey"] = (traceKey: string | undefined): void => {
      if (traceKey === undefined) {
        TraceKey.prompt();
      } else {
        TraceKey.set(traceKey);
      }
    };

    window.addEventListener("error", (event) => {
      const fileName = event.filename.toLowerCase();
      const rootUrl = Sys.settings.rootUrl.toLowerCase();
      console.log(event);
      if (fileName.indexOf(rootUrl) === -1) {
        console.error(
          `A third party extension at ${event.filename} raised the following exception:`,
          event.error
        );
        return;
      }

      if (event.message.includes("ResizeObserver")) {
        console.warn("ResizeObserver raised the following error:", event.error);
        return;
      }

      console.error(event.error);

      if (process.env.NODE_ENV === "production" && !Routing.isAt("error")) {
        Routing.goToErrorPage(null);
      } else {
        // Ensure loading animation is removed.
        RequestsStore.instance.clearAllProcessing();
      }
    });

    if (!Sys.isEdge) {
      window.addEventListener("dragover", (event) => {
        if (event.dataTransfer) {
          event.dataTransfer.dropEffect = "none";
        }

        event.preventDefault();

        return false;
      });
    }

    (document.body as HTMLBodyElement).onbeforeunload = Sys.onBeforeUnload;

    GridItem.initialize();

    let languageCode: string | null = null;
    const hash = Routing.getHash();

    if (hash) {
      languageCode = hash["languageCode"] as string;
      if (languageCode) {
        Sys.deleteCookie(Sys.languageCodeCookie);
        delete hash["languageCode"];
        Sys.setHash(hash, false, true);
      }

      if (!!hash["PosseSessionToken"]) {
        Sys.deleteCookie(Sys.guestSessionTokenCookie);
        Sys.setCookie(
          Sys.sessionTokenCookie,
          hash["PosseSessionToken"] as string
        );
        delete hash["PosseSessionToken"];

        if (Object.keys(hash).length) {
          Sys.setHash(hash, false, true, true);
        } else {
          Sys.setHash("", false, true, true);
        }
      }
    }

    const success: boolean = await SystemConfigService.loadConfig(languageCode);

    if (languageCode) {
      window.location.reload();
    }

    if (success) {
      this.headerIntegrationBlock();
      this.bodyIntegrationBlock();
      await Routing.initialize();
    }

    // FUTURE
    // Fixes material ui bug where setting modal dialogs or masks sets
    // aria-hidden on all tags in the body. See this bug report:
    // https://github.com/mui-org/material-ui/issues/19450
    AriaHiddenManager.supressAriaHidden(document.getElementById("announcer")!);
    AriaHiddenManager.supressAriaHidden(
      document.getElementById("announcerAssertive")!
    );
  }

  // Returns the specified object as an escaped query string.
  public static objectToQueryString(object: object): string {
    return Object.keys(object)
      .map((key) => {
        let result: string;

        if (isNaN(parseInt(key, 10))) {
          const rawValue = object[key];

          // Convert all values to arrays so the same code path can be
          // followed when adding them to the result.
          const values: string[] = Array.isArray(rawValue)
            ? (rawValue as Array<string>)
            : [rawValue as string];

          const uriKey = encodeURIComponent(key);
          result = values
            .map((v) => `${uriKey}=${encodeURIComponent(v)}`)
            .join("&");
        } else {
          result = object[key];
        }

        return result;
      })
      .join("&");
  }

  // Before unload event handler for the body.
  public static onBeforeUnload(event: BeforeUnloadEvent) {
    if (Sys.ignoreChanges) {
      return;
    }

    if (Sys.hasChanges()) {
      event.returnValue = Localization.getBuiltInMessage("outstandingChanges");
    }
  }

  // Determine when the document has been loaded.
  public static onReadyStateChange() {
    if (document.readyState === "complete") {
      Sys.initialize();
    }
  }

  public static setCookie(name: string, value: string, days: number = 0): void {
    if (name) {
      let expires: string | null = null;

      if (days > 0) {
        const today = new Date();
        expires = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() + days,
          0,
          0,
          1
        ).toUTCString();
      }

      document.cookie =
        `${encodeURIComponent(name)}=${encodeURIComponent(value)}; ` +
        `path=${Sys.cookiePath}; expires=${expires}`;
    }
  }

  // Sets the current hash value.
  public static setHash(
    value: string | object,
    merge: boolean = false,
    replaceHash: boolean = false,
    ignoreHashChanges: boolean = false
  ) {
    const hash = Routing.getHash();
    let hashString: string;

    if (replaceHash) {
      const href = window.location.href.split("#")[0];

      if (typeof value === "string") {
        window.history.replaceState(null, "", `${href}#${value}`);
      } else {
        if (merge) {
          window.history.replaceState(
            null,
            "",
            `${href}#${Sys.objectToQueryString(
              Object.assign(hash || Object.create(null), value)
            )}`
          );
        } else {
          window.history.replaceState(
            null,
            "",
            `${href}#${Sys.objectToQueryString(value)}`
          );
        }
      }
    } else {
      if (typeof value === "string") {
        hashString = value;
      } else {
        if (merge) {
          hashString = Sys.objectToQueryString(
            Object.assign(hash || Object.create(null), value)
          );
        } else {
          hashString = Sys.objectToQueryString(value);
        }
      }

      if (window.location.hash !== hashString) {
        if (ignoreHashChanges) {
          Sys.ignoreHashChanges = true;
        }

        window.location.hash = hashString;
      }
    }
  }
}

import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../../core/Localization";
import Sys from "../../core/Sys";
import TrackableCollection from "../../core/TrackableCollection";
import TrackableModel from "../../core/TrackableModel";
import MenuItem from "../../coreui/MenuItem";
import { TableChildProps } from "../../coreui/Table";
import PaneRow from "../../models/PaneRow";
import { RoundTripProps } from "../../services/ActionButtonService";
import { AccessLevel } from "../AccessLevel";
import { ActionButtonProps, ActionButtonRuntimeProps } from "../ActionButton";
import ApiButton from "../ApiButton";
import { MenuItemProps } from "../MenuItem";
import { SelectChildProps } from "../SelectControl";
import ConfirmationDialog from "./ConfirmationDialog";

interface Props extends ActionButtonProps {}

interface State {
  isConfirmDialogOpen?: boolean;
}

export class DeleteRowButton extends React.Component<Props, State> {
  private static getLabel(runtimeProperties: ActionButtonRuntimeProps): string {
    const label = !!runtimeProperties.label
      ? runtimeProperties.label
      : Localization.getBuiltInMessage("DataTable.deleteRowLabel");

    return label;
  }

  private static onClick(
    props: RoundTripProps & {
      propagated: TableChildProps & SelectChildProps;
    }
  ): void {
    const parentTable = props.propagated.parentTable;

    const row = PaneRow.get(props.dataId)!;
    const widget = row.getWidgetT<null, ActionButtonRuntimeProps>(props.name);
    const deleteAnnouncement = Localization.getBuiltInMessage(
      "Button.succeeded",
      {
        label: DeleteRowButton.getLabel(widget.properties),
      }
    );

    if (props.propagated.parentSelect) {
      let objectHandles: string[];
      if (parentTable.isVerticalLayout) {
        objectHandles = [props.propagated.parentRowObjectHandle!];
      } else {
        objectHandles = parentTable.selection
          .getSelectedRows()
          .map((r) => r.objectHandle);
      }

      parentTable.getTable().focusHeaderToolbar();
      props.propagated.parentSelect.onRowsRemoved(objectHandles);
      Sys.announce(deleteAnnouncement);

      return;
    }

    // Do not need to call focusHeaderToolbar() for grids with columns
    // because focus is retained by the toolbar when the toolbars switch.
    if (parentTable.isVerticalLayout) {
      TrackableCollection.deleteRow(
        parentTable.configProps.contentDataId!,
        props.propagated.parentRowKey!
      );

      parentTable.getTable().focusHeaderToolbar();
    } else {
      const collection = TrackableModel.models.get(
        parentTable.configProps.contentDataId!
      ) as TrackableCollection;

      collection.delete(parentTable.selection.getSelectedRows());
    }

    Sys.announce(deleteAnnouncement);
    parentTable.populateData();
  }

  public static renderMenuItem(props: MenuItemProps): JSX.Element {
    const { config, runtime, ...otherProps } = props;
    const configProps = config as unknown as Props;
    const runtimeProps = runtime as ActionButtonRuntimeProps;

    const [isConfirmDialogOpen, setIsConfirmDialogOpen] =
      React.useState<boolean>(false);

    const onClick = () => {
      if (props.runtime.accessLevel >= AccessLevel.actionable) {
        if (runtimeProps.confirmMessage) {
          setIsConfirmDialogOpen(true);
        } else {
          DeleteRowButton.onClick(configProps);
          configProps.propagated.onItemClicked!();
        }
      }
    };

    const onAcceptConfirm = () => {
      setIsConfirmDialogOpen(false);
      DeleteRowButton.onClick(configProps);
      configProps.propagated.onItemClicked!();
    };

    const onCancelConfirm = () => {
      setIsConfirmDialogOpen(false);
    };

    return (
      <React.Fragment>
        {runtimeProps.confirmMessage ? (
          <ConfirmationDialog
            cancelButtonText={runtimeProps.cancelButtonText!}
            continueButtonColor={configProps.buttonColor}
            continueButtonIcon={configProps.iconName}
            continueButtonText={runtimeProps.continueButtonText!}
            isOpen={isConfirmDialogOpen}
            message={runtimeProps.confirmMessage}
            onCancel={onCancelConfirm}
            onContinue={onAcceptConfirm}
            title={runtimeProps.confirmTitle!}
          />
        ) : null}
        <MenuItem
          disabled={props.runtime.accessLevel === AccessLevel.disabled}
          iconName={configProps.iconName}
          indent={props.config.propagated ? props.config.propagated.indent : 0}
          onClick={onClick}
          {...otherProps}
        >
          {runtimeProps.label}
        </MenuItem>
      </React.Fragment>
    );
  }

  public constructor(props: Props) {
    super(props);

    this.state = { isConfirmDialogOpen: false };
  }

  private onAcceptConfirm = () => {
    this.setState({ isConfirmDialogOpen: false });
    DeleteRowButton.onClick(this.props);
  };

  private onCancelConfirm = () => {
    this.setState({ isConfirmDialogOpen: false });
  };

  private onClick = () => {
    const row = PaneRow.get(this.props.dataId)!;
    const widget = row.getWidgetT<null, ActionButtonRuntimeProps>(
      this.props.name
    );

    if (widget.properties.confirmMessage) {
      this.setState({ isConfirmDialogOpen: true });
    } else {
      DeleteRowButton.onClick(this.props);
    }
  };

  public render() {
    const row = PaneRow.get(this.props.dataId);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<null, ActionButtonRuntimeProps>(
      this.props.name
    );

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    return (
      <React.Fragment>
        {widget.properties.confirmMessage ? (
          <ConfirmationDialog
            cancelButtonText={widget.properties.cancelButtonText!}
            continueButtonColor={this.props.buttonColor}
            continueButtonIcon={this.props.iconName}
            continueButtonText={widget.properties.continueButtonText!}
            isOpen={this.state.isConfirmDialogOpen!}
            message={widget.properties.confirmMessage}
            onCancel={this.onCancelConfirm}
            onContinue={this.onAcceptConfirm}
            title={widget.properties.confirmTitle!}
          />
        ) : null}
        <ApiButton
          alternateText={widget.properties.alternateText}
          buttonColor="danger"
          disabled={widget.properties.accessLevel === AccessLevel.disabled}
          disabledHelpText={this.props.disabledHelpText}
          disabledHelpVisible={widget.properties.showDisabledHelp}
          iconName={this.props.iconName}
          isIconOnly={!widget.properties.label}
          label={DeleteRowButton.getLabel(widget.properties)}
          onClick={this.onClick}
          size="small"
          tabIndex={-1}
        />
      </React.Fragment>
    );
  }
}

export default observer(DeleteRowButton);

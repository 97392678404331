import { Autocomplete } from "@mui/material";
import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import Icon from "../coreui/Icon";
import TextField from "../coreui/TextField";
import Typography from "../coreui/Typography";
import PaneRow, { RuntimeWidget } from "../models/PaneRow";
import AddressSearchService from "../services/AddressSearchService";
import { AccessLevel } from "./AccessLevel";

export interface AddressSearchCriteriaValue {
  addressValue: object | null;
  userValue: string | null;
}

export interface Props {
  dataId: string;
  geocodeApiUrlFindAddressCandidates: string;
  geocodeApiUrlSuggest: string;
  helperText: string;
  label: string;
  mandatory: boolean;
  name: string;
  noResultsMessage: string;
  propagated?: object;
}

interface Option {
  isCollection?: boolean;
  magicKey: string;
  text: string;
}

interface State {
  didInputChange: boolean;
  options: Option[];
}

export interface RuntimeProperties {
  accessLevel: AccessLevel;
  category: string;
  countryCode: string;
  location: number[];
  searchExtent: number[];
}

export class AddressSearchCriteria extends React.PureComponent<Props, State> {
  public static readonly widgetTypeId: number = 83;

  private accessToken: string | null = null;
  private dataId: string = "";
  private name: string = "";

  public static clear(widgetName: string, row: PaneRow): void {
    const widget = row.getWidgetT<
      AddressSearchCriteriaValue,
      RuntimeProperties
    >(widgetName);

    widget.setValue({
      addressValue: null,
      userValue: null,
    });
  }

  public static isEntered(widgetName: string, row: PaneRow): boolean {
    const widget = row.getWidgetT<
      AddressSearchCriteriaValue,
      RuntimeProperties
    >(widgetName);

    return widget.value.addressValue !== null;
  }

  public constructor(props: Props) {
    super(props);

    makeObservable<
      AddressSearchCriteria,
      | "dataId"
      | "getSuggestions"
      | "name"
      | "onValueChange"
      | "syncDerivedWithProps"
    >(this, {
      dataId: observable,
      getSuggestions: action,
      name: observable,
      onValueChange: action,
      syncDerivedWithProps: action,
    });

    this.state = { didInputChange: false, options: [] };

    this.syncDerivedWithProps();
  }

  private async getCandidate(option: Option): Promise<void> {
    const row: PaneRow = PaneRow.get(this.dataId)!;
    const widget = row.getWidgetT<
      AddressSearchCriteriaValue,
      RuntimeProperties
    >(this.name);

    const esriResponse = await AddressSearchService.getCandidates(
      this.props.geocodeApiUrlFindAddressCandidates,
      widget.properties.category,
      widget.properties.location,
      option.magicKey,
      widget.properties.searchExtent,
      option.text,
      widget.properties.countryCode,
      this.accessToken
    );

    if (esriResponse.candidates) {
      widget.setValue({
        addressValue: esriResponse.candidates[0].attributes,
        userValue: widget.value.userValue,
      });
    } else if (esriResponse.error && esriResponse.error.code === 498) {
      await this.getToken();
      return this.getCandidate(option);
    }
  }

  private async getSuggestions(userValue: string): Promise<void> {
    const row: PaneRow = PaneRow.get(this.props.dataId)!;
    const widget = row.getWidgetT<
      AddressSearchCriteriaValue,
      RuntimeProperties
    >(this.name);

    widget.setValue({
      addressValue: widget.value.addressValue,
      userValue,
    });

    try {
      const esriResponse = await AddressSearchService.getSuggestions(
        this.props.geocodeApiUrlSuggest,
        widget.properties.category,
        widget.properties.countryCode,
        widget.properties.location,
        widget.properties.searchExtent,
        userValue,
        this.accessToken
      );

      if (esriResponse.suggestions) {
        this.setState({
          options: esriResponse.suggestions
            .filter((s) => !s.isCollection)
            .map((s) => ({
              magicKey: s.magicKey,
              text: s.text,
            })),
        });
      } else {
        this.setState({ options: [] });
        if (esriResponse.error && esriResponse.error.code === 498) {
          await this.getToken();
          return this.getSuggestions(userValue);
        }
      }
    } catch {
      this.setState({ options: [] });
    }
  }

  private async getToken(): Promise<void> {
    const row: PaneRow = PaneRow.get(this.dataId)!;

    this.accessToken = await AddressSearchService.getToken(
      row.rowKey,
      this.dataId,
      this.name
    );
  }

  private onInputChange = (event: React.SyntheticEvent, value: string) => {
    this.setState({ didInputChange: true });
    this.getSuggestions(value);
  };

  private onValueChange = (
    event: React.SyntheticEvent,
    option: Option | null
  ) => {
    this.setState({ didInputChange: false });

    if (option) {
      this.getCandidate(option);
    } else {
      const row: PaneRow = PaneRow.get(this.dataId)!;
      const widget = row.getWidgetT<
        AddressSearchCriteriaValue,
        RuntimeProperties
      >(this.name);

      widget.setValue({
        addressValue: null,
        userValue: null,
      });
    }
  };

  private syncDerivedWithProps(): void {
    this.dataId = this.props.dataId;
    this.name = this.props.name;
  }

  public componentDidUpdate(): void {
    this.syncDerivedWithProps();
  }

  public render() {
    const row: PaneRow = PaneRow.get(this.dataId)!;
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<
      AddressSearchCriteriaValue,
      RuntimeProperties
    >(this.name);

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    if (!this.accessToken) {
      this.getToken();
    }

    return (
      <Autocomplete
        autoComplete
        autoHighlight
        autoSelect={!!widget.value.userValue && this.state.didInputChange}
        filterOptions={(options: Option[]) => options}
        forcePopupIcon={false}
        getOptionLabel={(option: Option) => option.text}
        handleHomeEndKeys={false}
        inputValue={widget.value.userValue || ""}
        isOptionEqualToValue={(option: Option, optionValue: Option) =>
          option.text === optionValue.text
        }
        noOptionsText={this.props.noResultsMessage}
        onChange={this.onValueChange}
        onInputChange={this.onInputChange}
        options={this.state.options}
        renderInput={(params) => (
          <TextField
            {...params}
            helperText={this.props.helperText}
            inputProps={{
              ...params.inputProps,
              autoComplete: "off",
              style: { paddingLeft: 4, paddingTop: 24 },
              type: "search",
            }}
            InputProps={{
              ...params.InputProps,
              style: { paddingLeft: 12, paddingTop: 0 },
            }}
            label={this.props.label}
            name={this.props.name}
            required={this.props.mandatory}
            variant="filled"
          />
        )}
        renderOption={(props, option: Option) => {
          return (
            <li
              {...props}
              style={{
                alignItems: "center",
                display: "flex",
                minHeight: 40,
                width: "100%",
              }}
            >
              <Icon
                icon="fas fa-map-marker-alt"
                style={{ marginRight: ".4em" }}
              />
              <Typography ellipsis>{option.text}</Typography>
            </li>
          );
        }}
      />
    );
  }
}

export default observer(AddressSearchCriteria);

import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../../core/Localization";
import MenuItem from "../../coreui/MenuItem";
import PaneRow from "../../models/PaneRow";
import PaneDataStore from "../../stores/PaneDataStore";
import { AccessLevel } from "../AccessLevel";
import { ActionButtonProps, ActionButtonRuntimeProps } from "../ActionButton";
import ApiButton from "../ApiButton";
import { MenuItemProps } from "../MenuItem";
import { SelectChildProps } from "../SelectControl";

interface Props extends ActionButtonProps {}

export class SelectButton extends React.Component<Props> {
  private static onClick(propagated: SelectChildProps): void {
    propagated.parentSelect!.search(null);
  }

  public static renderMenuItem(props: MenuItemProps): JSX.Element {
    const { config, runtime, ...otherProps } = props;
    const configProps = config as unknown as Props;
    const runtimeProps = runtime as ActionButtonRuntimeProps;

    const onClick = (): void => {
      if (runtimeProps.accessLevel >= AccessLevel.actionable) {
        SelectButton.onClick(configProps.propagated);
        configProps.propagated.onItemClicked!();
      }
    };

    return (
      <MenuItem
        disabled={runtimeProps.accessLevel === AccessLevel.disabled}
        iconName={configProps.iconName}
        indent={props.config.propagated ? props.config.propagated.indent : 0}
        onClick={onClick}
        {...otherProps}
      >
        {runtimeProps.label}
      </MenuItem>
    );
  }

  private onClick = (): void => {
    SelectButton.onClick(this.props.propagated);
  };

  public render(): React.ReactNode {
    const row = PaneRow.get(this.props.dataId);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<null, ActionButtonRuntimeProps>(
      this.props.name
    );

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    const isIconOnly = !widget.properties.label;
    const label = isIconOnly
      ? Localization.getBuiltInMessage("SelectControl.selectButtonLabel")
      : widget.properties.label;

    // Find how many rows are in the select grid and report it out for users
    // needing accessible descriptions.
    let alternateText: string = "";
    if (widget.properties.alternateText) {
      alternateText = widget.properties.alternateText.endsWith(".")
        ? `${widget.properties.alternateText} `
        : `${widget.properties.alternateText}. `;
    }

    const selectGridRows: PaneRow[] = PaneDataStore.getPaneCollection(
      this.props.propagated.parentSelect.selectedDataId
    );

    if (selectGridRows.length === 1) {
      alternateText += Localization.getBuiltInMessage(
        "DataTable.selectCriteriaOneRowInTable"
      );
    } else if (selectGridRows.length > 1) {
      alternateText += Localization.getBuiltInMessage(
        "DataTable.selectCriteriaMultipleRowsInTable",
        { count: selectGridRows.length }
      );
    }

    return (
      <ApiButton
        alternateText={alternateText}
        buttonColor={this.props.buttonColor}
        disabled={widget.properties.accessLevel === AccessLevel.disabled}
        disabledHelpText={this.props.disabledHelpText}
        disabledHelpVisible={widget.properties.showDisabledHelp}
        iconName={this.props.iconName}
        isIconOnly={isIconOnly}
        label={label}
        onClick={this.onClick}
        size="small"
        tabIndex={-1}
      />
    );
  }
}

export default observer(SelectButton);

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox as MuiCheckbox,
  Theme,
} from "@mui/material";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import { action, autorun, Lambda, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { DialogChildProps } from "../config";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import getFieldHelperText from "../coreui/FieldHelperText";
import Icon from "../coreui/Icon";
import { TableVerticalLayoutProps } from "../coreui/Table";
import TextField from "../coreui/TextField";
import PaneRow from "../models/PaneRow";
import RoundTripService from "../services/RoundTripService";
import ErrorsStore from "../stores/ErrorsStore";
import SubPaneControlStore from "../stores/SubPaneControlStore";
import { AccessLevel } from "./AccessLevel";

interface ConfigProperties {
  controlKey: string | null;
  controlledPaneName: string | null;
  dataId: string;
  disabledHelpText: string;
  helperText: string;
  isPaneController: boolean;
  label: string;
  name: string;
  propagated?: DialogChildProps & TableVerticalLayoutProps;
  roundTripOnChange: boolean;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
  roundTripOnChange: boolean;
  showDisabledHelp: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    ripple: {
      overflow: "visible",
    },
    root: {
      "&:hover": { backgroundColor: "transparent" },
      backgroundColor: "transparent",
      display: "inline-block",
      outline: "none",
    },
  });

export class Checkbox extends React.Component<
  ConfigProperties & WithStyles<typeof styles>
> {
  private readonly componentId: string;
  private config: ConfigProperties;
  private dataId: string = "";
  private dialogRowKey?: string;
  private disposeObserve: Lambda;
  private formControlElement: HTMLDivElement;
  private readonly helperTextId: string;
  private readonly labelId: string;
  private name: string = "";
  private rowKey?: string;

  public constructor(props: ConfigProperties & WithStyles<typeof styles>) {
    super(props);

    makeObservable<
      Checkbox,
      "dataId" | "name" | "onChange" | "syncDerivedWithProps"
    >(this, {
      dataId: observable,
      name: observable,
      onChange: action,
      syncDerivedWithProps: action,
    });

    this.componentId = `checkbox-${Sys.nextId}`;
    this.helperTextId = `${this.componentId}-helper-text`;
    this.labelId = `${this.componentId}-labelled-by`;

    this.syncDerivedWithProps();
  }

  private announceErrors(errors: string[]): void {
    if (errors.length > 0) {
      Sys.announce(errors.join("; "));
    }
  }

  private onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const checked = event.target.checked;

    const row = PaneRow.get(this.dataId, this.rowKey)!;
    const widget = row.getWidgetT<boolean, RuntimeProperties>(this.name);

    const oldValue = widget.value;

    ErrorsStore.clearBusinessErrorsForWidget(this.dataId, this.name);
    widget.setValue(checked);

    if (!this.props.roundTripOnChange) {
      return;
    }

    RoundTripService.standardRoundTrip(
      "CheckBox/OnChange",
      { dataId: this.dataId, name: this.name },
      {
        dialogRowKey: this.dialogRowKey,
      }
    ).catch((reason) => {
      if (reason) {
        throw reason;
      } else {
        // If the round trip fails, undo the value change.
        widget.setValue(oldValue);
      }
    });

    if (Sys.isMobile && Sys.isSafari) {
      this.formControlElement.focus();
    }
  };

  private syncDerivedWithProps(): void {
    this.dataId = this.props.dataId;
    this.name = this.props.name;
    this.config = { ...this.props };
    this.dialogRowKey =
      this.props.propagated?.parentDialog?.rowKey || undefined;

    this.rowKey = undefined;
    if (this.props.propagated?.parentTable) {
      // Widget is being rendered in the vertical layout of a table.
      this.rowKey = this.props.propagated.rowKey;
    }
  }

  protected showSubPane(): void {
    if (!this.config.isPaneController) {
      return;
    }

    const row = PaneRow.get(this.dataId, this.rowKey);
    const widget = row!.getWidgetT<boolean, RuntimeProperties>(this.name);

    const controlKey: string = `${this.config.controlKey}_${this.rowKey || ""}`;
    if (widget.value) {
      SubPaneControlStore.showPane(controlKey, this.config.controlledPaneName!);
    } else {
      SubPaneControlStore.hidePane(controlKey);
    }
  }

  public componentDidMount(): void {
    this.disposeObserve = autorun(() => {
      this.showSubPane();
    });
  }

  public componentDidUpdate(): void {
    this.syncDerivedWithProps();
  }

  public componentWillUnmount(): void {
    this.disposeObserve();
  }

  public render(): React.ReactNode {
    const row = PaneRow.get(this.dataId, this.rowKey);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<boolean, RuntimeProperties>(this.name);

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    if (widget.properties.accessLevel === AccessLevel.disabled) {
      return (
        <TextField
          disabled={true}
          disabledHelpText={
            widget.properties.showDisabledHelp
              ? this.props.disabledHelpText
              : undefined
          }
          label={this.props.label}
          variant="filled"
        />
      );
    }

    if (widget.properties.accessLevel === AccessLevel.readOnly) {
      return (
        <TextField
          label={this.props.label}
          name={this.props.name}
          readOnly={true}
          value={
            widget.value ? Localization.getBuiltInMessage("booleanYes") : "-"
          }
          variant="filled"
        />
      );
    }

    const fieldHelperText = getFieldHelperText({
      getErrors: () => widget.properties.businessErrors,
      helperText: this.props.helperText,
    });

    // VERSION_WARNING iOS 15
    // There is a bug in Safari on iOS where input elements that are nested in
    // a label get trapped in voiceover navigation after a roundtrip if focus
    // returns back to the input element.
    // The work-around implemented here is to force voice-over's focus to be
    // on the element that contains both the input and the label, and to make
    // the browser's focus be on that same element after roundtrip.
    // This condition seems to circumvent the bug.
    let labelId: string | undefined;
    let label: React.ReactNode;
    if (Sys.isMobile && Sys.isSafari) {
      labelId = this.labelId;
      label = (
        <span aria-hidden="true" id={this.labelId} tabIndex={-1}>
          {this.props.label}
        </span>
      );
    } else {
      labelId = undefined;
      label = this.props.label;
    }

    return (
      <FormControl
        className={this.props.classes.root}
        error={fieldHelperText.hasErrors}
        fullWidth={true}
        ref={(element) => (this.formControlElement = element!)}
        tabIndex={Sys.isMobile && Sys.isSafari ? -1 : undefined}
      >
        <FormControlLabel
          aria-labelledby={labelId}
          control={
            <MuiCheckbox
              checked={widget.value}
              checkedIcon={<Icon icon="fas fa-check-square" />}
              color="default"
              icon={<Icon icon="far fa-square" />}
              inputProps={{
                "aria-describedby": fieldHelperText.helperText
                  ? this.helperTextId
                  : undefined,
              }}
              onBlur={() => {
                this.announceErrors(fieldHelperText.errors);
              }}
              onChange={this.onChange}
              TouchRippleProps={{ className: this.props.classes.ripple }}
            />
          }
          label={label}
        />
        {fieldHelperText.helperText && (
          <FormHelperText
            aria-hidden={true}
            component="div"
            id={this.helperTextId}
            style={{ marginTop: 0 }}
          >
            {fieldHelperText.helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

export default withStyles(styles)(observer(Checkbox));

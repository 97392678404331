import {
  colors,
  darken,
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  styled,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { ForegroundColorType } from "../theme";
import Icon from "./Icon";
import multiClassName from "./MultiClassName";

interface Props extends MuiButtonProps {
  // Override the MUI interface so that the label and icons are passed
  // explicitly rather than via children.
  children?: undefined;
  endIcon?: string;
  icon?: string;
  isLanguageSelect?: true;
  linkText: string;
  rel?: string;
  target?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  ripple: {
    color: theme.palette.text.primary,
    height: "calc(100% + 10px)",
    margin: "-5px -20px",
    width: "calc(100% + 40px)",
  },
  root: {
    "&:disabled": {
      color:
        theme.baseForegroundColor === ForegroundColorType.White
          ? "rgba(255, 255, 255, 0.2)"
          : colors.grey[300],
    },
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.isHeaderOrFooter
        ? darken(theme.palette.text.primary, 0.1)
        : darken(theme.palette.info.main, 0.1),
    },
    color: theme.isHeaderOrFooter
      ? theme.palette.text.primary
      : theme.palette.info.main,
    fontSize: 16,
    fontWeight: theme.isHeaderOrFooter ? 400 : 700,
    justifyContent: "left",
    letterSpacing: "normal",
    lineHeight: "20px",
    minHeight: 0,
    minWidth: 0,
    overflow: "visible",
    padding: 0,
    textTransform: "none",
  },
  rootHref: {
    userSelect: "auto",
  },
}));

const EndIcon = styled(Icon)({
  marginLeft: ".4em",
  verticalAlign: "-.15em",
});

const LinkText = styled("span")(({ theme }) => ({
  "&:hover": {
    textDecoration: theme.isHeaderOrFooter ? "underline" : "none",
  },
  overflow: "hidden",
  textDecoration: theme.isHeaderOrFooter ? "none" : "underline",
  textOverflow: "ellipsis",
}));

const StartIcon = styled(Icon, {
  shouldForwardProp: (propName) => propName !== "isLanguageSelect",
})<{ isLanguageSelect?: true }>(({ isLanguageSelect }) => ({
  display: "inline-block",
  marginRight: ".4em",
  verticalAlign: "-.15em",
  ...(isLanguageSelect && {
    fontSize: "24px",
    verticalAlign: "-7px",
  }),
}));

const ButtonLink = React.forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLButtonElement>): JSX.Element => {
    const classes = useStyles();
    const {
      children,
      className,
      endIcon,
      icon,
      isLanguageSelect,
      linkText,
      ...buttonProps
    } = props;

    return (
      <MuiButton
        className={multiClassName(
          className,
          classes.root,
          props.href ? classes.rootHref : ""
        )}
        ref={ref}
        TouchRippleProps={{ className: classes.ripple }}
        {...buttonProps}
      >
        <LinkText>
          {icon && (
            <StartIcon icon={icon} isLanguageSelect={isLanguageSelect} />
          )}
          {linkText}
          {endIcon && <EndIcon icon={endIcon} />}
        </LinkText>
      </MuiButton>
    );
  }
);

export default ButtonLink;

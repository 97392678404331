import { StyledEngineProvider } from "@mui/material";
import { createGenerateClassName, StylesProvider } from "@mui/styles";
import * as React from "react";
import { LayoutConfig } from "../config";
import SessionMessageDisplay from "../mustangui/SessionMessageDisplay";
import { ForegroundColorType, ThemeProvider } from "../theme";
import {
  Container,
  EnvironmentBanner,
  Footer,
  Header,
  LandingPageGreeting,
  MessagesSnackbar,
  PageProcessing,
} from "./components";

interface FooterConfig {
  backgroundColor: string;
  foreground: ForegroundColorType;
  layout: LayoutConfig;
}

interface HeaderConfig {
  backgroundColor: string;
  bottomBorderColor: string;
  foreground: ForegroundColorType;
  logoUrl: string;
  logoLabel: string;
  layout: LayoutConfig;
}

export interface LandingPageTemplateConfig {
  backgroundImageUrl: string;
  footer: FooterConfig;
  header: HeaderConfig;
  signInTitle: string;
  welcomeTitle1: string;
  welcomeTitle2: string;
}

interface Props {
  backgroundImageUrl: string;
  footer: FooterConfig;
  forgotPasswordUrl: string | null;
  header: HeaderConfig;
  signInTitle: string;
  welcomeTitle1: string;
  welcomeTitle2: string;
}

const generateHeaderClassName = createGenerateClassName({
  seed: "header",
});

const generateFooterClassName = createGenerateClassName({
  seed: "footer",
});

export function LandingPageTemplate(
  props: React.PropsWithChildren<Props>
): JSX.Element {
  // FUTURE
  // The StyledEngineProvider is used to ensure the Emotion styles are injected
  // before JSS. This can be removed when everything is moved over to Emotion.
  // i.e., once the project no longer references @mui/styles.
  return (
    <StyledEngineProvider injectFirst>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <EnvironmentBanner />
        <StylesProvider generateClassName={generateHeaderClassName}>
          <ThemeProvider foreground={props.header.foreground} header>
            <Header
              backgroundColor={props.header.backgroundColor}
              bottomBorderColor={props.header.bottomBorderColor}
              layout={props.header.layout}
              logoProps={{
                href: "#",
                imageUrl: props.header.logoUrl,
                label: props.header.logoLabel,
              }}
            />
          </ThemeProvider>
        </StylesProvider>
        <ThemeProvider>
          <PageProcessing />
          <SessionMessageDisplay />
          <main
            aria-label={`${props.welcomeTitle1} ${props.welcomeTitle2}`}
            style={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
            }}
          >
            <LandingPageGreeting
              backgroundImageUrl={props.backgroundImageUrl}
              logonProps={{
                forgotPasswordUrl: props.forgotPasswordUrl,
                title: props.signInTitle,
              }}
              title1={props.welcomeTitle1}
              title2={props.welcomeTitle2}
            />
            <Container content sx={{ flexGrow: 1 }}>
              {props.children}
            </Container>
          </main>
          <MessagesSnackbar />
        </ThemeProvider>
        <StylesProvider generateClassName={generateFooterClassName}>
          <ThemeProvider footer foreground={props.footer.foreground}>
            <Footer
              backgroundColor={props.footer.backgroundColor}
              layout={props.footer.layout}
            />
          </ThemeProvider>
        </StylesProvider>
      </div>
    </StyledEngineProvider>
  );
}

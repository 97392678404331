import { Badge, ClickAwayListener, darken, styled, Theme } from "@mui/material";
import * as React from "react";
import GridColumn from "../mustangui/Columns/GridColumn";
import Button from "./Button";
import { ErrorList } from "./ErrorList";
import { ErrorTooltip } from "./ErrorTooltip";

interface BadgeProps {
  errors: string[];
  isShort?: boolean;
}

interface Props extends BadgeProps {
  onClose?: () => void;
  onOpen?: () => void;
  open?: boolean;
  style?: React.CSSProperties;
  suppressEdit?: boolean;
}

interface State {
  open: boolean;
}

const StyledBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== "errors" && prop !== "isShort",
})<BadgeProps>(
  {
    "& .MuiBadge-badge": {
      right: 16,
      top: "calc(100% - 16px)",
    },
    alignItems: "center",
    borderStyle: "solid",
    borderWidth: 1,
    height: "calc(100% - 5px)",
    marginLeft: 1,
    marginTop: 2,
    width: "calc(100% - 3px)",
    zIndex: 1,
  },
  ({ theme, errors }) => ({
    borderColor: errors.length > 0 ? theme.palette.danger.main : "transparent",
  }),
  ({ isShort }) =>
    isShort
      ? {
          height: "calc(100% - 7px)",
          marginTop: 2,
        }
      : null
);

export class ErrorBadge extends React.PureComponent<Props, State> {
  private buttonRef = React.createRef<HTMLButtonElement>();

  public constructor(props: Props) {
    super(props);

    this.state = { open: false };
  }

  private onButtonClick = (): void => {
    // Fix for Safari not opening tooltip
    this.buttonRef.current!.focus();

    this.setState({ open: true });
  };

  private onButtonMouseDown = (): void => {
    if (this.props.suppressEdit) {
      GridColumn.suppressEdit = true;
    }
  };

  private onClickAway = (): void => {
    this.setState({ open: false });
  };

  public render(): React.ReactNode {
    const content =
      this.props.errors.length > 0 ? (
        <ClickAwayListener
          mouseEvent="onMouseDown"
          onClickAway={this.onClickAway}
        >
          <ErrorTooltip
            disableHoverListener
            enterTouchDelay={0}
            onClose={this.props.onClose}
            onOpen={this.props.onOpen}
            open={this.state.open || !!this.props.open}
            title={<ErrorList errors={this.props.errors} />}
          >
            <Button
              color="danger"
              customSize="tiny"
              icon="fas fa-exclamation"
              onClick={this.onButtonClick}
              onMouseDown={this.onButtonMouseDown}
              ref={this.buttonRef}
              tabIndex={-1}
            />
          </ErrorTooltip>
        </ClickAwayListener>
      ) : (
        <div />
      );

    return (
      <StyledBadge
        badgeContent={content}
        errors={this.props.errors}
        isShort={this.props.isShort}
        overlap="rectangular"
        style={this.props.style}
      >
        {this.props.children}
      </StyledBadge>
    );
  }
}

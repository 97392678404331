import { Breakpoint, styled } from "@mui/material";
import { observer } from "mobx-react";
import * as React from "react";
import { useWidth } from "../core/Responsive";
import PaneRow from "../models/PaneRow";
import { AccessLevel } from "./AccessLevel";

interface ConfigProperties {
  alternateText: string;
  dataId: string;
  imageHeight: { [key in Breakpoint]: number | undefined };
  imageUrl: string;
  imageWidth: { [key in Breakpoint]: number | undefined };
  name: string;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
}

const BrandingImage = styled("img")({
  display: "block",
  maxWidth: "100%",
});

export const BrandingImageDisplay = observer(
  (props: ConfigProperties): JSX.Element | null => {
    const width = useWidth();

    const row = PaneRow.get(props.dataId);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<null, RuntimeProperties>(props.name);
    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    const imageHeight = props.imageHeight[width];
    const imageWidth = props.imageWidth[width];
    if (!imageHeight && !imageWidth) {
      return null;
    }

    return (
      <BrandingImage
        aria-hidden={!props.alternateText || undefined}
        aria-label={props.alternateText || undefined}
        src={props.imageUrl}
        style={{ height: imageHeight, width: imageWidth }}
      />
    );
  }
);

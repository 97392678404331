import {
  Collapse,
  Fade,
  styled,
  useMediaQuery,
  useTheme,
  Zoom,
} from "@mui/material";
import * as React from "react";
import {
  Layout,
  LayoutChildProps,
  LayoutConfig,
  SiteSearchInfo,
} from "../../config";
import Sys from "../../core/Sys";
import { Container } from "./Container";
import { Logo, Props as LogoProps } from "./Logo";
import * as SiteCriteria from "./SiteCriteria";

export interface HeaderChildProps extends LayoutChildProps {
  parentHeader: {
    siteCriteria: {
      isExpanded: boolean;
      onCollapse: () => void;
      onExpand: (siteSearches: SiteSearchInfo[]) => void;
    };
  };
}

interface HeaderRootProps {
  backgroundColor: string;
  bottomBorderColor: string;
}

interface Props extends HeaderRootProps {
  layout?: LayoutConfig;
  logoProps: LogoProps;
}

const Content = styled(Container)({
  alignItems: "center",
  boxSizing: "border-box",
  display: "flex",
  height: "100%",
});

const HeaderRoot = styled("header")<HeaderRootProps>(({ theme, ...props }) => ({
  [theme.breakpoints.only("xs")]: {
    height: 72,
  },
  [theme.breakpoints.only("sm")]: {
    height: 88,
  },
  [theme.breakpoints.only("md")]: {
    height: 108,
  },
  [theme.breakpoints.up("lg")]: {
    height: 140,
  },
  "@media print": {
    display: "none",
  },
  backgroundColor: props.backgroundColor,
  borderBottomStyle: "solid",
  borderBottomWidth: 4,
  borderColor: props.bottomBorderColor,
}));

const HeaderToolbar = styled("div")({
  flex: "auto",
});

const ToolbarSiteCriteria = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "grid",
  gridColumnGap: theme.horizontalSpacing.widget.related,
  gridTemplateColumns: "270px 1fr auto",
}));

export const Header = (props: Props): JSX.Element => {
  const theme = useTheme();
  const renderSiteCriteriaInToolbar = useMediaQuery(theme.breakpoints.up("md"));

  const [searchCriteria, setSearchCriteria] = React.useState<string>("");
  const [selectedSearch, setSelectedSearch] =
    React.useState<SiteSearchInfo | null>(null);
  const [siteCriteriaInToolbarIsVisible, setSiteCriteriaInToolbarIsVisible] =
    React.useState<boolean>(false);
  const [siteCriteriaIsExpanded, setSiteCriteriaIsExpanded] =
    React.useState<boolean>(false);
  const [siteSearches, setSiteSearches] = React.useState<SiteSearchInfo[]>([]);

  const onSiteCriteriaCollapse = (): void => {
    setSiteCriteriaIsExpanded(false);
  };

  const onSiteCriteriaExpand = (newSiteSearches: SiteSearchInfo[]): void => {
    setSiteCriteriaIsExpanded(true);
    setSiteSearches(newSiteSearches);

    let searchIsSelected: boolean = false;
    if (selectedSearch !== null) {
      const candidateSearches: SiteSearchInfo[] = newSiteSearches.filter(
        (s) => s.url === selectedSearch.url
      );
      if (candidateSearches.length > 0) {
        setSelectedSearch(candidateSearches[0]);
        searchIsSelected = true;
      }
    }

    if (!searchIsSelected) {
      const defaultSearches: SiteSearchInfo[] = newSiteSearches.filter(
        (s) => s.isDefault
      );
      if (defaultSearches.length > 0) {
        setSelectedSearch(defaultSearches[0]);
      } else {
        setSelectedSearch(newSiteSearches[0]);
      }
    }

    setSearchCriteria("");
  };

  const onSiteCriteriaSearch = (): void => {
    if (selectedSearch === null) {
      return;
    }

    const baseUrl: string = selectedSearch.url.substr(1);
    const queryString = Sys.objectToQueryString({
      PosseAutoExecute: "Y",
      SiteCriteria: searchCriteria,
    });

    Sys.setHash(`${baseUrl}?${queryString}`);
    setSiteCriteriaIsExpanded(false);
  };

  const propagated: HeaderChildProps = {
    parentHeader: {
      siteCriteria: {
        isExpanded: siteCriteriaIsExpanded,
        onCollapse: onSiteCriteriaCollapse,
        onExpand: onSiteCriteriaExpand,
      },
    },
  };

  return (
    <React.Fragment>
      <HeaderRoot
        backgroundColor={props.backgroundColor}
        bottomBorderColor={props.bottomBorderColor}
      >
        <Content>
          <Logo {...props.logoProps} />
          {props.layout && (
            <HeaderToolbar>
              <Zoom
                in={
                  renderSiteCriteriaInToolbar &&
                  siteCriteriaIsExpanded &&
                  siteCriteriaInToolbarIsVisible
                }
                mountOnEnter={true}
                onExited={() => setSiteCriteriaInToolbarIsVisible(false)}
                unmountOnExit={true}
              >
                <ToolbarSiteCriteria>
                  <SiteCriteria.SearchSelect
                    onSelect={setSelectedSearch}
                    selectedSearch={selectedSearch}
                    siteSearches={siteSearches}
                  />
                  <SiteCriteria.SearchCriteriaField
                    helperText={selectedSearch?.helperText}
                    onCriteriaChange={setSearchCriteria}
                    onSearch={onSiteCriteriaSearch}
                    mandatory={selectedSearch?.mandatory}
                    value={searchCriteria}
                  />
                  <SiteCriteria.CollapseButton
                    onCollapse={onSiteCriteriaCollapse}
                  />
                </ToolbarSiteCriteria>
              </Zoom>
              <Fade
                in={
                  !(
                    renderSiteCriteriaInToolbar &&
                    (siteCriteriaIsExpanded || siteCriteriaInToolbarIsVisible)
                  )
                }
                mountOnEnter={true}
                onExited={() => setSiteCriteriaInToolbarIsVisible(true)}
                unmountOnExit={true}
              >
                <div>
                  <Layout
                    config={props.layout}
                    preventClear={true}
                    propagated={propagated}
                  />
                </div>
              </Fade>
            </HeaderToolbar>
          )}
        </Content>
      </HeaderRoot>
      {props.layout &&
        !renderSiteCriteriaInToolbar &&
        siteCriteriaIsExpanded && (
          <Collapse in={siteCriteriaIsExpanded}>
            <SiteCriteria.SearchSelect
              onSelect={setSelectedSearch}
              selectedSearch={selectedSearch}
              siteSearches={siteSearches}
            />
            <SiteCriteria.SearchCriteriaField
              helperText={selectedSearch?.helperText}
              onCriteriaChange={setSearchCriteria}
              onSearch={onSiteCriteriaSearch}
              mandatory={selectedSearch?.mandatory}
              value={searchCriteria}
            />
          </Collapse>
        )}
    </React.Fragment>
  );
};

import {
  DialogActions as MuiDialogActions,
  DialogActionsProps as MuiDialogActionsProps,
  Theme,
} from "@mui/material";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import * as React from "react";

interface Props extends MuiDialogActionsProps {}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        "&:first-child": {
          marginLeft: 0,
        },

        marginRight: 0,

        [theme.breakpoints.up("xs")]: {
          marginLeft: theme.horizontalSpacing.closelyRelated.xs,
        },
        [theme.breakpoints.up("sm")]: {
          marginLeft: theme.horizontalSpacing.closelyRelated.sm,
        },
        [theme.breakpoints.up("md")]: {
          marginLeft: theme.horizontalSpacing.closelyRelated.md,
        },
        [theme.breakpoints.up("lg")]: {
          marginLeft: theme.horizontalSpacing.closelyRelated.lg,
        },
      },
      [theme.breakpoints.up("xs")]: {
        marginBottom: theme.dialog.padding.xs,
        marginLeft: theme.dialog.padding.xs,
        marginRight: theme.dialog.padding.xs,
        // Space for button shadow
        marginTop: theme.verticalSpacing.related.xs - 4,
      },
      [theme.breakpoints.up("sm")]: {
        marginBottom: theme.dialog.padding.sm,
        marginLeft: theme.dialog.padding.sm,
        marginRight: theme.dialog.padding.sm,
        marginTop: theme.verticalSpacing.related.sm - 4,
      },
      [theme.breakpoints.up("md")]: {
        marginTop: theme.verticalSpacing.related.md - 4,
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: theme.verticalSpacing.related.lg - 4,
      },
    },
  });

export class DialogActions extends React.PureComponent<
  Props & WithStyles<typeof styles>
> {
  public render() {
    return <MuiDialogActions {...this.props} />;
  }
}

export default withStyles(styles)(DialogActions);

import { Box } from "@mui/material";
import { observer } from "mobx-react";
import * as React from "react";
import { SiteSearchInfo } from "../config";
import Localization from "../core/Localization";
import Button from "../coreui/Button";
import ButtonLink from "../coreui/ButtonLink";
import Icon from "../coreui/Icon";
import PaneRow from "../models/PaneRow";
import { HeaderChildProps } from "../templates/components/Header";
import { AccessLevel } from "./AccessLevel";

interface ConfigProperties {
  dataId: string;
  name: string;
  propagated: HeaderChildProps;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  siteSearches: SiteSearchInfo[];
}

export const SiteCriteriaLink = observer(
  (props: ConfigProperties): JSX.Element | null => {
    const onClick = (): void => {
      if (props.propagated.parentHeader.siteCriteria.isExpanded) {
        props.propagated.parentHeader.siteCriteria.onCollapse();
      } else {
        const currentRow = PaneRow.get(props.dataId)!;
        const currentWidget = currentRow.getWidgetT<null, RuntimeProperties>(
          props.name
        );

        props.propagated.parentHeader.siteCriteria.onExpand(
          currentWidget.properties.siteSearches
        );
      }
    };

    const row = PaneRow.get(props.dataId);
    if (!row) {
      return null;
    }
    const widget = row.getWidgetT<null, RuntimeProperties>(props.name);

    if (widget.properties.accessLevel <= AccessLevel.readOnly) {
      return null;
    }

    const label: string = Localization.getBuiltInMessage("search");
    const iconName: string = props.propagated.parentHeader.siteCriteria
      .isExpanded
      ? "fas fa-times"
      : "fas fa-search";

    return (
      <React.Fragment>
        <Box sx={{ display: { md: "inline-block", xs: "none" } }}>
          <ButtonLink
            aria-expanded={
              props.propagated.parentHeader.siteCriteria.isExpanded
            }
            aria-label={label}
            icon={iconName}
            linkText={label}
            onClick={onClick}
            tabIndex={-1}
          />
        </Box>
        <Box sx={{ display: { md: "none", xs: "inline-block" } }}>
          <Button
            aria-expanded={
              props.propagated.parentHeader.siteCriteria.isExpanded
            }
            aria-label={label}
            fab
            onClick={onClick}
            size="large"
            tabIndex={-1}
          >
            <Icon icon={iconName} />
          </Button>
        </Box>
      </React.Fragment>
    );
  }
);

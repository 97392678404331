import { observer } from "mobx-react";
import * as React from "react";
import ButtonLink from "../coreui/ButtonLink";
import FormData from "../coreui/FormData";
import { TableVerticalLayoutProps } from "../coreui/Table";
import PaneRow from "../models/PaneRow";
import { AccessLevel } from "./AccessLevel";

interface ConfigProperties {
  dataId: string;
  label: string;
  name: string;
  presentationId?: number;
  propagated?: TableVerticalLayoutProps;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  anchorText: string;
  objectId: number | null;
  presentationId?: number;
}

export class DataLink extends React.Component<ConfigProperties> {
  public render(): React.ReactNode {
    const rowKey = this.props.propagated?.rowKey;
    const row = PaneRow.get(this.props.dataId, rowKey);

    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<null, RuntimeProperties>(this.props.name);

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    if (
      widget.properties.accessLevel >= AccessLevel.actionable &&
      widget.properties.objectId !== null
    ) {
      const presentationId: number = this.props.presentationId
        ? this.props.presentationId
        : // Used when in a data grid vertical layout for DataLinkColumns
          widget.properties.presentationId!;

      return (
        <FormData label={this.props.label}>
          <ButtonLink
            href={`#/object/${widget.properties.objectId}/${presentationId}`}
            linkText={widget.properties.anchorText}
            style={{ height: 20, minHeight: 20 }}
            target="_self"
          />
        </FormData>
      );
    }

    return (
      <FormData label={this.props.label}>
        {widget.properties.anchorText || "-"}
      </FormData>
    );
  }
}

export default observer(DataLink);

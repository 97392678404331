import { Theme } from "@mui/material";
import {
  createStyles,
  CSSProperties,
  makeStyles,
  WithStyles,
  withStyles,
} from "@mui/styles";
import { observer } from "mobx-react";
import * as React from "react";
import AriaHiddenManager from "../../core/AriaHiddenManager";
import FocusManager from "../../core/FocusManager";
import Localization from "../../core/Localization";
import RequestPromise from "../../core/RequestPromise";
import Sys from "../../core/Sys";
import Button from "../../coreui/Button";
import FocusRipple from "../../coreui/FocusRipple";
import Icon from "../../coreui/Icon";
import MenuItem from "../../coreui/MenuItem";
import { TableChildProps } from "../../coreui/Table";
import PaneRow from "../../models/PaneRow";
import ActionButtonService, {
  OnNewRowResponse,
  RoundTripProps,
} from "../../services/ActionButtonService";
import { AccessLevel } from "../AccessLevel";
import { ActionButtonProps, ActionButtonRuntimeProps } from "../ActionButton";
import ApiButton from "../ApiButton";
import { MenuItemProps } from "../MenuItem";

interface Props extends ActionButtonProps {}

const styles = (theme: Theme) =>
  createStyles({
    fabIcon: {
      width: "100%",
    },
    input: {
      "&::-webkit-file-upload-button": {
        cursor: "pointer",
      },
      cursor: "pointer",
      height: "100%",
      left: 0,
      opacity: 0,
      position: "absolute",
      top: 0,
      width: "100%",
    },
  });

const menuStyles = makeStyles((theme: Theme) => ({
  input: {
    "&::-webkit-file-upload-button": {
      cursor: "pointer",
    },
    cursor: "pointer",
    height: "100%",
    left: 0,
    opacity: 0,
    position: "absolute",
    top: 0,
    width: "100%",
  } as CSSProperties,
}));

interface State {
  isUploadButtonFocused: boolean;
}

export class NewRowButton extends React.Component<
  Props & WithStyles<typeof styles>,
  State
> {
  private readonly uploadInputId: string;
  private onClickPromise: RequestPromise<void>;
  private uploadButtonRef = React.createRef<HTMLButtonElement>();

  private static getLabel = (
    runtimeProperties: ActionButtonRuntimeProps
  ): string => {
    const label = !!runtimeProperties.label
      ? runtimeProperties.label
      : Localization.getBuiltInMessage("DataTable.newRowLabel");

    return label;
  };

  private static onChange(
    propagated: TableChildProps,
    input: HTMLInputElement,
    restoreFocusElement: HTMLElement | null = null
  ) {
    if (!input.files || input.files.length <= 0) {
      return;
    }

    propagated.parentTable.uploadFiles(input.files).then(() => {
      input.value = "";
      // We must explicitly restore focus for mobile screen readers, otherwise
      // they lose focus after uploading a document
      if (restoreFocusElement) {
        FocusManager.grabFocus(restoreFocusElement);
      }
    });
  }

  private static onClick(
    config: RoundTripProps & { propagated: TableChildProps }
  ): RequestPromise<void> {
    if (config.propagated.parentTable.isVerticalLayout) {
      config.propagated.parentTable.getApi().paginationGoToFirstPage();
    }

    // VoiceOver on iOS bug: the mask that appears when editing in a related
    // edit dialog sets aria-hidden to true when it is created. This causes
    // VoiceOver on iOS to ignore the creation of the edit row button so that
    // when the dialog is dismissed, the button isn't in the accessibility tree.
    // Use AriaHiddenManager to prevent this behavior until after the button
    // is created.
    const endAriaHiddenSuppression = AriaHiddenManager.supressAriaHidden(
      document.getElementById("root")!
    );

    const row = PaneRow.get(config.dataId)!;
    const widget = row.getWidgetT<null, ActionButtonRuntimeProps>(config.name);

    // FUTURE
    // ActionButtonService.onNewRow() handles too much of the response, the
    // scope of what it handles should consist only of necessary plumbing to
    // handle the web interface. What needs to happen in the browser as a result
    // of the new row is more appropriately handled by the caller (this widget).
    // This change wasn't made now because it was found during system test.
    const newRowPromise = ActionButtonService.onNewRow(
      row.rowKey,
      config.dataId,
      config.name,
      config.propagated
    ).then((response: OnNewRowResponse) => {
      if (!config.propagated.parentTable.hasRelatedEditDialog) {
        if (
          response.businessErrors.length === 0 &&
          response.validationErrors.length === 0
        ) {
          setTimeout(() => {
            Sys.announce(
              Localization.getBuiltInMessage("Button.succeeded", {
                label: NewRowButton.getLabel(widget.properties),
              })
            );
          }, 2000);
        }

        // Give the table a chance to redraw.
        setTimeout(() => {
          config.propagated.parentTable.getTable().focusNewRow();
          endAriaHiddenSuppression();
        }, 500);
      }
    });

    return newRowPromise;
  }

  public static renderMenuItem(props: MenuItemProps): JSX.Element {
    const { config, runtime, ...otherProps } = props;
    const configProps = config as unknown as Props;
    const runtimeProps = runtime as ActionButtonRuntimeProps;

    if (props.runtime.accessLevel <= AccessLevel.readOnly) {
      return (
        <MenuItem
          disabled={props.runtime.accessLevel === AccessLevel.disabled}
          iconName={configProps.iconName}
          indent={props.config.propagated ? props.config.propagated.indent : 0}
          {...otherProps}
        >
          {runtimeProps.label}
        </MenuItem>
      );
    }

    if (configProps.uploadOnNew) {
      const classes = menuStyles();

      const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        NewRowButton.onChange(configProps.propagated, event.target);
        configProps.propagated.onItemClicked!();
      };

      const extensions: string | undefined =
        configProps.propagated.parentTable.validExtensions
          ?.map((extension) => `.${extension}`)
          .join(",");

      return (
        <MenuItem
          disabled={props.runtime.accessLevel === AccessLevel.disabled}
          iconName={configProps.iconName}
          indent={props.config.propagated ? props.config.propagated.indent : 0}
          onFocus={(e: React.FocusEvent<HTMLLIElement>) => {
            if (e.target.nodeName !== "INPUT") {
              const input: HTMLInputElement = e.target.querySelector("input")!;
              input.focus();
            }
          }}
          tabIndex={-1}
          {...otherProps}
        >
          <React.Fragment>
            {runtimeProps.label}
            <input
              accept={extensions}
              className={classes.input}
              multiple={true}
              onChange={onChange}
              tabIndex={0}
              type="file"
            />
          </React.Fragment>
        </MenuItem>
      );
    }

    return (
      <MenuItem
        iconName={configProps.iconName}
        indent={props.config.propagated ? props.config.propagated.indent : 0}
        onClick={() => {
          NewRowButton.onClick(configProps);
          configProps.propagated.onItemClicked!();
        }}
        {...otherProps}
      >
        {runtimeProps.label}
      </MenuItem>
    );
  }

  public constructor(props: Props & WithStyles<typeof styles>) {
    super(props);

    this.uploadInputId = `upload-new-button-${Sys.nextId}`;

    this.state = {
      isUploadButtonFocused: false,
    };
  }

  private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    NewRowButton.onChange(
      this.props.propagated,
      event.target,
      this.uploadButtonRef.current
    );
  };

  private onClick = () => {
    this.onClickPromise = NewRowButton.onClick(this.props);
  };

  public componentWillUnmount() {
    if (this.onClickPromise) {
      this.onClickPromise.abort();
    }
  }

  public render(): React.ReactNode {
    const row = PaneRow.get(this.props.dataId);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<null, ActionButtonRuntimeProps>(
      this.props.name
    );

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    if (
      this.props.uploadOnNew &&
      widget.properties.accessLevel >= AccessLevel.actionable
    ) {
      const extensions: string | undefined =
        this.props.propagated.parentTable.validExtensions
          ?.map((extension) => `.${extension}`)
          .join(",");

      const uploadInput = (
        <input
          accept={extensions}
          aria-label={widget.properties.alternateText}
          className={this.props.classes.input}
          id={this.uploadInputId}
          multiple={true}
          onBlur={() => {
            this.setState({ isUploadButtonFocused: false });
          }}
          onChange={this.onChange}
          onFocus={() => {
            this.setState({ isUploadButtonFocused: true });
          }}
          title={Localization.getBuiltInMessage("chooseFile")}
          type="file"
        />
      );

      if (widget.properties.label) {
        return (
          <React.Fragment>
            <Button
              aria-hidden
              color={this.props.buttonColor}
              component="label"
              icon={this.props.iconName}
              ref={this.uploadButtonRef}
              size="small"
              tabIndex={-1}
              // Address TypeScript error for htmlFor prop, which is not
              // recognized by the button component but is forwarded on to the
              // label element that gets rendered.
              {...({ htmlFor: this.uploadInputId } as any)}
            >
              {widget.properties.label}
              <FocusRipple visible={this.state.isUploadButtonFocused} />
            </Button>
            {uploadInput}
          </React.Fragment>
        );
      }

      return (
        <React.Fragment>
          <Button
            aria-hidden
            color={this.props.buttonColor}
            component="label"
            fab
            ref={this.uploadButtonRef}
            size="small"
            tabIndex={-1}
            // Address TypeScript error for htmlFor prop, which is not
            // recognized by the button component but is forwarded on to the
            // label element that gets rendered.
            {...({ htmlFor: this.uploadInputId } as any)}
          >
            <FocusRipple visible={this.state.isUploadButtonFocused} />
            <Icon
              className={this.props.classes.fabIcon}
              icon={this.props.iconName}
            />
          </Button>
          {uploadInput}
        </React.Fragment>
      );
    }

    const isIconOnly = !widget.properties.label;
    const label = isIconOnly
      ? Localization.getBuiltInMessage("DataTable.newRowLabel")
      : widget.properties.label;

    return (
      <ApiButton
        alternateText={widget.properties.alternateText}
        buttonColor={this.props.buttonColor}
        disabled={widget.properties.accessLevel === AccessLevel.disabled}
        disabledHelpText={this.props.disabledHelpText}
        disabledHelpVisible={widget.properties.showDisabledHelp}
        iconName={this.props.iconName}
        isIconOnly={isIconOnly}
        label={label}
        onClick={this.onClick}
        size="small"
        tabIndex={-1}
      />
    );
  }
}

export default withStyles(styles)(observer(NewRowButton));

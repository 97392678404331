import { Breakpoint } from "@mui/material";
import { observer } from "mobx-react";
import * as React from "react";
import { AddOnHost, AddOnHostRuntimeProperties } from "../config";
import { useWidth, WidthProps } from "../core/Responsive";
import PaneRow from "../models/PaneRow";
import { AccessLevel } from "./AccessLevel";

export interface ConfigProperties {
  breakPoints: { [key in Breakpoint]: { height: number; visible: boolean } };
  dataId: string;
  hostId: string;
  name: string;
}

interface State {
  height: number | null;
}

export class EmbeddedAddOn extends React.Component<
  ConfigProperties & WidthProps,
  State
> {
  public target: React.RefObject<HTMLDivElement>;

  /**
   * [DEPRECATED] Use AddOnHost.clearRoundTrip() instead
   */
  public static clearRoundTrip(): void {
    // FUTURE
    // Replace references to this method with AddOnHost.clearRoundTrip()
    AddOnHost.clearRoundTrip();
  }

  /**
   * [DEPRECATED] Use AddOnHost.rejectRoundTrip() instead
   */
  public static rejectRoundTrip(): void {
    // FUTURE
    // Replace references to this method with AddOnHost.rejectRoundTrip()
    AddOnHost.rejectRoundTrip();
  }

  /**
   * [DEPRECATED] Use AddOnHost.resolveRoundTrip() instead
   */
  public static resolveRoundTrip(): void {
    // FUTURE
    // Replace references to this method with AddOnHost.resolveRoundTrip()
    AddOnHost.resolveRoundTrip();
  }

  /**
   * [DEPRECATED] Use AddOnHost.roundTripStarting() instead
   */
  public static roundTripStarting(): void {
    // FUTURE
    // Replace references to this method with AddOnHost.roundTripStarting()
    AddOnHost.roundTripStarting();
  }

  public constructor(props: ConfigProperties & WidthProps) {
    super(props);
    this.state = { height: null };
    this.target = React.createRef<HTMLDivElement>();
  }

  public componentDidMount(): void {
    setTimeout(() => {
      // Ensure the entire component hierarchy is mounted before registering
      AddOnHost.getAddOn(this.props.hostId).registerEmbeddedAddOn(this);
    });
  }

  public componentDidUpdate(prevProps: ConfigProperties & WidthProps): void {
    if (prevProps.width !== this.props.width) {
      AddOnHost.getAddOn(this.props.hostId).embeddedAddOnChanged();
    }
  }

  public componentWillUnmount(): void {
    // The add-on host may already be unmounted if the whole presentation is
    // being unmounted.
    const addOn: AddOnHost | null = AddOnHost.tryGetAddOn(this.props.hostId);
    if (addOn) {
      addOn.unregisterEmbeddedAddOn(this);
    }
  }

  public render(): React.ReactNode {
    const row = PaneRow.get(this.props.dataId);

    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<string, AddOnHostRuntimeProperties>(
      this.props.name
    );

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    if (!this.props.breakPoints[this.props.width].visible) {
      return null;
    }

    const height =
      this.state.height || this.props.breakPoints[this.props.width].height;

    return (
      <div
        aria-owns={this.props.hostId}
        ref={this.target}
        role="presentation"
        style={{ height, transition: "height 500ms" }}
      />
    );
  }

  public setHeight(height: number | null) {
    this.setState({ height });
  }
}

// FUTURE
// This wrapper component was created to avoid the scope of converting this
// component to a hooks component during the MUI 5 upgrade. When the legacy
// component is converted to a hooks component, this wrapper can be removed and
// hooks it calls can be called by the converted component directly.
const Observer = observer(EmbeddedAddOn);
export default function Wrapped(props: ConfigProperties): JSX.Element {
  const width = useWidth();
  return <Observer {...props} width={width} />;
}

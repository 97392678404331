import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { Dialog, DialogLayoutConfig, SearchChildProps } from "../config";
import AppServer from "../core/AppServer";
import Localization from "../core/Localization";
import { useWidth, WidthProps } from "../core/Responsive";
import Sys from "../core/Sys";
import { BreakPointColumn } from "../coreui/Dialog";
import Table, { TableChildProps, TableProps } from "../coreui/Table";
import AsyncData from "../coreui/table/AsyncData";
import PaneRow from "../models/PaneRow";
import DialogService from "../services/DialogService";
import SimpleResultsGridControlService from "../services/SimpleResultsGridControlService";
import PaneDataStore from "../stores/PaneDataStore";
import GridColumn, { GridColumnConfigProperties } from "./Columns/GridColumn";
import TableDisplayDialog from "./Columns/TableDisplayDialog";
import ErrorBoundary from "./ErrorBoundary";
import { GridRelatedEditButton } from "./GridRelatedEditButton";
import Panel from "./Panel";

interface ConfigProperties {
  cardDepth: number;
  columns: GridColumnConfigProperties[];
  contentDataId: string;
  data?: object;
  dataId: string;
  description: string;
  dialogLayoutId?: number;
  footerToolbar?: object;
  headerToolbar?: object;
  name: string;
  propagated: SearchChildProps;
  summaryToolbar?: object;
  tableKey: string;
  verticalLayout?: object;
}

interface State {
  dialogRowKey?: string;
  isMounted: boolean;
}

export class SimpleResultsGridControl extends React.Component<
  ConfigProperties & WidthProps,
  State
> {
  private readonly contentDataId: string;
  private dialogLabelledById: string;
  private dialogLayoutConfig: DialogLayoutConfig | null = null;
  private populate: ((rows: PaneRow[]) => void) | null = null;
  private propagated: TableChildProps;
  private restoreLostFocus: (() => void) | null;
  private tableProps: TableProps;

  public constructor(props: ConfigProperties & WidthProps) {
    super(props);

    makeObservable<
      SimpleResultsGridControl,
      "dialogLayoutConfig" | "setDialogLayoutConfig"
    >(this, {
      dialogLayoutConfig: observable,
      setDialogLayoutConfig: action,
    });

    this.state = { isMounted: false };

    this.contentDataId = props.contentDataId;

    this.dialogLabelledById = `dialog-${Sys.nextId}-labelled-by`;

    this.propagated = {
      parentTable: {
        cardDepth: props.cardDepth,
        columns: props.columns,
        configProps: {
          contentDataId: props.contentDataId,
          data: props.data,
          dataId: props.dataId,
          name: props.name,
        },
        description: props.description,
        isDocumentGrid: false,
        openRowEditDialog: (r, i, e) => this.openRowDialog(r),
        populateData: () => this.populateData(),
      },
    } as TableChildProps;

    this.tableProps = {
      "aria-label": props.description,
      cardDepth: props.cardDepth,
      cellEdit: false,
      columns: [],
      contentDataId: props.contentDataId,
      dataId: props.dataId,
      footerToolbarChild: props.footerToolbar,
      getAccessibleDescription: this.getAccessibleDescription,
      headerToolbarChild: props.headerToolbar,
      ignoreBusinessErrors: true,
      isColumnFlex: (colId: string) =>
        GridColumn.isColumnFlex(props.columns, colId),
      isColumnVisible: (colId: string, breakpoint: string) =>
        GridColumn.isColumnVisible(props.columns, colId, breakpoint),
      minRowHeight: GridColumn.getColumnsMinRowHeight(props.columns),
      name: props.name,
      propagated: this.propagated,
      rowSelection: undefined,
      selectToolbarChild: undefined,
      setPopulate: (populate) => (this.populate = populate),
      setRestoreLostFocus: (restoreFocus) =>
        (this.restoreLostFocus = restoreFocus),
      showNoData: true,
      summaryToolbarChild: props.summaryToolbar,
    };
  }

  private getAccessibleDescription(rowCount: number): string {
    if (rowCount === 0) {
      return Localization.getBuiltInMessage("DataTable.tableRowCountZero");
    }

    if (rowCount === 1) {
      return Localization.getBuiltInMessage("DataTable.tableRowCountSingle");
    }

    return Localization.getBuiltInMessage("DataTable.tableRowCountMultiple", {
      count: rowCount,
    });
  }

  private onDialogClose = (): void => {
    AppServer.recoverStateFromPoint();

    this.setDialogLayoutConfig(null);
    this.setState({ dialogRowKey: undefined });
  };

  private onDialogOpen = async (
    parentRowKey: string
  ): Promise<BreakPointColumn[]> => {
    AppServer.createStateRecoveryPoint();

    const configRequest = DialogService.getConfig(this.props.dialogLayoutId!);

    const row: PaneRow = PaneRow.get(this.props.dataId)!;
    const dialogOpenRequest = SimpleResultsGridControlService.onDialogOpen(
      row.rowKey,
      this.props.dataId,
      this.props.name,
      parentRowKey
    );

    const [dialogOpenResponse, configResponse] = await Promise.all([
      dialogOpenRequest,
      configRequest,
    ]);

    AppServer.setState(dialogOpenResponse.appServerState);
    PaneDataStore.loadResponse(dialogOpenResponse.paneDataByDataId);

    this.setDialogLayoutConfig(configResponse.dialogLayout);

    return configResponse.breakPoints;
  };

  private openRowDialog(rowKey: string): void {
    this.setState({ dialogRowKey: rowKey });
  }

  private populateData = (): void => {
    const rows: PaneRow[] = PaneDataStore.getPaneCollection(this.contentDataId);

    setTimeout(() => {
      Sys.announce(
        Localization.getBuiltInMessage(
          rows.length === 0
            ? "DataTable.tableRowCountZero"
            : rows.length === 1
            ? "DataTable.tableRowCountSingle"
            : "DataTable.tableRowCountMultiple",
          { count: rows.length }
        )
      );
    }, 2000);

    if (this.populate !== null) {
      this.populate(rows);
    }
  };

  private setDialogLayoutConfig(config: DialogLayoutConfig | null): void {
    this.dialogLayoutConfig = config;
  }

  public componentDidMount(): void {
    for (const column of this.props.columns) {
      this.tableProps.columns.push(
        GridColumn.getColumnDef(column, this.props.columns, this.propagated)
      );
    }

    if (this.props.dialogLayoutId) {
      this.tableProps.columns.push(
        GridRelatedEditButton.createColDef(this.propagated)
      );
    }

    this.setState({ isMounted: true });
  }

  public componentDidUpdate(prevProps: ConfigProperties & WidthProps): void {
    if (prevProps.width !== this.props.width) {
      setTimeout(() => this.populateData());
    }
  }

  public componentWillUnmount(): void {
    this.setState({ isMounted: false });
  }

  public render(): React.ReactNode {
    const row = PaneRow.get(this.props.dataId);
    if (!row) {
      return null;
    }

    if (!this.props.propagated.parentSearch.succeeded) {
      // Only render the results grid after the search is executed
      return null;
    }

    return (
      <ErrorBoundary title={this.props.name}>
        <div style={{ position: "relative" }}>
          <AsyncData
            contentDataId={this.props.contentDataId}
            dataId={this.props.dataId}
            populateData={this.populateData}
          />
          {
            // FUTURE
            // An isMounted flag is used to ensure the Table constructor only
            // fires when there are actually columns for the table. The root
            // cause is that the Table component is doing rendering logic in
            // the constructor, which is counter to the architecture of React.
            // At "some point", likely when moving to the MUI Grid component,
            // this should be reworked.
            this.state.isMounted && (
              <Table
                {...this.tableProps}
                fullWidthChild={this.props.verticalLayout}
                tableKey={this.props.tableKey}
              />
            )
          }
          <TableDisplayDialog
            labelledById={this.dialogLabelledById}
            onClose={this.onDialogClose}
            onExited={() => this.restoreLostFocus!()}
            onOpen={this.onDialogOpen}
            parentRowKey={this.state.dialogRowKey}
          >
            {this.dialogLayoutConfig !== null ? (
              <Panel presentationId={this.dialogLayoutConfig.layoutId}>
                <Dialog
                  config={this.dialogLayoutConfig}
                  labelledById={this.dialogLabelledById}
                />
              </Panel>
            ) : null}
          </TableDisplayDialog>
        </div>
      </ErrorBoundary>
    );
  }
}

// FUTURE
// This wrapper component was created to avoid the scope of converting this
// component to a hooks component during the MUI 5 upgrade. When the legacy
// component is converted to a hooks component, this wrapper can be removed and
// hooks it calls can be called by the converted component directly.
const Observer = observer(SimpleResultsGridControl);
export default function Wrapped(props: ConfigProperties): JSX.Element {
  const width = useWidth();
  return <Observer {...props} width={width} />;
}

import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../../core/Localization";
import PaneRow from "../../models/PaneRow";
import { AccessLevel } from "../AccessLevel";
import { ActionButtonProps, ActionButtonRuntimeProps } from "../ActionButton";
import ApiButton from "../ApiButton";

interface Props extends ActionButtonProps {}

export class ExecuteSearchButton extends React.Component<Props> {
  private onClick = (): void => {
    this.props.propagated.parentSearch.search();
  };

  public render(): React.ReactNode {
    const row = PaneRow.get(this.props.dataId);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<null, ActionButtonRuntimeProps>(
      this.props.name
    );

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    const isIconOnly = !widget.properties.label;
    const label = isIconOnly
      ? Localization.getBuiltInMessage("SearchButton.executeSearchLabel")
      : widget.properties.label;

    return (
      <ApiButton
        alternateText={widget.properties.alternateText}
        buttonColor={this.props.buttonColor}
        disabled={widget.properties.accessLevel === AccessLevel.disabled}
        disabledHelpText={this.props.disabledHelpText}
        disabledHelpVisible={widget.properties.showDisabledHelp}
        iconName={this.props.iconName}
        isIconOnly={isIconOnly}
        label={label}
        onClick={this.onClick}
        size={this.props.size}
      />
    );
  }
}

export default observer(ExecuteSearchButton);

export interface State {
  hasDataChanges: boolean;
  panes: string[];
}

export default class AppServer {
  private static state: State | null = null;
  private static stateRecoveryPoint: State | null = null;
  private static stateRecoveryPointCreated: boolean = false;

  public static addPane(pane: string) {
    if (!AppServer.state) {
      throw Error("AppServer state must be set before a pane can be added");
    }

    AppServer.state.panes.push(pane);
  }

  public static clearState() {
    AppServer.state = null;
    AppServer.stateRecoveryPoint = null;
    AppServer.stateRecoveryPointCreated = false;
  }

  public static clearStateRecoveryPoint() {
    AppServer.stateRecoveryPoint = null;
    AppServer.stateRecoveryPointCreated = false;
  }

  public static createStateRecoveryPoint() {
    if (AppServer.stateRecoveryPointCreated) {
      throw Error(
        "An AppServer state recovery point may not be " +
          "created if one already exists."
      );
    }

    AppServer.stateRecoveryPoint = AppServer.state;
    AppServer.stateRecoveryPointCreated = true;
  }

  public static getState(): State | null {
    return AppServer.state;
  }

  public static hasChanges(): boolean {
    if (AppServer.state === null) {
      return false;
    }

    return AppServer.state.hasDataChanges;
  }

  public static recoverStateFromPoint() {
    if (!AppServer.stateRecoveryPointCreated) {
      throw Error(
        "An AppServer state recovery point must be created " +
          "before it can be recovered."
      );
    }

    AppServer.state = AppServer.stateRecoveryPoint;
    AppServer.stateRecoveryPoint = null;
    AppServer.stateRecoveryPointCreated = false;
  }

  public static setState(state: State) {
    AppServer.state = state;
  }
}

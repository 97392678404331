import { MenuList, Drawer as MuiDrawer, styled, Theme } from "@mui/material";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import * as React from "react";
import Localization from "../core/Localization";
import MaskingStore from "../stores/MaskingStore";
import Button from "./Button";

interface Props {
  anchorEl?: HTMLElement;
  children: React.ReactNode;
  onClose?: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    menuList: {
      [theme.breakpoints.up("xs")]: {
        marginLeft: 24,
        marginRight: 24,
      },
      [theme.breakpoints.up("md")]: {
        marginLeft: 16,
        marginRight: 16,
      },
      [theme.breakpoints.up("lg")]: {
        marginLeft: 0,
        marginRight: 0,
      },
      outline: "none",
      padding: 0,
    },
    paper: {
      maxWidth: "100%",
      [theme.breakpoints.only("xs")]: {
        width: "100%",
      },
    },
  });

const Header = styled("div")(({ theme }) => ({
  alignItems: "center",
  borderBottom: 1,
  borderBottomColor: theme.palette.grey[300],
  borderBottomStyle: "solid",
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: 8,
  paddingLeft: 40,
  paddingRight: 40,
  [theme.breakpoints.up("xs")]: {
    minHeight: 72,
  },
  [theme.breakpoints.up("sm")]: {
    minHeight: 88,
  },
  [theme.breakpoints.up("md")]: {
    minHeight: 108,
  },
  [theme.breakpoints.up("lg")]: {
    minHeight: 140,
  },
}));

export class Drawer extends React.PureComponent<
  Props & WithStyles<typeof styles>
> {
  private isMasked: boolean = false;
  private onClose = (): void => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  public componentDidUpdate(prevProps: Props): void {
    if (!prevProps.anchorEl && this.props.anchorEl) {
      setTimeout(() => {
        MaskingStore.maskOpened();
        this.isMasked = true;
      });
    } else if (prevProps.anchorEl && !this.props.anchorEl) {
      if (this.isMasked) {
        MaskingStore.maskClosed();
        this.isMasked = false;
      }

      prevProps.anchorEl.focus();
    }
  }

  public componentWillUnmount() {
    if (this.isMasked) {
      MaskingStore.maskClosed();
      this.isMasked = false;
    }
  }

  public render(): React.ReactNode {
    return (
      <MuiDrawer
        anchor="right"
        classes={{ paper: this.props.classes.paper }}
        className={this.props.classes.paper}
        onClose={this.onClose}
        open={this.props.anchorEl !== undefined}
        PaperProps={{
          "aria-label": Localization.getBuiltInMessage("menu"),
          role: "dialog",
        }}
      >
        <Header>
          <Button
            aria-label={Localization.getBuiltInMessage("close")}
            icon="fas fa-times"
            onClick={this.onClose}
          />
        </Header>
        <MenuList
          aria-label={Localization.getBuiltInMessage("menu")}
          autoFocusItem={true}
          className={this.props.classes.menuList}
          disableListWrap={true}
        >
          {this.props.children}
        </MenuList>
      </MuiDrawer>
    );
  }
}

export default withStyles(styles)(Drawer);

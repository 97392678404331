import { FormControlLabel, Radio as MuiRadio, Theme } from "@mui/material";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import { observer } from "mobx-react";
import * as React from "react";
import Sys from "../core/Sys";
import Icon from "./Icon";

interface Props {
  label: string;
  margin: boolean;
  roundTripOnChange: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

const styles = (theme: Theme) =>
  createStyles({
    icon: {
      // Makes the icon square
      marginRight: -1.1,
    },
    ripple: {
      overflow: "visible",
    },
  });

export class ApiRadio extends React.Component<
  Props & WithStyles<typeof styles>
> {
  private readonly componentId: string;
  private formLabelControlElement = React.createRef<HTMLDivElement>();

  public constructor(props: Props & WithStyles<typeof styles>) {
    super(props);

    this.componentId = `radio-${Sys.nextId}`;
  }

  public render(): React.ReactNode {
    // VERSION_WARNING iOS 15 / Safari 15 (Mac OS)
    // There is a bug in Safari where input elements that are nested in a label
    // get trapped in voiceover navigation after a roundtrip if focus returns
    // back to the input element. When this happens, focus will not proceed in
    // iOS while focus will be sent to the first element on the page in Safari
    // on MacOS.
    // The work-around implemented here is to force voice-over's focus to be
    // on the element that contains both the input and the label, and to make
    // the browser's focus be on that same element after roundtrip.
    // This condition seems to circumvent the bug.
    let labelId: string | undefined;
    let label: React.ReactNode;
    let onChange: (() => void) | undefined;
    if (Sys.isSafari) {
      labelId = `${this.componentId}-labelled-by`;
      label = (
        <span aria-hidden="true" id={labelId} tabIndex={-1}>
          {this.props.label}
        </span>
      );
      onChange = () => {
        if (this.props.roundTripOnChange) {
          this.formLabelControlElement.current!.focus();
        }
      };
    } else {
      labelId = undefined;
      label = this.props.label;
      onChange = undefined;
    }

    return (
      <FormControlLabel
        aria-labelledby={labelId}
        control={
          <MuiRadio
            checkedIcon={
              <Icon
                icon="far fa-dot-circle"
                className={this.props.classes.icon}
              />
            }
            color="default"
            icon={
              <Icon icon="far fa-circle" className={this.props.classes.icon} />
            }
            onChange={onChange}
            TouchRippleProps={{ className: this.props.classes.ripple }}
            value={this.props.value}
          />
        }
        id={this.componentId}
        label={label}
        ref={this.formLabelControlElement}
        style={this.props.margin ? { marginRight: 24 } : undefined}
      />
    );
  }
}

export default withStyles(styles)(observer(ApiRadio));

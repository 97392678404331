import { InputAdornment, Theme } from "@mui/material";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { SearchChildProps, SiteSearchInfo } from "../config";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import Button from "../coreui/Button";
import ComboBoxOption from "../coreui/ComboBoxOption";
import Select from "../coreui/Select";
import TextField from "../coreui/TextField";
import PaneRow from "../models/PaneRow";
import ErrorsStore from "../stores/ErrorsStore";
import { AccessLevel } from "./AccessLevel";

interface ConfigProperties {
  dataId: string;
  helperText: string;
  mandatory: boolean;
  name: string;
  propagated: SearchChildProps;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
  siteSearches: SiteSearchInfo[];
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "grid",
      gridColumnGap: theme.horizontalSpacing.widget.related,

      [theme.breakpoints.up("xs")]: {
        gridRowGap: theme.verticalSpacing.closelyRelated.xs,
        gridTemplateColumns: "1fr",
      },
      [theme.breakpoints.up("sm")]: {
        gridRowGap: theme.verticalSpacing.closelyRelated.sm,
      },
      [theme.breakpoints.up("md")]: {
        gridRowGap: theme.verticalSpacing.closelyRelated.md,
        gridTemplateColumns: "270px 1fr",
      },
      [theme.breakpoints.up("lg")]: {
        gridRowGap: theme.verticalSpacing.closelyRelated.lg,
      },
    },
  });

export class SiteCriteria extends React.Component<
  ConfigProperties & WithStyles<typeof styles>
> {
  private dataId: string = "";
  private name: string = "";

  public constructor(props: ConfigProperties & WithStyles<typeof styles>) {
    super(props);

    makeObservable<
      SiteCriteria,
      "dataId" | "name" | "onValueChange" | "syncDerivedWithProps"
    >(this, {
      dataId: observable,
      name: observable,
      onValueChange: action,
      syncDerivedWithProps: action,
    });

    this.syncDerivedWithProps();
  }

  private getErrors = (value: string): string[] => {
    const row = PaneRow.get(this.dataId);
    if (!row) {
      return [];
    }

    const widget = row.getWidgetT<string | null, RuntimeProperties>(this.name);
    return widget.properties.businessErrors;
  };

  private onClickSearchButton = (): void => {
    this.props.propagated.parentSearch.search();
  };

  private onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key !== "Enter") {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    this.props.propagated.parentSearch.search();
  };

  private onSearchForChange = (option: ComboBoxOption): void => {
    const baseUrl: string | undefined = option.value?.substring(1);

    if (!baseUrl) {
      return;
    }

    const row = PaneRow.get(this.props.dataId)!;
    const widget = row.getWidgetT<string | null, RuntimeProperties>(
      this.props.name
    );

    if (widget.value !== null) {
      const queryString = Sys.objectToQueryString({
        SiteCriteria: widget.value || undefined,
      });
      Sys.setHash(`${baseUrl}?${queryString}`);
    } else {
      Sys.setHash(baseUrl);
    }
  };

  private onValueChange = (value: string): void => {
    ErrorsStore.clearBusinessErrorsForWidget(this.dataId, this.name);

    const row = PaneRow.get(this.dataId)!;
    const widget = row.getWidgetT<string | null, RuntimeProperties>(this.name);
    widget.setValue(value === "" ? null : value);
  };

  private syncDerivedWithProps(): void {
    this.dataId = this.props.dataId;
    this.name = this.props.name;
  }

  public componentDidUpdate(): void {
    this.syncDerivedWithProps();
  }

  public render(): React.ReactNode {
    const row = PaneRow.get(this.dataId);

    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<string | null, RuntimeProperties>(this.name);

    let defaultSearch: string | null = null;
    const options: ComboBoxOption[] = [];
    for (const siteSearch of widget.properties.siteSearches) {
      if (siteSearch.isDefault) {
        defaultSearch = siteSearch.url;
      }
      options.push({
        display: siteSearch.description,
        value: siteSearch.url,
      });
    }

    return (
      <div className={this.props.classes.root}>
        <Select
          label={Localization.getBuiltInMessage("siteCriteriaSearchFor")}
          onValueChange={this.onSearchForChange}
          options={options}
          value={defaultSearch}
        />
        <TextField
          getErrors={this.getErrors}
          helperText={this.props.helperText}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" style={{ marginTop: -4 }}>
                <Button
                  aria-label={Localization.getBuiltInMessage("search")}
                  color="dark"
                  icon="fas fa-search"
                  onClick={this.onClickSearchButton}
                  size="small"
                />
              </InputAdornment>
            ),
          }}
          label={Localization.getBuiltInMessage("search")}
          name={this.props.name}
          onKeyPress={this.onKeyPress}
          onValueChange={this.onValueChange}
          required={this.props.mandatory}
          role="group"
          value={(widget.value || "") as string}
          variant="filled"
        />
      </div>
    );
  }
}

export default withStyles(styles)(observer(SiteCriteria));

import {
  Collapse as MuiCollapse,
  CollapseProps as MuiCollapseProps,
  Theme,
} from "@mui/material";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import * as React from "react";
import * as ReactDOM from "react-dom";
import multiClassName from "./MultiClassName";

interface Props extends MuiCollapseProps {}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      transition: theme.transitions.create(["height", "margin-top"]),
    },
  });

export class Collapse extends React.PureComponent<
  Props & WithStyles<typeof styles>
> {
  private onCollapse = (node: HTMLElement): void => {
    node.style.marginTop = "0px";
  };

  private onCollapseEnd = (node: HTMLElement): void => {
    node.style.display = "none";
  };

  private onExpand = (node: HTMLElement, isAppearing?: boolean): void => {
    node.style.marginTop = "";
  };

  private onExpandStart = (node: HTMLElement, isAppearing?: boolean): void => {
    node.style.display = "";
  };

  public componentDidMount(): void {
    if (this.props.in) {
      return;
    }

    // Ensure the element is in 'collapsed' state if it starts collapsed.
    const node = ReactDOM.findDOMNode(this)! as HTMLElement;
    node.style["display"] = "none";
    node.style["marginTop"] = "0px";
  }

  public render(): React.ReactNode {
    const {
      className,
      classes,
      children,
      onEnter: onEnterProp,
      onExited: onExitedProp,
      ...collapseProps
    } = this.props;

    const onEnter = (node: HTMLElement, isAppearing: boolean): void => {
      if (onEnterProp) {
        onEnterProp(node, isAppearing);
      }

      this.onExpandStart(node, isAppearing);
    };

    const onExited = (node: HTMLElement): void => {
      if (onExitedProp) {
        onExitedProp(node);
      }

      this.onCollapseEnd(node);
    };

    return (
      <MuiCollapse
        {...collapseProps}
        className={multiClassName(classes.container, className)}
        onEnter={onEnter}
        onEntering={this.onExpand}
        onExited={onExited}
        onExiting={this.onCollapse}
      >
        {children}
      </MuiCollapse>
    );
  }
}

export default withStyles(styles)(Collapse);

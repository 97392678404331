import { FormControl, FormHelperText, FormLabel, Theme } from "@mui/material";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import { observer } from "mobx-react";
import * as React from "react";
import { SearchChildProps } from "../config";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import Collapse from "../coreui/Collapse";
import ComboBoxOption from "../coreui/ComboBoxOption";
import DatePicker from "../coreui/DatePicker";
import getFieldHelperText from "../coreui/FieldHelperText";
import multiClassName from "../coreui/MultiClassName";
import Select from "../coreui/Select";
import PaneRow from "../models/PaneRow";
import { AccessLevel } from "./AccessLevel";

interface ConfigProperties {
  dataId: string;
  dateFormatError: string;
  dateRangeError: string;
  fromLabel: string;
  helperText: string;
  label: string;
  mandatory: boolean;
  name: string;
  propagated: SearchChildProps;
  toLabel: string;
}

export interface DateRangeCriteriaValue {
  fromDate: number[] | null;
  fromDateIsValid: boolean;
  selectedCriteriaName: string | null;
  toDate: number[] | null;
  toDateIsValid: boolean;
}

interface PredefinedCriteria {
  description: string;
  name: string;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  predefinedCriteria: PredefinedCriteria[];
}

interface State {
  hasInvalidRange: boolean;
  showSelectHelperText: boolean;
}

const styles = (theme: Theme) => {
  const collapseStyle = {};
  const spacingStyle = {};
  for (const breakPoint of theme.spacingBreakPoints) {
    const horizontalSpacing = theme.horizontalSpacing.related[breakPoint];
    const verticalSpacing = theme.verticalSpacing.closelyRelated[breakPoint];

    collapseStyle[theme.breakpoints.up(breakPoint)] = {
      marginTop: `${verticalSpacing}px`,
    };

    spacingStyle[theme.breakpoints.up(breakPoint)] = {
      gridColumnGap: `${horizontalSpacing}px`,
      gridRowGap: `${verticalSpacing}px`,
    };
  }

  const result = {
    collapse: collapseStyle,
    label: {
      marginBottom: 8,
    },
    rangeContainer: {
      [theme.breakpoints.up("sm")]: {
        gridTemplateColumns: "1fr 1fr",
      },
      [theme.breakpoints.only("xs")]: {
        gridTemplateColumns: "1fr",
      },

      display: "grid",
    },
    spacing: spacingStyle,
  };

  return createStyles(result);
};

export class DateRangeCriteria extends React.PureComponent<
  ConfigProperties & WithStyles<typeof styles>,
  State
> {
  private static readonly customRangeSentinel: string = "custom-range";
  public static readonly widgetTypeId: number = 81;

  private readonly componentId: string;
  private readonly helperTextId: string;
  private readonly labelId: string;

  private static deserializeDate(serializedDate: number[] | null): Date | null {
    if (serializedDate === null) {
      return null;
    }

    return new Date(
      serializedDate[0],
      serializedDate[1] - 1,
      serializedDate[2]
    );
  }

  private static serializeDate(date: Date | null): number[] | null {
    if (date === null) {
      return null;
    }

    return [date.getFullYear(), date.getMonth() + 1, date.getDate()];
  }

  public static clear(widgetName: string, row: PaneRow): void {
    const widget = row.getWidgetT<DateRangeCriteriaValue, RuntimeProperties>(
      widgetName
    );

    widget.setValue({
      fromDate: null,
      fromDateIsValid: true,
      selectedCriteriaName: null,
      toDate: null,
      toDateIsValid: true,
    });
  }

  public static isEntered(widgetName: string, row: PaneRow): boolean {
    const widget = row.getWidgetT<DateRangeCriteriaValue, RuntimeProperties>(
      widgetName
    );

    if (
      widget.value.selectedCriteriaName ===
      DateRangeCriteria.customRangeSentinel
    ) {
      return widget.value.fromDate !== null || widget.value.toDate !== null;
    }

    return widget.value.selectedCriteriaName !== null;
  }

  public constructor(props: ConfigProperties & WithStyles<typeof styles>) {
    super(props);

    this.componentId = `date-range-criteria-${Sys.nextId}`;
    this.helperTextId = `${this.componentId}-helper-text`;
    this.labelId = `${this.componentId}-label`;

    this.state = {
      hasInvalidRange: false,
      showSelectHelperText: true,
    };
  }

  private announceErrors(errors: string[]): void {
    if (errors.length > 0) {
      Sys.announce(errors.join("; "));
    }
  }

  private checkForErrors(fromDate: Date | null, toDate: Date | null): void {
    this.setState({ hasInvalidRange: false });

    if (fromDate !== null && toDate !== null) {
      if (fromDate.valueOf() > toDate.valueOf()) {
        this.setState({ hasInvalidRange: true });
      }
    }
  }

  private getErrors(): string[] {
    const errors = [];

    if (this.state.hasInvalidRange) {
      errors.push(this.props.dateRangeError);
    }

    return errors;
  }

  private onCollapseEnd = (node: HTMLElement): void => {
    this.setState({ showSelectHelperText: true });
  };

  private onEnterKeyPress = (): void => {
    this.props.propagated.parentSearch.search();
  };

  private onExpandStart = (node: HTMLElement, isAppearing?: boolean): void => {
    this.setState({ showSelectHelperText: false });
  };

  private onFromDateChange = (fromDate: Date | null): void => {
    const row: PaneRow = PaneRow.get(this.props.dataId)!;
    const widget = row.getWidgetT<DateRangeCriteriaValue, RuntimeProperties>(
      this.props.name
    );

    if (widget.value.toDateIsValid) {
      this.checkForErrors(
        fromDate,
        DateRangeCriteria.deserializeDate(widget.value.toDate)
      );
    }

    widget.setValue({
      ...widget.value,
      fromDate: DateRangeCriteria.serializeDate(fromDate),
      fromDateIsValid: true,
      selectedCriteriaName: DateRangeCriteria.customRangeSentinel,
    });
  };

  private onFromDateInvalid = (entry: string): void => {
    const row: PaneRow = PaneRow.get(this.props.dataId)!;
    const widget = row.getWidgetT<DateRangeCriteriaValue, RuntimeProperties>(
      this.props.name
    );

    widget.setValue({
      ...widget.value,
      fromDateIsValid: false,
    });
  };

  private onPredefinedCriteriaChange = (option: ComboBoxOption): void => {
    const row: PaneRow = PaneRow.get(this.props.dataId)!;
    const widget = row.getWidgetT<DateRangeCriteriaValue, RuntimeProperties>(
      this.props.name
    );

    this.setState({
      hasInvalidRange: false,
    });

    widget.setValue({
      fromDate: null,
      fromDateIsValid: true,
      selectedCriteriaName: option.value,
      toDate: null,
      toDateIsValid: true,
    });
  };

  private onToDateChange = (toDate: Date | null): void => {
    const row: PaneRow = PaneRow.get(this.props.dataId)!;
    const widget = row.getWidgetT<DateRangeCriteriaValue, RuntimeProperties>(
      this.props.name
    );

    if (widget.value.fromDateIsValid) {
      this.checkForErrors(
        DateRangeCriteria.deserializeDate(widget.value.fromDate),
        toDate
      );
    }

    widget.setValue({
      ...widget.value,
      selectedCriteriaName: DateRangeCriteria.customRangeSentinel,
      toDate: DateRangeCriteria.serializeDate(toDate),
      toDateIsValid: true,
    });
  };

  private onToDateInvalid = (entry: string): void => {
    const row: PaneRow = PaneRow.get(this.props.dataId)!;
    const widget = row.getWidgetT<DateRangeCriteriaValue, RuntimeProperties>(
      this.props.name
    );

    widget.setValue({
      ...widget.value,
      toDateIsValid: false,
    });
  };

  public render(): React.ReactNode {
    const row = PaneRow.get(this.props.dataId);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<DateRangeCriteriaValue, RuntimeProperties>(
      this.props.name
    );

    if (widget.properties.accessLevel <= AccessLevel.readOnly) {
      return null;
    }

    const fieldHelperText = getFieldHelperText({
      getErrors: (v) => this.getErrors(),
      helperText: this.props.helperText,
    });

    const fromDatePicker: React.ReactNode = (
      <DatePicker
        dateFormatError={this.props.dateFormatError}
        error={fieldHelperText.hasErrors}
        label={this.props.fromLabel}
        onChange={this.onFromDateChange}
        onEnterKeyPress={this.onEnterKeyPress}
        onInvalidEntry={this.onFromDateInvalid}
        value={DateRangeCriteria.deserializeDate(widget.value.fromDate)}
      />
    );

    const toDatePicker: React.ReactNode = (
      <DatePicker
        dateFormatError={this.props.dateFormatError}
        error={fieldHelperText.hasErrors}
        label={this.props.toLabel}
        onChange={this.onToDateChange}
        onEnterKeyPress={this.onEnterKeyPress}
        onInvalidEntry={this.onToDateInvalid}
        value={DateRangeCriteria.deserializeDate(widget.value.toDate)}
      />
    );

    if (widget.properties.predefinedCriteria.length === 0) {
      return (
        <FormControl error={fieldHelperText.hasErrors} fullWidth={true}>
          <FormLabel className={this.props.classes.label} id={this.labelId}>
            {this.props.label}
          </FormLabel>
          <div
            aria-labelledby={this.labelId}
            className={multiClassName(
              this.props.classes.rangeContainer,
              this.props.classes.spacing
            )}
          >
            {fromDatePicker}
            {toDatePicker}
          </div>
          {fieldHelperText.helperText && (
            <FormHelperText
              aria-hidden={true}
              component="div"
              id={this.helperTextId}
            >
              {fieldHelperText.helperText}
            </FormHelperText>
          )}
        </FormControl>
      );
    }

    const options: ComboBoxOption[] = widget.properties.predefinedCriteria.map(
      (v) => ({
        display: v.description,
        value: v.name,
      })
    );

    options.unshift({
      display: Localization.getBuiltInMessage("comboNoSelection"),
      value: null,
    });

    options.push({
      display: Localization.getBuiltInMessage("customDate"),
      value: DateRangeCriteria.customRangeSentinel,
    });

    return (
      <div>
        <Select
          error={fieldHelperText.hasErrors}
          helperText={
            this.state.showSelectHelperText
              ? fieldHelperText.helperText
              : undefined
          }
          label={this.props.label}
          onValueChange={this.onPredefinedCriteriaChange}
          options={options}
          showAsMandatory={this.props.mandatory}
          value={widget.value.selectedCriteriaName}
        />
        <Collapse
          className={this.props.classes.collapse}
          in={
            widget.value.selectedCriteriaName ===
            DateRangeCriteria.customRangeSentinel
          }
          onEnter={this.onExpandStart}
          onExited={this.onCollapseEnd}
        >
          <FormControl
            error={fieldHelperText.hasErrors}
            fullWidth={true}
            onBlur={() => {
              this.announceErrors(fieldHelperText.errors);
            }}
          >
            <div
              className={multiClassName(
                this.props.classes.rangeContainer,
                this.props.classes.spacing
              )}
            >
              {fromDatePicker}
              {toDatePicker}
            </div>
            {fieldHelperText.helperText && (
              <FormHelperText
                aria-hidden={true}
                component="div"
                id={this.helperTextId}
              >
                {fieldHelperText.helperText}
              </FormHelperText>
            )}
          </FormControl>
        </Collapse>
      </div>
    );
  }
}

export default withStyles(styles)(observer(DateRangeCriteria));

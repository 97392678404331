import * as React from "react";

interface Props {
  onFocusChanged?: (isFocused: boolean) => void;
}

export class FocusTracker extends React.PureComponent<Props> {
  private componentIsFocused: boolean = false;

  public constructor(props: Props) {
    super(props);
  }

  private onBlur = (event: React.FocusEvent<HTMLDivElement>): void => {
    if (
      !event.currentTarget.contains(event.relatedTarget as Node) &&
      event.target instanceof HTMLElement &&
      event.currentTarget.contains(event.target as Node)
    ) {
      this.componentIsFocused = false;
      if (this.props.onFocusChanged) {
        this.props.onFocusChanged(false);
      }
    }
  };

  private onFocus = (event: React.FocusEvent<HTMLDivElement>): void => {
    if (
      !event.currentTarget.contains(event.relatedTarget as Node) &&
      event.currentTarget.contains(event.target as Node)
    ) {
      this.componentIsFocused = true;
      if (this.props.onFocusChanged) {
        this.props.onFocusChanged(true);
      }
    }
  };

  public isFocused() {
    return this.componentIsFocused;
  }

  public render(): React.ReactNode {
    return (
      <div onBlur={this.onBlur} onFocus={this.onFocus}>
        {this.props.children}
      </div>
    );
  }
}

export default FocusTracker;

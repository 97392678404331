import * as React from "react";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import { ForegroundColorType, ThemeProvider } from "../theme";
import {
  BackgroundImage,
  Container,
  EnvironmentBanner,
  ErrorDisplay,
  Footer,
  Header,
} from "./components";

export interface ErrorCodeConfig {
  backgroundImage?: string;
  message: string;
  solutions: string[];
}

interface FooterConfig {
  backgroundColor: string;
  foreground: ForegroundColorType;
}

interface HeaderConfig {
  backgroundColor: string;
  bottomBorderColor: string;
  foreground: ForegroundColorType;
  logoUrl: string;
  logoLabel: string;
}

export interface ErrorPageTemplateConfig {
  backgroundColor: string;
  footer: FooterConfig;
  foreground: ForegroundColorType;
  header: HeaderConfig;
}

interface Props {
  backgroundColor?: string;
  config: ErrorCodeConfig;
  footer?: FooterConfig;
  foreground?: ForegroundColorType | null;
  header?: HeaderConfig;
  message?: string | null;
  solutionsTitle?: string;
  status: number;
}

export function ErrorPageTemplate(props: Props): JSX.Element {
  const mainContentRef = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    const message = Localization.getBuiltInMessage("GeneralError.errorPrefix");
    Sys.announce(message);

    mainContentRef.current!.focus();
  }, []);

  const goHome = () => {
    Sys.setHash("", false, true);
    window.location.assign(Sys.settings.rootUrl);
  };

  let backgroundColor: string;
  let foreground: ForegroundColorType;
  if (
    props.backgroundColor &&
    props.foreground !== null &&
    props.foreground !== undefined
  ) {
    // Set the background to 80% opacity
    backgroundColor = `${props.backgroundColor}CC`;
    foreground = props.foreground;
  } else {
    backgroundColor = "#b71c1cCC";
    foreground = ForegroundColorType.White;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <EnvironmentBanner />
      {props.header && (
        <ThemeProvider foreground={props.header.foreground} header>
          <Header
            backgroundColor={props.header.backgroundColor}
            bottomBorderColor={props.header.bottomBorderColor}
            logoProps={{
              imageUrl: props.header.logoUrl,
              label: props.header.logoLabel,
              onClick: goHome,
            }}
          />
        </ThemeProvider>
      )}
      <ThemeProvider foreground={foreground}>
        <main
          ref={mainContentRef}
          style={{
            backgroundColor,
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
          }}
          tabIndex={0}
        >
          {props.config.backgroundImage ? (
            <BackgroundImage url={props.config.backgroundImage} />
          ) : null}
          <Container sx={{ display: "flex", flexGrow: 1 }}>
            <ErrorDisplay
              detailedMessage={props.message || undefined}
              message={props.config.message}
              onHomeButtonClick={goHome}
              solutions={props.config.solutions}
              solutionsTitle={props.solutionsTitle}
              status={props.status}
            />
          </Container>
        </main>
      </ThemeProvider>
      {props.footer && (
        <ThemeProvider footer foreground={props.footer.foreground}>
          <Footer backgroundColor={props.footer.backgroundColor} />
        </ThemeProvider>
      )}
    </div>
  );
}

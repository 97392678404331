import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../../core/Localization";
import Sys from "../../core/Sys";
import PaneRow from "../../models/PaneRow";
import { AccessLevel } from "../AccessLevel";
import { ActionButtonProps, ActionButtonRuntimeProps } from "../ActionButton";
import ApiButton from "../ApiButton";

interface Props extends ActionButtonProps {}

export class ClearCriteriaButton extends React.Component<Props> {
  private get label(): string {
    const row = PaneRow.get(this.props.dataId)!;
    const widget = row.getWidgetT<null, ActionButtonRuntimeProps>(
      this.props.name
    );

    const label = !!widget.properties.label
      ? widget.properties.label
      : Localization.getBuiltInMessage("SearchButton.clearCriteriaLabel");

    return label;
  }

  private onClick = (): void => {
    this.props.propagated.parentSearch.clear();
    Sys.announce(
      Localization.getBuiltInMessage("Button.succeeded", {
        label: this.label,
      })
    );
  };

  public render(): React.ReactNode {
    const row = PaneRow.get(this.props.dataId);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<null, ActionButtonRuntimeProps>(
      this.props.name
    );

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    return (
      <ApiButton
        alternateText={widget.properties.alternateText}
        buttonColor={this.props.buttonColor}
        disabled={widget.properties.accessLevel === AccessLevel.disabled}
        disabledHelpText={this.props.disabledHelpText}
        disabledHelpVisible={widget.properties.showDisabledHelp}
        iconName={this.props.iconName}
        isIconOnly={!widget.properties.label}
        label={this.label}
        onClick={this.onClick}
        size={this.props.size}
      />
    );
  }
}

export default observer(ClearCriteriaButton);

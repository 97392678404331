import { Grid, styled, Theme, useMediaQuery } from "@mui/material";
import * as React from "react";
import Localization from "../../core/Localization";
import Button from "../../coreui/Button";
import Typography from "../../coreui/Typography";

interface Props {
  detailedMessage?: string;
  message: string;
  onHomeButtonClick: () => void;
  solutions: string[];
  solutionsTitle?: string;
  status: number;
}

const ContainerGrid = styled(Grid)({
  flexGrow: 1,
});

const ContentGrid = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  justifyContent: "center",
});

const Status = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  [theme.breakpoints.only("xs")]: { fontSize: 45, lineHeight: "60px" },
  [theme.breakpoints.up("sm")]: { fontSize: 56, lineHeight: "72px" },
  [theme.breakpoints.up("lg")]: { fontSize: 112, lineHeight: "148px" },
}));

export function ErrorDisplay(props: Props): JSX.Element {
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.only("xs"));
  return (
    <ContainerGrid container>
      <ContentGrid item md={6} xs={12}>
        <Status variant="h1">{props.status}</Status>
        <Typography variant="h3" paragraph={true}>
          {props.message}
        </Typography>
        {props.detailedMessage ? (
          <Typography paragraph={true}>
            {props.detailedMessage.split("\n").map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </Typography>
        ) : null}
        <Typography paragraph={true}>{props.solutionsTitle}</Typography>
        <ul>
          <Typography>
            {props.solutions.map((solution: string, index: number) => (
              <li key={index} style={{ marginBottom: 24 }}>
                {solution}
              </li>
            ))}
          </Typography>
        </ul>
        <div>
          {isXs ? (
            <Button fab icon="fas fa-home" onClick={props.onHomeButtonClick} />
          ) : (
            <Button icon="fas fa-home" onClick={props.onHomeButtonClick}>
              {Localization.getBuiltInMessage("home")}
            </Button>
          )}
        </div>
      </ContentGrid>
    </ContainerGrid>
  );
}

import Localization from "../core/Localization";
import Routing from "../core/Routing";
import Sys from "../core/Sys";
import ErrorsStore, { BusinessError } from "../stores/ErrorsStore";
import SessionMessagesStore, {
  SessionMessage,
} from "../stores/SessionMessagesStore";
import BaseService from "./BaseService";

export interface AccountActivationResponse {
  succeeded: boolean;
}

export interface CompleteAuthenticationResponse {
  businessErrors: BusinessError[];
  expirationSeconds?: number;
  sessionMessages?: SessionMessage[];
  userAccountObjectHandle?: string;
}

export interface ExternalAuthenticator {
  description: string;
  iconName: string;
  providerName: string;
  url: string;
}

export interface NewSessionResponse {
  expirationSeconds?: number;
  sessionMessages?: SessionMessage[];
  userAccountObjectHandle?: string;
}

export interface PasswordResetResponse {
  succeeded: boolean;
}

export interface UserInfoResponse {
  sessionMessages: SessionMessage[];
  userAccountObjectHandle: string;
}

export default class UserService {
  public static accountObjectHandle: string;

  public static get isAuthenticated(): boolean {
    return !!Sys.getCookie(Sys.sessionTokenCookie);
  }

  public static get isGuest(): boolean {
    return !!Sys.getCookie(Sys.guestSessionTokenCookie);
  }

  public static async checkSession(): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      BaseService.requestObject<number>("User/Session", null, null, null, "GET")
        .then((response) => {
          if (response && response > 0) {
            resolve(response);
          } else {
            reject(null);
          }
        })
        .catch(() => reject(null));
    });
  }

  public static async completeAuthentication(
    parameters: string | null
  ): Promise<number | undefined> {
    return new Promise<number | undefined>((resolve, reject) => {
      const providerName = Sys.getCookie("authenticatorProviderName");

      BaseService.requestObject(
        `User/CompleteAuthentication/${providerName}`,
        null,
        null,
        {
          parameters,
        },
        "POST",
        true
      )
        .then((response: CompleteAuthenticationResponse) => {
          if (response.businessErrors.length) {
            if (Sys.settings.useConfiguredAuthentication) {
              Routing.renderSignIn().then(() => {
                Sys.deleteCookie("authenticatorProviderName");
                Sys.deleteCookie("authenticatorHref");
                setTimeout(() => {
                  ErrorsStore.setBusinessErrors(response.businessErrors);
                }, 200);
              });
            } else {
              Sys.deleteCookie("authenticatorProviderName");
              Sys.deleteCookie("authenticatorHref");
              Routing.goToErrorPage(response.businessErrors[0].message);
            }
          } else {
            window.location.replace(Sys.getCookie("authenticatorHref")!);

            Sys.deleteCookie("authenticatorProviderName");
            Sys.deleteCookie("authenticatorHref");
          }

          if (response.userAccountObjectHandle) {
            UserService.accountObjectHandle = response.userAccountObjectHandle;
            SessionMessagesStore.setMessages(response.sessionMessages!);
          }

          resolve(response.expirationSeconds);
        })
        .catch((response) => {
          Sys.deleteCookie("authenticatorProviderName");
          Sys.deleteCookie("authenticatorHref");

          reject(null);
        });
    });
  }

  public static async getExternalAuthenticators(): Promise<
    ExternalAuthenticator[]
  > {
    return await BaseService.requestObject<ExternalAuthenticator[]>(
      "User/GetExternalAuthenticators",
      null,
      null,
      null,
      "GET"
    );
  }

  public static async getUserInfo(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      BaseService.requestObject<UserInfoResponse>(
        "User/UserInfo",
        null,
        null,
        null,
        "GET"
      )
        .then((response) => {
          UserService.accountObjectHandle = response.userAccountObjectHandle;
          SessionMessagesStore.setMessages(response.sessionMessages);
          resolve();
        })
        .catch(() => reject());
    });
  }

  public static logonAsGuest(): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      BaseService.requestObject<NewSessionResponse>("User/GuestSession")
        .then((response) => {
          if (response.expirationSeconds && response.expirationSeconds > 0) {
            if (response.userAccountObjectHandle) {
              UserService.accountObjectHandle =
                response.userAccountObjectHandle;
              SessionMessagesStore.setMessages(response.sessionMessages!);
            }

            resolve(response.expirationSeconds);
          } else {
            reject(null);
          }
        })
        .catch(() => {
          reject(null);
        });
    });
  }

  public static async logout(navigate: boolean = true): Promise<void> {
    if (navigate) {
      Sys.setHash("", false, true);
    }

    await BaseService.request("User/Session", undefined, "DELETE");
    Sys.deleteCookie(Sys.sessionTokenCookie);

    if (Sys.settings.signOutUrl) {
      window.location.href = Sys.settings.signOutUrl;
    } else {
      window.location.reload();
    }
  }

  public static async validateCredentials(
    userName: string,
    password: string
  ): Promise<string | null> {
    return new Promise<string | null>((resolve, reject) => {
      BaseService.requestObject(
        "User/Session",
        null,
        {
          password,
          userName,
        },
        null,
        "POST",
        true
      )
        .then((response: NewSessionResponse) => {
          if (response.userAccountObjectHandle) {
            UserService.accountObjectHandle = response.userAccountObjectHandle;
            SessionMessagesStore.setMessages(response.sessionMessages!);
          }

          resolve(null);
        })
        .catch((response) => {
          reject(null);
        });
    });
  }
}

import * as React from "react";
import { WidthProps } from "../core/Responsive";
import MenuItem, { FocusProps, MenuItemConfig } from "./MenuItem";
import { ToolbarOverflowChild } from "./ToolbarContainerOverflowSection";

export interface ConfigProperties extends WidthProps {
  child: { props: MenuItemConfig; type: string };
  dataId: string;
  // The disabled prop is used internally by MUI MenuList to determine whether
  // to include the MenuItem in focus processing.
  disabled: boolean;
  isLayoutOnly: boolean;
  lg: boolean;
  md: boolean;
  propagated: ToolbarOverflowChild;
  sm: boolean;
  xl: boolean;
  xs: boolean;
}

export class ToolbarContainerOverflowItem extends React.PureComponent<
  ConfigProperties & FocusProps
> {
  public render() {
    let child = this.props.child;
    if (this.props.propagated.hideIcons && child.props["iconName"]) {
      child = { ...child, props: { ...child.props } };
      child.props["iconName"] = undefined;
    }

    // Hidden via JavaScript instead of CSS to avoid wrapping MenuItem
    // which breaks keyboard navigation
    if (!this.props[this.props.width]) {
      return null;
    }

    return (
      <MenuItem
        autoFocus={this.props.autoFocus}
        child={child}
        disabled={this.props.disabled}
        propagated={this.props.propagated}
        tabIndex={this.props.tabIndex}
      />
    );
  }
}

export default ToolbarContainerOverflowItem;

import { Theme } from "@mui/material";
import {
  createStyles,
  CSSProperties,
  WithStyles,
  withStyles,
  WithTheme,
  withTheme,
} from "@mui/styles";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../core/Localization";
import FocusTracker from "../coreui/FocusTracker";
import KeyboardNavigationGroup from "../coreui/KeyboardNavigationGroup";
import Presentation from "../coreui/Presentation";
import { TableChildProps } from "../coreui/Table";
import PaneRow from "../models/PaneRow";
import { AccessLevel } from "./AccessLevel";
import { inputWrapperClassName } from "./ToolbarInputWrapper";

interface ChildWidgetProperties {
  accessLevel?: AccessLevel;
}

interface Props {
  childLayoutWidgetNames: string[];
  childWidgetNames: string[];
  dataId: string;
  endChild: object;
  lg: boolean;
  md: boolean;
  overflowChild: object;
  propagated: TableChildProps;
  sm: boolean;
  startChild: object;
  toolbarType: "header" | "footer" | "selection";
  xl: boolean;
  xs: boolean;
}

interface State {
  isFocused: boolean;
}

export interface ToolbarChildProps {
  hasParentToolbar: boolean;
}

const styles = (theme: Theme) => {
  const containerSpaceStyle = {};
  for (const breakPoint of theme.spacingBreakPoints) {
    const sectionSpacing = theme.freeflow.section.spacing[breakPoint];

    containerSpaceStyle[theme.breakpoints.up(breakPoint)] = {
      marginBottom: -sectionSpacing.vertical * 0.5,
      marginLeft: -sectionSpacing.horizontal * 0.5,
      marginRight: -sectionSpacing.horizontal * 0.5,
      marginTop: -sectionSpacing.vertical * 0.5,
    };
  }

  const visibilityStyles = {};
  for (const breakPoint of theme.visibilityBreakPoints) {
    visibilityStyles[`toolbar-${breakPoint}`] = {
      [theme.breakpoints.only(breakPoint)]: {
        display: "flex",
      },
    };
  }

  const result = {
    container: {
      ...containerSpaceStyle,
      alignItems: "center",
      display: "flex",
      flex: "1 1 auto",
      minWidth: 0,
    } as CSSProperties,
    focused: {
      borderBottomColor: `${theme.palette.grey[800]} !important`,
      borderBottomWidth: "2px !important",
      borderTopColor: `${theme.palette.grey[800]} !important`,
      borderTopWidth: "2px !important",
    } as CSSProperties,
    footer: {
      alignItems: "center",
      borderBottomColor: theme.palette.grey[300],
      borderBottomStyle: "solid",
      borderTopColor: "transparent",
      height: 70,
      paddingLeft: 24,
      paddingRight: 24,
    } as CSSProperties,
    header: {
      alignItems: "center",
      borderBottomColor: "transparent",
      borderTopColor: theme.palette.grey[300],
      borderTopStyle: "solid",
      height: 70,
      paddingLeft: 24,
      paddingRight: 24,
    } as CSSProperties,
    headerFooterVerticalLayout: {
      borderBottomColor: theme.palette.grey[300],
      borderTopColor: theme.palette.grey[300],
      paddingBottom: 16,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 16,
    },
    pageHeaderLayout: {
      borderBottomColor: "transparent",
      borderTopColor: "transparent",
      paddingLeft: 0,
      paddingRight: 0,
    },
    root: {
      borderBottomStyle: "solid",
      borderBottomWidth: 1,
      borderTopStyle: "solid",
      borderTopWidth: 1,
      display: "none",
      flexWrap: "nowrap",
      justifyContent: "space-between",
      outline: "none",
    } as CSSProperties,
    selection: {
      alignItems: "center",
      backgroundColor: theme.palette.grey[200],
      borderBottomColor: "transparent",
      borderTopColor: theme.palette.grey[300],
      height: 70,
      paddingLeft: 24,
      paddingRight: 24,
    },
    selectionVerticalLayout: {
      borderBottomColor: "transparent",
      borderTopColor: "transparent",
      paddingLeft: 0,
      paddingRight: 0,
    },
    ...visibilityStyles,
  };

  return createStyles(result);
};

export class Toolbar extends React.Component<
  Props & WithStyles<typeof styles> & WithTheme,
  State
> {
  public static childSelector: string = `a,button,input[type=file],.${inputWrapperClassName}`;

  public constructor(props: Props & WithStyles<typeof styles> & WithTheme) {
    super(props);
    this.state = { isFocused: false };
  }

  private onFocusChanged = (isFocused: boolean): void => {
    this.setState({ isFocused });
  };

  private shouldRender(): boolean {
    if (this.props.childLayoutWidgetNames.length > 0) {
      return true;
    }

    const row = PaneRow.get(this.props.dataId)!;
    for (const childWidgetName of this.props.childWidgetNames) {
      const childWidget = row.getWidgetT<null, ChildWidgetProperties>(
        childWidgetName
      );

      if (childWidget.properties.accessLevel === undefined) {
        return true;
      }

      if (childWidget.properties.accessLevel >= AccessLevel.disabled) {
        return true;
      }
    }

    return false;
  }

  public render(): React.ReactNode {
    if (!this.shouldRender()) {
      return null;
    }

    const classes: string[] = [this.props.classes.root];
    let isVerticalLayout: boolean = false;

    if (this.props.propagated.parentTable) {
      if (this.props.propagated.parentRowKey) {
        classes.push(this.props.classes.selectionVerticalLayout);
      } else if (this.props.propagated.parentTable.isVerticalLayout) {
        isVerticalLayout = true;
        classes.push(this.props.classes.headerFooterVerticalLayout);
      } else {
        classes.push(this.props.classes[this.props.toolbarType]);
      }
    } else {
      classes.push(this.props.classes.pageHeaderLayout);
    }

    const theme = this.props.theme as Theme;

    for (const breakPoint of theme.visibilityBreakPoints) {
      if (this.props[breakPoint]) {
        classes.push(this.props.classes[`toolbar-${breakPoint}`]);
      }
    }

    if (
      this.state.isFocused &&
      this.props.propagated.parentTable &&
      (this.props.toolbarType === "footer" ||
        this.props.toolbarType === "header")
    ) {
      classes.push(this.props.classes.focused);
    }

    const propagated: ToolbarChildProps & TableChildProps = {
      ...this.props.propagated,
      hasParentToolbar: true,
    };

    // When table is in vertical layout the footer toolbar will not render
    // the start child toolbar container. This will allow end child toolbar
    // container to be rendered full width which can contain the grid pager.
    const isVerticalFooter =
      isVerticalLayout && this.props.toolbarType === "footer";

    let ariaLabel: string | undefined = undefined;
    if (
      this.props.propagated.parentTable !== undefined &&
      !!this.props.propagated.parentTable.description
    ) {
      let builtInMessageCode;
      switch (this.props.toolbarType) {
        case "footer":
          builtInMessageCode = "DataTable.footerToolbarLabel";
          break;

        case "selection":
          builtInMessageCode = "DataTable.selectionToolbarLabel";
          break;

        default:
          builtInMessageCode = "DataTable.toolbarLabel";
      }
      ariaLabel = Localization.getBuiltInMessage(builtInMessageCode, {
        description: this.props.propagated.parentTable.description,
      });
    }

    return (
      <FocusTracker onFocusChanged={this.onFocusChanged}>
        <KeyboardNavigationGroup
          aria-label={ariaLabel}
          childSelector={Toolbar.childSelector}
          className={classes.join(" ")}
          role="toolbar"
        >
          {this.props.startChild && !isVerticalFooter ? (
            <div className={this.props.classes.container}>
              {Presentation.create(this.props.startChild, propagated)}
            </div>
          ) : null}
          {this.props.endChild || this.props.overflowChild ? (
            <div className={this.props.classes.container}>
              {this.props.endChild
                ? Presentation.create(this.props.endChild, propagated)
                : null}
              {this.props.overflowChild
                ? Presentation.create(this.props.overflowChild, propagated)
                : null}
            </div>
          ) : null}
        </KeyboardNavigationGroup>
      </FocusTracker>
    );
  }
}

export default withStyles(styles)(withTheme(observer(Toolbar)));

import { Theme } from "@mui/material";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import { observer } from "mobx-react";
import * as React from "react";
import AppServer from "../../core/AppServer";
import FocusManager from "../../core/FocusManager";
import Localization from "../../core/Localization";
import TrackableCollection from "../../core/TrackableCollection";
import TrackableModel from "../../core/TrackableModel";
import Button from "../../coreui/Button";
import Dialog, { BreakPointColumn } from "../../coreui/Dialog";
import DialogActions from "../../coreui/DialogActions";
import DialogContent from "../../coreui/DialogContent";
import PaneRow from "../../models/PaneRow";
import ErrorsStore from "../../stores/ErrorsStore";
import RequestsStore from "../../stores/RequestsStore";
import { AccessLevel } from "../AccessLevel";

interface ConfigProperties {
  children?: React.ReactNode;
  contentDataId: string;
  dataId: string;
  isFirstOpenOfNewRow?: boolean;
  labelledById: string;
  name: string;
  onAccept: (parentRowKey: string) => Promise<void>;
  onClose: (accepted: boolean) => void;
  onDeleteRow: () => void;
  onExited?: () => void;
  onOpen: (parentRowKey: string) => Promise<BreakPointColumn[]>;
  parentRowKey?: string;
}

interface State {
  breakPointColumns?: BreakPointColumn[];
  isDialogOpen?: boolean;
  isProcessing?: boolean;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
}
const styles = (theme: Theme) =>
  createStyles({
    dialogContent: {
      "& > div": {
        [theme.breakpoints.only("xs")]: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
  });

export class TableEditDialog extends React.Component<
  ConfigProperties & WithStyles<typeof styles>,
  State
> {
  // FUTURE
  // This component implements its own focus trap. Ideally, it would use the
  // focus trap that is built in by the MUI dialog, but two issues prevent this.
  //  1. Focus gets reset to the first focusable element in the dialog when the
  //     focus is leaving an empty grid control at the XS break-point. This can
  //     be addressed by replacing the vertical layout of the grid control with
  //     conventionally rendered cards, rather than involving ag-grid.
  //  2. There is an issue in Material-UI where the built-in focus trap causes
  //     focus to move to the first focusable element in the dialog when the
  //     keyboard is dismissed.
  //     https://github.com/mui/material-ui/issues/41608
  // The custom focus trap has been grouped together to make its eventual
  // removal easier.
  private static instances: TableEditDialog[] = [];
  private content: HTMLElement | null = null;
  private sentinelEnd: HTMLElement | null = null;
  private sentinelStart: HTMLElement | null = null;

  private isTopDialog = () => {
    return (
      TableEditDialog.instances[TableEditDialog.instances.length - 1] === this
    );
  };

  private loopFocus = (event: KeyboardEvent) => {
    // 9 = Tab
    const srcElement = event.srcElement as Node;
    if (
      event.keyCode === 9 &&
      this.isTopDialog() &&
      this.content &&
      (srcElement === this.sentinelEnd ||
        srcElement === this.sentinelStart ||
        this.content.contains(srcElement)) &&
      !this.content.contains(document.activeElement)
    ) {
      if (event.shiftKey) {
        this.sentinelEnd?.focus();
      } else {
        this.sentinelStart?.focus();
      }
    }
  };

  private onFocusTrapEntered = (node: HTMLElement): void => {
    this.content = node;
    this.sentinelStart = this.content.previousElementSibling as HTMLElement;
    this.sentinelEnd = this.content.nextElementSibling as HTMLElement;
    document.addEventListener("keydown", this.loopFocus, true);
  };

  private onFocusTrapEntering = (node: HTMLElement, isAppearing: boolean) => {
    TableEditDialog.instances.push(this);
  };

  private onFocusTrapExited = () => {
    TableEditDialog.instances.pop();
    document.removeEventListener("keydown", this.loopFocus, true);
  };

  private onFocusTrapUnmount = () => {
    if (this.isTopDialog()) {
      TableEditDialog.instances.pop();
    }

    document.removeEventListener("keydown", this.loopFocus, true);
  };
  // End Focus Trap

  private isDialogClosing: boolean = false;

  public constructor(props: ConfigProperties & WithStyles<typeof styles>) {
    super(props);

    this.state = {
      isDialogOpen: false,
      isProcessing: false,
    };
  }

  private onAccept = () => {
    if (this.isDialogClosing) {
      return;
    }
    this.isDialogClosing = true;

    this.setState({ isProcessing: true });

    this.props
      .onAccept(this.props.parentRowKey!)
      .then(() => this.closeDialog(true))
      .catch((reason) => {
        this.isDialogClosing = false;
        if (reason) {
          throw reason;
        }
      })
      .finally(() => this.setState({ isProcessing: false }));
  };

  private onCancel = () => {
    if (this.isDialogClosing) {
      return;
    }
    this.isDialogClosing = true;

    if (this.props.isFirstOpenOfNewRow) {
      const collection = TrackableModel.models.get(
        this.props.contentDataId
      ) as TrackableCollection;

      const model: TrackableModel = collection.find(
        (m) => m.getPrimaryKey() === this.props.parentRowKey
      )!;

      collection.delete(model);

      // Remove from the deleted collection as we're restoring the
      // app server state to before the new row was added
      collection.getDeleted().delete(model);
      this.props.onDeleteRow();
    }

    ErrorsStore.clearBusinessErrorsForTableRow(
      this.props.contentDataId,
      this.props.parentRowKey!
    );

    AppServer.recoverStateFromPoint();
    this.closeDialog(false);
  };

  private onClose = (
    event: object,
    reason: "escapeKeyDown" | "backdropClick"
  ) => {
    if (reason === "backdropClick") {
      return;
    }

    if (event["forced"]) {
      this.setState({ isDialogOpen: false });
    } else {
      this.onCancel();
    }
  };

  private onEntered = (node: HTMLElement, isAppearing: boolean) => {
    this.onFocusTrapEntered(node);
    FocusManager.grabFocusForChild(node, FocusManager.selectors.focusable);
  };

  private onExited = () => {
    this.onFocusTrapExited();
    if (this.props.onExited) {
      this.props.onExited();
    }
  };

  private openDialog = () => {
    RequestsStore.instance.processingStarted();

    this.props
      .onOpen(this.props.parentRowKey!)
      .then((breakPointColumns: BreakPointColumn[]) => {
        this.setState({
          breakPointColumns,
          isDialogOpen: true,
        });

        this.isDialogClosing = false;
      })
      .catch((reason) => {
        if (reason) {
          throw reason;
        }
        this.props.onClose(false);
      })
      .finally(() => RequestsStore.instance.processingStopped());
  };

  public closeDialog = (accepted: boolean) => {
    this.setState({ isDialogOpen: false });
    this.props.onClose(accepted);
  };

  public componentDidUpdate(prevProps: ConfigProperties) {
    if (
      this.props.parentRowKey !== prevProps.parentRowKey &&
      this.props.parentRowKey &&
      !this.state.isDialogOpen
    ) {
      this.openDialog();
    }
  }

  public componentWillUnmount() {
    if (this.state.isDialogOpen) {
      AppServer.clearStateRecoveryPoint();
    }
    this.onFocusTrapUnmount();
  }

  public render() {
    const parentTableRow = PaneRow.get(this.props.dataId);
    if (!parentTableRow) {
      return null;
    }

    const parentTable = parentTableRow.getWidgetT<null, RuntimeProperties>(
      this.props.name
    );

    const isEnterable =
      parentTable.properties.accessLevel >= AccessLevel.enterable;

    const isDialogOpen = this.state.isDialogOpen! && !!this.props.parentRowKey;

    return (
      <Dialog
        aria-labelledby={this.props.labelledById}
        breakPointColumns={this.state.breakPointColumns}
        onClose={this.onClose}
        disableEnforceFocus={true} // FUTURE - can be removed with the focus trap.
        open={isDialogOpen}
        TransitionProps={{
          onEntered: this.onEntered,
          onEntering: this.onFocusTrapEntering,
          onExited: this.onExited,
        }}
      >
        <DialogContent classes={{ root: this.props.classes.dialogContent }}>
          {this.props.children}
        </DialogContent>
        <DialogActions>
          {isEnterable ? (
            <Button onClick={this.onAccept}>
              {Localization.getBuiltInMessage("ok")}
            </Button>
          ) : null}
          <Button onClick={this.onCancel} style={{ marginLeft: 40 }}>
            {isEnterable
              ? Localization.getBuiltInMessage("cancel")
              : Localization.getBuiltInMessage("close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(observer(TableEditDialog));

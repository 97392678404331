import Presentation from "../coreui/Presentation";

export interface PaneUseConfig {
  component: object & { type: string };
  paneUseKey: string;
}

interface Props {
  config: PaneUseConfig;
  propagated: {};
}

export function PaneUse(props: Props): JSX.Element | null {
  return Presentation.create(props.config.component, props.propagated);
}

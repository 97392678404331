import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import Localization from "../core/Localization";
import DatePicker from "../coreui/DatePicker";
import { TableVerticalLayoutProps } from "../coreui/Table";
import TextField from "../coreui/TextField";
import PaneRow from "../models/PaneRow";
import ErrorsStore from "../stores/ErrorsStore";
import { AccessLevel } from "./AccessLevel";

interface ConfigProperties {
  dataId: string;
  dateFormatError: string;
  disabledHelpText: string;
  helperText: string;
  label: string;
  name: string;
  propagated?: TableVerticalLayoutProps;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
  showAsMandatory: boolean;
  showDisabledHelp: boolean;
}

export class DateEdit extends React.Component<ConfigProperties> {
  private dataId: string = "";
  private name: string = "";
  private rowKey?: string;

  private static deserializeDate(serializedDate: string | null): Date | null {
    if (serializedDate === null) {
      return null;
    }

    const date = moment(serializedDate, "YYYY-MM-DD", true);
    return date.toDate();
  }

  private static serializeDate(date: Date | null): string | null {
    if (date === null) {
      return null;
    }

    return moment(date).format("YYYY-MM-DD");
  }

  public static formatValue(
    value: Date | null | string,
    userFormatted: boolean
  ) {
    if (value && typeof value !== "string") {
      return moment(value).format(DateEdit.getDateFormat(userFormatted));
    }

    return value;
  }

  public static getDateFormat(userFormatted: boolean) {
    let format = "";

    if (userFormatted) {
      format = Localization.dateFormat;
    } else {
      format = "yyyy-mm-dd";
    }

    // Set case of date formatter to match moment format
    // https://momentjs.com/docs/#/parsing/string-format/
    format = format.replace(/d/gi, "D");
    format = format.replace(/m/gi, "M");
    format = format.replace(/y/gi, "Y");

    return format;
  }

  public constructor(props: ConfigProperties) {
    super(props);

    makeObservable<DateEdit, "dataId" | "name" | "syncDerivedWithProps">(this, {
      dataId: observable,
      name: observable,
      syncDerivedWithProps: action,
    });

    this.syncDerivedWithProps();
  }

  private getErrors = (value: string): string[] => {
    const row = PaneRow.get(this.dataId, this.rowKey);
    if (!row) {
      return [];
    }

    const widget = row.getWidgetT<string, RuntimeProperties>(this.name);
    return [...widget.properties.businessErrors];
  };

  private onChange = (value: Date | null): void => {
    ErrorsStore.clearBusinessErrorsForWidget(this.dataId, this.name);

    const row = PaneRow.get(this.dataId, this.rowKey)!;
    const widget = row.getWidgetT<string | null, RuntimeProperties>(this.name);
    widget.setValue(DateEdit.serializeDate(value));
  };

  private onInputChange = (): void => {
    ErrorsStore.clearBusinessErrorsForWidget(this.dataId, this.name);
  };

  private syncDerivedWithProps(): void {
    this.dataId = this.props.dataId;
    this.name = this.props.name;
    this.rowKey = this.props.propagated?.rowKey;
  }

  public componentDidUpdate(): void {
    this.syncDerivedWithProps();
  }

  public render(): React.ReactNode {
    const row = PaneRow.get(this.dataId, this.rowKey);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<string | null, RuntimeProperties>(this.name);

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    if (widget.properties.accessLevel === AccessLevel.disabled) {
      return (
        <TextField
          disabled={true}
          disabledHelpText={
            widget.properties.showDisabledHelp
              ? this.props.disabledHelpText
              : undefined
          }
          label={this.props.label}
          variant="filled"
        />
      );
    }

    const date = DateEdit.deserializeDate(widget.value);

    if (widget.properties.accessLevel === AccessLevel.readOnly) {
      return (
        <TextField
          label={this.props.label}
          name={this.props.name}
          readOnly={true}
          value={date !== null ? Localization.formatDate(date) : "-"}
          variant="filled"
        />
      );
    }

    return (
      <DatePicker
        dateFormatError={this.props.dateFormatError}
        helperText={this.props.helperText}
        label={this.props.label}
        getErrors={this.getErrors}
        onChange={this.onChange}
        onInputChange={this.onInputChange}
        required={widget.properties.showAsMandatory}
        value={date}
      />
    );
  }
}

export default observer(DateEdit);

import { autorun, IReactionDisposer } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import ComboBoxOption from "../coreui/ComboBoxOption";
import Table, { TableChildProps, TableProps } from "../coreui/Table";
import PaneRow from "../models/PaneRow";
import PaneDataStore from "../stores/PaneDataStore";
import GridColumn, { GridColumnConfigProperties } from "./Columns/GridColumn";
import ErrorBoundary from "./ErrorBoundary";
import { SelectChildProps } from "./SelectControl";

interface ConfigProperties {
  columns: GridColumnConfigProperties[];
  contentDataId: string;
  data?: object;
  footerToolbar: object;
  headerToolbar: object;
  propagated: SelectChildProps;
  tableKey: string;
}

interface State {
  isMounted: boolean;
}

export class SelectDialogResultsGrid extends React.Component<
  ConfigProperties,
  State
> {
  private dataMonitorDisposer: IReactionDisposer;
  private readonly contentDataId: string;
  private readonly isSelectDialogClosing: boolean;
  private populate: ((rows: PaneRow[]) => void) | null = null;
  private propagated: TableChildProps;
  private tableProps: TableProps;

  public constructor(props: ConfigProperties) {
    super(props);

    this.state = { isMounted: false };

    const parentSelect = props.propagated.parentSelect;

    this.contentDataId = props.contentDataId;
    this.isSelectDialogClosing = parentSelect.isDialogClosing;

    this.propagated = {
      parentTable: {
        cardDepth: 0,
        columns: props.columns,
        configProps: {
          contentDataId: props.contentDataId,
          data: props.data,
          dataId: parentSelect.configProps.dataId,
          name: parentSelect.configProps.name,
        },
        getRelationshipComboBoxOptions: this.getRelationshipComboBoxOptions,
        isDocumentGrid: false,
        populateData: () => this.populateData(),
      },
    } as TableChildProps;

    this.tableProps = {
      "aria-label": this.props.propagated.dialogResultsDescription,
      cardDepth: 0,
      cellEdit: true,
      columns: [],
      contentDataId: props.contentDataId,
      dataId: parentSelect.configProps.dataId,
      disableSelectAll: true,
      footerToolbarChild: props.footerToolbar,
      getSelectedRowKeys: parentSelect.getSelectedRowObjectHandles,
      headerToolbarChild: props.headerToolbar,
      ignoreBusinessErrors: true,
      initialPageSize: {
        lg: 5,
        md: 5,
        sm: 5,
        xl: 5,
        xs: 5,
      },
      isColumnFlex: (colId: string) =>
        GridColumn.isColumnFlex(props.columns, colId),
      isColumnVisible: (colId: string, breakpoint: string) =>
        GridColumn.isColumnVisible(props.columns, colId, breakpoint),
      minRowHeight: GridColumn.getColumnsMinRowHeight(props.columns),
      name: parentSelect.configProps.name,
      onRowSelected: parentSelect.onRowSelected,
      onRowUnselected: parentSelect.onRowUnselected,
      propagated: this.propagated,
      resetPageOnPopulate: true,
      rowSelection: parentSelect.configProps.isSingleSelect
        ? "single"
        : "multiple",
      setPopulate: (populate) => (this.populate = populate),
      showNoData: true,
    };
  }

  private getRelationshipComboBoxOptions = (
    widgetName: string,
    selectedOption: ComboBoxOption
  ): ComboBoxOption[] => {
    // Relationship combo boxes are always read-only on select controls,
    // since the only details that can be maintained are relationship
    // details, which are never mainted with a relationship combo box.
    return [selectedOption];
  };

  private populateData = () => {
    const rows: PaneRow[] = PaneDataStore.getPaneCollection(this.contentDataId);

    if (this.populate !== null) {
      this.populate(rows);

      // FUTURE
      // This function is called after a search is executed, and also
      // when the select button is clicked. After the select button is clicked,
      // the dialog closes, so it is wrong to then announce the number of rows
      // resulting from the search in this case.
      // The call that happens after a the select button is clicked is neither
      // rational nor efficient and should be fixed.
      if (!this.isSelectDialogClosing) {
        const message = Localization.getBuiltInMessage(
          rows.length === 0
            ? "DataTable.tableRowCountZero"
            : rows.length === 1
            ? "DataTable.tableRowCountSingle"
            : "DataTable.tableRowCountMultiple",
          { count: rows.length }
        );
        setTimeout(() => {
          Sys.announce(message);
        }, 1000);
      }
    }
  };

  public componentDidMount(): void {
    for (const column of this.props.columns) {
      this.tableProps.columns.push(
        GridColumn.getColumnDef(column, this.props.columns, this.propagated)
      );
    }

    setTimeout(() => {
      this.dataMonitorDisposer = autorun(() => this.populateData());
    });

    this.setState({ isMounted: true });
  }

  public componentWillUnmount(): void {
    if (this.dataMonitorDisposer) {
      this.dataMonitorDisposer();
    }

    this.setState({ isMounted: false });
  }

  public render(): React.ReactNode {
    return (
      <ErrorBoundary>
        {
          // FUTURE
          // An isMounted flag is used to ensure the Table constructor only
          // fires when there are actually columns for the table. The root
          // cause is that the Table component is doing rendering logic in
          // the constructor, which is counter to the architecture of React.
          // At "some point", likely when moving to the MUI Grid component,
          // this should be reworked.
          this.state.isMounted && (
            <Table
              {...this.tableProps}
              disableScrollOnPageChange={true}
              isInDialog={true}
              keepHeaderOnSelect={true}
              tableKey={this.props.tableKey}
            />
          )
        }
      </ErrorBoundary>
    );
  }
}

export default observer(SelectDialogResultsGrid);

import { action, makeObservable, observable, toJS } from "mobx";
import TrackableCollection from "../core/TrackableCollection";
import TrackableModel from "../core/TrackableModel";
import { AccessLevel } from "../mustangui/AccessLevel";
import { AddressSearchCriteriaValue } from "../mustangui/AddressSearchCriteria";
import { DateRangeCriteriaValue } from "../mustangui/DateRangeCriteria";
import { PaneDataRow } from "../stores/PaneDataStore";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RuntimeProperties = { [id: string]: any };

type Widgets = { [id: string]: RuntimeWidget };

export type WidgetValue =
  | boolean
  | Date
  | number
  | string
  | string[]
  | null
  | AddressSearchCriteriaValue
  | DateRangeCriteriaValue;

export interface RuntimeWidget {
  properties: RuntimeProperties;
  setValue: (value: WidgetValue) => void;
  value: WidgetValue;
  widgetTypeId: number;
}

export interface RuntimeWidgetT<
  ValueType extends WidgetValue = WidgetValue,
  PropertiesType extends RuntimeProperties = RuntimeProperties
> {
  properties: PropertiesType;
  setValue: (value: ValueType) => void;
  value: ValueType;
  widgetTypeId: number;
}

export interface WidgetData {
  [id: string]: RuntimeWidget;
}

interface CriteriaRuntimeProperties {
  accessLevel: AccessLevel;
}

export default class PaneRow extends TrackableModel {
  private rowWidgets: Widgets = {};

  public criteriaWidgetNames: string[];
  public currentJobLevel: number | null = null;
  public hierarchyLevel: number | null = null;
  public isCurrentJob: boolean | null = null;
  public isNew: boolean = false;
  public isVisible: boolean = false;
  public objectHandle: string;
  public rowKey: string;

  public static get(
    dataId: string,
    rowKey: string | null = null
  ): PaneRow | null {
    if (!TrackableModel.models.has(dataId)) {
      return null;
    }

    const collection = TrackableModel.models.get(dataId) as TrackableCollection;

    const observableCollection = collection.observableCollection
      ? collection.observableCollection
      : collection;

    if (observableCollection.length === 0) {
      return null;
    }

    if (!rowKey) {
      if (observableCollection.length > 1) {
        throw new Error(
          "A rowKey is required if the TrackableCollection has more " +
            "than one item"
        );
      }

      return observableCollection[0] as PaneRow;
    }

    const model = observableCollection.find((m) => m["rowKey"] === rowKey);

    if (!model) {
      return null;
    }

    return model as PaneRow;
  }

  constructor(dataId: string) {
    super(dataId);

    makeObservable<
      PaneRow,
      | "revertWidgetValue"
      | "rowWidgets"
      | "setWidgetProperty"
      | "setWidgetValue"
    >(this, {
      isVisible: observable,
      revertWidgetValue: action,
      rowWidgets: observable,
      setWidgetProperty: action,
      setWidgetValue: action,
    });
  }

  public get widgets(): Widgets {
    return this.rowWidgets;
  }

  public set widgets(value: Widgets) {
    for (const widgetName of Object.keys(value)) {
      const newWidget = value[widgetName] as RuntimeWidget;
      newWidget.setValue = (v) => this.setWidgetValue(widgetName, v);
      if (widgetName in this.rowWidgets) {
        const widget = this.rowWidgets[widgetName] as RuntimeWidget;
        for (const prop of Object.keys(newWidget.properties)) {
          // This check is used to detect if the property is an array
          // observable. It is safe right now because there are no runtime
          // properties on the widgets that are objects.
          //
          // Adding support for objects, though possible, would essentially
          // amount to implementing a recursive traversal, which greatly
          // increases the complexity of this method.
          const oldValue = widget.properties[prop];
          const newValue = newWidget.properties[prop];
          if (newValue instanceof Object) {
            if (typeof oldValue.replace !== "function") {
              throw new Error(
                "Observable arrays are the only" +
                  " objects supported in runtime data. " +
                  `Widget: "${widgetName}" ` +
                  `Property: "${prop}"`
              );
            }

            if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
              // Clone object / array properties
              oldValue.replace(toJS(newValue));
            }
          } else {
            this.setWidgetProperty(widgetName, prop, newValue);
          }
        }
        widget.value = value[widgetName].value;
      } else {
        this.rowWidgets[widgetName] = observable(newWidget);
      }
    }
  }

  private setWidgetProperty(
    widgetName: string,
    propName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  ): void {
    this.rowWidgets[widgetName].properties[propName] = value;
  }

  protected getPropertyNames(): string[] {
    return Object.keys(this.widgets);
  }

  protected loadData(data: TrackableModel) {
    const row = data as PaneRow;
    this.criteriaWidgetNames = [];
    this.currentJobLevel = row.currentJobLevel;
    this.hierarchyLevel = row.hierarchyLevel;
    this.isCurrentJob = row.isCurrentJob;
    this.isNew = row.isNew;
    this.isVisible = true;
    this.objectHandle = row.objectHandle;
    this.rowKey = row.rowKey;
    this.widgets = row.widgets;
  }

  protected setPropertyValue(propName: string, value: WidgetValue): void {
    this.widgets[propName].value = value;
  }

  public hasWidget(widgetName: string): boolean {
    return widgetName in this.widgets;
  }

  public loadCriteriaData(
    data: PaneDataRow,
    criteriaWidgetNames: string[]
  ): void {
    this.clear(false);
    this.isLoaded = true;
    this.isModified = criteriaWidgetNames.length > 0;
    this.criteriaWidgetNames = criteriaWidgetNames;
    this.trackUndo = false;

    this.currentJobLevel = null;
    this.hierarchyLevel = null;
    this.isCurrentJob = null;
    this.isNew = false;
    this.isVisible = true;
    this.objectHandle = data.objectHandle;
    this.rowKey = data.rowKey;
    this.widgets = data.widgets;

    for (const propertyName of this.getPropertyNames()) {
      this.initialValues[propertyName] = this.getPropertyValue(propertyName);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getModifiedPropertyValues(): { [id: string]: any } {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const props: { [id: string]: any } = {};
    for (const propertyName of this.getPropertyNames()) {
      if (!this.hasChanges(propertyName)) {
        if (this.criteriaWidgetNames.includes(propertyName)) {
          const criteriaWidget = this.getWidgetT<
            WidgetValue,
            CriteriaRuntimeProperties
          >(propertyName);

          if (criteriaWidget.properties.accessLevel < AccessLevel.enterable) {
            continue;
          }
        } else {
          continue;
        }
      }

      props[propertyName] = this.getPropertyValue(propertyName);
    }

    return props;
  }

  public getPrimaryKey(): string {
    return this.rowKey;
  }

  public getPropertyValue(propName: string): WidgetValue {
    return this.widgets[propName].value;
  }

  public getWidgetT<
    ValueType extends WidgetValue,
    PropertiesType extends RuntimeProperties
  >(widgetName: string): RuntimeWidgetT<ValueType, PropertiesType> {
    return this.widgets[widgetName] as RuntimeWidgetT<
      ValueType,
      PropertiesType
    >;
  }

  public getWidgetValue(widgetName: string): WidgetValue {
    return this.widgets[widgetName].value;
  }

  public revertWidgetValue(widgetName: string): void {
    this.revertValue(widgetName);
  }

  public setWidgetValue(widgetName: string, value: WidgetValue): void {
    this.setProperty(widgetName, value);
  }

  public updateWidget(widgetName: string, widgetData: RuntimeWidget): void {
    widgetData.setValue = (v) => this.setWidgetValue(widgetName, v);
    this.widgets[widgetName] = widgetData;
  }
}

TrackableModel.setPrimaryKeyName("PaneRow", "rowKey");

import {
  Breakpoint,
  styled,
  Typography,
  TypographyVariant,
} from "@mui/material";
import { observer } from "mobx-react";
import * as React from "react";
import { useWidth } from "../core/Responsive";
import { TableVerticalLayoutProps } from "../coreui/Table";
import PaneRow from "../models/PaneRow";
import { AccessLevel } from "./AccessLevel";

interface ConfigProperties {
  dataId: string;
  justification:
    | { [key in Breakpoint]: "Centered" | "Full" | "Left" | "Right" }
    | null;
  listStyle: boolean;
  name: string;
  propagated?: TableVerticalLayoutProps;
  textStyle: "Body 1" | "Body 2" | "Caption";
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  bodyText: string[];
}

const List = styled("ul")({
  marginBottom: 0,
  marginTop: 0,
  paddingLeft: 40,
});

// The cast to `typeof Typography` is required for the component prop to be
// recognized. See https://github.com/mui/material-ui/issues/15695
const Text = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    maxWidth: "600px",
  },
  [theme.breakpoints.up("lg")]: {
    maxWidth: "580px",
  },
})) as typeof Typography;

export const TextDisplay = observer(
  (props: ConfigProperties): JSX.Element | null => {
    const width = useWidth();

    const rowKey = props.propagated?.rowKey;
    const row = PaneRow.get(props.dataId, rowKey);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<null, RuntimeProperties>(props.name);

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    const children: React.ReactNode[] = [];
    const justification =
      props.justification === null ? "Left" : props.justification[width];

    widget.properties.bodyText.forEach((item: string, index: number) => {
      children.push(
        <Text
          align={
            justification === "Centered"
              ? "center"
              : justification === "Full"
              ? "justify"
              : justification === "Right"
              ? "right"
              : "left"
          }
          component={props.listStyle ? "li" : undefined!}
          key={index}
          style={{
            display: props.listStyle ? "list-item" : undefined,
            marginTop: index > 0 ? 24 : undefined,
          }}
          variant={
            {
              "Body 1": "body1",
              "Body 2": "body2",
              Caption: "caption",
            }[props.textStyle] as TypographyVariant
          }
        >
          {item}
        </Text>
      );
    });

    if (props.listStyle) {
      return <List>{children}</List>;
    }

    return <div>{children}</div>;
  }
);

import { Input, InputAdornment } from "@mui/material";
import { ICellEditorParams } from "ag-grid-community";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../../core/Localization";
import Button from "../../coreui/Button";
import { ErrorBadge } from "../../coreui/ErrorBadge";
import MultilineTextFieldDialog from "../../coreui/MultilineTextFieldDialog";
import { TableChildProps } from "../../coreui/Table";
import { CellUtil } from "../../coreui/table/CellUtil";
import PaneRow from "../../models/PaneRow";
import ErrorsStore from "../../stores/ErrorsStore";
import { GridColumnConfigProperties } from "./GridColumn";

interface ConfigProperties extends ICellEditorParams<PaneRow, string | null> {
  dataId: string;
  dataSize: number;
  maxSizeError: string;
  name: string;
  propagated: TableChildProps;
}

interface State {
  isDialogOpen?: boolean;
  value?: string | null;
}

interface RuntimeProperties {
  businessErrors: string[];
}

export class MLTextEditColumnEdit extends React.Component<
  ConfigProperties,
  State
> {
  public static readonly widgetType: string = "MLTextEditColumn";
  private inputElement: HTMLInputElement;

  public static getErrors(
    props: ConfigProperties,
    row: PaneRow,
    value: string | null
  ): string[] {
    const widget = row.getWidgetT<string | null, RuntimeProperties>(props.name);
    // Ensure errors are always accessed so the component knows to observe them
    const widgetErrors = [...widget.properties.businessErrors];
    const errors = row.hasChanges(props.name) ? [] : widgetErrors;

    if (props.dataSize && value && value.length > props.dataSize) {
      errors.push(props.maxSizeError);
    }

    return errors;
  }

  public constructor(props: ConfigProperties) {
    super(props);

    this.state = {
      isDialogOpen: false,
      value: props.value,
    };

    props.eGridCell.addEventListener("keydown", this.onCellKeyDown);
  }

  private get column(): GridColumnConfigProperties | undefined {
    const parentTable = this.props.propagated.parentTable;
    const tableColumns = parentTable.columns as GridColumnConfigProperties[];
    return tableColumns.find((c) => c.name === this.props.colDef.colId);
  }

  private onCellKeyDown = (event: KeyboardEvent): void => {
    // If the button has focus do not stop editing.
    if (
      event.key === "Enter" &&
      document.activeElement &&
      document.activeElement.tagName === "BUTTON"
    ) {
      event.preventDefault();
    }
  };

  private onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setValue(event.target.value);
  };

  private onDialogAccept = (value: string): void => {
    this.setValue(value);

    this.setState({ isDialogOpen: false });
  };

  private onDialogCancel = (): void => {
    this.setState({ isDialogOpen: false });
  };

  private onDialogExited = (): void => {
    const parentTable = this.props.propagated.parentTable;
    parentTable.setStopEditingWhenGridLosesFocus(true);
  };

  private onFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
    const textarea = event.target;
    textarea.setSelectionRange(textarea.value.length, textarea.value.length);
    textarea.scrollTop = textarea.scrollHeight;
  };

  private openDialog = (): void => {
    const parentTable = this.props.propagated.parentTable;
    parentTable.setStopEditingWhenGridLosesFocus(false);

    this.setState({ isDialogOpen: true });
  };

  private setValue(value: string): void {
    ErrorsStore.clearBusinessErrorsForTableCell(
      this.props.dataId,
      this.props.name,
      this.props.data.rowKey
    );
    this.setState({ value });
  }

  public componentDidMount(): void {
    CellUtil.disableGridNavigation(this.props.eGridCell, this.inputElement);

    CellUtil.setInitialFocus(this.inputElement);
  }

  public componentWillUnmount(): void {
    CellUtil.enableGridNavigation(this.props.eGridCell);
    this.props.eGridCell.removeEventListener("keydown", this.onCellKeyDown);
  }

  public getValue(): string | null {
    return this.state.value === "" ? null : this.state.value!;
  }

  public render(): React.ReactNode {
    const value = this.state.value || "";

    const errors: string[] = MLTextEditColumnEdit.getErrors(
      this.props,
      this.props.data!,
      value
    );

    const ariaLabel = Localization.getBuiltInMessage("StringEdit.editContent", {
      label: this.props.colDef.headerName,
    });

    return (
      <ErrorBadge
        errors={errors}
        isShort={
          this.props.data!.isNew &&
          this.props.propagated.parentTable.isDocumentGrid
        }
      >
        <Input
          autoFocus={true}
          endAdornment={
            <InputAdornment
              position="end"
              style={{ marginRight: 24, marginTop: -4 }}
            >
              <Button
                aria-label={ariaLabel}
                icon="fas fa-edit"
                onClick={this.openDialog}
                onKeyDown={(event: React.KeyboardEvent<HTMLButtonElement>) => {
                  if (
                    event.key === "Tab" &&
                    !event.shiftKey &&
                    this.props.api
                  ) {
                    this.props.api.stopEditing();
                    this.props.api.tabToNextCell();
                  } else if (event.key === "Enter") {
                    event.preventDefault();
                    event.stopPropagation();
                    this.openDialog();
                  }
                }}
                size="small"
              />
            </InputAdornment>
          }
          error={errors.length > 0}
          fullWidth={true}
          inputProps={{
            onFocus: this.onFocus,
            style: {
              height: 23,
              marginLeft: "24px",
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
          inputRef={(r) => (this.inputElement = r)}
          multiline
          onChange={this.onChange}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Tab" && event.shiftKey && this.props.api) {
              this.props.api.stopEditing();
              this.props.api.tabToPreviousCell();

              event.preventDefault();
              event.stopPropagation();
            }
          }}
          required={this.column?.required}
          style={{ height: "calc(100% + 2px)", paddingLeft: 1 }}
          value={value}
        />
        <MultilineTextFieldDialog
          label={this.props.colDef.headerName}
          onAccept={this.onDialogAccept}
          onCancel={this.onDialogCancel}
          onExited={this.onDialogExited}
          open={this.state.isDialogOpen!}
          value={value}
        />
      </ErrorBadge>
    );
  }
}

export default observer(MLTextEditColumnEdit);

import { Autocomplete, InputAdornment } from "@mui/material";
import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { SearchChildProps } from "../config";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import Button from "../coreui/Button";
import TextField from "../coreui/TextField";
import Typography from "../coreui/Typography";
import PaneRow from "../models/PaneRow";
import ErrorsStore from "../stores/ErrorsStore";
import PaneDataStore from "../stores/PaneDataStore";
import { AccessLevel } from "./AccessLevel";
import {
  SelectChildProps,
  RuntimeProperties as SelectControlRuntimeProperties,
} from "./SelectControl";

interface ConfigProperties {
  dataId: string;
  helperText: string;
  label: string;
  name: string;
  propagated: SelectChildProps & SearchChildProps;
  searchButtonIsVisible: boolean;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
  possibleValues: object[];
  showAsMandatory: boolean;
}

export class TextCriteria extends React.Component<ConfigProperties> {
  private readonly componentId: string;
  private dataId: string = "";
  private name: string = "";

  public constructor(props: ConfigProperties) {
    super(props);

    makeObservable<
      TextCriteria,
      "dataId" | "name" | "onValueChange" | "syncDerivedWithProps"
    >(this, {
      dataId: observable,
      name: observable,
      onValueChange: action,
      syncDerivedWithProps: action,
    });

    this.componentId = `text-criteria-${Sys.nextId}`;

    this.syncDerivedWithProps();
  }

  private getErrors = (value: string): string[] => {
    const row = PaneRow.get(this.dataId);
    if (!row) {
      return [];
    }

    const widget = row.getWidgetT<string | null, RuntimeProperties>(this.name);
    return widget.properties.businessErrors;
  };

  private onClickSearchButton = (): void => {
    this.props.propagated.parentSelect.search(this.props.name);
  };

  private onInputChange = (event: React.SyntheticEvent, value: string) => {
    ErrorsStore.clearBusinessErrorsForWidget(this.dataId, this.name);

    const row = PaneRow.get(this.dataId)!;
    const widget = row.getWidgetT<string | null, RuntimeProperties>(this.name);
    widget.setValue(value === "" ? null : value);
  };

  private onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key !== "Enter") {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (this.props.searchButtonIsVisible) {
      this.props.propagated.parentSelect.search(this.props.name);
    } else {
      this.props.propagated.parentSearch.search();
    }
  };

  private onValueChange = (value: string): void => {
    ErrorsStore.clearBusinessErrorsForWidget(this.dataId, this.name);

    const row = PaneRow.get(this.dataId)!;
    const widget = row.getWidgetT<string | null, RuntimeProperties>(this.name);
    widget.setValue(value === "" ? null : value);
  };

  private syncDerivedWithProps(): void {
    this.dataId = this.props.dataId;
    this.name = this.props.name;
  }

  public componentDidUpdate(): void {
    this.syncDerivedWithProps();
  }

  public render(): React.ReactNode {
    const row = PaneRow.get(this.dataId);

    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<string | null, RuntimeProperties>(this.name);

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    if (widget.properties.accessLevel === AccessLevel.disabled) {
      const parentSelect = this.props.propagated.parentSelect;
      const parentSelectRow = PaneRow.get(parentSelect.configProps.dataId)!;
      const parentSelectWidget = parentSelectRow.getWidgetT<
        null,
        SelectControlRuntimeProperties
      >(parentSelect.configProps.name)!;

      return (
        <TextField
          disabled={true}
          disabledHelpText={
            parentSelectWidget.properties.showDisabledHelp
              ? parentSelect.configProps.disabledHelpText
              : undefined
          }
          label={this.props.label}
          name={this.props.name}
          variant="filled"
        />
      );
    }

    const nullSentinelValue =
      widget.properties.accessLevel === AccessLevel.readOnly ? "-" : "";

    const isIOS = Sys.isMobile && Sys.isSafari;
    if (widget.properties.possibleValues.length > 0 && !isIOS) {
      return (
        <Autocomplete
          autoComplete
          disableClearable
          forcePopupIcon={true}
          freeSolo
          handleHomeEndKeys={false}
          inputValue={widget.value === null ? nullSentinelValue : widget.value}
          onInputChange={this.onInputChange}
          onKeyPress={this.onKeyPress}
          options={widget.properties.possibleValues}
          readOnly={widget.properties.accessLevel === AccessLevel.readOnly}
          renderInput={(params) => (
            <TextField
              {...params}
              endAdornment={
                this.props.searchButtonIsVisible ? (
                  <InputAdornment position="end" style={{ marginTop: -4 }}>
                    <Button
                      aria-label={Localization.getBuiltInMessage("search")}
                      icon="fas fa-search"
                      onClick={this.onClickSearchButton}
                      size="small"
                    />
                  </InputAdornment>
                ) : undefined
              }
              getErrors={this.getErrors}
              helperText={this.props.helperText}
              inputProps={{
                ...params.inputProps,
                autoComplete: "off",
                style: { paddingLeft: 4, paddingTop: 24 },
                type: "search",
              }}
              InputProps={{
                ...params.InputProps,
                style: { paddingLeft: 12, paddingTop: 0 },
              }}
              label={this.props.label}
              name={this.props.name}
              required={widget.properties.showAsMandatory}
              variant="filled"
            />
          )}
          renderOption={(props, option, state) => {
            const text = option as unknown as string;
            let markup;

            if (text && state.inputValue) {
              const index = text
                .toLowerCase()
                .indexOf(state.inputValue.toLowerCase());
              if (index > -1) {
                markup = (
                  <React.Fragment>
                    {text.substr(0, index)}
                    <b>{text.substr(index, state.inputValue.length)}</b>
                    {text.substr(index + state.inputValue.length)}
                  </React.Fragment>
                );
              } else {
                markup = option;
              }
            } else {
              markup = option;
            }

            return (
              <li
                {...props}
                style={{
                  alignItems: "center",
                  display: "flex",
                  minHeight: 40,
                  width: "100%",
                }}
              >
                <Typography ellipsis>{markup}</Typography>
              </li>
            );
          }}
          role="group"
        />
      );
    }

    let inputDescribedByText: string = "";
    if (
      this.props.propagated.parentSelect &&
      (!this.props.propagated.parentSelect.isDialogOpen ||
        this.props.propagated.parentSelect.isDialogClosing)
    ) {
      if (this.props.helperText) {
        inputDescribedByText = this.props.helperText.endsWith(".")
          ? `${this.props.helperText} `
          : `${this.props.helperText}. `;
      }

      const selectGridRows: PaneRow[] = PaneDataStore.getPaneCollection(
        this.props.propagated.parentSelect.selectedDataId
      );

      if (selectGridRows.length === 1) {
        inputDescribedByText += Localization.getBuiltInMessage(
          "DataTable.selectCriteriaOneRowInTable"
        );
      } else if (selectGridRows.length > 1) {
        inputDescribedByText += Localization.getBuiltInMessage(
          "DataTable.selectCriteriaMultipleRowsInTable",
          { count: selectGridRows.length }
        );
      }
    } else {
      // This TextCriteria widget is on a Search Layout. Simply use the
      // configured helper text as the accessible description.
      inputDescribedByText = this.props.helperText;
    }

    const describedById: string = `${this.componentId}-described-by`;

    return (
      <div>
        <TextField
          getErrors={this.getErrors}
          helperText={this.props.helperText}
          InputProps={{
            "aria-describedby": describedById,
            endAdornment: this.props.searchButtonIsVisible ? (
              <InputAdornment position="end" style={{ marginTop: -4 }}>
                <Button
                  aria-label={Localization.getBuiltInMessage("Search")}
                  icon="fas fa-search"
                  onClick={this.onClickSearchButton}
                  size="small"
                />
              </InputAdornment>
            ) : undefined,
          }}
          label={this.props.label}
          name={this.props.name}
          onKeyPress={this.onKeyPress}
          onValueChange={this.onValueChange}
          readOnly={widget.properties.accessLevel === AccessLevel.readOnly}
          required={widget.properties.showAsMandatory}
          value={widget.value === null ? nullSentinelValue : widget.value}
          variant="filled"
        />
        <span id={describedById} style={{ display: "none" }}>
          {inputDescribedByText}
        </span>
      </div>
    );
  }
}

export default observer(TextCriteria);

import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../../core/Localization";
import Routing from "../../core/Routing";
import MenuItem from "../../coreui/MenuItem";
import PaneRow from "../../models/PaneRow";
import UserService from "../../services/UserService";
import { AccessLevel } from "../AccessLevel";
import { ActionButtonProps, ActionButtonRuntimeProps } from "../ActionButton";
import ApiButton from "../ApiButton";
import { MenuItemProps } from "../MenuItem";

interface Props extends ActionButtonProps {}

export class SignOutButton extends React.Component<Props> {
  private static signOutError(): void {
    Routing.goToErrorPage("Only signed in users can sign out");
  }

  public static renderMenuItem(props: MenuItemProps): JSX.Element {
    const { config, runtime, ...otherProps } = props;
    const configProps = config as unknown as Props;
    const runtimeProps = runtime as ActionButtonRuntimeProps;

    let url: string | undefined;
    if (!UserService.isGuest) {
      url = "#/signout";
    }

    return (
      <MenuItem
        href={url}
        iconName={configProps.iconName}
        indent={props.config.propagated ? props.config.propagated.indent : 0}
        onClick={url ? undefined : SignOutButton.signOutError}
        {...otherProps}
      >
        {runtimeProps.label}
      </MenuItem>
    );
  }

  public render(): React.ReactNode {
    const row = PaneRow.get(this.props.dataId);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<null, ActionButtonRuntimeProps>(
      this.props.name
    );

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    let url: string | undefined;
    if (!UserService.isGuest) {
      url = "#/signout";
    }

    const isIconOnly = !widget.properties.label;
    const label = isIconOnly
      ? Localization.getBuiltInMessage("signOut")
      : widget.properties.label;

    return (
      <ApiButton
        alternateText={widget.properties.alternateText}
        buttonColor={this.props.buttonColor}
        disabled={widget.properties.accessLevel === AccessLevel.disabled}
        disabledHelpText={this.props.disabledHelpText}
        disabledHelpVisible={widget.properties.showDisabledHelp}
        href={url}
        iconName={this.props.iconName}
        isIconOnly={isIconOnly}
        label={label}
        onClick={url ? undefined : SignOutButton.signOutError}
        renderAsLink={this.props.renderAsLink}
        size={this.props.size}
      />
    );
  }
}

export default observer(SignOutButton);

import { observer } from "mobx-react";
import * as React from "react";
import { LayoutChildProps } from "../config";
import { useWidth, WidthProps } from "../core/Responsive";
import ButtonLink from "../coreui/ButtonLink";
import FormData from "../coreui/FormData";
import MenuItem from "../coreui/MenuItem";
import { TableVerticalLayoutProps } from "../coreui/Table";
import PaneRow from "../models/PaneRow";
import { AccessLevel } from "./AccessLevel";
import ApiButton from "./ApiButton";
import { MenuChild } from "./MenuButton";
import { MenuItemProps } from "./MenuItem";
import { ToolbarChildProps } from "./Toolbar";

interface ConfigProperties {
  dataId: string;
  displayType: {
    lg: "Link" | "Small Button" | "Medium Button" | "Large Button";
    md: "Link" | "Small Button" | "Medium Button" | "Large Button";
    sm: "Link" | "Small Button" | "Medium Button" | "Large Button";
    xl: "Link" | "Small Button" | "Medium Button" | "Large Button";
    xs: "Link" | "Small Button" | "Medium Button" | "Large Button";
  };
  iconName: string;
  label?: string;
  linkType: "External" | "Internal";
  name: string;
  propagated?: LayoutChildProps &
    MenuChild &
    TableVerticalLayoutProps &
    ToolbarChildProps;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  anchorText: string;
  url: string;
}

export class ManualLink extends React.Component<ConfigProperties & WidthProps> {
  public static renderMenuItem(props: MenuItemProps): JSX.Element {
    const { config, runtime, ...otherProps } = props;
    const configProps = config as unknown as ConfigProperties;
    const runtimeProps = runtime as RuntimeProperties;

    let url: string | undefined = undefined;
    if (runtimeProps.accessLevel >= AccessLevel.actionable) {
      url = runtimeProps.url;
    }

    const onClick = (): void => {
      if (runtimeProps.accessLevel >= AccessLevel.actionable) {
        configProps.propagated!.onItemClicked!();
      }
    };

    return (
      <MenuItem
        disabled={runtimeProps.accessLevel === AccessLevel.disabled}
        href={url}
        iconName={configProps.iconName}
        indent={props.config.propagated ? props.config.propagated.indent : 0}
        onClick={onClick}
        target={configProps.linkType === "Internal" ? "_self" : "_blank"}
        {...otherProps}
      >
        {runtimeProps.anchorText}
      </MenuItem>
    );
  }

  public render(): React.ReactNode {
    let rowKey = undefined;
    if (this.props.propagated?.parentTable) {
      // Widget is being rendered in the vertical layout of a table.
      rowKey = this.props.propagated.rowKey;
    }

    const row = PaneRow.get(this.props.dataId, rowKey);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<null, RuntimeProperties>(this.props.name);

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    const tabIndex: number =
      this.props.propagated &&
      (this.props.propagated.hasParentToolbar ||
        this.props.propagated.isInPageFooter)
        ? -1
        : 0;

    if (this.props.displayType[this.props.width] === "Link") {
      const link = (
        <ButtonLink
          disabled={widget.properties.accessLevel <= AccessLevel.readOnly}
          href={widget.properties.url}
          icon={this.props.iconName}
          linkText={widget.properties.anchorText}
          rel={this.props.linkType === "External" ? "noopener" : undefined}
          tabIndex={tabIndex}
          target={this.props.linkType === "External" ? "_blank" : "_self"}
        />
      );

      return this.props.label ? (
        <FormData label={this.props.label}>{link}</FormData>
      ) : (
        link
      );
    }

    let displayType: "large" | "medium" | "small" | undefined = undefined;
    if (this.props.width in this.props.displayType) {
      switch (this.props.displayType[this.props.width]) {
        case "Small Button":
          displayType = "small";
          break;
        case "Medium Button":
          displayType = "medium";
          break;
        case "Large Button":
          displayType = "large";
          break;
        default:
          throw new Error(
            "Unknown manual link display type" +
              `${this.props.displayType[this.props.width]}`
          );
      }
    }

    return (
      <ApiButton
        buttonColor="default"
        disabledHelpText=""
        href={widget.properties.url}
        iconName={this.props.iconName}
        isIconOnly={false}
        label={widget.properties.anchorText}
        size={displayType}
        tabIndex={tabIndex}
        target={this.props.linkType === "Internal" ? "_self" : "_blank"}
      />
    );
  }
}

// FUTURE
// This wrapper component was created to avoid the scope of converting this
// component to a hooks component during the MUI 5 upgrade. When the legacy
// component is converted to a hooks component, this wrapper can be removed and
// hooks it calls can be called by the converted component directly.
const Observer = observer(ManualLink);
export default function Wrapped(props: ConfigProperties): JSX.Element {
  const width = useWidth();
  return <Observer {...props} width={width} />;
}

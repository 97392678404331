import { styled, Typography } from "@mui/material";
import * as React from "react";
import { Logon, LogonProps } from "../../pages";
import { getPanelMeasurements } from "../../theme";
import { BackgroundImage } from "./BackgroundImage";
import { Container } from "./Container";

interface Props {
  backgroundImageUrl: string;
  logonProps: LogonProps;
  title1: string;
  title2: string;
}

const GreetingContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    height: "100%",
  },
  [theme.breakpoints.up("md")]: {
    marginBottom: 24,
    marginTop: 24,
  },
  [theme.breakpoints.up("lg")]: {
    marginBottom: 40,
    marginTop: 40,
  },
  alignItems: "center",
  display: "flex",
}));

const GreetingContent = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    marginRight: theme.horizontalSpacing.related.lg,
  },
  [theme.breakpoints.only("md")]: {
    marginRight: theme.horizontalSpacing.related.md,
  },
  flex: 1,
}));

const LogonContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    display: "block",
    padding: theme.paper.padding.lg,
    width:
      getPanelMeasurements(theme, "lg").columnWidth * 4 +
      getPanelMeasurements(theme, "lg").gutterWidth * 3 -
      theme.paper.padding.lg * 2,
  },
  [theme.breakpoints.only("md")]: {
    display: "block",
    padding: theme.paper.padding.md,
    width:
      getPanelMeasurements(theme, "md").columnWidth * 5 +
      getPanelMeasurements(theme, "md").gutterWidth * 4 -
      theme.paper.padding.md * 2,
  },
  backgroundColor: theme.palette.common.white,
  display: "none",
}));

const Root = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    height: 212,
  },
  [theme.breakpoints.up("sm")]: {
    height: 300,
  },
  [theme.breakpoints.up("md")]: {
    height: "auto",
  },
  position: "relative",
}));

const Title = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    textAlign: "center",
  },
  [theme.breakpoints.up("lg")]: {
    textAlign: "left",
  },
  [theme.breakpoints.only("md")]: {
    textAlign: "left",
  },
  color: theme.palette.common.white,
}));

const TitleLight = styled(Title)({
  fontWeight: 400,
});

export const LandingPageGreeting = (props: Props): JSX.Element => (
  <Root>
    <BackgroundImage mask url={props.backgroundImageUrl} />
    <GreetingContainer>
      <GreetingContent aria-hidden={true}>
        <TitleLight variant="h3">{props.title1}</TitleLight>
        <Title variant="h1">{props.title2}</Title>
      </GreetingContent>
      <LogonContainer>
        <Logon {...props.logonProps} />
      </LogonContainer>
    </GreetingContainer>
  </Root>
);

import { colors, createTheme, Theme } from "@mui/material";
import { ForegroundColorType } from "./ForegroundColorType";

const colorTypes = [
  "danger",
  "information",
  "primary",
  "secondary",
  "success",
  "warning",
] as const;

type ColorTypes = (typeof colorTypes)[number];

type PaletteColors = { [key in ColorTypes]: string };
export type PaletteConfig = { [key in ColorTypes]: string };

export class Palette {
  private static defaultColors: PaletteColors = {
    danger: colors.red[900],
    information: colors.lightBlue[900],
    primary: colors.grey[800],
    secondary: colors.blueGrey[900],
    success: colors.lightGreen[500],
    warning: colors.orange[500],
  };

  private static colors: PaletteColors = this.defaultColors;

  public static augmentTheme(theme: Theme): Theme {
    return createTheme(theme, {
      palette: {
        action: {
          disabled: colors.grey[300],
          disabledBackground: colors.grey[100],
        },
        danger: theme.palette.augmentColor({
          color: { main: Palette.colors.danger },
          name: "danger",
        }),
        dark: theme.palette.augmentColor({
          color: { main: colors.grey[800] },
          name: "dark",
        }),
        default: theme.palette.augmentColor({
          color: { main: colors.grey[300] },
          name: "default",
        }),
        divider: colors.grey[300],
        error: { main: Palette.colors.danger },
        info: { main: Palette.colors.information },
        primary: { main: Palette.colors.primary },
        secondary: { main: Palette.colors.secondary },
        success: theme.palette.augmentColor({
          color: { main: Palette.colors.success },
          name: "success",
        }),
        text: {
          disabled: colors.grey[300],
          primary:
            theme.baseForegroundColor === ForegroundColorType.White
              ? colors.common.white
              : colors.grey[800],
          secondary:
            theme.baseForegroundColor === ForegroundColorType.White
              ? colors.common.white
              : colors.grey[800],
        },
        warning: { main: Palette.colors.warning },
      },
    });
  }

  public static setConfig(config: PaletteConfig): void {
    Palette.colors = colorTypes.reduce((result, colorType) => {
      result[colorType] = config[colorType];
      return result;
    }, {} as PaletteColors);
  }
}

import { Hidden } from "@mui/material";
import {
  action,
  autorun,
  IReactionDisposer,
  makeObservable,
  observable,
} from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import AppServer from "../core/AppServer";
import RequestPromise from "../core/RequestPromise";
import Accordion from "../coreui/Accordion";
import Tab from "../coreui/Tab";
import { Tabs } from "../coreui/Tabs";
import PaneRow from "../models/PaneRow";
import BaseService from "../services/BaseService";
import RoundTripService from "../services/RoundTripService";
import ErrorsStore from "../stores/ErrorsStore";
import LayoutStateStore from "../stores/LayoutStateStore";
import PaneDataStore, {
  PaneData,
  PaneDataByDataId,
} from "../stores/PaneDataStore";
import { AccessLevel } from "./AccessLevel";
import ErrorBoundary from "./ErrorBoundary";
import TabPane from "./TabPane";

interface GetDataResponse {
  paneDataByDataId: PaneDataByDataId;
  paneState: string;
  validationErrors: string[];
}

interface ConfigProperties {
  dataId: string;
  name: string;
  tabs: TabProps[];
  uniqueId: string;
}

interface State {
  accordianCollapsed?: boolean;
  loadingPaneUseKeys?: string[];
  selectedPaneUseKey?: string;
  switchingTab?: boolean;
}

interface TabProps {
  dataId: string;
  disabledHelpText: string | null;
  label: string;
  paneUse: object;
  paneUseKey: string;
}

interface TabRuntimeProperties {
  accessLevel: AccessLevel;
  contentsExist: boolean;
  paneUseKey: string;
  rowKey: string;
  showDisabledHelp: boolean;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  selectedPaneUseKey: string | null;
  tabs: TabRuntimeProperties[];
}

export class TabControl extends React.Component<ConfigProperties, State> {
  private dataId: string = "";
  private dataMonitorDisposer: IReactionDisposer;
  private lastRetrievedPaneDate: Date | undefined;
  private loadedPaneUseKeys: string[] = [];
  private name: string = "";
  private retrieveDataPromises: RequestPromise<GetDataResponse>[] = [];

  public constructor(props: ConfigProperties) {
    super(props);

    makeObservable<TabControl, "dataId" | "name" | "syncDerivedWithProps">(
      this,
      {
        dataId: observable,
        name: observable,
        syncDerivedWithProps: action,
      }
    );

    this.state = {
      accordianCollapsed: false,
      loadingPaneUseKeys: [],
      switchingTab: false,
    };

    this.syncDerivedWithProps();
  }

  private dataMonitor = () => {
    const parentPane: PaneData = PaneDataStore.instance.getPane(this.dataId)!;

    if (!parentPane.lastRetrieved) {
      return;
    }

    if (
      this.lastRetrievedPaneDate &&
      this.lastRetrievedPaneDate >= parentPane.lastRetrieved
    ) {
      return;
    }

    for (const retrievePromise of this.retrieveDataPromises) {
      retrievePromise.abort();
    }

    const row = PaneRow.get(this.dataId)!;
    const widget = row.getWidgetT<null, RuntimeProperties>(this.name);
    const paneUseKey: string | null = widget.properties.selectedPaneUseKey;

    if (paneUseKey) {
      const activeTab: TabRuntimeProperties | undefined =
        widget.properties.tabs.find((t) => t.paneUseKey === paneUseKey);

      if (!activeTab) {
        throw new Error(`No tab with pane use key of ${paneUseKey} found`);
      }

      this.loadedPaneUseKeys = [activeTab.paneUseKey];

      this.setState({
        loadingPaneUseKeys: [],
        selectedPaneUseKey: activeTab.paneUseKey,
      });
    } else {
      this.loadedPaneUseKeys = [];
      this.setState({
        loadingPaneUseKeys: [],
        selectedPaneUseKey: undefined,
      });
    }

    PaneDataStore.clearAllDataChanges();

    this.lastRetrievedPaneDate = parentPane.lastRetrieved;
  };

  private getTabId(tab: TabProps, content: boolean): string {
    return (
      `${this.props.uniqueId}_${content ? "content" : "tab"}` +
      `_${tab.paneUseKey}`
    );
  }

  private loadSelectedTab(): void {
    const row = PaneRow.get(this.dataId)!;
    const widget = row.getWidgetT<null, RuntimeProperties>(this.name);

    const tabRuntimeProps: TabRuntimeProperties = widget.properties.tabs.find(
      (t) => t.paneUseKey === this.state.selectedPaneUseKey
    )!;
    const paneUseKey: string = this.state.selectedPaneUseKey!;

    this.setState((prevState) => {
      const loadingPaneUseKeys: string[] =
        prevState.loadingPaneUseKeys!.slice();
      loadingPaneUseKeys.push(paneUseKey);

      return { loadingPaneUseKeys, switchingTab: false };
    });

    const requestPromise: RequestPromise<GetDataResponse> =
      RoundTripService.partialDataRetrevial(
        `TabControl/GetData/${tabRuntimeProps.rowKey}/${this.dataId}/${paneUseKey}`
      );

    this.retrieveDataPromises.push(requestPromise);

    requestPromise
      .then((response: GetDataResponse) => {
        this.retrieveDataPromises = this.retrieveDataPromises.filter(
          (p) => p !== requestPromise
        );

        if (response.validationErrors && response.validationErrors.length > 0) {
          ErrorsStore.showErrors(response.validationErrors);
          if (this.loadedPaneUseKeys.length > 0) {
            this.updateSelectedTab(null, this.loadedPaneUseKeys[0]);
          }
        } else {
          PaneDataStore.loadResponse(response.paneDataByDataId);
          ErrorsStore.setBusinessErrors();

          this.loadedPaneUseKeys.push(paneUseKey);
          AppServer.addPane(response.paneState);
        }

        this.setState((prevState) => {
          const loadingPaneUseKeys: string[] =
            prevState.loadingPaneUseKeys!.filter((k) => k !== paneUseKey);

          return { loadingPaneUseKeys, switchingTab: false };
        });
      })
      .catch((request) => {
        const message: string | null =
          BaseService.getRequestExceptionMessage(request);
        if (message) {
          ErrorsStore.showErrors([message]);
        }
        if (this.loadedPaneUseKeys.length > 0) {
          this.updateSelectedTab(null, this.loadedPaneUseKeys[0]);
        }
      });
  }

  private renderAccordion(
    tab: TabProps,
    runtimeProperties: RuntimeProperties
  ): React.ReactNode {
    const tabRuntimeProps = runtimeProperties.tabs.find(
      (t) => t.paneUseKey === tab.paneUseKey
    )!;

    if (!tabRuntimeProps) {
      return null;
    }

    if (tabRuntimeProps.accessLevel === AccessLevel.hidden) {
      return null;
    }

    const businessErrorsCount: number =
      ErrorsStore.getBusinessErrorsCountForPane(tabRuntimeProps.paneUseKey);

    return (
      <Accordion
        badge={
          businessErrorsCount > 0
            ? "errors"
            : tabRuntimeProps.contentsExist
            ? "contents"
            : undefined
        }
        businessErrorsCount={businessErrorsCount}
        disabled={tabRuntimeProps.accessLevel === AccessLevel.disabled}
        disabledHelpText={tab.disabledHelpText}
        expanded={
          !this.state.accordianCollapsed &&
          tab.paneUseKey === this.state.selectedPaneUseKey
        }
        key={tab.paneUseKey}
        label={tab.label}
        labelId={this.getTabId(tab, false)}
        onChange={this.updateSelectedTab}
        showDisabledHelp={tabRuntimeProps.showDisabledHelp}
        value={tab.paneUseKey}
      >
        <ErrorBoundary title={this.props.name}>
          {tab.paneUseKey === this.state.selectedPaneUseKey
            ? this.renderSelectedTabPane(runtimeProperties)
            : null}
        </ErrorBoundary>
      </Accordion>
    );
  }

  private renderSelectedTabPane(
    runtimeProperties: RuntimeProperties
  ): React.ReactNode {
    if (runtimeProperties.accessLevel <= AccessLevel.disabled) {
      return null;
    }

    const selectedTab: TabProps = this.props.tabs.find(
      (t) => t.paneUseKey === this.state.selectedPaneUseKey
    )!;

    return (
      <TabPane
        id={this.getTabId(selectedTab, true)}
        isLoaded={this.loadedPaneUseKeys.indexOf(selectedTab.paneUseKey) >= 0}
        isLoading={
          this.state.loadingPaneUseKeys!.indexOf(selectedTab.paneUseKey) >= 0
        }
        isSwitching={!!this.state.switchingTab}
        paneUse={selectedTab.paneUse}
        paneUseKey={selectedTab.paneUseKey}
        tabId={this.getTabId(selectedTab, false)}
      />
    );
  }

  private renderTabButton(
    tab: TabProps,
    tabRuntimeProps: TabRuntimeProperties
  ): React.ReactNode {
    const businessErrorsCount: number =
      ErrorsStore.getBusinessErrorsCountForPane(tabRuntimeProps.paneUseKey);

    if (tabRuntimeProps.accessLevel === AccessLevel.hidden) {
      return null;
    }

    return (
      <Tab
        aria-controls={this.getTabId(tab, true)}
        badge={
          businessErrorsCount > 0
            ? "errors"
            : tabRuntimeProps.contentsExist
            ? "contents"
            : undefined
        }
        businessErrorsCount={businessErrorsCount}
        disabled={tabRuntimeProps.accessLevel === AccessLevel.disabled}
        disabledHelpText={tab.disabledHelpText}
        id={this.getTabId(tab, false)}
        key={tab.paneUseKey}
        label={tab.label}
        showDisabledHelp={tabRuntimeProps.showDisabledHelp}
        value={tab.paneUseKey}
      />
    );
  }

  private syncDerivedWithProps(): void {
    this.dataId = this.props.dataId;
    this.name = this.props.name;
  }

  private updateSelectedTab = (
    event: React.ChangeEvent<{}> | null,
    value: string
  ) => {
    this.setState(
      (prevState) => {
        return {
          accordianCollapsed:
            !prevState.accordianCollapsed &&
            value === prevState.selectedPaneUseKey,
          selectedPaneUseKey: value,
          switchingTab: true,
        };
      },
      () => {
        LayoutStateStore.setSelectedTab(
          this.props.dataId,
          this.props.name,
          this.state.selectedPaneUseKey!
        );

        if (
          this.loadedPaneUseKeys.indexOf(this.state.selectedPaneUseKey!) < 0
        ) {
          this.loadSelectedTab();
        }
      }
    );
  };

  public componentDidMount(): void {
    this.dataMonitorDisposer = autorun(this.dataMonitor);
  }

  public componentDidUpdate(): void {
    this.syncDerivedWithProps();
  }

  public componentWillUnmount(): void {
    for (const retrievePromise of this.retrieveDataPromises) {
      retrievePromise.abort();
    }

    if (this.dataMonitorDisposer) {
      this.dataMonitorDisposer();
    }

    LayoutStateStore.setSelectedTab(this.props.dataId, this.props.name, null);
  }

  public render(): React.ReactNode {
    if (!this.state.selectedPaneUseKey || this.props.tabs.length <= 0) {
      return null;
    }

    const row = PaneRow.get(this.props.dataId);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<null, RuntimeProperties>(this.props.name);

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    return (
      <div>
        <Hidden smDown>
          <Tabs
            onChange={
              this.updateSelectedTab as ((
                event: React.ChangeEvent<{}>,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                value: any
              ) => void) &
                ((event: React.FormEvent<HTMLButtonElement>) => void)
            }
            value={this.state.selectedPaneUseKey}
          >
            {this.props.tabs.map((tab) =>
              this.renderTabButton(
                tab,
                widget.properties.tabs.find(
                  (t) => t.paneUseKey === tab.paneUseKey
                )!
              )
            )}
          </Tabs>
          <ErrorBoundary title={this.props.name}>
            {this.renderSelectedTabPane(widget.properties)}
          </ErrorBoundary>
        </Hidden>
        <Hidden smUp>
          {this.props.tabs.map((t) =>
            this.renderAccordion(t, widget.properties)
          )}
        </Hidden>
      </div>
    );
  }
}

export default observer(TabControl);

import { ICellRendererParams } from "ag-grid-community";

export class CellUtil {
  private static customNavigationKeys = ["End", "Home", "PageDown", "PageUp"];

  private static disableNavigationKeys = [
    "ArrowDown",
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "End",
    "Home",
  ];

  private static disableCellNavigation(event: KeyboardEvent): void {
    if (CellUtil.disableNavigationKeys.indexOf(event.key) >= 0) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  public static customizeGridNavigation(
    event: KeyboardEvent,
    params: ICellRendererParams
  ): void {
    if (!CellUtil.customNavigationKeys.includes(event.key)) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    const currentPage: number = params.api.paginationGetCurrentPage();

    switch (event.key) {
      case "End":
      case "Home":
        // Disable default ag-grid behaviour
        break;

      case "PageDown":
        if (currentPage < params.api.paginationGetTotalPages() - 1) {
          params.api.paginationGoToNextPage();

          const firstRow = params.api.getFirstDisplayedRow();
          params.api.setFocusedCell(firstRow, params.column!);
        }
        break;

      case "PageUp":
        if (currentPage > 0) {
          params.api.paginationGoToPreviousPage();

          const lastRow = params.api.getLastDisplayedRow();
          params.api.setFocusedCell(lastRow, params.column!);
        }
        break;

      default:
        throw new Error(`Unknown key ${event.key}`);
    }
  }

  public static disableGridNavigation(
    cell: HTMLElement,
    input: HTMLInputElement
  ): void {
    cell.addEventListener("keydown", CellUtil.disableCellNavigation);

    input.addEventListener("keydown", (event: KeyboardEvent) => {
      if (CellUtil.disableNavigationKeys.indexOf(event.key) >= 0) {
        event.stopPropagation();
      }
    });
  }

  public static enableGridNavigation(cell: HTMLElement): void {
    cell.removeEventListener("keydown", CellUtil.disableCellNavigation);
  }

  public static runOnAction(
    cell: HTMLElement,
    action: (event: KeyboardEvent) => void
  ): void {
    cell.addEventListener("keydown", (event: KeyboardEvent) => {
      if (event.key === "Enter" || event.key === " ") {
        event.preventDefault();
        event.stopPropagation();
        action(event);
      }
    });
  }

  public static setAriaAttributes(
    gridCell: HTMLElement,
    errorMessageId: string,
    invalid: boolean
  ) {
    if (invalid) {
      gridCell.setAttribute("aria-invalid", "true");
      gridCell.setAttribute("aria-describedby", errorMessageId);
    } else {
      gridCell.setAttribute("aria-invalid", "false");
      gridCell.removeAttribute("aria-describedby");
    }
  }

  public static setInitialFocus(input: HTMLInputElement): void {
    // AutoFocus does not work for initial render.
    window.setTimeout(() => input.focus(), 10);
  }

  public static setReadOnlyAttribute(
    cell: HTMLElement,
    readOnly: boolean
  ): void {
    cell.setAttribute("aria-readonly", readOnly ? "true" : "false");
  }
}

import { ICellRendererParams } from "ag-grid-community";
import * as React from "react";
import { TableChildProps } from "../../coreui/Table";
import Typography from "../../coreui/Typography";
import PaneRow, {
  RuntimeProperties,
  RuntimeWidget,
  RuntimeWidgetT,
} from "../../models/PaneRow";
import { DateEdit } from "../DateEdit";
import { FunctionName } from "../TableSummary";
import { DateEditColumnEdit } from "./DateEditColumnEdit";
import {
  GridColumnConfigProperties,
  RenderInlineProperties,
} from "./GridColumn";
import TextColumn from "./TextColumn";

interface ConfigProperties extends ICellRendererParams<PaneRow, string | null> {
  dataId: string;
  name: string;
  propagated: TableChildProps;
}

export class DateEditColumn extends React.PureComponent<ConfigProperties> {
  public static readonly widgetType: string = "DateEditColumn";

  public static getFilterText(
    column: GridColumnConfigProperties,
    propagated: TableChildProps,
    row: PaneRow
  ): string {
    const widget = row.getWidgetT<string | null, RuntimeProperties>(
      column.name
    );

    if (!widget.value) {
      return "";
    }

    const parsed = DateEditColumnEdit.getCurrentValueParsed(
      false,
      widget.value
    );
    const formatted = DateEdit.formatValue(parsed, true);

    return formatted ? formatted : "";
  }

  public static getSummaryValue(
    runtimeData: RuntimeWidget[],
    configProperties: ConfigProperties,
    functionName: FunctionName
  ): string | null {
    const filter = (
      data: RuntimeWidget
    ): data is RuntimeWidgetT<string, {}> => {
      if (data.value === null) {
        return false;
      }
      if (typeof data.value !== "string") {
        throw new Error(`Unexpected data type ${typeof data.value}`);
      }
      return true;
    };

    const values = runtimeData.filter(filter).map((data): string => data.value);

    if (functionName === "Count") {
      return values.length.toString();
    }

    if (values.length === 0) {
      return null;
    }

    let summaryFn: (result: Date, value: Date) => Date;
    if (functionName === "Maximum") {
      summaryFn = (result, value) => (value > result ? value : result);
    } else if (functionName === "Minimum") {
      summaryFn = (result, value) => (value < result ? value : result);
    } else {
      throw new Error(`Unknown summary function ${functionName}`);
    }

    const result: Date = values
      .map((value) => DateEditColumnEdit.getCurrentValueParsed(false, value)!)
      .reduce(summaryFn);

    return DateEdit.formatValue(result, true);
  }

  public static renderInline(
    props: RenderInlineProperties
  ): JSX.Element | null {
    const widget = props.row.getWidgetT<string | null, RuntimeProperties>(
      props.column.name
    );
    const date: Date | null = DateEditColumnEdit.getCurrentValueParsed(
      false,
      widget.value
    );

    if (date === null) {
      return null;
    }

    return (
      <Typography className={props.className} variant="body1">
        {DateEdit.formatValue(date, true)}
      </Typography>
    );
  }

  public constructor(props: ConfigProperties) {
    super(props);

    props.eGridCell.addEventListener("keydown", this.onCellKeyDown);
  }

  private onCellKeyDown = (event: KeyboardEvent): void => {
    if (event.key === " " && !this.props.api.getEditingCells().length) {
      this.props.api.startEditingCell({
        colKey: this.props.column!.getColId(),
        rowIndex: this.props.rowIndex,
      });

      event.preventDefault();
      event.stopPropagation();
    }
  };

  public render() {
    const { value, ...otherProps } = this.props;

    const formattedValue = DateEdit.formatValue(
      DateEditColumnEdit.getCurrentValueParsed(false, value),
      true
    );

    return <TextColumn showEllipsis value={formattedValue} {...otherProps} />;
  }
}

export default DateEditColumn;

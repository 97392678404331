import {
  Grid,
  styled,
  Theme,
  Typography,
  TypographyVariant,
} from "@mui/material";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../core/Localization";
import Sys from "../core/Sys";
import Icon from "../coreui/Icon";
import PaneRow from "../models/PaneRow";
import { AccessLevel } from "./AccessLevel";
import { MediaChild } from "./Media";

interface ConfigProperties {
  dataId: string;
  headingSize:
    | "Level 1"
    | "Level 2"
    | "Level 3"
    | "Level 4"
    | "Level 5"
    | "Level 6";
  iconName: string;
  name: string;
  propagated: MediaChild;
}

export interface RuntimeProperties {
  accessLevel: AccessLevel;
  headingText: string;
  helperText: string;
  showAsMandatory: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    goToTopBtn: {
      backgroundColor: theme.palette.common.white,
      marginRight: 8,

      [theme.breakpoints.up("xs")]: {
        marginLeft: 16,
        marginTop: 4,
      },
      [theme.breakpoints.up("sm")]: {
        marginLeft: 24,
        marginTop: 8,
      },
      [theme.breakpoints.up("lg")]: {
        marginLeft: 40,
        marginTop: 16,
      },
    },
    helperText: {
      marginTop: 8,
      maxWidth: 580,
    },
    root: {
      "&:focus": {
        outline: "none",
      },
      "&:focus-visible": {
        outline: "none",
      },
    },
    topMediaContainerChild: {
      textAlign: "center",
    },
  });

const MandatoryIndicator = styled("span")(({ theme }) => ({
  color: theme.palette.danger.main,
}));

export class GroupHeading extends React.Component<
  ConfigProperties & WithStyles<typeof styles>
> {
  private readonly componentId: string;
  private readonly helperTextId: string;
  private readonly mandatoryIndicatorId: string;

  public constructor(props: ConfigProperties & WithStyles<typeof styles>) {
    super(props);

    this.componentId = `group-heading-${Sys.nextId}`;
    this.helperTextId = `${this.componentId}-helper-text`;
    this.mandatoryIndicatorId = `${this.componentId}-mandatory-indicator`;
  }

  public render(): React.ReactNode {
    const row = PaneRow.get(this.props.dataId);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<null, RuntimeProperties>(this.props.name);

    const variant = {
      "Level 1": "h1",
      "Level 2": "h2",
      "Level 3": "h3",
      "Level 4": "h4",
      "Level 5": "h5",
      "Level 6": "h6",
    }[this.props.headingSize] as TypographyVariant;

    const classes: string[] = [this.props.classes.root];
    const helperClasses: string[] = [this.props.classes.helperText];

    const mandatoryIndicator: string = Localization.getBuiltInMessage(
      "Heading.mandatoryIndicator"
    );

    const ariaDescribedByIds = [];
    if (widget.properties.showAsMandatory) {
      ariaDescribedByIds.push(this.mandatoryIndicatorId);
    }
    if (widget.properties.helperText) {
      ariaDescribedByIds.push(this.helperTextId);
    }

    const ariaDescribedBy: string | undefined =
      ariaDescribedByIds.length > 0 ? ariaDescribedByIds.join(" ") : undefined;

    if (this.props.propagated?.parentMediaOrientation === "Top") {
      classes.push(this.props.classes.topMediaContainerChild);
      helperClasses.push(this.props.classes.topMediaContainerChild);
    }

    return (
      <div>
        <Grid container wrap="nowrap">
          {this.props.iconName && (
            <Grid item>
              <Typography variant={variant}>
                <Icon
                  fixedWidth
                  icon={this.props.iconName}
                  style={{ display: "inline", marginRight: ".4em" }}
                />
              </Typography>
            </Grid>
          )}
          <Grid item style={{ flex: "1 1 auto" }}>
            <Typography
              aria-describedby={ariaDescribedBy}
              className={classes.join(" ")}
              id={this.componentId}
              tabIndex={-1}
              variant={variant}
            >
              <span>{widget.properties.headingText}</span>
              {widget.properties.showAsMandatory ? (
                <MandatoryIndicator aria-hidden={true}>
                  &nbsp;*
                </MandatoryIndicator>
              ) : null}
            </Typography>
          </Grid>
        </Grid>
        {widget.properties.helperText ? (
          <Typography
            className={helperClasses.join(" ")}
            id={this.helperTextId}
          >
            {widget.properties.helperText}
          </Typography>
        ) : null}
        {widget.properties.showAsMandatory ? (
          <div id={this.mandatoryIndicatorId} style={{ display: "none" }}>
            {mandatoryIndicator}
          </div>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(observer(GroupHeading));

import { action, observable } from "mobx";

export default class SubPaneControlStore {
  private static currentPaneKeyByControlKey: {
    [controlKey: string]: string | undefined;
  } = observable({});

  public static hidePane = action((controlKey: string): void => {
    SubPaneControlStore.currentPaneKeyByControlKey[controlKey] = undefined;
  });

  public static showPane = action(
    (controlKey: string, paneKey: string): void => {
      SubPaneControlStore.currentPaneKeyByControlKey[controlKey] = paneKey;
    }
  );

  public static getCurrentPaneKeyForControlKey(
    controlKey: string
  ): string | undefined {
    if (!(controlKey in SubPaneControlStore.currentPaneKeyByControlKey)) {
      SubPaneControlStore.currentPaneKeyByControlKey[controlKey] = undefined;
    }

    return SubPaneControlStore.currentPaneKeyByControlKey[controlKey];
  }
}

import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  Presentation as PresentationLayout,
  SearchPresentation,
  SearchPresentationConfig,
} from "../config";
import AppServer, { State } from "../core/AppServer";
import Routing from "../core/Routing";
import Sys from "../core/Sys";
import TrackableCollection from "../core/TrackableCollection";
import Presentation from "../coreui/Presentation";
import Panel from "../mustangui/Panel";
import PresentationService from "../services/PresentationService";
import SearchService from "../services/SearchService";
import PaneDataStore from "../stores/PaneDataStore";
import { BasePageTemplate, BasePageTemplateConfig } from "../templates";
import Logon from "./Logon";

export interface GuestPageConfig {
  forgotPasswordUrl: string | null;
  signInTitle: string;
  template: BasePageTemplateConfig;
}

export class GuestPage {
  private static config: GuestPageConfig | null = null;

  public static async render(
    presentationId: number,
    objectHandle: string,
    parameters: string | null,
    appServerState: State | null = null
  ): Promise<void> {
    if (GuestPage.config === null) {
      throw new Error(
        "Guest page config must be set before the guest page may be " +
          "rendered"
      );
    }

    const templateResponse = await PresentationService.getGuestPageData(
      appServerState
    );
    AppServer.setState(templateResponse.appServerState);
    PaneDataStore.loadResponse(templateResponse.paneDataByDataId);

    const templateConfig = GuestPage.config.template;

    const dataResponse = await PresentationService.getPresentationData(
      presentationId,
      objectHandle,
      parameters
    );

    if (dataResponse.shouldRedirectHome) {
      AppServer.setState(dataResponse.appServerState);
      Sys.setHash("");

      return;
    }

    // FUTURE
    // The presentation is first rendered without any children to give the
    // models a chance to be cleared without the new presentation reacting
    // to their on-change events.
    //
    // The components should be made tolerant of missing data, that way the
    // on-change events fired by changing the data will be benign. Then we
    // can take full advantage of React's architecture for making minimum
    // changes to the DOM (this approach effectively removes the entire
    // presentation from the DOM and re-renders the new one, which is not as
    // efficient as it could be).
    const navigatingToSamePresentation =
      Presentation.currentPresentationId &&
      Presentation.currentPresentationId === dataResponse.layoutId!;
    // Avoiding clearing the presentation if navigating to the same
    // presentation helps this to be slightly more efficient.
    if (!navigatingToSamePresentation) {
      ReactDOM.render(
        <BasePageTemplate
          backgroundImageUrl={templateConfig.backgroundImageUrl}
          footer={templateConfig.footer}
          header={templateConfig.header}
        />,
        document.getElementById("root")
      );
    }

    const presentation = await PresentationService.getPresentationConfig(
      dataResponse.layoutId!
    );

    AppServer.setState(dataResponse.appServerState);
    PaneDataStore.loadResponse(dataResponse.paneDataByDataId!);

    ReactDOM.render(
      <BasePageTemplate
        backgroundImageUrl={templateConfig.backgroundImageUrl}
        footer={templateConfig.footer}
        header={templateConfig.header}
        paperWidth={presentation.paperWidth}
      >
        <Panel presentationId={presentation.layoutId}>
          <PresentationLayout config={presentation} />
        </Panel>
      </BasePageTemplate>,
      document.getElementById("root")
    );

    Routing.setDocumentTitle(
      dataResponse.objectTitle,
      presentation.objectDefDescription
    );
  }

  public static async renderSearch(
    presentationId: number,
    autoExecute: boolean,
    queryStringValues: string | null
  ): Promise<void> {
    if (GuestPage.config === null) {
      throw new Error(
        "Guest page config must be set before the guest page may be " +
          "rendered"
      );
    }

    const templateResponse = await PresentationService.getGuestPageData(null);
    AppServer.setState(templateResponse.appServerState);
    PaneDataStore.loadResponse(templateResponse.paneDataByDataId);

    const templateConfig = GuestPage.config.template;

    // FUTURE
    // The presentation is first rendered without any children to give the
    // models a chance to be cleared without the new presentation reacting
    // to their on-change events.
    //
    // The components should be made tolerant of missing data, that way the
    // on-change events fired by changing the data will be benign. Then we
    // can take full advantage of React's architecture for making minimum
    // changes to the DOM (this approach effectively removes the entire
    // presentation from the DOM and re-renders the new one, which is not as
    // efficient as it could be).
    const navigatingToSamePresentation =
      Presentation.currentPresentationId &&
      Presentation.currentPresentationId === presentationId;
    // Avoiding clearing the presentation if navigating to the same
    // presentation helps this to be slightly more efficient.
    if (!navigatingToSamePresentation) {
      ReactDOM.render(
        <BasePageTemplate
          backgroundImageUrl={templateConfig.backgroundImageUrl}
          footer={templateConfig.footer}
          header={templateConfig.header}
        />,
        document.getElementById("root")
      );
    }

    const presentation: SearchPresentationConfig =
      await SearchService.getConfig(presentationId);

    const dataResponse = await SearchService.getData(
      presentationId,
      queryStringValues
    );

    AppServer.setState(dataResponse.appServerState);
    PaneDataStore.loadCriteriaPane(
      dataResponse.criteriaPaneData,
      presentation.criteriaWidgetNames
    );
    PaneDataStore.loadResponse(dataResponse.paneDataByDataId);

    ReactDOM.render(
      <BasePageTemplate
        backgroundImageUrl={templateConfig.backgroundImageUrl}
        footer={templateConfig.footer}
        header={templateConfig.header}
      >
        <Panel presentationId={presentation.layoutId}>
          <SearchPresentation
            config={presentation}
            autoExecute={autoExecute}
            queryStringValues={queryStringValues}
          />
        </Panel>
      </BasePageTemplate>,
      document.getElementById("root")
    );

    Routing.setDocumentTitle(presentation.description);
    Sys.ignoreChanges = true;
  }

  public static async renderSignIn(): Promise<void> {
    if (GuestPage.config === null) {
      throw new Error(
        "Guest page config must be set before the sign-in page may be " +
          "rendered"
      );
    }

    const response = await PresentationService.getGuestPageData(null);

    AppServer.setState(response.appServerState);
    PaneDataStore.loadResponse(response.paneDataByDataId);

    const templateConfig = GuestPage.config.template;
    ReactDOM.render(
      <BasePageTemplate
        backgroundImageUrl={templateConfig.backgroundImageUrl}
        footer={templateConfig.footer}
        header={templateConfig.header}
        paperWidth={{
          lg: 6,
          md: 6,
          sm: 6,
          xl: 6,
          xs: 4,
        }}
      >
        <Logon
          autoFocus
          forgotPasswordUrl={GuestPage.config.forgotPasswordUrl}
          title={GuestPage.config.signInTitle}
        />
      </BasePageTemplate>,
      document.getElementById("root")
    );
  }

  public static setConfig(config: GuestPageConfig) {
    if (GuestPage.config !== null) {
      throw new Error("Guest page config is already set");
    }

    GuestPage.config = config;

    const templateConfig = GuestPage.config.template;
    const allDataIds = [
      ...templateConfig.footer.layout.dataIds,
      ...templateConfig.header.layout.dataIds,
    ];

    for (const dataId of allDataIds) {
      new TrackableCollection("PaneRow", dataId);
    }
  }
}

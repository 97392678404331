import { Theme, useMediaQuery } from "@mui/material";
import { observer } from "mobx-react";
import * as React from "react";
import MenuItem from "../../coreui/MenuItem";
import PaneRow from "../../models/PaneRow";
import { AccessLevel } from "../AccessLevel";
import { ActionButtonProps, ActionButtonRuntimeProps } from "../ActionButton";
import ApiButton from "../ApiButton";
import { MenuItemProps } from "../MenuItem";
import { WizardControl as WizardControlBase } from "../WizardControl";
import { StepProps } from "../WizardStepPane";

interface Props extends ActionButtonProps {}

export const WizardNextButtonMenuItem = (props: MenuItemProps): JSX.Element => {
  const { config, runtime, ...otherProps } = props;
  const configProps = config as unknown as Props;
  const runtimeProps = runtime as ActionButtonRuntimeProps;

  const onClick = () => {
    if (props.runtime.accessLevel >= AccessLevel.actionable) {
      WizardControlBase.gotoNextStep();
      configProps.propagated.onItemClicked!();
    }
  };

  return (
    <MenuItem
      disabled={props.runtime.accessLevel === AccessLevel.disabled}
      iconName={configProps.iconName}
      indent={props.config.propagated ? props.config.propagated.indent : 0}
      onClick={onClick}
      {...otherProps}
    >
      {runtimeProps.label}
    </MenuItem>
  );
};

export const WizardNextButton = observer((props: Props): JSX.Element | null => {
  const isIconOnly = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only("xs")
  );

  const row = PaneRow.get(props.dataId);
  if (!row) {
    return null;
  }

  const wizardProperties = WizardControlBase.getWidgetProperties();
  const last: number = wizardProperties.steps.length - 1;
  const selectedStep: number = wizardProperties.steps.findIndex(
    (s) => s.paneUseKey === wizardProperties.selectedPaneUseKey
  )!;

  if (selectedStep >= last) {
    return null;
  }

  const widget = row.getWidgetT<null, ActionButtonRuntimeProps>(props.name);

  if (widget.properties.accessLevel === AccessLevel.hidden) {
    return null;
  }

  let label: string = widget.properties.label;
  let iconName: string = "fas fa-arrow-right";

  const count: number = WizardControlBase.getStepCount();
  const step: number = WizardControlBase.getStepNumber(selectedStep);
  const steps: StepProps[] | null = WizardControlBase.getSteps();

  if (step === count && steps) {
    label = steps[last].label;
    iconName = "fas fa-check";
  }

  return (
    <ApiButton
      alternateText={widget.properties.alternateText}
      buttonColor={props.buttonColor}
      disabled={widget.properties.accessLevel === AccessLevel.disabled}
      disabledHelpText={props.disabledHelpText}
      disabledHelpVisible={widget.properties.showDisabledHelp}
      endIcon={!isIconOnly ? iconName : undefined}
      // Always use an arrow icon if icon-only because there is no label
      // to indicate that the button will navigate to the finish step.
      iconName={isIconOnly ? "fas fa-arrow-right" : undefined}
      isIconOnly={isIconOnly}
      label={label}
      onClick={() => WizardControlBase.gotoNextStep()}
      size={props.size}
    />
  );
});

import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import * as React from "react";
import { ForegroundColorType } from "./ForegroundColorType";
import { MustangTheme } from "./MustangTheme";

interface Props {
  footer?: true;
  foreground?: ForegroundColorType;
  header?: true;
}

export const ThemeProvider = (
  props: React.PropsWithChildren<Props>
): JSX.Element => (
  <MuiThemeProvider
    theme={MustangTheme.create(props.foreground, props.header || props.footer)}
  >
    {props.children}
  </MuiThemeProvider>
);

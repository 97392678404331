import { Breakpoint } from "@mui/material";
import { observer } from "mobx-react";
import * as React from "react";
import FocusManager from "../core/FocusManager";
import { useWidth } from "../core/Responsive";
import PaneRow from "../models/PaneRow";
import { RuntimeProperties as HeadingRuntimeProperties } from "../mustangui/GroupHeading";
import { Layout, LayoutConfig, Props as LayoutProps } from "./Layout";

interface HeadingInfo {
  breakPoint: string;
  dataId: string;
  name: string;
}

export interface PresentationConfig extends LayoutConfig {
  objectDefDescription: string;
  paperWidth: { [key in Breakpoint]: 3 | 4 | 6 | 8 | null };
  titleHeadings: HeadingInfo[];
}

interface Props extends LayoutProps {
  config: PresentationConfig;
  isHome?: boolean;
}

export const Presentation = observer((props: Props): JSX.Element => {
  const width = useWidth();
  const formRef = React.useRef<HTMLFormElement>(null);

  const focusFirstHeading = (): void => {
    if (formRef.current === null) {
      // This should never happen, but if it does it is not worth having the
      // application crash over it.
      console.warn(
        `No form element existed when attempting to focus the page heading`
      );
    } else {
      FocusManager.grabFocusForChild(
        formRef.current,
        [
          'h1[tabindex="-1"]',
          'h2[tabindex="-1"]',
          'h3[tabindex="-1"]',
          'h4[tabindex="-1"]',
          'h5[tabindex="-1"]',
          'h6[tabindex="-1"]',
        ].join(", ")
      );
    }
  };

  React.useEffect((): void => {
    if (!props.isHome) {
      // The setTimeout appears to be necessary to ensure the runtime data is
      // loaded and everything can render appropriately. Perhaps the mobx
      // observables need another frame to catch up?
      setTimeout(() => focusFirstHeading());
    }
  }, [props.isHome]);

  const searchWidth = width === "xl" ? "lg" : width;
  const headingInfo = props.config.titleHeadings.find(
    (h) => h.breakPoint === searchWidth
  );

  let headingText: string | undefined = undefined;
  if (headingInfo) {
    const row = PaneRow.get(headingInfo.dataId)!;
    const widget = row?.getWidgetT<null, HeadingRuntimeProperties>(
      headingInfo.name
    );
    headingText = widget?.properties.headingText;
  }

  return (
    <form aria-label={headingText} ref={formRef}>
      <Layout config={props.config} />
    </form>
  );
});

import {
  Fab,
  FabProps,
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  styled,
} from "@mui/material";
import * as React from "react";
import Icon from "./Icon";

export interface ButtonProps extends MuiButtonProps {
  customSize?: "tiny";
  endIcon?: string;
  fab?: boolean;
  fullWidth?: boolean;
  icon?: string;
  label?: string;
  target?: string;
}

const FabIcon = styled(Icon)({
  width: "100%",
});

const Label = styled("span")({
  overflow: "hidden",
  textOverflow: "ellipsis",
});

interface RootButtonProps {
  fullWidth?: boolean;
}

const RootButton = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== "fullWidth",
})<RootButtonProps>(({ fullWidth }) => ({
  gap: "0.4em",
  ...(fullWidth && {
    width: "100%",
  }),
}));

interface RootFabProps {
  tiny?: boolean;
}

const RootFab = styled(Fab, {
  shouldForwardProp: (prop) => prop !== "tiny",
})<RootFabProps>(({ tiny }) =>
  tiny
    ? {
        fontSize: 10,
        height: 16,
        minHeight: 16,
        minWidth: 16,
        width: 16,
      }
    : null
);

const Button = React.forwardRef(
  (
    props: ButtonProps,
    ref: React.ForwardedRef<HTMLButtonElement>
  ): JSX.Element => {
    const {
      children,
      color = "default",
      customSize,
      endIcon,
      fab,
      fullWidth,
      icon,
      label,
      variant = "contained",
      ...otherProps
    } = props;

    let labelElement: React.ReactNode = null;
    if (label) {
      labelElement = <Label>{label}</Label>;
    } else if (children && typeof children === "string") {
      labelElement = <Label>{children}</Label>;
    } else {
      labelElement = children;
    }

    const buttonChild = (
      <React.Fragment>
        {icon ? (
          labelElement ? (
            <Icon fontSize="inherit" icon={icon} />
          ) : (
            <FabIcon icon={icon} />
          )
        ) : null}
        {labelElement}
        {endIcon ? <Icon icon={endIcon} /> : null}
      </React.Fragment>
    );

    if (fab || (icon && !labelElement)) {
      const { size, ...fabProps } = otherProps as FabProps;

      return (
        <RootFab
          color={color}
          ref={ref}
          size={customSize === "tiny" ? "small" : size}
          tiny={customSize === "tiny"}
          {...fabProps}
        >
          {buttonChild}
        </RootFab>
      );
    }

    return (
      <RootButton
        color={color === "default" && variant === "text" ? "dark" : color}
        fullWidth={fullWidth}
        ref={ref}
        variant={variant}
        {...otherProps}
      >
        {buttonChild}
      </RootButton>
    );
  }
);

export default Button;

import { Breakpoint } from "@mui/material";
import { observer } from "mobx-react";
import * as React from "react";
import { useWidth, WidthProps } from "../core/Responsive";
import { CheckBoxGroup } from "../coreui/CheckBoxGroup";
import { CheckBoxGroupOption } from "../coreui/CheckBoxGroupOption";
import PaneRow from "../models/PaneRow";
import ErrorsStore from "../stores/ErrorsStore";
import { AccessLevel } from "./AccessLevel";

interface ConfigProperties {
  dataId: string;
  helperText: string;
  label: string;
  name: string;
  options: CheckBoxGroupOption[];
  orientationByBreakPoint: { [key in Breakpoint]: "horizontal" | "vertical" };
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
}

export class DomainCheckBoxCriteria extends React.Component<
  ConfigProperties & WidthProps
> {
  public static readonly widgetTypeId: number = 90;

  public static clear(widgetName: string, row: PaneRow): void {
    const widget = row.getWidgetT<string[], RuntimeProperties>(widgetName);
    widget.setValue([]);
  }

  public static isEntered(widgetName: string, row: PaneRow): boolean {
    const widget = row.getWidgetT<string[], RuntimeProperties>(widgetName);
    return widget.value.length > 0;
  }

  private onOptionChange = (
    option: CheckBoxGroupOption,
    checked: boolean
  ): void => {
    ErrorsStore.clearBusinessErrorsForWidget(
      this.props.dataId,
      this.props.name
    );

    const row = PaneRow.get(this.props.dataId)!;
    const widget = row.getWidgetT<string[], RuntimeProperties>(this.props.name);

    const values = [...widget.value];
    if (checked) {
      values.push(option.value);
    } else {
      const index: number = values.indexOf(option.value);
      values.splice(index, 1);
    }

    widget.setValue(values);
  };

  public render(): React.ReactNode {
    const row = PaneRow.get(this.props.dataId);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<string[], RuntimeProperties>(this.props.name);

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    return (
      <CheckBoxGroup
        getErrors={() => widget.properties.businessErrors}
        helperText={this.props.helperText}
        label={this.props.label}
        onOptionChange={this.onOptionChange}
        options={this.props.options}
        orientation={this.props.orientationByBreakPoint[this.props.width]}
        selectedValues={widget.value}
      />
    );
  }
}

// FUTURE
// This wrapper component was created to avoid the scope of converting this
// component to a hooks component during the MUI 5 upgrade. When the legacy
// component is converted to a hooks component, this wrapper can be removed and
// hooks it calls can be called by the converted component directly.
const Observer = observer(DomainCheckBoxCriteria);
export default function Wrapped(props: ConfigProperties): JSX.Element {
  const width = useWidth();
  return <Observer {...props} width={width} />;
}

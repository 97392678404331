import { action, autorun, Lambda, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { DialogChildProps } from "../config";
import ComboBoxOption from "../coreui/ComboBoxOption";
import Select from "../coreui/Select";
import { TableVerticalLayoutProps } from "../coreui/Table";
import TextField from "../coreui/TextField";
import PaneRow from "../models/PaneRow";
import RoundTripService from "../services/RoundTripService";
import ErrorsStore from "../stores/ErrorsStore";
import SubPaneControlStore from "../stores/SubPaneControlStore";
import { AccessLevel } from "./AccessLevel";

interface ConfigProperties {
  controlKey: string | null;
  controlledPaneKeysByValue: object | null;
  dataId: string;
  disabledHelpText: string;
  helperText: string;
  isPaneController: boolean;
  label: string;
  name: string;
  options: ComboBoxOption[];
  propagated?: DialogChildProps & TableVerticalLayoutProps;
  roundTripOnChange: boolean;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  businessErrors: string[];
  selectedDisplayValue: string;
  selectedValue: string | null;
  showAsMandatory: boolean;
  showDisabledHelp: boolean;
}

export class DomainComboBox extends React.Component<ConfigProperties> {
  private config: ConfigProperties;
  private dataId: string = "";
  private dialogRowKey?: string;
  private name: string = "";
  private rowKey?: string;

  protected disposeObserve: Lambda;

  public static getOptions(
    propOptions: ComboBoxOption[],
    selectedValue: string | null,
    selectedDisplayValue: string
  ): ComboBoxOption[] {
    const options = [...propOptions];

    if (selectedValue) {
      // Add the initially selected value as a historic option if is not
      // among the current candidates
      const optionInList = options.find((o) => o.value === selectedValue);

      if (optionInList === undefined) {
        const historicOption: ComboBoxOption = {
          display: selectedDisplayValue,
          historic: true,
          value: selectedValue,
        };

        options.push(historicOption);
      }
    }

    return options;
  }

  public constructor(props: ConfigProperties) {
    super(props);

    makeObservable<
      DomainComboBox,
      "dataId" | "name" | "onValueChange" | "syncDerivedWithProps"
    >(this, {
      dataId: observable,
      name: observable,
      onValueChange: action,
      syncDerivedWithProps: action,
    });

    this.syncDerivedWithProps();
  }

  private onValueChange = (value: ComboBoxOption | null) => {
    const row = PaneRow.get(this.dataId, this.rowKey);
    const widget = row!.getWidgetT<string | null, RuntimeProperties>(this.name);

    const oldValue = widget.value;

    ErrorsStore.clearBusinessErrorsForWidget(this.dataId, this.name);
    widget.setValue(value?.value || null);

    if (!this.config.roundTripOnChange) {
      return;
    }

    RoundTripService.standardRoundTrip(
      "DomainComboBox/OnChange",
      { dataId: this.dataId, name: this.name },
      {
        dialogRowKey: this.dialogRowKey,
      }
    ).catch((reason) => {
      if (reason) {
        throw reason;
      } else {
        // If the round trip fails, undo the value change.
        widget.setValue(oldValue);
      }
    });
  };

  private syncDerivedWithProps(): void {
    this.dataId = this.props.dataId;
    this.name = this.props.name;
    this.config = { ...this.props };
    this.dialogRowKey =
      this.props.propagated?.parentDialog?.rowKey || undefined;

    this.rowKey = undefined;
    if (this.props.propagated?.parentTable) {
      // Widget is rendered in the vertical layout of a grid.
      this.rowKey = this.props.propagated.rowKey;
    }
  }

  protected showSubPane(): void {
    if (!this.config.isPaneController) {
      return;
    }

    const row = PaneRow.get(this.dataId, this.rowKey);
    const widget = row!.getWidgetT<string | null, RuntimeProperties>(this.name);

    const controlledPaneKey =
      this.config.controlledPaneKeysByValue![widget.value || ""];
    const controlKey: string = `${this.config.controlKey}_${this.rowKey || ""}`;

    if (controlledPaneKey) {
      SubPaneControlStore.showPane(controlKey, controlledPaneKey);
    } else {
      SubPaneControlStore.hidePane(controlKey);
    }
  }

  public componentDidMount(): void {
    this.disposeObserve = autorun(() => {
      this.showSubPane();
    });
  }

  public componentDidUpdate(): void {
    this.syncDerivedWithProps();
  }

  public componentWillUnmount(): void {
    this.disposeObserve();
  }

  public render(): React.ReactNode {
    const row = PaneRow.get(this.dataId, this.rowKey);
    if (!row) {
      return null;
    }

    const widget = row.getWidgetT<string | null, RuntimeProperties>(this.name);

    if (widget.properties.accessLevel === AccessLevel.hidden) {
      return null;
    }

    if (widget.properties.accessLevel === AccessLevel.disabled) {
      return (
        <TextField
          disabled={true}
          disabledHelpText={
            widget.properties.showDisabledHelp
              ? this.props.disabledHelpText
              : undefined
          }
          label={this.props.label}
          name={this.props.name}
          variant="filled"
        />
      );
    }

    const options: ComboBoxOption[] = DomainComboBox.getOptions(
      this.props.options,
      widget.properties.selectedValue,
      widget.properties.selectedDisplayValue
    );

    return (
      <Select
        getErrors={() => widget.properties.businessErrors}
        helperText={this.props.helperText}
        label={this.props.label}
        onValueChange={this.onValueChange}
        options={options}
        readOnly={widget.properties.accessLevel === AccessLevel.readOnly}
        showAsMandatory={widget.properties.showAsMandatory}
        value={widget.value}
      />
    );
  }
}

export default observer(DomainComboBox);

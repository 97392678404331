import { ButtonBase, styled, Theme } from "@mui/material";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import * as React from "react";
import Localization from "../core/Localization";
import Button from "./Button";

interface Props {
  alternateText: string;
  disableFocusRipple?: boolean;
  fit: "cover" | "contain";
  height: number;
  href: string;
  imgSrc: string;
  linkRef?: (r: HTMLElement | null) => void;
  tabIndex?: number;
  width: number;
}

const styles = (theme: Theme) =>
  createStyles({
    image: {
      height: "100%",
      width: "100%",
    },
    ripple: {
      height: "calc(100% + 30px)",
      margin: "-15px",
      width: "calc(100% + 30px)",
    },
    root: {
      display: "inline-block",
      outline: "none",
      overflow: "hidden",
      position: "relative",
      [theme.breakpoints.only("xs")]: {
        maxWidth: "100%",
      },
    },
  });

const Link = styled(Button)({
  bottom: 4,
  pointerEvents: "none",
  position: "absolute",
  right: 4,
});

export class ImageWithLink extends React.PureComponent<
  Props & WithStyles<typeof styles>
> {
  public render() {
    return (
      <ButtonBase
        className={this.props.classes.root}
        focusRipple={!this.props.disableFocusRipple}
        href={this.props.href}
        ref={(r) =>
          this.props.linkRef ? this.props.linkRef(r as HTMLElement) : null
        }
        style={{ height: this.props.height }}
        tabIndex={this.props.tabIndex}
        target="_blank"
        TouchRippleProps={{ className: this.props.classes.ripple }}
      >
        <img
          className={this.props.classes.image}
          src={this.props.imgSrc}
          style={{
            maxWidth: "100%",
            objectFit: this.props.fit,
            width: this.props.width,
          }}
        />
        <Link
          aria-label={Localization.getBuiltInMessage(
            "DocumentDownload.downloadButtonAlternateText",
            { alternateText: this.props.alternateText }
          )}
          customSize="tiny"
          icon="fas fa-download"
          tabIndex={-1}
        />
      </ButtonBase>
    );
  }
}

export default withStyles(styles)(ImageWithLink);

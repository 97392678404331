import * as React from "react";
import ProjectGridContainerItem, {
  ConfigProperties as ItemConfig,
} from "./ProjectGridContainerItem";
import { ProjectGridControlChildProps } from "./ProjectGridControl";

interface ConfigProperties {
  item: ItemConfig;
  propagated: ProjectGridControlChildProps;
}

export default function ProjectGridContainer(
  props: ConfigProperties
): JSX.Element {
  return (
    <ProjectGridContainerItem {...props.item} propagated={props.propagated} />
  );
}

import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  styled,
} from "@mui/material";
import * as React from "react";
import { useWidth } from "../core/Responsive";
import MaskingStore from "../stores/MaskingStore";
import { getPanelMeasurements, PanelMeasurements } from "../theme";

export interface BreakPointColumn {
  columns: number;
  size: "lg" | "md" | "sm" | "xs";
}

interface Props extends MuiDialogProps {
  breakPointColumns?: BreakPointColumn[];
}

const ResponsiveDialog = styled(MuiDialog, {
  shouldForwardProp: (prop) => prop !== "breakPointColumns",
})<Props>(
  ({ theme, fullScreen }) => ({
    "& .MuiDialog-paper": {
      [theme.breakpoints.up("sm")]: {
        maxWidth: theme.dialog.maxWidths.sm,
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: theme.dialog.maxWidths.md,
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: theme.dialog.maxWidths.lg,
      },
      ...(!fullScreen && {
        [theme.breakpoints.down("sm")]: {
          margin: 16,
        },
      }),
      margin: 0,
    },
  }),
  ({ theme, breakPointColumns }) =>
    breakPointColumns && {
      "& .MuiDialog-paper": {
        ...breakPointColumns.reduce((result, bpc) => {
          const minWidth = 345;
          const measurements: PanelMeasurements = getPanelMeasurements(
            theme,
            bpc.size
          );

          const columnsWidth = bpc.columns * measurements.columnWidth;
          const guttersWidth = (bpc.columns + 1) * measurements.gutterWidth;
          const width = columnsWidth + guttersWidth;

          result[theme.breakpoints.up(bpc.size)] = {
            width: width < minWidth ? minWidth : width,
          };

          return result;
        }, {}),
      },
    }
);

export default function Dialog(props: Props): JSX.Element {
  const { breakPointColumns, TransitionProps, ...otherProps } = props;
  const { onEntering, onExited, ...dialogTransitionProps } =
    TransitionProps || {};

  const isMasked = React.useRef<boolean>(false);
  const width = useWidth();

  const trackClose = React.useCallback(() => {
    if (isMasked.current) {
      MaskingStore.maskClosed();
      isMasked.current = false;
    }
  }, []);

  const forceClose = React.useCallback(() => {
    if (!props.open) {
      return;
    }

    if (props.onClose) {
      props.onClose({ forced: true }, "escapeKeyDown");
    }
    trackClose();
  }, [props.onClose, props.open]);

  const trackOpen = React.useCallback(() => {
    MaskingStore.maskOpened();
    isMasked.current = true;
  }, []);

  React.useEffect(() => {
    return () => {
      if (isMasked.current) {
        MaskingStore.maskClosed();
        isMasked.current = false;
      }
      forceClose();
    };
  }, []);

  return (
    <ResponsiveDialog
      fullScreen={width === "xs"}
      fullWidth={!!breakPointColumns}
      maxWidth={false}
      TransitionProps={{
        onEntering: (node: HTMLElement, isAppearing: boolean) => {
          trackOpen();
          if (onEntering) {
            onEntering(node, isAppearing);
          }
        },
        onExited: (node: HTMLElement) => {
          trackClose();
          if (onExited) {
            onExited(node);
          }
        },
        ...dialogTransitionProps,
      }}
      {...otherProps}
    />
  );
}

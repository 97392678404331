import {
  CellClassParams,
  ColDef,
  Column,
  ColumnFunctionCallbackParams,
  EditableCallbackParams,
  GetQuickFilterTextParams,
  HeaderClassParams,
  IRowNode,
  NestedFieldPaths,
  ValueGetterParams,
  ValueSetterParams,
} from "ag-grid-community";
import * as React from "react";
import { EditMask } from "../../coreui/EditMask";
import Icon from "../../coreui/Icon";
import { TableChildProps, TableContext } from "../../coreui/Table";
import PaneRow, { RuntimeWidget } from "../../models/PaneRow";
import DocumentService from "../../services/DocumentService";
import { AccessLevel } from "../AccessLevel";
import { DateEdit } from "../DateEdit";
import { FilterStandardization } from "../FilterStandardization";
import { NumericEdit } from "../NumericEdit";
import CheckBoxColumn, {
  CheckBoxColumn as CheckBoxColumnBase,
} from "./CheckBoxColumn";
import ComponentTypeDisplayColumn from "./ComponentTypeDisplayColumn";
import DataImageDisplayColumn, {
  DataImageDisplayColumn as DataImageDisplayColumnBase,
} from "./DataImageDisplayColumn";
import DataLinkColumn, {
  DataLinkColumn as DataLinkColumnBase,
} from "./DataLinkColumn";
import DateEditColumn from "./DateEditColumn";
import DateEditColumnEdit from "./DateEditColumnEdit";
import DomainComboBoxColumnDisplay from "./DomainComboBoxColumnDisplay";
import DomainComboBoxColumnEdit from "./DomainComboBoxColumnEdit";
import HierarchyDisplayColumn, {
  HierarchyDisplayHeader,
} from "./HierarchyDisplayColumn";
import IconDisplayColumn from "./IconDisplayColumn";
import ManualLinkColumn, {
  ManualLinkColumn as ManualLinkColumnBase,
} from "./ManualLinkColumn";
import { MLTextEditColumn } from "./MLTextEditColumn";
import MLTextEditColumnEdit from "./MLTextEditColumnEdit";
import NumericEditColumn from "./NumericEditColumn";
import NumericEditColumnEdit from "./NumericEditColumnEdit";
import RelationshipComboBoxColumnDisplay from "./RelationshipComboBoxColumnDisplay";
import RelationshipComboBoxColumnEdit from "./RelationshipComboBoxColumnEdit";
import { SLTextEditColumn } from "./SLTextEditColumn";
import SLTextEditColumnEdit from "./SLTextEditColumnEdit";
import TextColumn from "./TextColumn";
import { TextDisplayColumn } from "./TextDisplayColumn";

export interface GridColumnConfigProperties {
  flex: boolean;
  header: string;
  lg: boolean;
  md: boolean;
  name: string;
  required: boolean;
  sm: boolean;
  sortDescending: boolean;
  sortEnabled: boolean;
  widgetProps: object;
  widgetType: string;
  width?: number;
  xs: boolean;
}

export interface RenderInlineProperties {
  className?: string;
  column: GridColumnConfigProperties;
  row: PaneRow;
}

export default class GridColumn {
  public static suppressEdit: boolean = false;

  private static comparator(
    column: GridColumnConfigProperties,
    rowA: PaneRow,
    rowB: PaneRow
  ): number {
    const widgetA = rowA.getWidgetT<null, { sortIndex: number }>(column.name);
    const widgetB = rowB.getWidgetT<null, { sortIndex: number }>(column.name);

    return widgetA.properties.sortIndex - widgetB.properties.sortIndex;
  }

  private static getColumnHeaderClass = (
    colDef: ColDef,
    context: TableContext,
    columns: GridColumnConfigProperties[],
    tableDataId: string,
    tableName: string
  ): string => {
    const column: GridColumnConfigProperties | undefined = columns.find(
      (c) => c.name === colDef.colId
    );
    const showRightBorder: boolean = GridColumn.shouldShowRightBorderOnColumn(
      colDef,
      context
    );
    const isRight: boolean =
      !!column && column.widgetProps["justification"] === "Right";

    const paneRow = PaneRow.get(tableDataId)!;
    const table = paneRow.getWidgetT<null, { accessLevel: AccessLevel }>(
      tableName
    );

    const parentGridIsReadOnly =
      table.properties.accessLevel <= AccessLevel.readOnly;
    const required = column && column.required && !parentGridIsReadOnly;
    return (
      (required ? "cx-header-required " : "") +
      (showRightBorder ? "" : "cx-header-last ") +
      (isRight ? "cx-header-right " : "")
    );
  };

  private static isColumnEditable(
    params: ColumnFunctionCallbackParams<PaneRow>,
    column: GridColumnConfigProperties
  ) {
    // Error badge must suppress editing on click.
    if (
      !params.colDef.field ||
      column.widgetType === "CheckBoxColumn" ||
      GridColumn.suppressEdit
    ) {
      GridColumn.suppressEdit = false;

      return false;
    }

    const props = column.widgetProps as { name: string };
    const row: PaneRow = params.data!;
    const widget = row.getWidgetT<null, { accessLevel: AccessLevel }>(
      props.name
    );

    return widget.properties.accessLevel >= AccessLevel.enterable;
  }

  private static shouldShowRightBorderOnColumn(
    colDef: ColDef,
    context: TableContext
  ): boolean {
    let visibleCols = context
      .getColumnApi()
      .getDisplayedCenterColumns()
      .filter((c) => c.getColId() !== "_fillerFixed");

    // If there is a horizontal scroll bar on the table, don't show the
    // filler column border
    if (context.hasHorizontalScrollBar()) {
      visibleCols = visibleCols.filter((c) => c.getColId() !== "_filler");
    }

    const lastColumn: Column | null =
      visibleCols.length > 0 ? visibleCols[visibleCols.length - 1] : null;

    return !!lastColumn && lastColumn.getColId() !== colDef.colId!;
  }

  public static getColumnDef(
    column: GridColumnConfigProperties,
    allColumns: GridColumnConfigProperties[],
    propagated: TableChildProps
  ): ColDef {
    const cellHorizontalPadding: number = 48;
    let editorComponent: object | null = null;
    let headerComponent: object | null = null;
    let minWidth = 60;
    let getQuickFilterText: (params: GetQuickFilterTextParams) => string;
    let rendererComponent: object | null = null;
    let width = column.width;

    switch (column.widgetType) {
      case CheckBoxColumnBase.widgetType:
        rendererComponent = CheckBoxColumn;
        break;
      case ComponentTypeDisplayColumn.widgetType:
        rendererComponent = ComponentTypeDisplayColumn;
        break;
      case DataImageDisplayColumnBase.widgetType:
        rendererComponent = DataImageDisplayColumn;
        minWidth = column.widgetProps["imageWidth"] + cellHorizontalPadding;
        break;
      case DataLinkColumnBase.widgetType:
        rendererComponent = DataLinkColumn;
        break;
      case DateEditColumn.widgetType:
        rendererComponent = DateEditColumn;
        editorComponent = DateEditColumnEdit;
        break;
      case DomainComboBoxColumnDisplay.widgetType:
        rendererComponent = DomainComboBoxColumnDisplay;
        editorComponent = DomainComboBoxColumnEdit;
        break;
      case HierarchyDisplayColumn.widgetType:
        headerComponent = HierarchyDisplayHeader;
        rendererComponent = HierarchyDisplayColumn;
        break;
      case IconDisplayColumn.widgetType:
        rendererComponent = IconDisplayColumn;
        width = 16;
        break;
      case ManualLinkColumnBase.widgetType:
        rendererComponent = ManualLinkColumn;
        break;
      case MLTextEditColumn.widgetType:
        rendererComponent = TextColumn;
        editorComponent = MLTextEditColumnEdit;
        break;
      case NumericEditColumn.widgetType:
        rendererComponent = NumericEditColumn;
        editorComponent = NumericEditColumnEdit;
        break;
      case RelationshipComboBoxColumnDisplay.widgetType:
        rendererComponent = RelationshipComboBoxColumnDisplay;
        editorComponent = RelationshipComboBoxColumnEdit;
        break;
      case SLTextEditColumn.widgetType:
        rendererComponent = TextColumn;
        editorComponent = SLTextEditColumnEdit;
        break;
      case TextDisplayColumn.widgetType:
        rendererComponent = TextColumn;
        break;
      default:
        throw new Error(
          "Unable to create column with widgetType " + `${column.widgetType}.`
        );
    }

    const getVisibleQuickFilterText = (params: GetQuickFilterTextParams) => {
      if (!params.column.isVisible()) {
        return "";
      }

      const row: PaneRow = params.node.data as PaneRow;
      const filterText = GridColumn.getFilterText(column, propagated, row);
      const translatedFilterText =
        FilterStandardization.Standardize(filterText);

      return translatedFilterText;
    };

    const sortingOrder: ("asc" | "desc")[] = column.sortDescending
      ? ["desc", "asc"]
      : ["asc", "desc"];

    if (column.flex && width && width + cellHorizontalPadding > minWidth) {
      minWidth = width + cellHorizontalPadding;
    }

    const result: ColDef<PaneRow> = {
      autoHeight: false,
      cellClass: "cx-cell",
      cellClassRules: {
        "cx-cell-last": (params: CellClassParams<PaneRow>) =>
          !GridColumn.shouldShowRightBorderOnColumn(
            params.colDef,
            params.context as TableContext
          ),
      },
      cellEditor: editorComponent,
      cellEditorParams: { ...column.widgetProps, propagated },
      cellRenderer: rendererComponent,
      cellRendererParams: { ...column.widgetProps, propagated },
      cellStyle: {
        overflow: "visible",
        padding: "0px",
      },
      colId: column.name,
      comparator: (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        valueA: any,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        valueB: any,
        nodeA?: IRowNode<PaneRow>,
        nodeB?: IRowNode<PaneRow>,
        isInverted?: boolean
      ): number => GridColumn.comparator(column, nodeA!.data!, nodeB!.data!),
      editable: (params: EditableCallbackParams<PaneRow>) =>
        GridColumn.isColumnEditable(params, column),
      field: column.name as unknown as NestedFieldPaths<PaneRow>,
      getQuickFilterText: getVisibleQuickFilterText,
      headerClass: (params: HeaderClassParams<PaneRow>) =>
        GridColumn.getColumnHeaderClass(
          params.colDef,
          params.context as TableContext,
          allColumns,
          propagated.parentTable.configProps.dataId,
          propagated.parentTable.configProps.name
        ),
      headerComponent,
      headerComponentParams: {
        // Inject a mandatory indicator in the default ag-grid header template.
        // https://www.ag-grid.com/react-data-grid/column-headers/#header-templates
        template: `
          <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text"></span>
              <span class="cx-mandatory-indicator" aria-hidden="true">&nbsp;*</span>
              <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
              <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
              <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
              <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
            </div>
          </div>`,
      },
      headerName: column.header || "",
      hide: true,
      lockPinned: true,
      minWidth,
      resizable: !column.flex,
      sortable: column.sortEnabled,
      sortingOrder,
      suppressSizeToFit: !column.flex || !width,
      valueGetter: (params: ValueGetterParams<PaneRow>) =>
        params.data!.getWidgetValue(params.colDef.field!),
      valueSetter: (params: ValueSetterParams<PaneRow>) => {
        params.data!.setWidgetValue(params.colDef.field!, params.newValue);
        return true;
      },
      width: width ? width + cellHorizontalPadding : undefined,
    };

    return result;
  }

  public static getColumnsMinRowHeight(
    columns: GridColumnConfigProperties[]
  ): number {
    const cellVerticalPadding = 24;

    let rowHeight = 48;

    for (const column of columns) {
      let colRowHeight = 0;
      switch (column.widgetType) {
        case "DataImageDisplayColumn":
          colRowHeight =
            column.widgetProps["imageHeight"] + cellVerticalPadding;
          break;
        default:
          colRowHeight = 0;
      }

      if (colRowHeight > rowHeight) {
        rowHeight = colRowHeight;
      }
    }

    return rowHeight;
  }

  public static getFilterText(
    column: GridColumnConfigProperties,
    propagated: TableChildProps,
    row: PaneRow
  ): string {
    switch (column.widgetType) {
      case CheckBoxColumnBase.widgetType:
        return CheckBoxColumnBase.getFilterText(column, propagated, row);
      case ComponentTypeDisplayColumn.widgetType:
        return ComponentTypeDisplayColumn.getFilterText(
          column,
          propagated,
          row
        );
      case DataImageDisplayColumnBase.widgetType:
        return DataImageDisplayColumnBase.getFilterText(
          column,
          propagated,
          row
        );
      case DataLinkColumnBase.widgetType:
        return DataLinkColumnBase.getFilterText(column, propagated, row);
      case DateEditColumn.widgetType:
        return DateEditColumn.getFilterText(column, propagated, row);
      case DomainComboBoxColumnDisplay.widgetType:
        return DomainComboBoxColumnDisplay.getFilterText(
          column,
          propagated,
          row
        );
      case HierarchyDisplayColumn.widgetType:
        return HierarchyDisplayColumn.getFilterText(column, propagated, row);
      case IconDisplayColumn.widgetType:
        return IconDisplayColumn.getFilterText(column, propagated, row);
      case ManualLinkColumnBase.widgetType:
        return ManualLinkColumnBase.getFilterText(column, propagated, row);
      case MLTextEditColumn.widgetType:
        return MLTextEditColumn.getFilterText(column, propagated, row);
      case NumericEditColumn.widgetType:
        return NumericEditColumn.getFilterText(column, propagated, row);
      case RelationshipComboBoxColumnDisplay.widgetType:
        return RelationshipComboBoxColumnDisplay.getFilterText(
          column,
          propagated,
          row
        );
      case SLTextEditColumn.widgetType:
        return SLTextEditColumn.getFilterText(column, propagated, row);
      case TextDisplayColumn.widgetType:
        return TextDisplayColumn.getFilterText(column, propagated, row);
      default:
        throw new Error(
          "GridColumn.getFilterText does not support " +
            `widgetType ${column.widgetType}`
        );
    }
  }

  public static isColumnFlex(
    columns: GridColumnConfigProperties[],
    colId: string
  ): boolean {
    const column = columns.find((c) => c.name === colId);
    return column !== undefined && column.flex;
  }

  public static getPrintValue(
    column: GridColumnConfigProperties,
    widget: RuntimeWidget,
    paneRow: PaneRow
  ) {
    const runtimeProperties = widget.properties;

    let value =
      widget.value || runtimeProperties.label || runtimeProperties.anchorText;

    switch (column.widgetType) {
      case CheckBoxColumnBase.widgetType:
        value = (
          <Icon
            icon={value ? "fas fa-check" : ""}
            style={{
              fontSize: "24px",
              margin: "0 auto",
            }}
          />
        );
        break;
      case ComponentTypeDisplayColumn.widgetType:
        if (runtimeProperties.iconName) {
          value = (
            <React.Fragment>
              <Icon
                icon={runtimeProperties.iconName}
                style={{
                  marginRight: ".4em",
                }}
              />
              <div>{runtimeProperties.label}</div>
            </React.Fragment>
          );
        }
        break;
      case DataImageDisplayColumnBase.widgetType:
        value = (
          <img
            aria-label={runtimeProperties.alternateText}
            src={DocumentService.getThumbnailUrl(
              runtimeProperties.documentHandle,
              runtimeProperties.pendingDocumentId,
              runtimeProperties.pendingThumbnailId,
              column.widgetProps["thumbnailType"],
              runtimeProperties.downloadToken
            )}
            style={{
              height: column.widgetProps["imageHeight"],
              objectFit: "cover",
              width: column.widgetProps["imageWidth"],
            }}
          />
        );
        break;
      case DateEditColumn.widgetType:
        value = DateEdit.formatValue(
          DateEditColumnEdit.getCurrentValueParsed(false, value),
          true
        );
        break;
      case HierarchyDisplayColumn.widgetType:
        if (runtimeProperties.iconName) {
          value = (
            <React.Fragment>
              <Icon
                icon={runtimeProperties.iconName}
                style={{
                  marginRight: ".4em",
                }}
              />
              <div>{runtimeProperties.label}</div>
            </React.Fragment>
          );
        }

        value = (
          <div
            style={{
              alignItems: "center",
              display: "flex",
              marginLeft: 24 * (paneRow.hierarchyLevel! - 1),
            }}
          >
            {value}
          </div>
        );
        break;
      case IconDisplayColumn.widgetType:
        value = <Icon icon={runtimeProperties.iconName} />;
        break;
      case NumericEditColumn.widgetType:
        value = NumericEdit.formatNumericValue(
          value ? parseFloat(value) : null,
          true,
          column.widgetProps["scale"]
        );
        break;
      case RelationshipComboBoxColumnDisplay.widgetType:
        value = runtimeProperties.selectedDisplayValue;
        break;
      case SLTextEditColumn.widgetType:
        if (column.widgetProps["editMask"] && value) {
          value = EditMask.formatValue(column.widgetProps["editMask"], value);
        }
        break;
      case TextDisplayColumn.widgetType:
        if (runtimeProperties.bodyText) {
          value = runtimeProperties.bodyText.join(" ");
        }
        break;
      default:
        break;
    }

    if (value && typeof value === "string") {
      value = value.substr(0, 1000);
    }

    return value;
  }

  public static isColumnVisible(
    columns: GridColumnConfigProperties[],
    colId: string,
    breakpoint: string
  ): boolean {
    const column = columns.find((c) => c.name === colId);
    return column !== undefined && column[breakpoint];
  }

  public static renderInline(
    props: RenderInlineProperties
  ): JSX.Element | null {
    switch (props.column.widgetType) {
      case CheckBoxColumnBase.widgetType:
        return CheckBoxColumnBase.renderInline(props);
      case DataLinkColumnBase.widgetType:
        return DataLinkColumnBase.renderInline(props);
      case DateEditColumn.widgetType:
        return DateEditColumn.renderInline(props);
      case DomainComboBoxColumnDisplay.widgetType:
        return DomainComboBoxColumnDisplay.renderInline(props);
      case HierarchyDisplayColumn.widgetType:
        return HierarchyDisplayColumn.renderInline(props);
      case ManualLinkColumnBase.widgetType:
        return ManualLinkColumnBase.renderInline(props);
      case NumericEditColumn.widgetType:
        return NumericEditColumn.renderInline(props);
      case SLTextEditColumn.widgetType:
        return SLTextEditColumn.renderInline(props);
      case TextDisplayColumn.widgetType:
        return TextDisplayColumn.renderInline(props);
      default:
        throw new Error(
          "GridColumn.renderInline does not support " +
            `widgetType ${props.column.widgetType}`
        );
    }
  }
}

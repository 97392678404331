import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import FocusManager from "../core/FocusManager";
import Paper from "../coreui/Paper";
import Presentation from "../coreui/Presentation";
import ProcessingMask from "../coreui/ProcessingMask";

interface Props {
  id: string;
  isLoaded: boolean;
  isLoading: boolean;
  isSwitching: boolean;
  paneUse: object;
  paneUseKey: string;
  tabId: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxSizing: "border-box",
    minHeight: 80 + 8 * 2, // Loading animation + padding
    position: "relative",
  },
}));

export default function TabPane(props: Props): JSX.Element {
  const styles = useStyles();
  const isPresentationNavigation = React.useRef<boolean>(true);
  const paneRef = React.useRef<HTMLElement | null>(null);
  const shouldFocusOnLoad = React.useRef<boolean>(false);

  const focusFirstElement = (): void => {
    shouldFocusOnLoad.current = false;
    if (paneRef.current === null) {
      console.warn(
        "No tab pane element exists when attempting to focus the first " +
          "element in the tab pane."
      );
    } else {
      FocusManager.grabFocusForChild(
        paneRef.current,
        [
          FocusManager.selectors.headings,
          FocusManager.selectors.focusable,
        ].join(", ")
      );
    }
  };

  React.useEffect(() => {
    // Focus must only be set when navigating between tabs on the same
    // presentation, so as not to interfere with focus being set to the main
    // heading when navigating to a new presentation.
    if (isPresentationNavigation.current) {
      isPresentationNavigation.current = false;
    } else if (!props.isLoaded) {
      shouldFocusOnLoad.current = true;
    } else {
      focusFirstElement();
    }
  }, [props.paneUseKey]);

  React.useEffect(() => {
    if (props.isLoaded && shouldFocusOnLoad.current) {
      focusFirstElement();
    }
  }, [props.isLoaded]);

  // The key attribute tells React to recreate the component from scratch when
  // the selected tab changes. Without this, child components are reused and
  // constructors are not called
  return (
    <Paper
      aria-labelledby={props.tabId}
      card={true}
      cardDepth={0}
      className={styles.root}
      elevation={0}
      id={props.id}
      margin={true}
      ref={paneRef}
      role="tabpanel"
    >
      {props.isLoaded ? (
        <div key={props.paneUseKey}>{Presentation.create(props.paneUse)}</div>
      ) : null}
      {!props.isSwitching || props.isLoading ? (
        <ProcessingMask isOpen={props.isLoading} />
      ) : null}
    </Paper>
  );
}

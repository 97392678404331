import * as React from "react";
import { TableChildProps } from "../../coreui/Table";
import Typography from "../../coreui/Typography";
import PaneRow from "../../models/PaneRow";
import { AccessLevel } from "../AccessLevel";
import {
  GridColumnConfigProperties,
  RenderInlineProperties,
} from "./GridColumn";

interface RuntimeProperties {
  accessLevel: AccessLevel;
  bodyText: string[] | null;
}

export class TextDisplayColumn {
  public static readonly widgetType: string = "TextDisplayColumn";

  public static getFilterText(
    column: GridColumnConfigProperties,
    propagated: TableChildProps,
    row: PaneRow
  ): string {
    const widget = row.getWidgetT<null, RuntimeProperties>(column.name);

    if (!widget.properties.bodyText) {
      return "";
    }

    return widget.properties.bodyText.join(" ");
  }

  public static renderInline(
    props: RenderInlineProperties
  ): JSX.Element | null {
    const widget = props.row.getWidgetT<null, RuntimeProperties>(
      props.column.name
    );

    if (!widget.properties.bodyText) {
      return null;
    }

    return (
      <Typography className={props.className} variant="body1">
        {widget.properties.bodyText.join(" ")}
      </Typography>
    );
  }
}

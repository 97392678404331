import AppServer from "../core/AppServer";
import Presentation from "../coreui/Presentation";
import { EmbeddedAddOn as EmbeddedAddOnBase } from "../mustangui/EmbeddedAddOn";
import { AuthenticatedPage, ErrorPage, GuestPage, LandingPage } from "../pages";
import NewObjectService from "../services/NewObjectService";
import PresentationService from "../services/PresentationService";
import SearchService from "../services/SearchService";
import UserService from "../services/UserService";
import ErrorsStore, { BusinessError } from "../stores/ErrorsStore";
import LayoutStateStore from "../stores/LayoutStateStore";
import RequestsStore from "../stores/RequestsStore";
import SessionMessagesStore from "../stores/SessionMessagesStore";
import Localization from "./Localization";
import Logging from "./Logging";
import Sys from "./Sys";

export type QueryStringObject = { [key: string | number]: string | string[] };

export default class Routing {
  private static readonly nonConfiguredAuthSessionExpiredRoute: string =
    "SessionExpired";
  private static sessionExpirationTimeout: number;

  private static extractHashParameters(hash: QueryStringObject): string | null {
    const parameters: string[] = [];

    for (const key in hash) {
      if (!hash.hasOwnProperty(key)) {
        continue;
      }

      if (key === "0") {
        continue;
      }

      if (key.toLowerCase().includes("posse")) {
        continue;
      }

      const rawValue = hash[key];

      // Convert all values to arrays so the same code path can be
      // followed when adding them to the parameters.
      const values: string[] = Array.isArray(rawValue)
        ? (rawValue as Array<string>)
        : [rawValue as string];

      const uriKey: string = encodeURIComponent(key);
      for (const value of values) {
        const uriValue: string = encodeURIComponent(value);
        parameters.push(`${uriKey}=${uriValue}`);
      }
    }

    if (parameters.length === 0) {
      return null;
    }

    return parameters.join("&");
  }

  private static getHashValue(): string | null {
    // Strip the trailing "/" from the hash
    const hash = window.location.hash.replace(/\/$/, "");
    if (hash.length <= 1) {
      return null;
    }

    // Strip the leading "#" from the hash
    return hash.substring(1);
  }

  private static getRouteToken(
    hash: QueryStringObject,
    tokenIndex: number
  ): string | null {
    if (!("0" in hash)) {
      return null;
    }

    const route = hash["0"] as string;
    const parts: string[] = route
      .split("/")
      .filter((p: string) => p.length > 0);

    return parts.length > tokenIndex ? parts[tokenIndex] : null;
  }

  private static goToNewObjectErrorPage(
    validationErrors: string[],
    businessErrors: BusinessError[]
  ): void {
    let type = "Validation";
    let errors = validationErrors;

    if (businessErrors?.length > 0) {
      errors = businessErrors.map((error) => error.message);
      type = "Business";
    }

    if (errors?.length > 0) {
      Logging.log(window.location.hash, "Invalid New Object URL");
      Logging.log(errors.join("\n"), `Invalid New Object ${type} Errors`);

      // Clear currentComponent so error page is rendered without
      // navigating so failed new object URL is not in back
      // history.
      Presentation.currentComponent = undefined;
      Routing.goToErrorPage("Invalid New Object Configuration");
    }
  }

  private static goToNonConfiguredAuthenticationSessionExpiredRoute() {
    const hrefRoot = window.location.href.split("#")[0];
    let newHref = `${hrefRoot}${Routing.nonConfiguredAuthSessionExpiredRoute}`;

    // Set RedirectUrl query parameter to the hash portion so
    // non-configured authentication could redirect back to where user
    // requested.
    const hash = Routing.getHashValue();
    if (hash) {
      newHref = `${newHref}?RedirectUrl=${hash}`;
    }

    // Set new href without hash "#" so that request is sent to server/proxy
    // to capture and handle the non-configured authentication session
    // expired route
    window.location.href = newHref;
  }

  private static async goToRoute(
    hash: QueryStringObject | null,
    sessionValidated: boolean
  ): Promise<boolean> {
    const rootToken = hash !== null ? Routing.getRouteToken(hash, 0) : null;
    const routeRoot = rootToken ? rootToken.toLowerCase() : null;

    if (routeRoot === "error") {
      const infoToken = Routing.getRouteToken(hash!, 1)!;
      const info = JSON.parse(atob(infoToken));
      const { status, message } = info;

      await ErrorPage.render(message, status);

      return true;
    }

    if (routeRoot === "signin") {
      await Routing.renderSignIn();

      return true;
    }

    if (routeRoot === "signout") {
      // Confirm continue has already been processed, ignore changes
      // on page reload
      Sys.ignoreChanges = true;

      await UserService.logout();

      return true;
    }

    if (routeRoot === "completeauthentication") {
      const parameters = Routing.extractHashParameters(hash!);
      await Routing.completeAuthentication(parameters);

      return true;
    }

    RequestsStore.instance.processingStarted();
    let routeIsValid: boolean = true;
    let sessionIsValid: boolean = true;

    if (!sessionValidated) {
      sessionIsValid = await Routing.validateUserSession();
    }

    if (sessionIsValid) {
      routeIsValid = await Routing.goToRoutesRequiringSession(hash, routeRoot);
    }

    RequestsStore.instance.processingStopped();

    // If no valid session return true since user will be routed to
    // login page.
    return routeIsValid;
  }

  private static async goToRoutesRequiringSession(
    hash: QueryStringObject | null,
    routeRoot: string | null
  ): Promise<boolean> {
    if (!hash) {
      await Routing.renderHomePage();

      return true;
    }

    LayoutStateStore.clear();
    if (routeRoot === "newobject") {
      const objectDefName = Routing.getRouteToken(hash!, 1);

      if (objectDefName) {
        // The new object is specified using the query string API
        const presentationName = Routing.getRouteToken(hash!, 2);

        if (!presentationName) {
          return false;
        }

        const parameters = Routing.extractHashParameters(hash);

        const fromObjectId = parseInt(hash["PosseFromObjectId"] as string, 10);
        const jobId = parseInt(hash["PosseJobId"] as string, 10);

        if (jobId) {
          // The new object is for a job process
          await Routing.renderNewObjectPresentationForJobProcessByName(
            objectDefName,
            presentationName,
            jobId,
            parameters
          );
        } else {
          await Routing.renderNewObjectPresentationForName(
            objectDefName,
            presentationName,
            isNaN(fromObjectId) ? null : fromObjectId,
            (hash["PosseEndPoint"] as string) || null,
            parameters
          );
        }
      } else if ("FromWidgetId" in hash) {
        // The new object is specified using a configured widget
        const fromPaneUseKey = hash["FromPaneUseKey"] as string;
        const fromRowKey = hash["FromRowKey"] as string;
        const fromWidgetId = parseInt(hash["FromWidgetId"] as string, 10);
        const newObjectHandle = hash["NewObjectHandle"] as string;
        const presentationId = parseInt(hash["PresentationId"] as string, 10);

        if (
          !fromPaneUseKey ||
          !fromRowKey ||
          isNaN(fromWidgetId) ||
          !newObjectHandle ||
          isNaN(presentationId)
        ) {
          return false;
        }

        await Routing.renderNewObjectPresentationForIdInternal(
          presentationId,
          fromPaneUseKey,
          fromRowKey,
          fromWidgetId,
          newObjectHandle
        );
      } else {
        // A new object page from the query string API was refreshed
        const newObjectDefId = parseInt(hash["NewObjectDefId"] as string, 10);
        const presentationId = parseInt(hash["PresentationId"] as string, 10);

        if (isNaN(newObjectDefId) || isNaN(presentationId)) {
          return false;
        }

        const endPointId = parseInt(hash["EndPointId"] as string, 10);
        const fromObjectId = parseInt(hash["FromObjectId"] as string, 10);

        if ("PosseJobId" in hash) {
          const jobId = parseInt(hash["PosseJobId"] as string, 10);

          if (isNaN(jobId)) {
            return false;
          }

          await Routing.renderNewObjectPresentationForJobProcessById(
            newObjectDefId,
            presentationId,
            jobId,
            (hash["Parameters"] as string) || null
          );
        } else {
          await Routing.renderNewObjectPresentationForIdExternal(
            newObjectDefId,
            presentationId,
            isNaN(fromObjectId) ? null : fromObjectId,
            isNaN(endPointId) ? null : endPointId,
            (hash["Parameters"] as string) || null
          );
        }
      }

      // Resetting application state to no changes to suppress the
      // Pending Changes warning if user tries to leave new object
      // presentation before interacting with the page
      const state = AppServer.getState();
      if (state) {
        state.hasDataChanges = false;
      }

      return true;
    }

    if (routeRoot === "object") {
      const objectHandle = Routing.getRouteToken(hash, 1);
      const presentationNameOrId = Routing.getRouteToken(hash, 2);

      if (!objectHandle || !presentationNameOrId) {
        return false;
      }

      const parameters = Routing.extractHashParameters(hash);

      const presentationId = parseInt(presentationNameOrId, 10);
      if (isNaN(presentationId)) {
        await Routing.renderPresentationForName(
          presentationNameOrId,
          objectHandle,
          parameters
        );
      } else {
        await Routing.renderPresentationForId(
          presentationId,
          objectHandle,
          parameters
        );
      }

      return true;
    }

    if (routeRoot === "search") {
      const presentationNameOrId = Routing.getRouteToken(hash, 1);

      if (!presentationNameOrId) {
        return false;
      }

      let autoExecute: boolean = false;
      if (hash["PosseAutoExecute"]) {
        const posseAutoExecute = hash["PosseAutoExecute"] as string;
        autoExecute = posseAutoExecute.toLowerCase() === "y";
      }

      const parameters = Routing.extractHashParameters(hash);

      const presentationId = parseInt(presentationNameOrId, 10);
      if (isNaN(presentationId)) {
        await Routing.renderSearchPresentationForName(
          presentationNameOrId,
          autoExecute,
          parameters
        );
      } else {
        await Routing.renderSearchPresentationForId(
          presentationId,
          autoExecute,
          parameters
        );
      }

      return true;
    }

    return false;
  }

  private static onHashChange() {
    Routing.processHash();
  }

  private static queryStringToObject(
    queryString: string | null
  ): QueryStringObject {
    const result: QueryStringObject = Object.create({});

    if (!queryString) {
      return result;
    }

    queryString.split(/[&?]+/).forEach((item, index) => {
      if (!item) {
        return;
      }

      if (item.includes("=")) {
        const [uriKey, uriValue] = item.split("=");
        const key = decodeURIComponent(uriKey).replace("+", "%20");
        const value = decodeURIComponent(uriValue).replace("+", "%20");

        if (result.hasOwnProperty(key)) {
          // Query string parameters specified multiple times are
          // stored as an array of values.
          if (Array.isArray(result[key])) {
            (result[key] as Array<string>).push(value);
          } else {
            result[key] = [result[key] as string, value];
          }
        } else {
          result[key] = value;
        }
      } else {
        result[index] = decodeURIComponent(item.replace("+", "%20"));
      }
    });

    return result;
  }

  private static async renderHomePage(): Promise<void> {
    RequestsStore.instance.processingStarted(null, false);

    if (UserService.isAuthenticated) {
      await AuthenticatedPage.renderHome();
    } else {
      await LandingPage.render();
    }

    Routing.setDocumentTitle();
    RequestsStore.instance.processingStopped();
  }

  private static async renderNewObjectPresentationForIdExternal(
    newObjectDefId: number,
    presentationId: number,
    fromObjectId: number | null,
    endPointId: number | null,
    parameters: string | null
  ): Promise<void> {
    RequestsStore.instance.processingStarted(null, true);

    const response = await NewObjectService.forIdExternal(
      newObjectDefId,
      presentationId,
      fromObjectId,
      endPointId,
      parameters
    );

    if (
      response.validationErrors?.length > 0 ||
      response.businessErrors?.length > 0
    ) {
      Routing.goToNewObjectErrorPage(
        response.validationErrors,
        response.businessErrors
      );

      return;
    }

    AppServer.setState(response.appServerState);

    if (UserService.isAuthenticated) {
      await AuthenticatedPage.render(
        presentationId,
        response.newObjectHandle,
        null,
        AppServer.getState()
      );
    } else {
      await GuestPage.render(
        presentationId,
        response.newObjectHandle,
        null,
        AppServer.getState()
      );
    }

    if (response.newSessionMessages) {
      SessionMessagesStore.addMessages(response.newSessionMessages);
    }

    RequestsStore.instance.processingStopped();
  }

  private static async renderNewObjectPresentationForIdInternal(
    presentationId: number,
    fromPaneUseKey: string,
    fromRowKey: string,
    fromWidgetId: number,
    newObjectHandle: string
  ): Promise<void> {
    RequestsStore.instance.processingStarted(null, false);

    const response = await NewObjectService.forIdInternal(
      fromPaneUseKey,
      fromRowKey,
      fromWidgetId,
      newObjectHandle
    );

    if (
      response.validationErrors?.length > 0 ||
      response.businessErrors?.length > 0
    ) {
      Routing.goToNewObjectErrorPage(
        response.validationErrors,
        response.businessErrors
      );

      return;
    }

    AppServer.setState(response.appServerState);

    if (UserService.isAuthenticated) {
      await AuthenticatedPage.render(
        presentationId,
        response.newObjectHandle,
        null,
        AppServer.getState()
      );
    } else {
      await GuestPage.render(
        presentationId,
        response.newObjectHandle,
        null,
        AppServer.getState()
      );
    }

    if (response.sessionMessages) {
      SessionMessagesStore.setMessages(response.sessionMessages);
    }

    RequestsStore.instance.processingStopped();
  }

  private static async renderNewObjectPresentationForJobProcessById(
    processTypeId: number,
    presentationId: number,
    jobId: number,
    parameters: string | null
  ): Promise<void> {
    RequestsStore.instance.processingStarted(null, true);

    const response = await NewObjectService.forJobProcessById(
      processTypeId,
      presentationId,
      jobId,
      parameters
    );

    if (
      response.validationErrors?.length > 0 ||
      response.businessErrors?.length > 0
    ) {
      Routing.goToNewObjectErrorPage(
        response.validationErrors,
        response.businessErrors
      );

      return;
    }

    AppServer.setState(response.appServerState);

    if (UserService.isAuthenticated) {
      await AuthenticatedPage.render(
        response.presentationId,
        response.newObjectHandle,
        null,
        AppServer.getState()
      );
    } else {
      await GuestPage.render(
        response.presentationId,
        response.newObjectHandle,
        null,
        AppServer.getState()
      );
    }

    if (response.newSessionMessages) {
      SessionMessagesStore.addMessages(response.newSessionMessages);
    }

    RequestsStore.instance.processingStopped();
  }

  private static async renderNewObjectPresentationForJobProcessByName(
    processTypeName: string,
    presentationName: string,
    jobId: number,
    parameters: string | null
  ): Promise<void> {
    RequestsStore.instance.processingStarted(null, true);

    const response = await NewObjectService.forJobProcessByName(
      processTypeName,
      presentationName,
      jobId,
      parameters
    );

    if (
      response.validationErrors?.length > 0 ||
      response.businessErrors?.length > 0
    ) {
      Routing.goToNewObjectErrorPage(
        response.validationErrors,
        response.businessErrors
      );

      return;
    }

    AppServer.setState(response.appServerState);

    const urlParameters: string[] = [
      `PresentationId=${response.presentationId}`,
      `NewObjectDefId=${response.newObjectDefId}`,
      `PosseJobId=${jobId}`,
    ];

    if (parameters) {
      urlParameters.push(`Parameters=${encodeURIComponent(parameters)}`);
    }

    const url = `/newobject?${urlParameters.join("&")}`;
    Sys.setHash(url, false, true);

    if (UserService.isAuthenticated) {
      await AuthenticatedPage.render(
        response.presentationId,
        response.newObjectHandle,
        null,
        AppServer.getState()
      );
    } else {
      await GuestPage.render(
        response.presentationId,
        response.newObjectHandle,
        null,
        AppServer.getState()
      );
    }

    if (response.newSessionMessages) {
      SessionMessagesStore.addMessages(response.newSessionMessages);
    }

    RequestsStore.instance.processingStopped();
  }

  private static async renderNewObjectPresentationForName(
    objectDefName: string,
    presentationName: string,
    fromObjectId: number | null,
    endPointName: string | null,
    parameters: string | null
  ): Promise<void> {
    RequestsStore.instance.processingStarted(null, true);

    const response = await NewObjectService.forName(
      objectDefName,
      presentationName,
      fromObjectId,
      endPointName,
      parameters
    );

    if (
      response.validationErrors?.length > 0 ||
      response.businessErrors?.length > 0
    ) {
      Routing.goToNewObjectErrorPage(
        response.validationErrors,
        response.businessErrors
      );

      return;
    }

    AppServer.setState(response.appServerState);

    const urlParameters: string[] = [
      `PresentationId=${response.presentationId}`,
      `NewObjectDefId=${response.newObjectDefId}`,
    ];

    if (fromObjectId) {
      urlParameters.push(`FromObjectId=${fromObjectId}`);
    }

    if (response.endPointId) {
      urlParameters.push(`EndPointId=${response.endPointId}`);
    }

    if (parameters) {
      urlParameters.push(`Parameters=${encodeURIComponent(parameters)}`);
    }

    const url = `/newobject?${urlParameters.join("&")}`;
    Sys.setHash(url, false, true);

    if (UserService.isAuthenticated) {
      await AuthenticatedPage.render(
        response.presentationId,
        response.newObjectHandle,
        null,
        AppServer.getState()
      );
    } else {
      await GuestPage.render(
        response.presentationId,
        response.newObjectHandle,
        null,
        AppServer.getState()
      );
    }

    if (response.newSessionMessages) {
      SessionMessagesStore.addMessages(response.newSessionMessages);
    }

    RequestsStore.instance.processingStopped();
  }

  private static async renderPresentationForId(
    presentationId: number,
    objectHandle: string,
    parameters: string | null
  ): Promise<void> {
    RequestsStore.instance.processingStarted(null, false);

    if (UserService.isAuthenticated) {
      await AuthenticatedPage.render(presentationId, objectHandle, parameters);
    } else {
      await GuestPage.render(presentationId, objectHandle, parameters);
    }
    RequestsStore.instance.processingStopped();
  }

  private static async renderPresentationForName(
    presentationName: string,
    objectHandle: string,
    parameters: string | null
  ): Promise<void> {
    RequestsStore.instance.processingStarted(null, false);

    const presentationId = await PresentationService.getPresentationIdForName(
      presentationName,
      objectHandle
    );

    if (UserService.isAuthenticated) {
      await AuthenticatedPage.render(presentationId, objectHandle, parameters);
    } else {
      await GuestPage.render(presentationId, objectHandle, parameters);
    }
    RequestsStore.instance.processingStopped();
  }

  private static async renderSearchPresentationForId(
    presentationId: number,
    autoExecute: boolean,
    parameters: string | null
  ): Promise<void> {
    RequestsStore.instance.processingStarted(null, false);

    if (UserService.isAuthenticated) {
      await AuthenticatedPage.renderSearch(
        presentationId,
        autoExecute,
        parameters
      );
    } else {
      await GuestPage.renderSearch(presentationId, autoExecute, parameters);
    }
    RequestsStore.instance.processingStopped();
  }

  private static async renderSearchPresentationForName(
    presentationName: string,
    autoExecute: boolean,
    parameters: string | null
  ): Promise<void> {
    RequestsStore.instance.processingStarted(null, false);

    const presentationId: number = await SearchService.getPresentationIdForName(
      presentationName
    );

    if (UserService.isAuthenticated) {
      await AuthenticatedPage.renderSearch(
        presentationId,
        autoExecute,
        parameters
      );
    } else {
      await GuestPage.renderSearch(presentationId, autoExecute, parameters);
    }
    RequestsStore.instance.processingStopped();
  }

  private static setSessionTimeoutWarning(expirationSeconds: number): void {
    clearTimeout(Routing.sessionExpirationTimeout);

    if (expirationSeconds <= 0) {
      const message: string = "Expiration seconds must be greater than zero";
      Logging.warn(message);
      throw new Error(message);
    }

    const maxMilliseconds: number = Math.pow(2, 31);
    const expirationMilliseconds: number = expirationSeconds * 1000;
    if (expirationMilliseconds > maxMilliseconds) {
      const message: string =
        "Expiration seconds must be less than " +
        `${Math.floor(maxMilliseconds / 1000).toFixed(0)}`;
      Logging.warn(message);
      throw new Error(message);
    }

    Routing.sessionExpirationTimeout = window.setTimeout(() => {
      if (Sys.settings.useConfiguredAuthentication) {
        if (navigator.onLine) {
          if (UserService.isAuthenticated) {
            ErrorsStore.showErrors([
              Localization.getBuiltInMessage("sessionExpired"),
            ]);
            Routing.renderSignIn();
          } else {
            window.location.reload();
          }
        } else {
          window.ononline = (event: Event) => {
            if (UserService.isAuthenticated) {
              Routing.renderSignIn();
            } else {
              window.location.reload();
            }
          };
        }
      } else {
        // Authentication is not handled by POSSE
        Routing.goToNonConfiguredAuthenticationSessionExpiredRoute();
      }
    }, expirationMilliseconds);
  }

  private static async validateUserSession(): Promise<boolean> {
    if (Sys.settings.useConfiguredAuthentication) {
      if (UserService.isAuthenticated) {
        try {
          const expirationSeconds = await UserService.checkSession();

          Routing.setSessionTimeoutWarning(expirationSeconds);
        } catch {
          // Session expired
          const expirationSeconds = await UserService.logonAsGuest();

          Routing.setSessionTimeoutWarning(expirationSeconds);

          if (Routing.getHashValue()) {
            ErrorsStore.showErrors([
              Localization.getBuiltInMessage("sessionExpired"),
            ]);
            await Routing.renderSignIn();
          } else {
            await LandingPage.render();
          }

          return false;
        }
      } else if (UserService.isGuest) {
        try {
          const expirationSeconds = await UserService.checkSession();

          Routing.setSessionTimeoutWarning(expirationSeconds);
        } catch {
          const expirationSeconds = await UserService.logonAsGuest();

          Routing.setSessionTimeoutWarning(expirationSeconds);
        }
      } else {
        const expirationSeconds = await UserService.logonAsGuest();

        Routing.setSessionTimeoutWarning(expirationSeconds);
      }
    } else {
      try {
        const expirationSeconds = await UserService.checkSession();

        Routing.setSessionTimeoutWarning(expirationSeconds);
      } catch {
        // Authentication is not handled by POSSE
        Routing.goToNonConfiguredAuthenticationSessionExpiredRoute();

        return false;
      }
    }

    return true;
  }

  public static async completeAuthentication(
    parameters: string | null
  ): Promise<void> {
    const expirationSeconds = await UserService.completeAuthentication(
      parameters
    );

    if (expirationSeconds) {
      Routing.setSessionTimeoutWarning(expirationSeconds);
    }
  }

  public static getHash(): QueryStringObject | null {
    const hash = Routing.getHashValue();
    if (!hash) {
      return null;
    }

    return Routing.queryStringToObject(hash);
  }

  public static goToErrorPage(
    message: string | null,
    status: number = 500
  ): void {
    EmbeddedAddOnBase.clearRoundTrip();

    const errorInfo = btoa(JSON.stringify({ message, status }));
    const errorHash = `/error/${encodeURIComponent(errorInfo)}`;

    if (Presentation.currentComponent) {
      // Navigate to the error page if a presentation is already loaded,
      // that way clicking the back button on the error page will go back
      // to the presentation that was loaded before the error occured.
      Sys.ignoreChanges = true;
      Sys.setHash(errorHash, false, false);
    } else {
      // Update the hash but go to the error page without a navigate if
      // a presentation is currently loading. That way clicking the back
      // button on the error page will go back to the presentation which
      // the user was navigating from, rather than the presentation that
      // caused the error on load.
      Sys.setHash(errorHash, false, true);
      ErrorPage.render(message, status);
    }
  }

  public static async initialize(): Promise<void> {
    window.onhashchange = Routing.onHashChange;

    const sessionIsValid: boolean = await Routing.validateUserSession();

    if (!sessionIsValid) {
      return;
    }

    // Get user info if not already set. This is already set during login
    // or Guest Session creation.
    if (!UserService.accountObjectHandle) {
      await UserService.getUserInfo();
    }

    Routing.processHash(true);
  }

  public static isAt(rootToken: string): boolean {
    const hash = Routing.getHash();
    if (!hash) {
      return false;
    }

    const routeRoot = Routing.getRouteToken(hash, 0);
    if (!routeRoot) {
      return false;
    }

    return routeRoot.toLowerCase() === rootToken.toLowerCase();
  }

  public static async isNonConfiguredAuthenticationSessionExpiredRoute(): Promise<boolean> {
    // If route is non-configured authentication route then navigate to error
    // page since this route should not get back to WebUI. It is suppose
    // to be captured and redirected to the external non-configured
    // authentication mechanism where a valid Posse Session is to be created
    // and returned to WebUI using the RedirectUrl parameter or WebUI root.
    if (Routing.isAt(Routing.nonConfiguredAuthSessionExpiredRoute)) {
      ErrorPage.render("No Non-Configured Authentication Session Handler", 404);

      return true;
    }

    return false;
  }

  public static async processHash(
    sessionValidated: boolean = false
  ): Promise<void> {
    if (Sys.ignoreHashChanges) {
      Sys.ignoreHashChanges = false;

      return;
    }

    try {
      await Sys.confirmContinue();
    } catch {
      Sys.ignoreChanges = false;
      Sys.setHash(Sys.lastHash || Object.create(null), false, false, true);

      return;
    }

    const hash = Routing.getHash();
    Sys.lastHash = hash;
    Sys.ignoreChanges = false;
    ErrorsStore.clearErrors();
    RequestsStore.instance.clearSaved();

    const routeIsValid = await Routing.goToRoute(hash, sessionValidated);

    if (!routeIsValid) {
      ErrorPage.render("Not found", 404);
    }
  }

  public static async renderSignIn(): Promise<void> {
    EmbeddedAddOnBase.clearRoundTrip();

    if (Sys.settings.useConfiguredAuthentication) {
      if (UserService.isAuthenticated) {
        await UserService.logout(false);
      }

      // Ensure there is a Guest session
      if (!UserService.isGuest) {
        const expirationSeconds = await UserService.logonAsGuest();
        Routing.setSessionTimeoutWarning(expirationSeconds);
      }

      await GuestPage.renderSignIn();

      Routing.setDocumentTitle(Localization.getBuiltInMessage("signIn"));
    } else {
      // Authentication is not handled by POSSE
      Routing.goToNonConfiguredAuthenticationSessionExpiredRoute();
    }

    RequestsStore.instance.clearAllProcessing();
  }

  public static setDocumentTitle(
    objectTitle: string | null = null,
    objectDefDescription: string | null = null
  ) {
    const components: string[] = [];

    Presentation.objectTitle = objectTitle;
    Presentation.objectDefDescription = objectDefDescription;

    if (Sys.settings.nonProdEnvironment) {
      components.push(Sys.settings.nonProdEnvironment);
    }

    const objectComponents: string[] = [];
    if (objectTitle) {
      objectComponents.push(objectTitle);
    }

    if (objectDefDescription) {
      objectComponents.push(`(${objectDefDescription})`);
    }

    if (objectComponents.length !== 0) {
      components.push(objectComponents.join(" "));
    }

    components.push(Sys.settings.siteName);

    document.title = components.join(" - ");

    Sys.debounceMethod(
      () => {
        Sys.announce(document.title);
      },
      "Page.DocumentTitle",
      2000
    );
  }
}

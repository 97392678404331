import { Box, Checkbox, styled } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { IHeaderParams } from "ag-grid-community";
import * as React from "react";
import Localization from "../../core/Localization";
import Sys from "../../core/Sys";
import Icon from "../Icon";
import { TableChildProps } from "../Table";

interface Props extends IHeaderParams {
  propagated: TableChildProps;
}

const Container = styled("div")({
  display: "grid",
  height: "100%",
  placeItems: "center",
  width: "100%",
});

class SelectionHeader extends React.Component<Props> {
  private readonly componentId: string;
  private readonly describedById: string;

  public constructor(props: Props) {
    super(props);

    this.componentId = `selection-header-${Sys.nextId}`;
    this.describedById = `${this.componentId}-described-by`;

    props.eGridHeader.addEventListener("keydown", this.onHeaderKeyDown);
  }

  private onHeaderKeyDown = (event: KeyboardEvent): void => {
    if (!this.props.propagated.parentTable.selection.isSelectAllEnabled) {
      return;
    }

    if (event.key === " " || event.key === "Enter") {
      const selection = this.props.propagated.parentTable.selection;
      const api = this.props.api;

      event.preventDefault();
      event.stopPropagation();

      selection.setAllSelected(
        selection.getSelectedCount() !==
          api.getModel().getRowCount() + api.getPinnedTopRowCount()
      );
      this.props.propagated.parentTable.getTable().focus();
    }
  };

  private updateGridHeaderDescribedBy(): void {
    const { api, eGridHeader } = this.props;
    const rowCount = api.getModel().getRowCount() + api.getPinnedTopRowCount();

    if (rowCount <= 0) {
      eGridHeader.removeAttribute("aria-describedby");
    } else {
      eGridHeader.setAttribute("aria-describedby", this.describedById);
    }
  }

  public componentDidMount(): void {
    this.updateGridHeaderDescribedBy();
  }

  public componentDidUpdate(): void {
    this.updateGridHeaderDescribedBy();
  }

  public componentWillUnmount(): void {
    this.props.eGridHeader.removeEventListener("keydown", this.onHeaderKeyDown);
  }

  public render(): React.ReactNode {
    if (!this.props.propagated.parentTable.selection.isSelectAllEnabled) {
      return null;
    }

    const selectedCount =
      this.props.propagated.parentTable.selection.getSelectedCount();
    const icon = selectedCount === 0 ? "far fa-square" : "fas fa-minus-square";
    const { api } = this.props;
    const rowCount = api.getModel().getRowCount() + api.getPinnedTopRowCount();

    if (rowCount <= 0) {
      return (
        <div
          aria-label={Localization.getBuiltInMessage(
            "DataTable.selectRowColumnHeaderLabel"
          )}
        />
      );
    }

    return (
      <Container aria-describedby={this.describedById}>
        <Checkbox
          aria-label={Localization.getBuiltInMessage(
            "DataTable.selectRowColumnHeaderLabel"
          )}
          checked={selectedCount === rowCount}
          checkedIcon={<Icon icon="fas fa-check-square" />}
          color="default"
          icon={<Icon icon={icon} />}
          onChange={(
            event: React.ChangeEvent<HTMLInputElement>,
            checked: boolean
          ) => {
            this.props.propagated.parentTable.selection.setAllSelected(checked);
            this.props.propagated.parentTable.getTable().focus();
          }}
          tabIndex={-1}
        />
        <Box aria-hidden id={this.describedById} sx={visuallyHidden}>
          {Localization.getBuiltInMessage("DataTable.selectAllInstructions")}
        </Box>
      </Container>
    );
  }
}

export default SelectionHeader;

import { IHeaderParams } from "ag-grid-community";
import * as React from "react";
import Localization from "../../core/Localization";
import Sys from "../../core/Sys";
import Icon from "../../coreui/Icon";
import { TableChildProps } from "../../coreui/Table";
import { FocusCellRendererParams } from "../../coreui/table/CellFocusUtil";
import { CellUtil } from "../../coreui/table/CellUtil";
import Typography from "../../coreui/Typography";
import PaneRow from "../../models/PaneRow";
import { AccessLevel } from "../AccessLevel";
import {
  GridColumnConfigProperties,
  RenderInlineProperties,
} from "./GridColumn";

interface ConfigProperties extends FocusCellRendererParams<null> {
  dataId: string;
  name: string;
}

interface RuntimeProperties {
  accessLevel: AccessLevel;
  iconName: string;
  label: string;
}

export class HierarchyDisplayColumn extends React.PureComponent<ConfigProperties> {
  public static readonly widgetType: string = "HierarchyDisplayColumn";

  public static getFilterText(
    column: GridColumnConfigProperties,
    propagated: TableChildProps,
    row: PaneRow
  ): string {
    const widget = row.getWidgetT<null, RuntimeProperties>(column.name);
    return widget.properties.label;
  }

  public static renderInline(props: RenderInlineProperties): JSX.Element {
    const widget = props.row.getWidgetT<null, RuntimeProperties>(
      props.column.name
    );

    return (
      <React.Fragment>
        <Icon
          fixedWidth
          icon={widget.properties.iconName}
          style={{
            fontSize: 16,
            height: "auto",
            marginRight: ".4em",
          }}
        />
        <Typography className={props.className} variant="body1">
          {widget.properties.label}
        </Typography>
      </React.Fragment>
    );
  }

  public render(): React.ReactNode {
    const row = this.props.data!;
    const widget = row.getWidgetT<null, RuntimeProperties>(this.props.name);

    CellUtil.setReadOnlyAttribute(this.props.eGridCell, true);

    const gridApi = this.props.propagated.parentTable.getApi();
    const rootRow = gridApi.getModel().getRow(0)!.data as PaneRow;
    const indentLevel = row.hierarchyLevel! - rootRow.hierarchyLevel! + 1;

    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          margin: `0px 24px 0px ${24 * indentLevel}px`,
        }}
      >
        <Icon
          icon={widget.properties.iconName}
          style={{
            fontSize: 16,
            height: "auto",
            marginRight: ".4em",
          }}
        />
        <div
          style={{
            lineHeight: "normal",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%",
          }}
        >
          {widget.properties.label}
        </div>
      </div>
    );
  }
}

export class HierarchyDisplayHeader extends React.Component {
  private readonly componentId: string;
  private readonly describedById: string;

  public constructor(params: IHeaderParams) {
    super(params);

    this.componentId = `hierarchy-display-header-${Sys.nextId}`;
    this.describedById = `${this.componentId}-described-by`;

    params.eGridHeader.setAttribute("aria-describedby", this.describedById);
  }

  render(): React.ReactNode {
    // The class of screenReaderOnly is needed here instead of using a style of
    // "display: none" because VoiceOver will not read the column header when
    // the column is focused if the header has a style of "display: none"
    return (
      <div className="screenReaderOnly" id={this.describedById}>
        {Localization.getBuiltInMessage("DataTable.projectGridHierarchyHeader")}
      </div>
    );
  }
}

export default HierarchyDisplayColumn;

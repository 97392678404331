import RequestsStore from "../stores/RequestsStore";

export default class FocusManager {
  public static readonly selectors = {
    focusable: [
      "a[href]",
      "button",
      "details",
      "input",
      "select",
      "textarea",
      '[tabindex]:not([tabindex="-1"])',
    ].join(", "),

    headings: [
      'h1[tabindex="-1"]',
      'h2[tabindex="-1"]',
      'h3[tabindex="-1"]',
      'h4[tabindex="-1"]',
      'h5[tabindex="-1"]',
      'h6[tabindex="-1"]',
    ].join(", "),
  };

  private static elementIsVisible(element: HTMLElement): boolean {
    return !!(
      element.offsetWidth ||
      element.offsetHeight ||
      element.getClientRects().length
    );
  }

  /**
   * Attempts to set focus on the given element. Waits until the requests store
   * is no longer processing anything before setting focus so the Processing
   * Mask doesn't steal focus from the element. As a safety valve, this method
   * will give up and not set focus on the element if more than ~15 seconds
   * elapse while waiting on the requests store.
   */
  public static grabFocus(element: HTMLElement): void {
    const numberOfRetries: number = 150;
    const renderDelay: number = 100;
    const timeBetweenRetries: number = 100;

    function tryGrabFocus(retriesLeft: number): void {
      if (retriesLeft <= 0) {
        return;
      }

      if (RequestsStore.instance.processingInfo.isProcessing) {
        setTimeout(() => tryGrabFocus(retriesLeft - 1), timeBetweenRetries);
        return;
      }

      // Wait just a little bit longer because the ProcessingMask may still be
      // up after the RequestsStore instructs it to close because it stays up
      // for a minimum amount of time once it's opened to reduce the appearance
      // of it flashing into and out of existence.
      const waitTime = Math.max(
        500 - timeBetweenRetries * (numberOfRetries - retriesLeft),
        100
      );
      setTimeout(() => element.focus(), waitTime);
    }

    // Give screen readers time to catch up if the focusable element was just
    // recently rendered. If focus is set immediately, the screen reader's own
    // internal model of the DOM may not be fully set up by the time the screen
    // reader announces the focused element. This can lead to incomplete context
    // being announced - e.g. the element is announced, but not the dialog or
    // form that it is a part of.
    setTimeout(() => tryGrabFocus(numberOfRetries), renderDelay);
  }

  /**
   * Attempts to set focus on the first visible element that is a child of the
   * given root element which matches the given query selector. See grabFocus()
   * for details on how focus gets set.
   */
  public static grabFocusForChild(
    rootElement: HTMLElement,
    selector: string
  ): void {
    const focusCandidates = Array.from(rootElement.querySelectorAll(selector));
    for (const e of focusCandidates) {
      const focusCandidate = e as HTMLElement;
      if (FocusManager.elementIsVisible(focusCandidate)) {
        FocusManager.grabFocus(focusCandidate);
        break;
      }
    }
  }
}

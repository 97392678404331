import { Typography } from "@mui/material";
import * as React from "react";
import { SearchChildProps } from "../config";

interface ConfigProperties {
  propagated: SearchChildProps;
}

export const ResultsCountDisplay = (
  props: ConfigProperties
): JSX.Element | null => {
  if (!props.propagated.parentSearch.succeeded) {
    return null;
  }

  return (
    <Typography
      component="p"
      style={{ display: "inline-block", fontWeight: 400 }}
      variant="h3"
    >
      {props.propagated.parentSearch.resultsCountMessage}
    </Typography>
  );
};

import { ButtonProps as MuiButtonProps, styled } from "@mui/material";
import * as React from "react";
import Localization from "../core/Localization";
import Button from "./Button";

interface Props extends MuiButtonProps {
  direction?: "left" | "right";
  visible?: boolean;
}

export const TabScrollButton = styled(
  (props: Props) => {
    const {
      endIcon, // Removed so it can be ignored.
      direction,
      visible,
      ...buttonProps
    } = props;

    return (
      <Button
        aria-label={Localization.getBuiltInMessage(
          direction === "left" ? "scrollLeft" : "scrollRight"
        )}
        disabled={!visible}
        fab
        icon={
          direction === "left" ? "fas fa-chevron-left" : "fas fa-chevron-right"
        }
        size="medium"
        tabIndex={-1}
        {...buttonProps}
      />
    );
  },
  { shouldForwardProp: (prop) => prop !== "slotProps" }
)<Props>(({ direction }) => ({
  "@media print": {
    display: "none",
  },
  alignSelf: "flex-end",
  flexShrink: 0,
  marginBottom: 8,
  marginLeft: 8,
  marginRight: direction === "right" ? 1 : undefined,
  order: direction === "left" ? 1 : 2,
  zIndex: 1,
}));

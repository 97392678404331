import { TableChildProps } from "../../coreui/Table";
import PaneRow, { RuntimeProperties } from "../../models/PaneRow";
import { GridColumnConfigProperties } from "./GridColumn";

export class MLTextEditColumn {
  public static readonly widgetType: string = "MLTextEditColumn";

  public static getFilterText(
    column: GridColumnConfigProperties,
    propagated: TableChildProps,
    row: PaneRow
  ): string {
    const widget = row.getWidgetT<string | null, RuntimeProperties>(
      column.name
    );
    return widget.value || "";
  }
}

import * as React from "react";
import * as ReactDOM from "react-dom";
import { Layout, LayoutConfig } from "../config";
import AppServer from "../core/AppServer";
import Sys from "../core/Sys";
import TrackableCollection from "../core/TrackableCollection";
import Presentation from "../coreui/Presentation";
import Panel from "../mustangui/Panel";
import PresentationService from "../services/PresentationService";
import UserService from "../services/UserService";
import PaneDataStore from "../stores/PaneDataStore";
import {
  LandingPageTemplate,
  LandingPageTemplateConfig,
  LogoBase,
} from "../templates";

export interface LandingPageConfig {
  forgotPasswordUrl: string | null;
  homeLayout: LayoutConfig;
  signInTitle: string;
  template: LandingPageTemplateConfig;
}

export class LandingPage {
  private static config: LandingPageConfig | null = null;

  public static async render() {
    if (LandingPage.config === null) {
      throw new Error(
        "Landing page config must be set before the landing page " +
          "may be rendered"
      );
    }

    const templateResponse = await PresentationService.getLandingPageData();
    AppServer.setState(templateResponse.appServerState);
    PaneDataStore.loadResponse(templateResponse.paneDataByDataId);

    const templateConfig = LandingPage.config.template;

    const dataResponse = await PresentationService.getPresentationData(
      LandingPage.config.homeLayout.layoutId,
      UserService.accountObjectHandle
    );

    if (dataResponse.shouldRedirectHome) {
      AppServer.setState(dataResponse.appServerState);
      Sys.setHash("");

      return;
    }

    // FUTURE
    // The presentation is first rendered without any children to give the
    // models a chance to be cleared without the new presentation reacting
    // to their on-change events.
    //
    // The components should be made tolerant of missing data, that way the
    // on-change events fired by changing the data will be benign. Then we
    // can take full advantage of React's architecture for making minimum
    // changes to the DOM (this approach effectively removes the entire
    // presentation from the DOM and re-renders the new one, which is not as
    // efficient as it could be).
    const navigatingToSamePresentation =
      Presentation.currentPresentationId &&
      Presentation.currentPresentationId === dataResponse.layoutId!;
    // Avoiding clearing the presentation if navigating to the same
    // presentation helps this to be slightly more efficient.
    if (!navigatingToSamePresentation) {
      ReactDOM.render(
        <LandingPageTemplate
          backgroundImageUrl={templateConfig.backgroundImageUrl}
          footer={templateConfig.footer}
          forgotPasswordUrl={LandingPage.config.forgotPasswordUrl}
          header={templateConfig.header}
          signInTitle={LandingPage.config.signInTitle}
          welcomeTitle1={templateConfig.welcomeTitle1}
          welcomeTitle2={templateConfig.welcomeTitle2}
        />,
        document.getElementById("root")
      );
    }

    let presentation = LandingPage.config.homeLayout;
    if (presentation.layoutId !== dataResponse.layoutId) {
      // The preload script performed a redirect
      presentation = await PresentationService.getPresentationConfig(
        dataResponse.layoutId!
      );
    }

    AppServer.setState(dataResponse.appServerState);
    PaneDataStore.loadResponse(dataResponse.paneDataByDataId!);

    ReactDOM.render(
      <LandingPageTemplate
        backgroundImageUrl={templateConfig.backgroundImageUrl}
        footer={templateConfig.footer}
        forgotPasswordUrl={LandingPage.config.forgotPasswordUrl}
        header={templateConfig.header}
        signInTitle={LandingPage.config.signInTitle}
        welcomeTitle1={templateConfig.welcomeTitle1}
        welcomeTitle2={templateConfig.welcomeTitle2}
      >
        <Panel presentationId={presentation.layoutId}>
          <Layout config={presentation} />
        </Panel>
      </LandingPageTemplate>,
      document.getElementById("root")
    );

    Sys.ignoreChanges = true;
    LogoBase.grabFocus();
  }

  public static setConfig(config: LandingPageConfig) {
    if (LandingPage.config !== null) {
      throw new Error("Landing page config is already set");
    }

    LandingPage.config = config;

    const templateConfig = LandingPage.config.template;
    const allDataIds = [
      ...templateConfig.footer.layout.dataIds,
      ...templateConfig.header.layout.dataIds,
      ...LandingPage.config.homeLayout.dataIds,
    ];

    for (const dataId of allDataIds) {
      new TrackableCollection("PaneRow", dataId);
    }
  }
}

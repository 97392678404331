import {
  action,
  autorun,
  IReactionDisposer,
  makeObservable,
  observable,
  transaction,
} from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import Localization from "../core/Localization";
import RequestPromise from "../core/RequestPromise";
import Sys from "../core/Sys";
import PaneRow from "../models/PaneRow";
import NewObjectService from "../services/NewObjectService";
import RoundTripService, {
  RoundTripResponse,
} from "../services/RoundTripService";
import ErrorsStore from "../stores/ErrorsStore";
import LayoutStateStore from "../stores/LayoutStateStore";
import PaneDataStore, {
  PaneData,
  PaneDataByDataId,
} from "../stores/PaneDataStore";
import RequestsStore from "../stores/RequestsStore";
import { AccessLevel } from "./AccessLevel";
import ErrorBoundary from "./ErrorBoundary";
import WizardStepPane, { StepProps } from "./WizardStepPane";

interface GetDataResponse {
  paneDataByDataId: PaneDataByDataId;
  validationErrors: string[];
}

interface Props {
  dataId: string;
  name: string;
  steps: StepProps[];
}

interface State {
  selectedPaneUseKey?: string;
}

export interface StepWidgetProperties {
  accessLevel: AccessLevel;
  paneUseKey: string;
  rowKey: string;
  stepComplete: boolean;
}

export interface WidgetProperties {
  accessLevel: AccessLevel;
  selectedPaneUseKey: string | null;
  steps: StepWidgetProperties[];
}

export class WizardControl extends React.PureComponent<Props, State> {
  private static widgetProperties: WidgetProperties = observable({
    accessLevel: AccessLevel.hidden,
    selectedPaneUseKey: null,
    steps: [],
  });
  public static instance: WizardControl | null = null;
  private dataId: string = "";
  private dataMonitorDisposer: IReactionDisposer;
  private lastRetrievedPaneDate: Date | undefined;
  private name: string = "";
  private retrieveDataPromises: RequestPromise<GetDataResponse>[] = [];
  private steps: StepProps[];

  private static finishNavigate(
    response: RoundTripResponse,
    scrollToPageTop: boolean
  ) {
    PaneDataStore.clearAllDataChanges();

    if (WizardControl.isOnLastStep()) {
      if (response.newObjectId) {
        NewObjectService.navigateToNewObject(
          response.newObjectId,
          response.layoutId!
        );
      }

      ErrorsStore.clearErrors();
      RequestsStore.instance.setSaved("fas fa-save");
    }

    if (scrollToPageTop) {
      window.scrollTo(0, 0);
    }
  }

  public static announceNavigation(previousStepIndex: number) {
    const instance = WizardControl.instance;
    if (!instance) {
      return;
    }

    const stepCount = instance.steps.length;
    const complete =
      WizardControl.widgetProperties?.steps[previousStepIndex].stepComplete;

    const announcement: string = Localization.getBuiltInMessage(
      complete ? "Wizard.stepComplete" : "Wizard.stepIncomplete",
      {
        count: stepCount,
        step: previousStepIndex + 1,
      }
    );

    Sys.announce(announcement);
  }

  public static getStepCount(): number {
    let result: number = WizardControl.widgetProperties.steps.length;

    for (const step of WizardControl.widgetProperties.steps) {
      if (step.accessLevel === AccessLevel.hidden) {
        result--;
      }
    }

    return result;
  }

  public static getStepNumber(step: number): number {
    let result: number = step + 1;

    for (let index = 0; index <= step; index++) {
      if (
        WizardControl.widgetProperties.steps[index].accessLevel ===
        AccessLevel.hidden
      ) {
        result--;
      }
    }

    return result;
  }

  public static getSteps(): StepProps[] | null {
    const instance = WizardControl.instance;
    if (!instance) {
      return null;
    }

    return instance.steps;
  }

  public static getWidgetProperties(): WidgetProperties {
    return WizardControl.widgetProperties;
  }

  public static async gotoNextStep(): Promise<void> {
    const instance = WizardControl.instance;
    if (!instance) {
      return;
    }

    const previousStepIndex = instance.steps.findIndex(
      (s) => s.paneUseKey === instance.state.selectedPaneUseKey
    );
    RequestsStore.instance.processingStarted(null, false, true);

    try {
      const response: RoundTripResponse =
        await RoundTripService.standardRoundTrip(
          "WizardControl/OnNavigate",
          { dataId: instance.dataId, name: instance.name },
          { navigationDirection: "next" },
          true
        );

      if (
        response.businessErrors.length === 0 &&
        response.validationErrors.length === 0
      ) {
        WizardControl.announceNavigation(previousStepIndex);
        WizardControl.finishNavigate(response, true);
      }
    } catch (reason) {
      if (reason) {
        throw reason;
      }
    } finally {
      RequestsStore.instance.processingStopped();
    }
  }

  public static async gotoPreviousStep(): Promise<void> {
    const instance = WizardControl.instance;
    if (!instance) {
      return;
    }

    const previousStepIndex = instance.steps.findIndex(
      (s) => s.paneUseKey === instance.state.selectedPaneUseKey
    );
    RequestsStore.instance.processingStarted(null, false, true);

    try {
      const response: RoundTripResponse =
        await RoundTripService.standardRoundTrip(
          "WizardControl/OnNavigate",
          { dataId: instance.dataId, name: instance.name },
          { navigationDirection: "previous" },
          true
        );

      if (
        response.businessErrors.length === 0 &&
        response.validationErrors.length === 0
      ) {
        WizardControl.announceNavigation(previousStepIndex);
        WizardControl.finishNavigate(response, true);
      }
    } catch (reason) {
      if (reason) {
        throw reason;
      }
    } finally {
      RequestsStore.instance.processingStopped();
    }
  }

  public static async gotoStep(navigationPaneUseKey: string): Promise<void> {
    const instance = WizardControl.instance;
    if (!instance) {
      return;
    }

    const previousStepIndex = instance.steps.findIndex(
      (s) => s.paneUseKey === instance.state.selectedPaneUseKey
    );

    try {
      const response: RoundTripResponse =
        await RoundTripService.standardRoundTrip(
          "WizardControl/OnNavigate",
          { dataId: instance.dataId, name: instance.name },
          { navigationPaneUseKey }
        );

      if (
        response.businessErrors.length === 0 &&
        response.validationErrors.length === 0
      ) {
        WizardControl.announceNavigation(previousStepIndex);
        WizardControl.finishNavigate(response, false);
      }
    } catch (reason) {
      if (reason) {
        throw reason;
      }
    }
  }

  public static isOnLastStep(): boolean {
    const instance = WizardControl.instance;
    if (!instance) {
      return false;
    }

    const index = instance.steps.findIndex(
      (s) => s.paneUseKey === instance.state.selectedPaneUseKey
    );

    return index === instance.steps.length - 1;
  }

  public constructor(props: Props) {
    super(props);

    makeObservable<WizardControl, "dataId" | "name" | "syncDerivedWithProps">(
      this,
      {
        dataId: observable,
        name: observable,
        syncDerivedWithProps: action,
      }
    );

    this.state = {
      selectedPaneUseKey: undefined,
    };

    WizardControl.instance = this;

    this.syncDerivedWithProps();
  }

  private dataMonitor = () => {
    const parentPane: PaneData = PaneDataStore.instance.getPane(this.dataId)!;

    if (!parentPane.lastRetrieved) {
      return;
    }

    if (
      this.lastRetrievedPaneDate &&
      this.lastRetrievedPaneDate >= parentPane.lastRetrieved
    ) {
      return;
    }

    for (const retrievePromise of this.retrieveDataPromises) {
      retrievePromise.abort();
    }

    const row = PaneRow.get(this.dataId)!;
    const widget = row.getWidgetT<null, WidgetProperties>(this.name)!;
    transaction(
      action(() => {
        // Copy the members accross rather than directly assigning to maintain
        // the original observable reference.
        WizardControl.widgetProperties.accessLevel =
          widget.properties.accessLevel;
        WizardControl.widgetProperties.selectedPaneUseKey =
          widget.properties.selectedPaneUseKey;
        WizardControl.widgetProperties.steps = widget.properties.steps;
      })
    );

    const paneUseKey: string | null =
      WizardControl.widgetProperties.selectedPaneUseKey;

    LayoutStateStore.setSelectedStep(paneUseKey);

    if (paneUseKey) {
      const activeStep: StepWidgetProperties | undefined =
        WizardControl.widgetProperties.steps.find(
          (t) => t.paneUseKey === paneUseKey
        );

      if (!activeStep) {
        throw new Error(`No step with pane use key of ${paneUseKey} found`);
      }

      this.setState({
        selectedPaneUseKey: activeStep.paneUseKey,
      });
    } else {
      this.setState({
        selectedPaneUseKey: undefined,
      });
    }

    this.lastRetrievedPaneDate = parentPane.lastRetrieved;
  };

  private syncDerivedWithProps(): void {
    this.dataId = this.props.dataId;
    this.name = this.props.name;
    this.steps = this.props.steps;
  }

  public componentDidMount(): void {
    this.dataMonitorDisposer = autorun(this.dataMonitor);
  }

  public componentDidUpdate(): void {
    this.syncDerivedWithProps();
  }

  public componentWillUnmount(): void {
    WizardControl.instance = null;

    for (const retrievePromise of this.retrieveDataPromises) {
      retrievePromise.abort();
    }

    this.dataMonitorDisposer();

    LayoutStateStore.setSelectedStep(null);
  }

  public render(): React.ReactNode {
    if (!this.state.selectedPaneUseKey || this.props.steps.length <= 0) {
      return null;
    }

    if (WizardControl.widgetProperties.accessLevel <= AccessLevel.disabled) {
      return null;
    }

    const selectedStep: StepProps = this.props.steps.find(
      (s) => s.paneUseKey === this.state.selectedPaneUseKey
    )!;

    return (
      <ErrorBoundary title={this.props.name}>
        <WizardStepPane {...selectedStep} />
      </ErrorBoundary>
    );
  }
}

export default observer(WizardControl);
